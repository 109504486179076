import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Customer } from '../models/auth';
import { Contact } from '../models/order-management';
import { CmxHttpClient } from '../services/api.service';

// INTERFACES
@Injectable()
export class CustomersService {
  constructor(private http: CmxHttpClient) {}

  getCustomers(): Observable<Customer[]> {
    return this.http.get<Customer[]>(`/v4/secm/customers`);
  }

  getCustomer(customerId: string): Observable<Customer> {
    return this.http.get<Customer>(`/v4/secm/customers/${customerId}`);
  }

  getCustomerContacts(customerId: string): Observable<Contact[]> {
    return this.http.get<Contact[]>(`/v4/secm/customers/${customerId}/contacts`);
  }
}
