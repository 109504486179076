import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CmxHttpClient } from '../services/api.service';
import { createHttpParams } from '../shared/helpers';

// INTERFACES
import { IJobsiteCommercialChannelResponse } from '../models/jobsite-commercial-channel/get-jobsite-commercial-channel-response';
import { IJobsiteCommercialChannelRequest } from '../models/jobsite-commercial-channel/jobsite-commercial-channel-request';

@Injectable()
export class JobsiteCommercialChannelService {
  constructor(private http: CmxHttpClient) {}

  getJobsiteByCommercialChannelCode(
    request: IJobsiteCommercialChannelRequest,
  ): Observable<IJobsiteCommercialChannelResponse> {
    const httpParams = request ? createHttpParams(request) : new HttpParams();
    return this.http.get<IJobsiteCommercialChannelResponse>(`/v6/sm/projects`, {
      params: httpParams,
    });
  }

  getJobsiteByCommercialChannelCodeV7(
    request: IJobsiteCommercialChannelRequest,
  ): Observable<IJobsiteCommercialChannelResponse> {
    const httpParams = request ? createHttpParams(request) : new HttpParams();
    return this.http.get<IJobsiteCommercialChannelResponse>(`/v7/sm/projects`, {
      params: httpParams,
    });
  }
}
