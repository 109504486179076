export enum DefaultLanguage {
  // tslint:disable-next-line:max-line-length
  'views.login.termsIssue' = 'An error has occurred. Please try to log in again. If the problem persist, please contact your Digital Admin.',
  'views.login.popupBlock' = 'Please enable pop-up windows for this site in your browser',
  'view.login.lblWarnMessageTitle' = 'Planned Maintenance',
  'view.login.lblWarnMessage' = '',
  'views.login.alert.sessionExpireTitle' = 'Session Expired',
  'views.login.alert.sessionExpireMsg' = 'Session Expired: Please login again.',
  'views.login.title' = 'Welcome',
  'views.login.subTitle' = 'Log in to CEMEX Go.',
  'views.login.productBrand' = 'CEMEX Go',
  'views.login.productHeadingMain' = 'Innovating the way you build',
  'views.login.productHeadingSecondary' = 'With CEMEX Go you will be able to:',
  'views.login.productAdvantage1' = 'Explore our product portfolio and place your order instantly.',
  'views.login.productAdvantage2' = 'Optimize, track and manage every step of your operation from purchase to delivery.',
  'views.login.productAdvantage3' = 'Gain control of your invoices and purchases in real-time from any device.',
  'views.login.languageSelect' = 'Select Language',
  'views.login.credit_request_label_header_1' = 'Not a CEMEX customer?',
  'views.login.credit_request_options_label_2' = 'We have options for you too.',
  'views.login.credit_request_options_label_5' = 'Apply for credit with CEMEX',
  'views.login.credit_request_options_label_3' = 'Request a CEMEX credit line',
  'views.login.credit_request_options_label_4' = '',
  'views.login.companyBrand' = 'CEMEX',
  'views.login.username' = 'Username',
  'views.login.usernamePlaceholder' = 'Enter your username',
  'views.login.password' = 'Password',
  'views.login.passwordPlaceholder' = 'Enter your password',
  'views.login.forgot_password' = 'Forgot password?',
  //'views.login.btn_login' = 'Log in',
  'views.login.loginError4XX' = 'The user or password you entered are incorrect. Please try again.',
  'views.login.loginError5XX' = 'CEMEX GO is currently unavailable in your country due to maintenance.' +
    'Please try again later.',
  'views.login.loginErrorSystem' = 'An error has occurred while trying to log in. Please try again.',
  'views.login.btn_login' = 'Login',
  //'views.login.productBrand' = 'CEMEX Go',
  'views.resetPassword.title' = 'Change your password',
  'views.resetPassword.instructions' = 'Please enter your new password and confirm by entering it again.',
  'views.resetPassword.firstTimeLogIn' = 'This is your first time logging in. Please reset your temporary password to continue.',
  'views.resetPassword.welcomeTitle' = 'Welcome',
  'views.resetPassword.EmailNotDelivered' = 'Password changed successfully but there was an error delivering the confirmation email.',
  'views.resetPassword.tokenInvalid' = 'Request has expired, please request a new password change',
  'views.resetPassword.tokenExceed' = 'You have Exceeded the number of attempts allowed for your reset password request, the max is 3.',
  'views.login.403Error' = 'CEMEX GO is currently unavailable in your country due to maintenance. Please try again later.',
  'views.login.error' = 'The user or password you entered are incorrect. Please try again.',
  'views.selfResetPassword.lblInvalidPassword' = 'Invalid Password',
  'views.resetPassword.unauthorizeError' = 'Unauthorized',
  'views.resetPassword.lblNewPassword' = 'New Password',
  'views.resetPassword.lblEnterNewPassword' = 'Enter new password',
  'views.userManagement.form.lblInvalidFormat' = 'Invalid format',
  'views.userManagement.form.lblInvalidCharacter' = 'characters  and + are not allowed',
  'views.resetPassword.lblConfirmPassword' = 'Confirm password',
  'views.resetPassword.error' = 'Passwords do not match.',
  'views.signup.btnSave' = 'Save',
  'views.resetPassword.success' = 'Your password has been successfully changed, please go to Log in and enter your new password.',
}
