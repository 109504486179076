import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CmxTabComponent } from './components/cmx-tab/cmx-tab.component';
import { CmxTabsComponent } from './components/cmx-tabs/cmx-tabs.component';

@NgModule({
    declarations: [
        CmxTabsComponent,
        CmxTabComponent,
    ],
    exports: [
        CmxTabsComponent,
        CmxTabComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
    ],
})
export class CmxTabsModule { }
