import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CmxHttpClient } from '../services/api.service';
import { ContractBalanceResp } from '../models/contract-balance/contract-balance.interface';

@Injectable()
export class ContractBalanceService {
  constructor(private http: CmxHttpClient) {}

  getBalance(agreementItemId: number): Observable<ContractBalanceResp> {
    return this.http.get(`/v2/qm/agreementitems/${agreementItemId}/balance`);
  }
}
