import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CmxHttpClient } from '../services/api.service';

// INTERFACES
import { UpdatePatchResponse } from '../models/common';
import { OrderItem, OrderItemComment, OrderItemLog } from '../models/order-items';
import { CounterOffer } from '../models/order-items/counter-offer';

@Injectable()
export class OrderItemsService {
  constructor(private http: CmxHttpClient) {}

  getOrderItem(orderItemId: string): Observable<OrderItem> {
    return this.http.get<OrderItem>(`/v4/sm/orderrequestitems/${orderItemId}`);
  }

  createOrderItem(orderItem: OrderItem): Observable<UpdatePatchResponse> {
    return this.http.post<UpdatePatchResponse>(`/v4/sm/orderrequestitems`, orderItem);
  }

  updateOrderItem(orderItemId: string, orderItem: OrderItem): Observable<UpdatePatchResponse> {
    return this.http.put<UpdatePatchResponse>(`/v4/sm/orderrequestitems/${orderItemId}`, orderItem);
  }

  patchOrderItem(orderItemId: string, orderItem: OrderItem): Observable<UpdatePatchResponse> {
    return this.http.patch<UpdatePatchResponse>(`/v4/sm/orderrequestitems/${orderItemId}`, orderItem);
  }

  // tslint:disable-next-line:no-any
  removeOrderItem(orderItemId: string): Observable<any> {
    // tslint:disable-next-line:no-any
    return this.http.delete<any>(
      `/v4/sm/orders/${orderItemId}
            `,
    );
  }

  getOrderItemComments(orderItemId: string): Observable<OrderItemComment[]> {
    return this.http.get<OrderItemComment[]>(`/v4/sm/orderrequestitems/${orderItemId}/comments`);
  }

  createOrderItemComment(orderItemId: string, comment: Comment): Observable<OrderItemComment> {
    return this.http.post<OrderItemComment>(`/v4/sm/orderrequestitems/${orderItemId}/comments`, comment);
  }

  getOrderItemLogs(orderItemId: string): Observable<OrderItemLog[]> {
    return this.http.get<OrderItemLog[]>(`/v4/sm/orderrequestitems/${orderItemId}/logs`);
  }

  patchCounterOffer(orderItemId: number, counterOffers: CounterOffer[]) {
    return this.http.patch(`/v6/sm/counteroffers/OQI/${orderItemId}`, {
      counterOffers: counterOffers,
    });
  }
}
