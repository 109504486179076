export class Day {
    public number: number;
    public date: Date;
    public disabled: boolean = false;
    public range: boolean = false;
    public selected: boolean = false;

    constructor(_date: Date, _number: number) {
        this.number = _number;
        this.date = _date;
    }
}
