import { Pipe, PipeTransform } from '@angular/core';

import {
  FormatterService,
  DateTimeActions
} from './../services/formatter.service';
import { TranslationService } from './../services/translation.service';

@Pipe({
  name: 'cmxDateMoment',
  pure: false
})
export class CmxDateMomentPipe implements PipeTransform {
  constructor(
    private translationService: TranslationService,
    private formatterService: FormatterService
  ) {}

  public transform(
    dateTime,
    action: DateTimeActions = DateTimeActions.ConvertToLocal
  ): string {
    return this.formatterService.formatDateTimeMoment(
      dateTime,
      this.translationService.selectedLanguage.languageISO.replace('_', '-'),
      this.translationService.selectedLanguage.formatDate,
      action
    );
  }
}
