import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CmxTagsComponent } from "./components/tags.component";

@NgModule({
  declarations: [
    CmxTagsComponent,
  ],
  exports: [
    CmxTagsComponent,
  ],
  imports: [ CommonModule ],
  providers: [],
})
export class CmxTagsModule {
}
