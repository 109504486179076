import {
  Component,
  ElementRef,
  ViewChild,
  AfterViewChecked,
} from '@angular/core';
import { StoreService } from './services/store.service';
import { SessionService } from '../../../../../../angular-services-v8/session.service';
import { TranslationService } from '../../../../../../angular-localization-v7/services/translation.service';
import { ILegalEntity } from '../../../../../../angular-types-v2/index.interface';

declare global {
  // tslint:disable-next-line:interface-name
  interface Window {
    chatWidget: any;
  }
}

export interface IAuth {
  access_token?: string | null;
  jwt?: string | null;
  customer_id?: number | null;
  customer_code?: string | null;
  country_code?: string | null;
  user_id?: string | null;
  user_timezone?: string | null;
}

export interface ILanguageSelect {
  languageName: string;
  languageISO: string;
  isSelected: boolean;
}

@Component({
  selector: 'cmx-wizeline-chatbot',
  styleUrls: ['wizeline-chatbot.component.scss'],
  templateUrl: 'wizeline-chatbot.component.html',
})
export class WizelineChatbotComponent implements AfterViewChecked {
  @ViewChild('botWindow') public botWindowElement: ElementRef;
  private globalCustomerCode: string | any;
  private widgetHasInit: boolean;

  constructor(
    private store: StoreService,
    private sessionService: SessionService,
    private translationService: TranslationService
  ) {
    this.widgetHasInit = false;
    // subscribe to required services
    this.sessionService.currentLegalEntity.subscribe(
      (legalEntity: ILegalEntity) => {
        if (legalEntity !== null) {
          this.onDetectChangesSessionStore();
          if (!this.globalCustomerCode) {
            const newChatbotAuth: any = this.getChatbotAuth();
            this.setCredentials(newChatbotAuth);
            this.globalCustomerCode = this.store.customerCode;
          }
        }
      }
    );
    this.translationService.getSelectedLanguage().subscribe((language: any) => {
      this.onDetectChangesLanguage(language);
    });
  }

  public ngAfterViewChecked(): void {
    if (!this.widgetHasInit) {
      this.initChatbot();
    }
  }

  public initChatbot(): void {
    try {
      this.fireEvent('MountEvent', window);
      /* Change to actual department name
       * This may be the country code in local storage
       * For testing purposes its set to Support English
       */
      // handle account keys in CDN
      // window.chatWidget.setDepartment([GET COUNTRY HERE]);
      this.widgetHasInit = true;
    } catch (err) {
      // log errors if necesary
      // console.log('ERROR LOADING', err);
    }
  }

  public onDetectChangesSessionStore(): void {
    const changedCustomerCode: any =
      this.globalCustomerCode !== this.store.customerCode;
    const newChatbotAuth: any = this.getChatbotAuth();
    if (changedCustomerCode) {
      const justLoggedIn: any = !this.globalCustomerCode;
      if (!justLoggedIn) {
        this.updateLegalEntity(this.store.customerCode, newChatbotAuth);
      } else {
        this.setCredentials(newChatbotAuth);
        this.onDetectChangesLanguage(this.translationService.selectedLanguage);
      }
      this.globalCustomerCode = changedCustomerCode;
    }
  }

  public onDetectChangesLanguage(lang: ILanguageSelect): void {
    if (window.chatWidget) {
      const currentLanguage: any = lang.languageISO.substring(0, 2);
      const positionOfUnderscore: number = lang.languageISO.indexOf('_') + 1;
      const currentCountry: any =
        lang.languageISO.substring(positionOfUnderscore);
      window.chatWidget.setDepartment(currentCountry);
      if (currentLanguage === 'es') {
        window.chatWidget.setLang('es');
      } else {
        window.chatWidget.setLang('en');
      }
    }
  }

  private getChatbotAuth(): object {
    const auth: IAuth = {
      access_token: undefined,
      country_code: undefined,
      customer_code: undefined,
      customer_id: undefined,
      jwt: undefined,
      user_id: undefined,
      user_timezone: undefined,
    };
    const userLegalEntity: any | null =
      sessionStorage.getItem('user_legal_entity');
    const userProfile: any | null = this.sessionService.profile;
    let legalEntityId: any;
    let userId: any;
    if (userLegalEntity) {
      legalEntityId = JSON.parse(userLegalEntity).legalEntityId;
    }
    if (userProfile) {
      userId = JSON.parse(userProfile).userId;
    }
    const timezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (
      this.store.isUserLogged() &&
      this.store.accessToken &&
      this.store.jwt &&
      this.store.customerId &&
      this.store.countryCode
    ) {
      auth.access_token = this.store.accessToken;
      auth.jwt = this.store.jwt;
      if (userLegalEntity) {
        auth.customer_id = legalEntityId;
      } else {
        auth.customer_id = this.store.customerId;
      }
      auth.customer_code = this.store.customerCode;
      auth.country_code = this.store.countryCode;
      if (userId) {
        auth.user_id = userId;
      }
      auth.user_timezone = timezone;
    }
    return auth;
  }

  private setCredentials(auth: any): void {
    const newAuth: any = {
      data: auth,
      integration: 'cemex',
    };
    if (auth !== ' ' && window.chatWidget) {
      if (!this.widgetHasInit) {
        this.initChatbot();
      }
      window.chatWidget.setCredentialsAuth(newAuth);
    }
  }

  private updateLegalEntity(
    customerCode: string | null,
    newChatbotAuth: any
  ): void {
    if (window.chatWidget) {
      const userLegalEntity: any | null =
        sessionStorage.getItem('user_legal_entity');
      const legalEntityId: any = JSON.parse(userLegalEntity).legalEntityId;
      newChatbotAuth.customer_code = customerCode;
      newChatbotAuth.customer_id = legalEntityId;

      const newAuth: any = {
        data: newChatbotAuth,
        integration: 'cemex',
      };
      window.chatWidget.sendLegalEntityCode(newAuth);
    }
  }

  private fireEvent(name: string, target: any): void {
    target.dispatchEvent(new Event(name, { bubbles: true, cancelable: true }));
  }
}
