import { RouterModule, Routes } from '@angular/router';
import { ClickOutsideDirectiveModule } from './../../directives/click-outside.directive.module';
import { CmxWebComponentsModule } from '@cmx-web-components/angular';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { PipesModule } from '../../pipes/pipes.module';
import { ReactiveFormsModule } from '@angular/forms';
import { UserNotificationsComponent } from './user-notifications.component';
import { CmxPaginationModule } from 'src/app/cmx-pagination/v7/projects/cmx-pagination-v7/src/lib/cmx-pagination.module';
import { CmxDatepickerModule } from 'src/app/cmx-datepicker/v4/src/cmx-datepicker.module';
import { CmxDialogModule } from 'src/app/cmx-dialog/v7/projects/cmx-dialog-v7/src/lib/cmx-dialog.module';
import { AlertModule } from 'src/app/cmx-alert/v4/src/alert.module';
import { UserNotificationsFilterGroupPipe } from './pipes/user-notifications-filter-group.pipe';
import { UserNotificationsGroupComponent } from './components/user-notifications-group.component/user-notifications-group.component';

export const ROUTES: Routes = [
  { path: '', component: UserNotificationsComponent },
];
@NgModule({
  imports: [
    RouterModule.forChild(ROUTES),
    CmxWebComponentsModule.forRoot(),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AlertModule,
    CmxDialogModule,
    CmxPaginationModule,
    CmxDialogModule,
    CmxDatepickerModule,
    PipesModule,
    ClickOutsideDirectiveModule,
  ],
  declarations: [
    UserNotificationsComponent,
    UserNotificationsGroupComponent,
    UserNotificationsFilterGroupPipe,
  ],
  providers: [
    {
      provide: 'CMX_DATEPICKER_TAGS',
      useValue: { cancel: 'Cancel', apply: 'Apply' },
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UserNotificationsModule {}
