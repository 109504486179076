import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';

@Injectable()
export class CmxFlugeeService {
  public languages: any = {
    en_US: 'ltr',
    es_MX: 'ltr',
    es_CO: 'ltr',
    en_GB: 'ltr',
    fr_FR: 'ltr',
    de_DE: 'ltr',
    he_IL: 'rtl',
    pl_PL: 'ltr',
  };

  public dirObservable: Subject<string> = new Subject();

  private _dir: string;

  // GET DIR VALUE
  public getDir() {
    return this._dir;
  }

  // SEND DIR VALUE
  public sendDir(value: string) {
    this._dir = value;
    this.dirObservable.next(this.languages[value]);
  }
}
