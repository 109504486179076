export class Filter {
    public name: string;
    public value: any;
    public active: boolean;
    public columnKey: any;
    public visible = true;

    constructor( _name: string, _value: any, _active: boolean, _columnKey: any ) {
        this.name = _name;
        this.value = _value;
        this.active = _active;
        this.columnKey = _columnKey;
    }
}
