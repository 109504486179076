<ng-container [ngSwitch]="action">
    <ng-container *ngSwitchCase="'monthView'">
        <month-selector
            [currentDate]="currentDate" [min]="min" [max]="max" (selectedMonth)="updateCurrentMonth($event)" (selectedYear)="updateCurrentYear($event)" (actionView)="updateActionView($event)">
        </month-selector>
    </ng-container>
    <ng-container *ngSwitchCase="'yearView'">
        <year-selector
            [currentDate]="currentDate" [min]="min" [max]="max" (selectedYear)="updateCurrentYear($event)" (actionView)="updateActionView($event)">
        </year-selector>
    </ng-container>
    <ng-container *ngSwitchCase="'calendarView'">
        <div class="cmx-datepicker" [dir]="rtl ? 'rtl': 'ltr'">
            <div class="cmx-datepicker__header">
                <button cmx-link-icon srText="Previous Month" [disabled]="!displayedDate.canMoveToPreviousMonth" (click)="moveMonth(-1)">
                    <span class="cmx-icon-straight-arrow-left" aria-hidden="true"></span>
                </button>
                <div class="cmx-datepicker__current-period">
                    <button cmx-link (click)="monthToggle()">
                        {{ displayedDate.monthName }}
                    </button>
                    <button cmx-link (click)="yearToggle()">
                        {{ displayedDate.fullYear }}
                    </button>
                </div>
                <button cmx-link-icon srText="Next Month" [disabled]="!displayedDate.canMoveToNextMonth" (click)="moveMonth(1)">
                    <span class="cmx-icon-straight-arrow-right" aria-hidden="true"></span>
                </button>
            </div>
            <!-- end .datepicker-header -->
            <div class="cmx-datepicker__body">
                <table class="cmx-datepicker__days-grid">
                    <thead>
                        <tr class="cmx-datepicker__days-grid-row">
                            <ng-container *ngFor="let day of weekdays">
                                <td class="cmx-datepicker__days-grid-cell">
                                    {{ day }}
                                </td>
                            </ng-container>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let week of weeks">
                            <tr class="cmx-datepicker__days-grid-row">
                                <ng-container *ngFor="let day of week">
                                    <ng-container *ngIf="day !== undefined; else ghostCell">
                                        <td class="cmx-datepicker__days-grid-cell"
                                        [class.cmx-in-range]="day.range"
                                        [class.cmx-active-start]="day.selected === true && range.min && day.number === range.min.getDate()
                                        && day.date.getMonth() === range.min.getMonth() && numberOfClicks === 0 && range.min.getTime() !== range.max.getTime() && singleDateSelection === false"
                                        [class.first]="day.selected === true && range.min && day.number === range.min.getDate()
                                        && day.date.getMonth() === range.min.getMonth() && numberOfClicks === 0 && range.min.getTime() !== range.max.getTime() && singleDateSelection === false"
                                        [class.cmx-active-end]="day.selected === true && range.max && day.number === range.max.getDate()
                                        && day.date.getMonth() === range.max.getMonth() && numberOfClicks === 0 && range.min.getTime() !== range.max.getTime() && singleDateSelection === false"
                                        [class.last]="day.selected === true && range.max && day.number === range.max.getDate()
                                        && day.date.getMonth() === range.max.getMonth() && numberOfClicks === 0 && range.min.getTime() !== range.max.getTime() && singleDateSelection === false">
                                            <button class="cmx-datepicker__day"
                                            (click)="selectDate(day)" [class.disabled]="day.disabled" [class.active]="day.selected"
                                            [class.today]="day.date.getMonth() === today.getMonth() && day.date.getDate() === today.getDate() && day.date.getFullYear() === today.getFullYear()">
                                                {{ day.number }}
                                                <div *ngIf="getDateLabel(day.date)" class="cmx-datepicker__day--label" [ngStyle]="{'background': getDateLabel(day.date).color}"></div>
                                            </button>
                                        </td>
                                    </ng-container>
                                    <ng-template #ghostCell>
                                        <td class="cmx-datepicker__days-grid-cell"></td>
                                    </ng-template>
                                </ng-container>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
            <div *ngIf="labels && labels.length" class="cmx-datepicker__labels">
              <div class="cmx-datepicker__labels--container">
                <div *ngFor="let label of labels">
                    <span class="cmx-datepicker__labels__color-block" [ngStyle]="{'background': label.cssColor}"></span>
                    <span class="cmx-datepicker__labels__item">{{ label.name }}</span>
                </div>
              </div>
            </div>
            <!-- end .cmx-datepicker__body -->
            <div class="cmx-datepicker__footer">
                <div class="cmx-datepicker__cta" *ngIf="!buttonless">
                    <button cmx-link class="cmx-cancel-button" data-tid="datepicker-cancel-button" (click)="cancelSelection()">
                        {{ tags.cancel }}
                    </button>
                    <button cmx-button class="cmx-apply-button" data-tid="datepicker-apply-button" [disabled]="!userSelectedSomething()" (click)="applyClicked()">
                        {{ tags.apply }}
                    </button>
                </div>
                <!-- end cmx-datepicker__cta -->
            </div>
            <!-- end .cmx-datepicker__footer -->
        </div>
    </ng-container>
</ng-container>
