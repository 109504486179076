import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TimeWindowsReq, TimeWindowsRes } from '../models/time-windows';
import { CmxHttpClient } from '../services/api.service';
import { createHttpParams } from '../shared/helpers';

@Injectable()
export class TimeWindowsService {
  constructor(private http: CmxHttpClient) {}

  getTimeWindows(request: TimeWindowsReq): Observable<TimeWindowsRes> {
    const httpParams = request ? createHttpParams(request) : new HttpParams();

    return this.http.get<TimeWindowsRes>(`/v6/sm/timewindows`, {
      params: httpParams,
    });
  }
}
