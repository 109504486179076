import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { CwcPicker } from '@cmx-web-components/angular';
import { BehaviorSubject, Subscription } from 'rxjs';
import { LoadingStatus } from '../cmx-dashboard-active-loads-card/cmx-dashboard-active-loads-card.service';
import { ListChartCardService } from './list-chart-card.service';
import { Status } from './types';
import { RecentOrder } from '../shared/types'
import { CountlyEvents } from '../countly-events.service';

@Component({
  selector: 'list-chart-card',
  templateUrl: './list-chart-card.component.html',
  styleUrls: ['./list-chart-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListChartCardComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('dateRangePicker') datePicker: CwcPicker;

  @Input()
  /**
   * @var queryOptions this options goes straight into fetching routine
   * it's assumed, that falsy value will prevent component from firing API calls
   */
  queryOptions: Record<string, string | number>;

  @Input()
  title: string;
  @Input()
  dateRanges?: Array<{
    label: string;
    from: string; // ISO String
    to: string;
  }>;

  @Input()
  /**
   * @var statusesList data to show when no API call is intended
   */
  statusesList: Status[];
  @Input()
  /**
   * @var chartTotalLabel label to show inside the pie chart indicating the total amount
   */
  chartTotalLabel = 'Total';
  @Input()
  /**
   * @var statusListItemOrdersLabel label to show after the amount of status element
   */
  statusListItemOrdersLabel: string;
  /**
   * @var emptyListText label to show in the empty result message
   */
  @Input()
  emptyListText;

  /**
   * @var datePlaceholder placeholder for Date range selection
   */
  @Input()
  datePlaceholder: string;

  /**
   * @var renderRecentOrders render recent orders for extended version
   */
  @Input()
  renderRecentOrders: boolean;

  /**
   * @var recentOrdersLabels labels for extended version {title, viewAll}
   */
  @Input()
  recentOrdersLabels: {
    title: string,
    viewAll: string,
    order: string,
  };

  /**
  * @var renderTotals render also totals for financial statuses 
  */
  @Input()
  renderTotals: boolean;

  /**
   * @var buttonLable label for button which links user to Payment documents
   */
  @Input()
  buttonLabel: string;

  /**
   * @var pickerLabelCancel label for cancel button in picker for mobile view
   */
  @Input()
  pickerLabelCancel: string;

  @Input()
  emptyButtonLabel: string;

  @ViewChild('statusCell') statusCell!: any;
  @ViewChild('statusUl') statusUl!: any;

  chartData$ = new BehaviorSubject<Array<{ name: string; value: number }>>([]);
  chartColor$ = new BehaviorSubject<string[]>([]);
  recentOrdersData$ = new BehaviorSubject<Array<RecentOrder>>([]);
  LoadingStatus = LoadingStatus;
  hasData = true;
  changeEmitter$ = new BehaviorSubject<boolean>(this.hasData);
  activeOrderIndex = 0;
  enableInsetShadowList = false;
  scrolledToBottom = false;
  scrolletToTop = true;

  // counter for date range change to help prevent emitting analytics events on initial page loading
  private counterForDateRange: number = 0;

  /**
   * returns async source for status of component
   */
  public get loadingStatus$() {
    return this.service.loadingStatus$;
  }

  subscriptions: Subscription[] = [];

  public get statuses$() {
    return this.service.statuses$;
  }

  public get recentOrders$() {
    return this.service.recentOrders$;
  }

  constructor(
    private service: ListChartCardService,
    public router: Router,
    private countlyEventsService: CountlyEvents,
    private ref: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.subscriptions.push(
      this.service.statuses$.subscribe(value => {
        if (value && value.length != 0) {
          const result = value.every(element => {
            if (element.value === 0) {
              return true;
            } else {
              return false;
            }
          });
          this.hasData = !result
          this.changeEmitter$.next(this.hasData)
          this.setChartData(value);
          setTimeout(() => {
            if (this.hasData) {
              this.scrolledToBottom = false;
              this.scrolletToTop = true;
              this.enableScrollableCard();
            }
          }, 300);
        } else {
          this.hasData = false
          this.changeEmitter$.next(this.hasData)
        }
      }),
      this.service.recentOrders$.subscribe(value => {
        if (value && value.length !== 0) {
          this.recentOrdersData$.next(value)
        }
      })
    );

    if (this.queryOptions) {
      this.fetchData();
    } else {
      this.service.initStatusesData(this.statusesList);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.enableScrollableCard();
  }

  enableScrollableCard() {
    if (this.statusUl && this.statusCell) {
      const listStatusHeight = this.statusUl.nativeElement.clientHeight;
      const statusContentHeight = this.statusCell.el.clientHeight;
      this.enableInsetShadowList = listStatusHeight > statusContentHeight;
      this.ref.detectChanges();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('queryOptions' in changes) {
      const {
        queryOptions: { currentValue: queryOptions }
      } = changes;

      if (queryOptions) {
        this.fetchData(queryOptions);
        if (this.dateRanges && this.datePicker) {
          this.counterForDateRange = 0;
          this.datePicker.value = this.dateRanges[0]
        }
      }
    }

    if ('statusesList' in changes) {
      const {
        statusesList: { currentValue: statusesList }
      } = changes;
      if (statusesList) {
        this.service.initStatusesData(this.statusesList)
      }
    }
  }

  private fetchData(queryOptions: ListChartCardComponent['queryOptions'] = {}) {
    let from, to;
    if (this.dateRanges && this.dateRanges.length) {
      from = this.dateRanges[0].from;
      to = this.dateRanges[0].to;
    }
    this.service.fetchNewQuery(
      this.getRequestOptions({
        ...queryOptions,
        from,
        to
      })
    );
  }

  private getRequestOptions(rewriteOptions = {}) {
    return {
      ...this.queryOptions,
      ...rewriteOptions
    };
  }

  private setChartData(statuses: Status[]) {
    const transformedArray = statuses.reduce((accumulator, item) => {
      const existingItem = accumulator.find(i => i.chartColor === item.chartColor);
      if (existingItem) {
        existingItem.value += item.value;
      } else {
        accumulator.push({ chartColor: item.chartColor, value: item.value });
      }
      return accumulator;
    }, []);

    const chartData = transformedArray.map(status => ({
      name: status.name,
      value: status.value
    }));
    const chartColors = transformedArray.map(status => this.getChartColor(status));

    this.chartData$.next(chartData);
    this.chartColor$.next(chartColors);
  }

  private handleNewDateRange(range: { from: string; to: string }) {
    if (range && this.queryOptions) {
      this.service.fetchNewQuery(
        this.getRequestOptions({
          from: range.from,
          to: range.to
        })
      );
    }
  }

  onDateRangeChange(option: { label: string; from: string; to: string, id: string }) {
    this.handleNewDateRange(option);
    // creating event for analytics, counter could be removed when cwc version gets to 0.9.61, after that we can use cwcUserChange
    if (this.counterForDateRange > 0) {
      this.countlyEventsService.onChangeForAnalytics('order_history_date_range', option.id)
    }
    this.counterForDateRange++
  }

  public getStatusVariant(status: Status) {
    return status.color;
  }

  public getChartColor(status) {
    return status.chartColor;
  }

  public changeOrder(dir) {
    this.activeOrderIndex = this.activeOrderIndex + (dir ? 1 : -1)
  }

  public navigateToOrderDetail() {
    // parameters for order detail page
    const queryParams = this.recentOrdersData$.getValue()[this.activeOrderIndex].params;
    // string created out of parameters for url
    const queryString = Object.keys(queryParams).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
    .join('&');
    
    const fullPath = window.location.origin + '/ordersnproduct/app/orders-products-catalog/order-detail?' + queryString;
    window.location.href = fullPath;
  }

  trackScroll(event) {
    const scrollTop = event.srcElement.scrollTop;
    const maxScroll = event.srcElement.scrollHeight - event.srcElement.offsetHeight;
    const scrollTracked = scrollTop / maxScroll;
    this.scrolledToBottom = scrollTracked >= 0.9;
    this.scrolletToTop = scrollTracked === 0;
  }

  ngOnDestroy(): void {
    this.chartColor$.unsubscribe();
    this.chartData$.unsubscribe();
    this.subscriptions.forEach(s => s.unsubscribe);
  }
}
