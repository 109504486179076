import { Component, ElementRef, ViewChild, OnInit, OnDestroy, ChangeDetectionStrategy, Renderer2 } from '@angular/core';
import { SessionService } from '../../../../../../angular-services-v7/session.service';
import { TranslationService } from '../../../../../../angular-localization-v7/services/translation.service';
import { ScriptStore } from './store/script.store';
import { language } from '../../../../../../app.module';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

declare global {
  interface Window {
    $aivo: any;
    aivoFn?: any;
  }
}
@Component({
  selector: 'cmx-chatbot',
  styleUrls: ['chatbot.component.scss'],
  templateUrl: 'chatbot.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ChatbotComponent implements OnInit, OnDestroy {
  @ViewChild('botWindow') public botWindowElement: ElementRef;
  @ViewChild('cbdock') public dock: ElementRef;
  @ViewChild('cbfloat') public floatContainer: ElementRef;
  public showDockButton = true;
  public en_script = false;
  public es_script = false;
  public token: string;
  private unsubscribe$ = new Subject<void>();


  constructor(
    public translationService: TranslationService,
    public sessionService: SessionService,
    private renderer: Renderer2
  ) {
    if (!window.aivoFn) {
      window.aivoFn = {};
    }
  }

  public ngOnInit(): void {
      this.getlanguageAndToken();
  }
  public getlanguageAndToken() {
    this.translationService.getSelectedLanguage()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((language) => {
        let isJwtPresent = !!sessionStorage.getItem('jwt');
        let script_type = isJwtPresent ? '_private' : '_public';
        let script_name = language.languageISO + script_type;

        let matchedScript = ScriptStore.find(script => script.name === script_name);
        this.token = matchedScript ? matchedScript.src : 'd308859c1615895b44f290c77619ce4e';
        this.loadOlivia(this.token, script_type, this.sessionService.userProfile);
        window.aivoFn.init();
      });
  }
  public loadOlivia(token, script_type, sessionService) {
    const country = this.getCurrentCountry();
    const languageISO = this.translationService.getLanguageByCountryCode(country).languageISO.substring(0, 2);
    if (languageISO === "es") {
      this.en_script = false;
      this.es_script = true;
    } else if (languageISO === "en" || languageISO === "" || languageISO === null || languageISO === undefined) {
      this.en_script = true;
      this.es_script = false;
    }
    window.aivoFn = {
      // Initialization: Executes on the initial page load
      init: function () {
        const defaultToken = token;
        this.checkAndInsertMainScript(defaultToken);
      },
      // Function that handles the full cycle: removing previous scripts, validating, and loading the new script
      checkAndInsertMainScript: function (token) {
        const src = 'https://cdn.agentbot.net/core/latest/core.js';
        // Remove all old scripts and the bot container
        this.removeAllAgentbotScripts();
        this.removeAgentAppContainer(); // Remove the bot container
        // Insert the new script and execute the bot
        this.insertScript(src, token);
      },
      // Function that executes when the language changes or during navigation
      onNavigationChange: function (token) {
        this.checkAndInsertMainScript(token);
      },
    
      // Function to insert a script into the DOM and ensure it runs correctly
      insertScript: function (src, token) {
        const script = document.createElement('script');
        script.type = 'application/javascript';
        script.src = src;
        script.async = true;
        // Add a listener to ensure the script runs when it's loaded
        script.onload = () => {
          this.runBot(token);
        };
        script.onerror = function () {
          console.error(`Error loading script ${src}`);
        };
        document.body.appendChild(script);
      },
      // Function that runs the bot after the script has loaded
      runBot: function (token) {
        // Wait until $aivo is ready before running the bot
        const waitForAivo = setInterval(() => {
          if (!window.$aivo) {
            clearInterval(waitForAivo);
            window.aivoFn.init();
            return;
          }
          window.$aivo.status = undefined;
          if (window.$aivo && window.$aivo.run) {
            clearInterval(waitForAivo); // Stop waiting once $aivo is available
            window.$aivo.run(token); // Run the bot with the provided token
            // Open the chat window
            window.$aivo.ready(() => {
              if (script_type === '_private') {
                let name = sessionService.fullName;
                let email = sessionService.userAccount;
                let firstname = sessionService.firstName;
                let lastname = sessionService.lastName;
                let rfc = JSON.parse(String(sessionStorage.getItem('user_legal_entity'))).legalEntityDesc;
                // let jwt = this.sessionStorage.getItem('jwt');
                let phone = sessionService.phoneNumber;
    
                window.$aivo.user.set({
                  'countryName': country,
                  'jwt': 'true',
                  'fullName': name,
                  'userAccount': email,
                  'name': name,
                  'email': email,
                  'IsClosed': '',
                  'firstName': firstname,
                  'lastName': lastname,
                  'customField1': rfc,
                  'customField1Label': rfc,
                  'phoneNumber': phone
                });
              } else if (script_type === '_public') {
                window.$aivo.user.set({
                  'countryName': country,
                  'jwt': '',
                  'fullName': '',
                  'userAccount': '',
                  'name': '',
                  'email': '',
                  'IsClosed': '',
                  'firstName': '',
                  'lastName': '',
                  'customField1': '',
                  'customField1Label': 'Cemex',
                  'phoneNumber': ''
                });
              }
              // window.$aivo.user.set({ name: 'test' });
            });
          }
        }, 500); // Check every 500ms if $aivo is ready
      },
      // Function to remove all scripts related to "agentbot"
      removeAllAgentbotScripts: function () {
        const scripts = document.querySelectorAll('script[src*="agentbot"]');
        scripts.forEach((script) => {
          script.remove();
        });
      },
      // Function to remove the AgentBot container from the DOM
      removeAgentAppContainer: function () {
        const container = document.getElementById('AgentAppContainer');
        if (container) {
          container.remove(); // Remove the bot container
        } else {
        }
      },
      // Function to check if the main script is already loaded
      isScriptLoaded: function (src) {
        return !!document.querySelector(`script[src="${src}"]`);
      },
    };    

  }
  public ClickOnCloseButton() {
    if (document.getElementById('cbdock')) {
      document.getElementById('cbdock').classList.add('active');
    }
    if (document.getElementById('cbfloat')) {
      document.getElementById('cbfloat').classList.add('hidden');
    }
    if (document.getElementById('cbpopup')) {
      document.getElementById('cbpopup').classList.remove('hidden');
    }

    setTimeout(() => {
      if (document.getElementById('cbpopup')) {
        document.getElementById('cbpopup').classList.add('hidden');
      }
    }, 2000);
  }
  public ClickOnDockButton() {
    if (document.getElementById('cbdock')) {
      document.getElementById('cbdock').classList.remove('active');
    }
    if (document.getElementById('cbfloat')) {
      document.getElementById('cbfloat').classList.remove('hidden');
    }
    if (document.getElementById('cbpopup')) {
      document.getElementById('cbpopup').classList.add('hidden');
    }
  }
  public OpenChat() {
    window.$aivo.chat.open();
  }
  public getCurrentCountry() {
    let positionOfUnderscore: number;
    let currentCountry: string;
    if (sessionStorage.getItem('language') !== null) {
      positionOfUnderscore = sessionStorage.getItem('language').indexOf('_') + 1;
      currentCountry = sessionStorage.getItem('language').substring(positionOfUnderscore);
    } else if (localStorage.getItem('language') !== null) {
      positionOfUnderscore = localStorage.getItem('language').indexOf('_') + 1;
      currentCountry = localStorage.getItem('language').substring(positionOfUnderscore);
    } else if (window['LANGUAGE'] !== null) {
      positionOfUnderscore = window['LANGUAGE'].indexOf('_') + 1;
      currentCountry = window['LANGUAGE'].substring(positionOfUnderscore);
    }
    return currentCountry;
  }
  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
