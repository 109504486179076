import { Directive, ElementRef, HostListener } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Directive({
  /* tslint:disable-next-line:directive-selector */
  selector: 'cwc-ld-input',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CwcInputValueAccessorDirective,
      multi: true,
    },
  ],
})

export class CwcInputValueAccessorDirective implements ControlValueAccessor {
  protected lastValue: any;

  constructor(protected el: ElementRef) { }

  @HostListener('cwcBlur')
  public _handleBlurEvent() {
    this.onTouched();
  }

  @HostListener('cwcChange', ['$event.detail'])
  public _handleCwcChange(value: any) {
    this.handleChangeEvent(value);
  }

  public writeValue(value: any) {
    this.el.nativeElement.value = this.lastValue = value == null ? '' : value;
  }

  public handleChangeEvent(value: any) {
    if (value !== this.lastValue) {
      this.lastValue = value;
      this.onChange(value);
    }
  }

  public registerOnChange(fn: (value: any) => void) {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean) {
    this.el.nativeElement.disabled = isDisabled;
  }

  private onChange: (value: any) => void = () => {
    /**/
  }

  private onTouched: () => void = () => {
    /**/
  }
}
