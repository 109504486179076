import { isPlatformBrowser } from '@angular/common';
import { Inject, Pipe, PLATFORM_ID, PipeTransform } from '@angular/core';

import { ILanguage } from './../interfaces/language.interface';
import { FormatterService } from './../services/formatter.service';
import { TranslationService } from './../services/translation.service';
import { SessionService } from 'src/app/angular-services-v8/session.service';

@Pipe({
  name: 'cmxCurrency',
  pure: true
})
export class CmxCurrencyPipe implements PipeTransform {
  // FIXME: I should be injecting the window object but that will break the implementation. I will not do that just yet.
  // Making this public so I can access it in the test
  public window: any;

  constructor(
    private formatter: FormatterService,
    private translation: TranslationService,
    private sessionService: SessionService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    if (isPlatformBrowser(platformId)) {
      this.window = window;
    }
  }

  public transform(value: any, countryCode?: string): string {
    if (value === '' || value == null || isNaN(value as any)) {
      console.warn('The value is not a number');
      return '';
    }

    countryCode = countryCode || this.sessionService.country;;

    // tslint:disable-next-line:triple-equals
    if (countryCode == undefined) {
      console.warn(`Can't retrieve countryCode`);
      return '';
    }

    const lang: ILanguage = this.translation.getLanguageByCountryCode(
      countryCode
    );

    const symbolPosition =
      (lang.currencySymbolFloat && lang.currencySymbolFloat.toLowerCase()) ||
      'left';
    const currencySymbol = lang.currencySymbol || '$';

    const formattedNumber: string = this.formatter.formatNumber(
      value,
      lang && lang.decimalNumbersMoney,
      lang && lang.thousandSeparator,
      lang && lang.decimalSeparator
    );

    const numberWithCurrency =
      symbolPosition === 'left'
        ? `${currencySymbol}${formattedNumber}`
        : `${formattedNumber}${currencySymbol}`;

    return '&lrm;' + numberWithCurrency;
  }
}
