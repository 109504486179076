import { PipeTransform, Pipe } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

// tslint:disable-next-line: pipe-naming
@Pipe({ name: 'safe' })
// tslint:disable-next-line: class-name
export class safepipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  public transform(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
