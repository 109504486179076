import { Component, OnInit, HostListener, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription, interval, of } from 'rxjs';
import { SessionService } from '../../../app/angular-services-v8/session.service';
import { StorageService, LocationService, ExternalsystemsService, GoogleMapsService, PasswordService } from '../../../app/services/';
import { TranslationService } from '../../../app/angular-localization-v7/services/translation.service';
import { FeatureToggleService } from './../../feature-toggle-service/v1/src/feature-toggle.service';

import { DefaultLanguage } from '../../../app/models/login';
import { LangTools } from '../../../app/helpers/functions/lang.functions';

import { Language } from '../../../app/models';
import { AppFeatures } from '../../../toggleConfig';
import { LocalizationComponent } from './../../components/localization/localization.component';
import { filter, map, take } from 'rxjs/operators';

import { RecaptchaComponent } from 'ng-recaptcha';
import { DCMConstants } from '../../helpers/DCM.constants';
import { CountlyService } from 'src/app/helpers-v1';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TokenResponse } from 'src/app/cmx-login/v7/projects/cmx-login/src/lib/model/token-response';
import { CookiebotService } from 'src/app/cmx-cookiebot-v7/services/cmx-cookiebot.service';
declare const google;

@Component({
  selector: 'login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss'],
  providers: [GoogleMapsService],
})
export class LoginComponent
  extends LocalizationComponent
  implements OnInit, AfterViewInit
{
  @ViewChild(RecaptchaComponent)
  public recaptcha: RecaptchaComponent;
  public showCaptcha = false;
  public showReCaptcha = false;
  public key = DCMConstants.GOOGLE_RECAPTCHA_KEY;
  public submitted: boolean = false;
  public resetPassword = false;
  public touError;
  public popupBlock;
  public sessionExpired: any;
  public currentLang: Language;
  public showMaintenanceAlert = false;
  public redirectTo: string;
  public countryHasChatbot = false;
  public countryHasChatbotv2 = false;
  public countryHasLinkCreditRequest = false;
  public viewReady = false;
  public triggerResendPasswordAlert = false;
  public lang: any = {};
  public canDisplaySignUpCard = false;
  private defaultLang = window['LANGUAGE'];
  private envLanguages: Language[] = window['CMX_LANGUAGES'];
  private envEuHost = window['HOST_EUROPE'] || '';
  private envAmeHost = window['HOST_AMERICA'] || '';
  private envShowMaintenanceAlert = window['SHOW_MAINTENANCE_ALERT'] || 'false';
  private countriesToForceMaintenance: string =
    window['SHOW_FORCE_MAINTENANCE_ALERT'] || '';
  public creditRequestLink = window['URLNOCUSTOMERS'];
  private screenWidth: number;
  public minScreenWidth = false;
  public temporaryPasswordFlag = false;
  public errorModal: boolean = false;
  public errorMsg: string = '';
  private subscription: Array<Subscription> = [];
  public valueTimerBefore = '';
  public valueTimer = '';
  public timerToShowLabel : any;
  public timer: Subscription;
  public timerMinutes = '00';
  public timerSeconds = '00';
  private maxTimeToExpireCode = 10;
  public enableResendPasswordBtn = true;
  public showTimerText = false;
  public form: FormGroup;
  private externalAuth = new Subject();
  public isWarningResendPassword = false;

  private generateCookie = true;
  private grantType = 'password';
  private scope = 'security';
  private userInformation = 'false';
  private include = 'userinfo,profile,applications,roles,customers,oldVersion';
  private routeAfterLogin: 'dashboard' | 'resetPassword';
  public validateTemporaryPassword: boolean = true;
  public appName: string = 'DCM';
  private euHost = window['HOST_EUROPE'];
  private ameHost = window['HOST_AMERICA'];
  private euRegion = ['GB', 'UK'];
  private onServiceReadyCookiebot = false;

  constructor(
    public translationService: TranslationService,
    private formBuilder: FormBuilder,
    public langTools: LangTools,
    private activatedRoute: ActivatedRoute,
    private sessionService: SessionService,
    private route: ActivatedRoute,
    private featureToggleService: FeatureToggleService,
    private storageService: StorageService,
    private externalSystemsService: ExternalsystemsService,
    private locationService: LocationService,
    public router: Router,
    private googleMapsService: GoogleMapsService,
    private countlyService: CountlyService,
    private passwordService: PasswordService,
    private cookiebotService: CookiebotService,
  ) {
    super();
    this.showMaintenanceAlert = this.envShowMaintenanceAlert === 'true';
    this.touError = window.sessionStorage.getItem('touError');
    this.popupBlock = false;
    this.screenWidth = window.innerWidth;
    this.minScreenWidth = this.screenWidth <= 768;
    this.sessionService.clearCookie('cmxTmpSession');
    window.sessionStorage.removeItem('currentAlertEmail');
    window.sessionStorage.removeItem('touSigned');
    window.sessionStorage.removeItem('touError');
    window.sessionStorage.removeItem('userSegment');
    window.sessionStorage.removeItem('userSubsegment');
    window.sessionStorage.removeItem('notificationsFeature');
    window.sessionStorage.removeItem('defaultDateFormat');
    window.sessionStorage.removeItem('notification_settings');

    const temporalLanguage = sessionStorage.getItem('temporalLanguage');
    if(temporalLanguage){
      this.translationService.setLanguage(temporalLanguage);
      sessionStorage.removeItem('temporalLanguage');
    }

    this.currentLang = this.translationService.selectedLanguage;

    this.verificationOnForceMaintenanceAlert();
    this.activatedRoute.queryParams.subscribe((params) => {
      this.sessionExpired = params['sessionExpired'];
      this.clearExpirationParam();
    });

    // we set up the language at constructor
    this.lang = this.langTools.setUpLang(DefaultLanguage);

    GoogleMapsService.load({ language: this.currentLang.languageISO })
      .then((res) => {
        this.getPosition();
      })
      .catch((error) => console.log(error));

    this.form = this.formBuilder.group({
      temporaryPassword: [null, Validators.required],
    });
  }

  ngOnInit(): void {
    this.showCaptcha = false;
    this.showReCaptcha = false;
    this.redirectTo = this.route.snapshot.queryParams['returnUrl'] || '';
    this.checkIfCountryHasChatbot();
    this.checkIfCountryHasLinkCreditRequest();
    this.checkIfExternalIntegration();
    this.translationService.languageLoaded.subscribe(() => {
      // we set up language once again when changes from the translation service arrive.
      this.lang = this.langTools.setUpLang(DefaultLanguage);
    });

    // External authentication
    this.route.queryParams
      .pipe(filter((params: {}) => Object.keys(params).length > 0))
      .subscribe((params: { [x: string]: string }) => {
        const userAccount = params['userAccount'];
        const process = params['process'] === 'MPR';
        this.resetPassword = !!(userAccount && process);
        sessionStorage.setItem(userAccount ? 'userAccount' : null, userAccount);

        if (params['returnUrl']) {
          if (params['returnUrl'].indexOf('external=true') > -1) {
            // Is calling from external source (Console)
            // Listen for token and customer
            const afterAuthPage = window.location.origin + params['returnUrl'];
            this.externalAuth.pipe(take(1)).subscribe((auth: any) => {
              if (auth) {
                // Reload to go to return url
                parent.postMessage('authenticated', '*');
                window.location.href = afterAuthPage;
              }
            });
          }
        }
      });
    this.startCountly();
    this.countlyService.addTrackingPage('Login');
    this.enableResendPasswordBtn = true;
    this.resetPassword ? this.triggerResendPasswordAlert = false : null;
    this.cookiebotService.onServiceReady$.subscribe((response) => {
      this.onServiceReadyCookiebot = response;
    }, error => {
      this.onServiceReadyCookiebot = false;
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = event.target.innerWidth;
    this.minScreenWidth = this.screenWidth <= 768;
  }

  @HostListener('window:message', ['$event'])
  onMessage(e: { data: string }) {
    if (!this.sessionService.dataSession) {
      // Only activate when there is no token data
      try {
        if (e.data) {
          const obj = JSON.parse(e.data);
          if (obj.token) {
            // Is an auth token
            this.sessionService.updateDataSession(obj);            
            this.externalAuth.next(true);
          }
        }
      } catch (e) {}
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.viewReady = true;
    }, 200);
  }

  public ngOnDestroy(): void {
    this.subscription.forEach((s) => s.unsubscribe());
    this.countlyService.endSession();
  }

  changeLanguage(languageISO): void {
    this.translationService.setLanguage(languageISO);
    this.currentLang = this.translationService.selectedLanguage;
    localStorage.setItem('isChangeLanguage', 'true');
    this.verificationOnForceMaintenanceAlert(languageISO);
    this.checkIfCountryHasChatbot();
    this.checkIfCountryHasLinkCreditRequest();
  }

  private checkCookie(name: any) {
    const cookieName = name + '=';
    if (document.cookie.indexOf(cookieName) === 0) {
      return document.cookie.substring(
        cookieName.length,
        document.cookie.length
      );
    }
    return null;
  }

  private checkIfCountryHasChatbot() {
    let lang = this.currentLang.languageISO;
    if (!lang) {
      lang = navigator.language;
    }
    const country = lang.substring(3, 5);
    this.featureToggleService.isLoaded$.subscribe(() => {
      this.countryHasChatbot = this.featureToggleService.isEnabledFor(
        AppFeatures.UseChatbot,
        { country }
      );
    });
    this.featureToggleService.isLoaded$.subscribe(() => {
      this.countryHasChatbotv2 = this.featureToggleService.isEnabledFor(
        AppFeatures.UseChatbotv2,
        { country }
      );
    });
  }

  private checkIfCountryHasLinkCreditRequest() {
    let lang = this.currentLang.languageISO;
    if (!lang) {
      lang = navigator.language;
    }

    const country = lang.substring(3, 5);
    this.featureToggleService.isLoaded$.subscribe(() => {
      this.countryHasLinkCreditRequest = this.featureToggleService.isEnabledFor(
        AppFeatures.UseLinkCreditRequest,
        { country }
      );
    });
  }

  private checkIfDisplaySignUpCard(country: string, state: string) {
    this.featureToggleService.isLoaded$.subscribe(() => {
      this.canDisplaySignUpCard = this.featureToggleService.isEnabledFor(
        AppFeatures.CanDisplaySignUpCard,
        { country, state }
      );

      if (this.canDisplaySignUpCard) {
        let data = this.sessionService.dataSession
        data.country = country;
        this.sessionService.updateDataSession(data);
        const event = {
          key: 'ShowSingOnCard',
          Country: country,
        };
        this.countlyService.addTracking('add_event', event);
      }
    });
  }

  private verificationOnForceMaintenanceAlert(setLanguage: string = null) {
    if (this.showMaintenanceAlert === false) {
      const countriesToSplit: string[] =
        this.countriesToForceMaintenance.split(',');
      const fromSession: string =
        this.storageService.getFromSessionStorage('language');
      const fromStorage: string =
        this.storageService.getFromLocalStorage('language');
      if (
        countriesToSplit.indexOf(fromSession) !== -1 ||
        countriesToSplit.indexOf(fromStorage) !== -1 ||
        countriesToSplit.indexOf(setLanguage) !== -1
      ) {
        this.showMaintenanceAlert = true;
      } else {
        this.showMaintenanceAlert = false;
      }
    }
  }

  private clearExpirationParam(): void {
    const query = window.location.search.substring(1);
    if (query.length && query.indexOf('sessionExpired') > -1) {
      if (
        window.history !== undefined &&
        window.history.pushState !== undefined
      ) {
        window.history.pushState({}, document.title, window.location.pathname);
      }
    }
  }

  private checkIfExternalIntegration() {
    let externalIntegration =
      this.route.snapshot.queryParamMap.get('platform') || '';
    let externalUser = this.route.snapshot.queryParamMap.get('usr') || '';
    let companyID = this.route.snapshot.queryParamMap.get('companyId') || '';
    let projectID = this.route.snapshot.queryParamMap.get('projectId') || '';
    if (
      externalIntegration !== '' &&
      externalUser !== '' &&
      window.location !== window.parent.location
    ) {
      localStorage.setItem('platform', externalIntegration);
      localStorage.setItem('usr', externalUser);
      localStorage.setItem('companyId', companyID);
      localStorage.setItem('projectId', projectID);
    } else if (
      localStorage.getItem('platform') !== null &&
      localStorage.getItem('usr') !== null
    ) {
      if (window.location !== window.parent.location) {
        localStorage.removeItem('codeExt');
        externalIntegration = localStorage.getItem('platform');
        externalUser = localStorage.getItem('usr');
        companyID = localStorage.getItem('companyId');
        projectID = localStorage.getItem('projectId');
      } else {
        this.sessionService.clean();
        return;
      }
    } else {
      this.sessionService.clean();
      return;
    }

    (<HTMLInputElement>(
      document.getElementById('cmx-login-form-username-field')
    )).value = '';
    (<HTMLInputElement>(
      document.getElementById('cmx-login-form-password-field')
    )).value = '';
    (<HTMLInputElement>(
      document.getElementById('cmx-login-form-username-field')
    )).disabled = true;
    (<HTMLInputElement>(
      document.getElementById('cmx-login-form-password-field')
    )).disabled = true;
    (<HTMLInputElement>(
      document.getElementById('cmx-login-form-submit-btn')
    )).disabled = true;

    const tokenExpired = this.isTokenExpired(
      this.sessionService.expiresIn
    );
    if (!tokenExpired) {
      this.router.navigate(['dashboard'], {
        queryParams: { returnUrl: this.redirectTo },
      });
    } else {
      this.externalSystemsService.getSettings(externalIntegration).subscribe(
        (data: any) => {
          let settingsURL: { [key: string]: string };
          settingsURL = {};
          for (let _i = 0; _i < data.length; _i++) {
            settingsURL[data[_i]['settingKey']] = data[_i]['settingValue'];
          }
          const loginUrl =
            settingsURL['login.hostname'] +
            settingsURL['login.authorize'] +
            '?response_type=' +
            settingsURL['authorizationCode.response_type'] +
            '&client_id=' +
            settingsURL['client_id'] +
            '&redirect_uri=' +
            settingsURL['authorizationCode.redirect_uri'];
          const processMessages = function () {
            const codeExt = arguments['0']['data']['code'];
            if (codeExt) {
              localStorage.setItem('codeExt', codeExt);
              (<HTMLInputElement>(
                document.getElementById('cmx-login-form-username-field')
              )).disabled = false;
              (<HTMLInputElement>(
                document.getElementById('cmx-login-form-password-field')
              )).disabled = false;
              (<HTMLInputElement>(
                document.getElementById('cmx-login-form-submit-btn')
              )).disabled = false;
            }
          };
          let widthSize = 600;
          let heightSize = 450;
          if (localStorage.getItem('authAllowed')) {
            widthSize = 10;
            heightSize = 10;
          }
          window.addEventListener('message', processMessages, false);
          const windowName = 'procoreIntegration';
          const newWin = window.open(
            String(loginUrl),
            windowName,
            `
              toolbar=no,
              location=no,
              status=no,
              menubar=no,
              scrollbars=no,
              resizable=no,
              fullscreen=no,
              width=${widthSize},
              height=${heightSize},
              top=10,
              left=10
            `
          );

          if (newWin === null || typeof newWin === 'undefined') {
            this.popupBlock = true;
          } else {
            this.popupBlock = false;
          }

          setTimeout(() => {
            if (window.location.href.search('login') !== -1) {
              localStorage.removeItem('codeExt');
              this.checkIfExternalIntegration();
            }
          }, 600000);
        },
        (error) => {
          console.error(error);
        }
      );
      this.redirectTo = '/external-systems/app/pair-customers';
    }
  }

  private getTokenExpirationDate(expiration: number): Date {
    if (expiration === undefined) {
      return null;
    }
    const date = new Date();
    date.setUTCSeconds(expiration);
    return date;
  }

  private isTokenExpired(expiration: string): boolean {
    const date = this.getTokenExpirationDate(parseInt(expiration, 10));
    if (date === undefined) {
      return false;
    }
    return !(date.valueOf() > new Date().valueOf());
  }

  public goToCreditRequestLink() {
    if (
      this.creditRequestLink === undefined ||
      this.creditRequestLink === null ||
      this.creditRequestLink === ''
    ) {
      this.creditRequestLink = 'http://uscldcrrwad01.azurewebsites.net';
    }
  }

  public resolved(captchaResponse: string): void {
    if (captchaResponse !== '') {
      const formSubmitBtnId = this.resetPassword
        ? 'cmx-login-reset-password-btn'
        : 'cmx-login-form-submit-btn';

      const formSubmitBtn = document.getElementById(
        formSubmitBtnId
      ) as HTMLInputElement;
      formSubmitBtn.disabled = false;
    } else {
      this.recaptcha.reset();
    }
  }

  public checkVal(event: Event) {
    this.validateCaptcha();
  }

  public checkPwd(event: Event) {
    this.validateCaptcha();
  }

  private validateCaptcha() {
    (<HTMLInputElement>(
      document.getElementById('cmx-login-form-submit-btn')
    )).disabled = true;
    var elementUs = <HTMLInputElement>(
      document.getElementById('cmx-login-form-username-field')
    );
    var elementPwd = <HTMLInputElement>(
      document.getElementById('cmx-login-form-password-field')
    );
    if (elementUs.value.length > 0 && elementPwd.value.length > 0) {
      this.submitted = true;
    } else {
      this.submitted = false;
      this.recaptcha.reset();
    }
  }

  private createCaptcha() {
    const className = this.resetPassword ? 'reset__password' : 'cmx-login__submit';
    const divButton = <HTMLDivElement>(
      document.getElementsByClassName(className)[0]
    );
    const captcha = document.getElementById('div-recaptcha');
    divButton.before(captcha);
  }

  public onLoginAttemptFail(rsp: any) {
    this.showCaptcha = false;
    this.showReCaptcha = false;
    try {
      this.createCaptcha();
      const objson = JSON.parse(rsp._body);
      if (objson.showCaptcha) {
        this.showCaptcha = true;
        this.showReCaptcha = true;

        if (!this.resetPassword) {
          var submitBtn = <HTMLInputElement>(
            document.getElementById('cmx-login-form-submit-btn')
          );
          submitBtn.disabled = true;
          var elementUsr = <HTMLInputElement>(
            document.getElementById('cmx-login-form-username-field')
          );
          elementUsr.value = '';
          elementUsr.addEventListener('change', (e: Event) => this.checkVal(e));
          var elementPwd = <HTMLInputElement>(
            document.getElementById('cmx-login-form-password-field')
          );
          elementPwd.value = '';
          elementPwd.addEventListener('keyup', (e: Event) => this.checkPwd(e));
        } else {
          const submitBtn = <HTMLInputElement>(
            document.getElementById('cmx-login-form-submit-btn')
          );
          submitBtn.disabled = true;
        }
        this.recaptcha.reset();
      }
    } catch (e) {
      console.log(e);
    }
  }

  private getPosition() {
    this.googleMapsService.getPosition().then((pos) => {
      this.geocodeInitialize(pos.lat, pos.lng);
    });
  }

  private geocodeInitialize(lat: string, lng: string) {
    const geocoder = new google.maps.Geocoder();
    const latlng = { lat: parseFloat(lat), lng: parseFloat(lng) };
    geocoder.geocode({ location: latlng })
      .then((response) => {
        if (response && response.results[0]) {
          var country = null,
            administrativeArea = null;
          var c, lc, component;
          for (var r = 0, rl = response.results.length; r < rl; r += 1) {
            var result = response.results[r];
            if (!administrativeArea && result.types[0] === 'locality') {
              for (
                c = 0, lc = result.address_components.length;
                c < lc;
                c += 1
              ) {
                component = result.address_components[c];
                if (component.types[0] === 'administrative_area_level_1') {
                  administrativeArea = component.long_name;
                  break;
                }
              }
            } else if (
              !administrativeArea &&
              result.types[0] === 'administrative_area_level_1'
            ) {
              for (
                c = 0, lc = result.address_components.length;
                c < lc;
                c += 1
              ) {
                component = result.address_components[c];
                if (component.types[0] === 'administrative_area_level_1') {
                  administrativeArea = component.long_name;
                  break;
                }
              }
            } else if (!country && result.types[0] === 'country') {
              country = result.address_components[0].short_name;
            }
            if (administrativeArea && country) {
              break;
            }
          }
          this.checkIfDisplaySignUpCard(country, administrativeArea);
          this.cookiebotService.setPosition(country, administrativeArea);
        } else {
          console.log('No results found');
        }
      })
      .catch((e) => console.log('Geocoder failed due to: ' + e));
  }

  private startCountly(): void {
    const countlyKey = window['COUNTLY_KEY'];
    const countlyUrl = window['COUNTLY_URL'];
    this.countlyService.startService(countlyUrl, countlyKey);
    const userId = 0; // not an authenticated user
    if (this.countlyService.Countly) {
      this.countlyService.Countly.app_version = '2.0';
      this.countlyService.changeId('', userId.toString());
      this.countlyService.init();
    }
  }

  public goToSignOn() {
    let country = this.sessionService.country;
    const event = {
      key: 'goToSingOn',
      Country: country ? country : '',
    };
    this.countlyService.addTracking('add_event', event);
    this.router.navigate(['/sign-on']);
  }

  public startCountdownCounter(timerMinutes, timerSeconds) {
      const numberOfSeconds = timerMinutes * 60 + timerSeconds;
      var seconds = timerSeconds ? timerSeconds : 60;
      var minutes = timerMinutes;

      this.timerMinutes = minutes < 10 ? '0' + minutes.toString(): minutes.toString();
      this.timerSeconds = '00';
      const numbers = interval(1000);
      this.timer = numbers.pipe(take(numberOfSeconds)).subscribe(currentSecond => {
          if (currentSecond === 0 && seconds === 60) {
            minutes = minutes - 1;
          }

          seconds = seconds - 1;
          this.timerSeconds = seconds < 10 ? '0' + seconds.toString() : seconds.toString();

          if (seconds === 59 && currentSecond > 0) {
            minutes = (minutes - 1) < 0 ? minutes : minutes - 1;
          }

          if (seconds === 0 && currentSecond !== (numberOfSeconds - 1) &&
            minutes > 0) {
            seconds = 60;
          }

          if ((minutes === 0 && ((numberOfSeconds - currentSecond) === 61))) {
            minutes = 1;
          }

          this.timerMinutes = minutes < 10 ? '0' + minutes.toString(): minutes.toString();
          this.valueTimer = this.timerMinutes + ':' + this.timerSeconds;
          this.timerToShowLabel = this.timerToShowLabel.replace(this.valueTimerBefore, this.valueTimer);
          this.valueTimerBefore = this.valueTimer;
          if (minutes == '0' && seconds == '0') {
            sessionStorage.removeItem('timerToShowLabel');
            this.enableResendPasswordBtn = true;
            this.triggerResendPasswordAlert = false;
            this.showTimerText = false;
            this.timer.unsubscribe();
          }
      });
      this.subscription.push(this.timer);
  }

  private resetTimerValues(minutes, seconds) {
    if (this.timer) {
      this.timer.unsubscribe();
    }
    this.timerToShowLabel = this.translationService.pt("views.reset-password.button-continue.timer-label.time");
    this.valueTimerBefore = (minutes < 10 ? '0' + minutes.toString() : minutes.toString()) + ':' + (seconds ? seconds.toString() : '00');
    this.timerToShowLabel = this.timerToShowLabel.replace('{value}', this.valueTimerBefore);
  }

  public loginErrorMessage() {
    if (this.temporaryPasswordFlag) {
      return this.translationService.pt(
        'views.global.input-password.password-incorrect'
      );
    }
  }

  public loginWithTemporaryPassword() {
    if (!this.form.valid) {
      return;
    }
    const username: string = sessionStorage.getItem('userAccount');
    const password: string = this.form.get('temporaryPassword').value;

    let consent = null;
    if(this.onServiceReadyCookiebot) {
      consent = this.cookiebotService.getConsent();
    }
    else {
      if(this.cookiebotService.getLoadScript()) {
        consent = this.cookiebotService.getErrorScriptCookiebot(username);
      }
    }

    this.sessionService
      .login(
        username,
        password,
        this.generateCookie,
        this.appName,
        this.validateTemporaryPassword,
        this.grantType,
        this.scope,
        this.userInformation,
        this.include,
        true,
        consent
      )
      .then((tokenResponse: TokenResponse) => {
        // Redirection between regions (America / Europe) depending on user region.z
        const userRegion = tokenResponse
          ? tokenResponse.oauth2.region
          : this.sessionService.userRegion;
        const regionHost: string =
          this.euRegion.indexOf(userRegion) > -1 ? this.euHost : this.ameHost;

        const hasTemporaryPassword =
          tokenResponse?.profile?.hasTemporaryPassword;
        const canChangePassword = tokenResponse?.profile?.canChangePassword;

        if (hasTemporaryPassword && canChangePassword) {
          this.routeAfterLogin = 'resetPassword';
          const tempPasswordHandler = {
            access_token: tokenResponse.oauth2.access_token,
            jwt: tokenResponse.jwt,
            username,
            firstName: tokenResponse.profile.firstName,
            lastName: tokenResponse.profile.lastName,
            country: tokenResponse.country,
          };
          this.sessionService.createCookie(
            'cmxTmpSession',
            JSON.stringify(tempPasswordHandler)
          );
        } else {
          this.sessionService.clearCookie('cmxTmpSession');
          this.routeAfterLogin = 'dashboard';
        }

        const params = new URLSearchParams();
        if (this.redirectTo) {
          params.append('returnUrl', this.redirectTo);
        }

        // If user is already on the right region, does normal routing
        const currentHost = window.location.host;
        if (regionHost !== currentHost) {
          sessionStorage.clear();
          const baseUrl = `${window.location.protocol}//${regionHost}/${this.routeAfterLogin}`;
          window.location.href = `${baseUrl}?returnUrl=${encodeURIComponent(
            this.redirectTo
          )}`;
        } else {
          const destionationUrl =
            this.routeAfterLogin + '?' + params.toString();
          this.router.navigateByUrl(destionationUrl);
        }
      })
      .catch((err) => {
        if (err.status === 401) {
          this.temporaryPasswordFlag = true;
        }
        this.onLoginAttemptFail(err);
      });
  }

  public reSendPassword(): void {
    if (this.enableResendPasswordBtn) {
      this.enableResendPasswordBtn = false;
      this.subscription.push(
        this.passwordService.requestResetPassword().subscribe({
          next: (response: any) => {
            if(response.status === 208) {
              this.isWarningResendPassword = true;
            }
            else {
              this.isWarningResendPassword = false;
            }
            const rsp = response.json();
            const loginAfter = Number(rsp.loginAfter);
            const loginAfterToSet = loginAfter + 15;
            const seconds = loginAfterToSet;
            let minutes;
            let leftSeconds;

            if (seconds > 0) {
              minutes = Math.floor(seconds / 60);
              leftSeconds = seconds % 60;
            }

            const maxMinToExpire = seconds > 0 ? minutes : this.maxTimeToExpireCode;
            if (seconds > 0) {
              this.resetTimerValues(maxMinToExpire, leftSeconds);
              this.startCountdownCounter(maxMinToExpire, leftSeconds);
            } else {
              this.resetTimerValues(maxMinToExpire, null);
              this.startCountdownCounter(maxMinToExpire, null);
            }
            this.triggerResendPasswordAlert = true;
            this.showTimerText = true;
          },
          error: (error) => {
            if (error.status === 400) {
              this.router.navigate(['/login']);
              this.resetPassword = false;
            } else {
              this.errorModal = true;
              this.errorMsg = `${error.httpCode}: ${this.translationService.pt(error.httpMessage)}`;
            }
            this.enableResendPasswordBtn = true;
          },
        })
      );
    }
  }

  public goToLogin() {
    this.resetPassword = false;
    this.router.navigate(['/login']);
    this.triggerResendPasswordAlert = false;
    this.showCaptcha = false;
    this.enableResendPasswordBtn = true;
    this.showTimerText = false;
    this.timer.unsubscribe();
  }

  private getConsent() {
    let cookiebot = window['Cookiebot'];
    if (!cookiebot || !cookiebot.consent || cookiebot.consent.stamp === 0 || cookiebot.consent.stamp === '0') {
      return null;
    }
    return {
      consent: cookiebot['consent'],
      consentUTC: cookiebot['consentUTC']
    }
  }

  public disabledLoginTemporaryPassword(): boolean {
    if(this.onServiceReadyCookiebot) {
      const consent = this.cookiebotService.getConsent();
      return (this.form.get('temporaryPassword').value?.length! < 1 || this.showReCaptcha || consent === null);
    }
    else {
      return (this.form.get('temporaryPassword').value?.length! < 1 || this.showReCaptcha);
    }
  }

}
