import { Profile } from './profile';
import { Application } from './application';
import { FunctionGroup } from './functionGroup';
import { Role } from './role';
import { ICustomer } from '../angular-types-v2/index.interface';
export class UserProfile {
  public userId?: number;
  public userAccount!: string;
  public countryCode!: string;
  public fullName?: string;
  public firstName?: string;
  public lastName?: string;
  public phone?: string;
  public phoneNumber?: string;
  public status?: string;
  public customer?: ICustomer;
  public customerId?: number;
  public userType?: string;
  public applications?: Application[];
  public roles?: Role[];
  public functions?: FunctionGroup[];
  public profile?: Profile;
  public userPosition?: string;
  public customerDesc?: string;
  public customerCode?: string;
  public isDigitalAdmin?: boolean;
  public token?: string;
  public userSelectedId?: 'A' | 'S';
  public cloudContactMethodId?: number;
}
