// tslint:disable:no-any
import { OrderLogUser } from './order-log-user';

export interface OrderLog {
  logItemId: string;
  action: string;
  user: OrderLogUser;
  itemDateTime: string;
  newPayload?: string;
  newOrderType?: any;
  newStatus?: any;
  previousPayload?: string;
}
