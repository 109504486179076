import { HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductLinesRes } from '../models/uploads/product-lines-res';
import { SalesRegionsRes } from '../models/uploads/sales-regions-res';
import { UploadFileReq, UploadV7FileReq } from '../models/uploads/upload-file-req';
import { UploadFileRes, UploadFileV7Res } from '../models/uploads/upload-file-res';
import { CmxHttpClient } from '../services/api.service';

@Injectable()
export class UploadsService {
  constructor(private http: CmxHttpClient) {}

  uploadFile(uploadFile: UploadFileReq): Observable<HttpEvent<UploadFileRes>> {
    const formData = new FormData();
    const blobUploadFile = JSON.stringify({
      files: [
        {
          fileName: uploadFile.file.name,
          referenceDate: new Date().toISOString(),
          referenceStatus: 'NEW',
          storageType: 'FST',
          countryCode: uploadFile.countryCode,
          documentType: {
            documentTypeCode: uploadFile.documentTypeCode,
            isPath: true,
          },
          config: {
            1: {
              name: 'customer',
              value: uploadFile.customerId,
            },
          },
          serviceCenterTblId: uploadFile.serviceCenterTblId,
          productLineId: uploadFile.productLineId,
        },
      ],
    });

    formData.append('filename1', new Blob([blobUploadFile], { type: 'application/json' }), 'UploadFile.json');
    formData.append('filename2', uploadFile.file);

    return this.http.upload<UploadFileRes>('/v6/ds/documents', formData);
  }

  uploadFileV7(uploadFile: UploadV7FileReq): Observable<HttpEvent<UploadFileV7Res>> {
    const formData = new FormData();
    const documentRequest = {
      documentName: uploadFile.file.name,
      countryCode: uploadFile.countryCode,
      customer: {
        customerCode: uploadFile.customerCode,
      },
    };
    if (uploadFile.serviceCenterTblId) {
      documentRequest['serviceCenter'] = { serviceCenterId: uploadFile.serviceCenterTblId };
    }
    const blobUploadFile = JSON.stringify(documentRequest);

    formData.append('filename1', new Blob([blobUploadFile], { type: 'application/json' }), 'UploadFile.json');
    formData.append('filename2', uploadFile.file);

    return this.http.upload<UploadFileV7Res>(`/v7/ds/documents/${uploadFile.documentTypeCode}`, formData);
  }

  getSalesRegions(productLine: number): Observable<SalesRegionsRes> {
    return this.http.get<SalesRegionsRes>(`/v6/sm/productlines/${productLine}/salesregions`);
  }

  getProductLines(customerId: number): Observable<ProductLinesRes> {
    return this.http.get<ProductLinesRes>(`/v6/sm/productlines?customerId=${customerId}&group=true`);
  }
}
