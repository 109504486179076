import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { DateNamesService } from "./../../services/date-names.service";
import { IMonth } from "./../../models/datepicker-interfaces";

@Component({
    selector: "month-selector",
    styleUrls: ["./../../../../../../../scssv4/cmx-components/cmx-datepicker/v4/cmx-datepicker.component.scss"],
    templateUrl: "./month-selector.component.html",
})
export class MonthSelectorComponent implements OnInit {
    @Input() public currentDate: Date;
    @Input()
    get max(): Date {
        return this._max;
    }
    set max($value: Date) {
        this._max = new Date($value.getFullYear(), $value.getMonth() + 1, 0);
        if (this.currentDate && this._min) {
            this.reset();
        }
    }
    @Input()
    get min(): Date {
        return this._min;
    }
    set min($value: Date) {
        this._min = new Date($value.getFullYear(), $value.getMonth(), 1);
        if (this.currentDate && this._min) {
            this.reset();
        }
    }

    @Output() public selectedMonth: EventEmitter<number> = new EventEmitter<number>();
    @Output() public selectedYear: EventEmitter<number> = new EventEmitter<number>();
    @Output() public actionView: EventEmitter<string> = new EventEmitter<string>();

    public monthsShort: IMonth[][] = [];
    public currentMonth: string;
    public currentYear: number;
    private _max: Date;
    private _min: Date;

    constructor(private dateNames: DateNamesService) {
        //
    }

    public ngOnInit(): void {
        this.reset();
    }

    public previousYear(): void {
        if (this.currentYear - 1 >= this._min.getFullYear()) {
            this.currentYear -= 1;
            this.currentDate.setFullYear(this.currentYear);
            this.reset();
        }
    }

    public nextYear(): void {
        if (this.currentYear + 1 <= this._max.getFullYear()) {
            this.currentYear += 1;
            this.currentDate.setFullYear(this.currentYear);
            this.reset();
        }
    }

    public monthToggle(): void {
        this.selectedYear.emit(this.currentYear);
        this.actionView.emit("calendarView");
    }

    public yearToggle(): void {
        this.actionView.emit("yearView");
    }

    public selectMonth(month: IMonth): void {
        if (!month.disabled) {
            this.selectedMonth.emit(month.number);
        }
    }

    private reset(): void {
        this.monthsShort = [];
        this.currentMonth = this.dateNames.getMonthName(this.currentDate.getMonth());
        this.currentYear = this.currentDate.getFullYear();
        const auxNames: string[][] = this.dateNames.getMonthShortNames();

        for (let r: number = 0; r < auxNames.length; r++) {
            const auxRow: IMonth[] = [];
            const cols: string[] = auxNames[r];
            for (let c: number = 0; c < cols.length; c++) {
                const auxMonth: number = r * cols.length + c;
                const auxDate: Date = new Date(this.currentYear, auxMonth, 1);
                const aux: IMonth = {
                    disabled: (auxDate < this._min || auxDate > this._max),
                    label: cols[c],
                    number: auxMonth,
                };
                auxRow.push(aux);
            }
            this.monthsShort.push(auxRow);
        }
    }
}
