import { Injectable } from '@angular/core';
// classes
import { ProjectConstants } from './project.constants';

@Injectable()
export class EndpointsConstants extends ProjectConstants {
    // versions
    public V1 = 'v1/';
    public V2 = 'v2/';
    public V3 = 'v3/';
    public V4 = 'v4/';
    public V5 = 'v5/';
    public V6 = 'v6/';
    // capabilities
    public armCapability = 'arm/';
    public legalCapability = 'legal/';
    public dsCapability = 'ds/';
    public rcCapability = 'rc/';
    public crmCapability = 'crm/';
    public cumCapability = 'cum/';
    public peCapability = 'pe/';
    public repCapability = 'rep/';
    public secmCapability = 'secm/';
    public ceCapability = 'ce/';
    // base path
    protected BASEPATH = this.apiBasePath;
    // working environment
    protected ORG = this.apiOrg;
    protected ENV = this.apiEnv;

    constructor() {
        super();
    }

    // =================================================================================================================
    // Expositions
    // =================================================================================================================

    public getLoginPath(): string {
        const url = this.BASEPATH + this.ORG + this.ENV + this.V2 + 'secm/oam/oauth2/token';
        return url;
    }

    public getBasePath(): string {
        return this.BASEPATH + this.ORG + this.ENV;
    }
}
