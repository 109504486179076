import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CmxHttpClient } from '../services/api.service';
import { createHttpParams } from '../shared/helpers';

// INTERFACES
import {
  ISalesDocument,
  ISalesDocumentsReq,
  ISalesDocumentsRes,
  ISalesDocumentTypeId,
  ISalesDocumentTypesRes,
} from '../models/sales-document-management';

@Injectable()
export class SalesDocumentManagementService {
  API_ENDPOINT = '/v1/im/salesdocumenttypes';

  constructor(private http: CmxHttpClient) {}

  // SALES DOCUMENT MANAGEMENT GET REQUEST
  getSalesDocuments(request: ISalesDocumentsReq): Observable<ISalesDocumentsRes> {
    const httpParams = request ? createHttpParams(request) : new HttpParams();
    return this.http.get<ISalesDocumentsRes>(`/v5/sm/salesdocuments`, {
      params: httpParams,
    });
  }

  getDocumentTypes(): Observable<ISalesDocumentTypesRes> {
    return this.http.get(this.API_ENDPOINT);
  }

  getDocumentById(id: ISalesDocumentTypeId): Observable<ISalesDocument> {
    return this.http.get(`${this.API_ENDPOINT}/${id}`);
  }
}
