import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CmxCheckboxComponent } from './components/cmx-checkbox.component';


@NgModule({
    declarations: [
        CmxCheckboxComponent,
    ],
    exports: [
        CmxCheckboxComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
    ],
})
export class CmxCheckboxModule { }
