import {
  Component,
  Input,
  OnDestroy,
  AfterContentInit,
  ContentChildren,
  QueryList,
  Renderer2,
  ElementRef,
  HostListener,
  HostBinding,
  ViewChild,
  ViewContainerRef} from "@angular/core";
import { Subscription } from "rxjs/Subscription";
import { CmxDropdownItemComponent } from "./cmx-dropdown-item/";


@Component({
  selector: "cmx-dropdown1",
  styleUrls: ["./../../../../../../scssv4/cmx-components/cmx-dropdown/v4/cmx-dropdown.component.scss"],
  templateUrl: "./cmx-dropdown/cmx-dropdown.component.html",
})
export class TestComponent implements AfterContentInit, OnDestroy {
  @ViewChild("itemsPositioner", { read: ViewContainerRef }) public dynamicComponentContainer: ViewContainerRef;
  @Input()
  set title( value: string ) {
      if ( value !== undefined ) {
          this._title = value;
      }
  }
  get title(): string {
      return this._title;
  }
  @Input()
  set rtl( value: boolean ) {
      if ( value !== undefined ) {
          this._rtl = value;
      }
  }
  get rtl(): boolean {
      return this._rtl;
  }
  @Input()
  set closeOnClick( value: boolean ) {
      if ( value !== undefined ) {
          this._closeOnClick = value;
      }
  }
  get closeOnClick(): boolean {
      return this._closeOnClick;
  }
  @Input()
  set enableSearch( value: boolean ) {
      if ( value !== undefined ) {
          this._enableSearch = value;
      }
  }
  get enableSearch(): boolean {
      return this._enableSearch;
  }
  @Input()
  set placeholder(value: string) {
      if ( value !== undefined ) {
          this.originalPlaceholder = value;
          this._placeholder = this.truncate(value);
      }
  }
  get placeholder(): string {
      return this._placeholder;
  }
  @Input()
  set maxLength(value: number) {
      if ( value ) {
          this._maxLength = value;
      }
  }
  get maxLength(): number {
      return this._maxLength;
  }
  @Input()
  get disabled(): boolean {
      return this._disabled;
  }
  set disabled(value: boolean) {
      const coerceBoolean: boolean = ( value != undefined && `${value}` !== "false" );
      this._disabled = coerceBoolean;
  }

  @ContentChildren( CmxDropdownItemComponent )
  public dropdownItems: QueryList<CmxDropdownItemComponent>;

  public _enableSearch: boolean = false;
  public viewportHeight: number = 0;

  @ViewChild("itemsPositioner")
  private itemsPositioner: ElementRef;

  public _title: string;
  private _rtl: boolean = false;
  private _closeOnClick: boolean = false;
  private _maxLength: number;
  public _placeholder: string;
  private _disabled: boolean;
  public showItems: boolean = false;
  private originalPlaceholder: string;
  private changeSubscription: Subscription;
  private childHeight: number = 47;

  constructor( private renderer: Renderer2 ) {
      //
  }

  public close( $event: Event ): void {
    if ($event) { $event.stopPropagation(); }
    this.showItems = false;
    this.processToggle();
  }

  public ngAfterContentInit(): void {
      if ( this.placeholder ) {
          this.originalPlaceholder = this.placeholder;
      }
      this.processOptions();
      this.changeSubscription = this.dropdownItems.changes.subscribe( () => {
          this.processOptions();
      });
  }

  public ngOnDestroy(): void {
      this.changeSubscription.unsubscribe();
  }

  @HostListener( "click", [ "$event" ] )
  public open( $event: Event ): void {
      if ( this.showItems === false ) {
          this.showItems = true;
          this.processToggle();
          setTimeout(() => { this.isInsideViewport(); }, 100);
      } else {
          $event.stopPropagation();
      }
  }

  public filterDropdownItems( searchTerm: string ): void {
        if ( this.dropdownItems.length > 0 && searchTerm.length > 0) {
            this.dropdownItems.forEach( ( dropdownItem: any ) => {
                if ( dropdownItem.searchValue !== undefined  &&
                dropdownItem.searchValue.toLowerCase().includes( searchTerm.toLowerCase() ) ) {
                    dropdownItem.hide = false;
                } else {
                    dropdownItem.hide = true;
                }
            });
        } else {
            this.dropdownItems.forEach( ( dropdownItem: any ) => {
                dropdownItem.hide = false;
            });
        }
    }

    public checkIfClose( $event: Event ): void {
        if ( this._closeOnClick === true ) {
            this.close( $event );
        }
    }

  // ================================================================================
  // functionality
  // ================================================================================

  private processOptions(): void {
      if ( this.dropdownItems.length > 0 && this.dropdownItems.length < 4 ) {
          this.viewportHeight = ( this.childHeight * this.dropdownItems.length );
      } else if ( this.dropdownItems.length >= 4 ) {
          this.viewportHeight = ( this.childHeight * 4 );
      }
  }

  private truncate( value: string ): string {
      const trail: string = "...";
      if ( value.length > this._maxLength ) {
          return value.substring( 0, this._maxLength ) + trail;
      } else {
          return value;
      }
  }

  // ================================================================================
  // dom HTML view interactions
  // ================================================================================

  private processToggle(): void {
      if ( !this.disabled && this.dropdownItems && this.dropdownItems.length > 0 ) {
          const bodyElement: any = document.getElementsByTagName("BODY")[0];
          if ( this.showItems ) {
              this.renderer.setStyle( bodyElement, "overflow", "hidden" );
          } else {
              this.renderer.setStyle( bodyElement, "overflow", undefined );
          }
      }
  }

  /**
   * @description Checks whether to move the container of the items if it is outside the viewport
   */
  private isInsideViewport(): void {
      const sidePositioner: string = this.rtl ? "right" : "left";
      // clearing
      console.log(this.renderer);
      console.log(this.itemsPositioner);
      this.renderer.setStyle( this.itemsPositioner.nativeElement, sidePositioner, undefined );
      this.renderer.setStyle( this.itemsPositioner.nativeElement, "top", undefined );
      // calculations
      const rect: ClientRect = this.itemsPositioner.nativeElement.getBoundingClientRect();
      console.log(rect);
      const screenHeight: number = window.innerHeight;
      const screenWidth: number = window.innerWidth;
      const adjustment: number = -1 * rect.left;
      if (this.rtl) {
          if ( rect.left <= 0 ) {
              const pxValue: number = Math.abs( rect.left ) + adjustment;
              this.renderer.setStyle(
                  this.itemsPositioner.nativeElement, sidePositioner, ( -1 *  pxValue ) + "px",
              );
          }
      } else {
          const pxValue: number = Math.abs( rect.left ) + adjustment;
          console.log(pxValue);
          this.renderer.setStyle(
              this.itemsPositioner.nativeElement, sidePositioner, ( -1 *  pxValue ) + "px",
          );
      }
      if ( rect.right > screenWidth ) {
          // move to the left
          const pxValue: number = Math.abs( rect.right - screenWidth ) + adjustment;
          this.renderer.setStyle(
              this.itemsPositioner.nativeElement, sidePositioner, ( -1 *  pxValue ) + "px",
          );
      }
      if ( rect.bottom > screenHeight ) {
          // move to the top
          const pxValue: number = Math.abs( rect.bottom - screenHeight );
          this.renderer.setStyle(
              this.itemsPositioner.nativeElement, "top", ( -1 *  pxValue ) + "px",
          );
      }
  }
}
