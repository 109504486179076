import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../angular-localization-v7/services/translation.service';
@Pipe({
  name: 'translationByCountry'
})
export class TranslationByCountryPipe implements PipeTransform {

  constructor(private translationService: TranslationService) {}

  transform(baseKey: string, countryCode: string): string {
    if (countryCode !== 'US') {
      return this.translationService.pt(`${baseKey}.${countryCode.toLocaleLowerCase()}`);
    }
    return this.translationService.pt(baseKey);
  }
}
