interface Scripts {
    name: string;
    src: string;
}
export const ScriptStore: Scripts[] = [

    {name: 'es', src: '5dd112ebe06d075a2f154ea994c6507d'},
    {name: 'en', src: 'd308859c1615895b44f290c77619ce4e'},
    {name: 'es_ES_private', src: '25c0bc960fa7bdbc47a53731d575c453'},
    {name: 'es_ES_public', src: 'b746a6b30395bf93705c9e0d9385bbf7'},
    {name: 'es_CO_private', src: 'b5c577600b26ff54c7a3bf9bb93c79e2'},
    {name: 'es_CO_public', src: '29c5f3a5489daab83243e5d2df8fb37d'},
    {name: 'es_MX_private', src: '4e59f2e7da2e71a85d1de3856d8b3702'},
    {name: 'es_MX_public', src: '6078e80980deddaa7719b2199fc2ab97'},
    {name: 'en_US_private', src: 'dada62aac9f5310481dc78a3f346d7c6'},
    {name: 'en_US_public', src: '1342817364ceea22808ce6597a88f7bc'},
    {name: 'en_PH_private', src: '02dd9a333e6c261ed2cc5e0c753010cb'},
    {name: 'en_PH_public', src: 'e5facb4779e45b1dc56281d416becb3b'},
    {name: 'en_GB_private', src: '772cfe856456bed691faa3f428bc25ff'},
    {name: 'en_GB_public', src: 'b4a788759f0fc0864e8d375f3d87ad14'},
    {name: 'es_GT_public', src: 'fc04607bb8ea9d310e74121dd295b4a0'},
    {name: 'es_GT_private', src: '0a4bc8b40492fd6d27ad51dbe41a691c'},
    {name: 'es_PA_public', src: '3f07b959b21658b32b52fa208311125c'},
    {name: 'es_PA_private', src: '61ebf4e355cada6d254f697a7f1ecc41'},
    {name: 'es_PE_public', src: 'e63cb89f387588bb05630491278904b4'},
    {name: 'es_PE_private', src: 'c230c1c77e4270dda8cfefcf3d3989c1'},
    {name: 'es_DO_public', src: '77500151ee9e4051859930845222c1f8'},
    {name: 'es_DO_private', src: '62bb053473ecb899b64a6c75fe0e4963'},
    {name: 'es_PR_public', src: '2e896f412406c84defac106c19e48f28'},
    {name: 'es_PR_private', src: 'a72ce757746417e3ef064158435d99f5'},
    {name: 'es_NI_public', src: 'c1d2510babd5713287b0db0c31e71d92'},
    {name: 'es_NI_private', src: '80513bb30d31aaa153e3f2f80ae54dd5'},
  ];

export enum languagesAvailable {
    en,
    es,
    en_test,
    es_test,
    es_MX,
    es_CO,
    es_ES,
    es_US,
    en_US,
    en_PH,
    en_GB,
    es_MX_test,
    es_CO_test,
    es_ES_test,
    es_US_test,
    en_US_test,
    en_PH_test,
    en_GB_test,

    es_MX_private,
    es_CO_private,
    es_ES_private,
    en_US_private,
    en_PH_private,
    en_GB_private,
    es_GT_private,
    es_PA_private,
    es_PE_private,
    es_DO_private,
    es_PR_private,
    es_NI_private,

    es_MX_public,
    es_CO_public,
    es_ES_public,
    es_GT_public,
    es_PA_public,
    es_PE_public,
    es_DO_public,
    es_PR_public,
    es_NI_public,
    en_US_public,
    en_PH_public,
    en_GB_public,

    es_MX_test_public,
    es_CO_test_public,
    es_ES_test_public,
    en_US_test_public,
    en_PH_test_public,
    en_GB_test_public,
    es_GT_test_public,
    es_PA_test_public,
    es_PE_test_public,
    es_DO_test_public,
    es_PR_test_public,
    es_NI_test_public,


    es_MX_test_private,
    es_CO_test_private,
    es_ES_test_private,
    en_US_test_private,
    en_PH_test_private,
    en_GB_test_private,
    es_GT_test_private,
    es_PA_test_private,
    es_PE_test_private,
    es_DO_test_private,
    es_PR_test_private,
    es_NI_test_private
}
