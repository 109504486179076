import { NgModule } from '@angular/core';
import { ClickOutsideDirective } from './click-outiside.directive.component';

@NgModule({
    imports: [],
    exports: [ClickOutsideDirective],
    declarations: [ClickOutsideDirective],
    providers: [],
})
export class ClickOutsideDirectiveModule { }
