import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { CmxHttpClient } from './http-client.service';
import { Application } from './../models';

@Injectable()
export class ApplicationService {

    constructor(private cmxHttp: CmxHttpClient) { }

    getApplicationByCode(applicationCode: string) {
        const uri = `v6/secm/applications?applicationCode=${applicationCode}`;
        return this.cmxHttp.get<Application[]>(uri)
            .pipe(map((res) => {
              return res['applications'] ? res['applications'][0] : null;
            }));
    }

    getUserApplicationsAndRoles() {
        return this.cmxHttp.get<Application[]>('v5/secm/user/data/applications');
    }

    // TODO: DEPRECATE
    getApplicationsAndRolesForUser(userId: number) {
        const uri = `v5/secm/user/${userId}/applications`;
        return this.cmxHttp.get<Application[]>(uri)
          .pipe(map((res) => res['applications'] ? res['applications'] : []));
    }

    getAllApplicationsAndRoles() {
        return this.cmxHttp.get<Application[]>('v5/secm/applications?internalRoles=false')
          .pipe(map((res) => res['applications'] ? res['applications'] : []));
    }
}
