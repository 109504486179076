import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export interface BroadcastEvent {
    key: any;
    data?: any;
}

@Injectable()
export class Broadcaster {
    public static CHANGE_SCREEN_HOME = 'change_screen';
    public static DCM_APP_LOGOUT = 'dcm_app_logout_event';
    public static DCM_LANGUAGE_CHANGE = 'dcm_language_change_event';
    public static DCM_LANGUAGE_FETCHED = 'dcm_language_fetched_event';
    public static DCM_LEGAL_ENTITY_CHANGE = 'dcm_legal_entity_change_event';
    public static DCM_LANGUAGE_SELECTION = 'dcm_language_selection_event';

    public static _eventBus: Subject<BroadcastEvent> = null;

    constructor() {
        if (Broadcaster._eventBus == null || Broadcaster._eventBus === undefined) {
            Broadcaster._eventBus = new Subject<BroadcastEvent>();
        }
    }

    broadcast(key: any, data?: any) {
        Broadcaster._eventBus.next({ key, data });
    }

    on<T>(key: any): Observable<T> {
        const result = Broadcaster._eventBus.asObservable()
            .pipe(
                filter(event => {
                    return event.key === key;
                }),
                map(event => <T>event.data),
            );
        return result;
    }
}
