
import { Pipe, PipeTransform } from '@angular/core';
import { DateTimeActions, FormatterService } from '../angular-localization-v7/services/formatter.service';
import { TranslationService } from '../angular-localization-v7/services/translation.service';
import { Features } from '../feature-toggle-service/v2/src/app/models/features.enum';

declare var require: any;
let moment = require('moment');

@Pipe({
    name: 'cmxDateMoment',
    pure: false,
})
export class DateMomentPipe implements PipeTransform {
    constructor(private translationService: TranslationService,
        private formatterService: FormatterService) { }

    transform(dateTime: Features, action: DateTimeActions = DateTimeActions.ConvertToLocal): string {
        return this.formatterService
            .formatDateTimeMoment(dateTime, this.translationService.selectedLanguage.languageISO.replace('_', '-'),
                this.translationService.selectedLanguage.formatDate, action);
    }
}
