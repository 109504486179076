import { Column } from './column.class';

export class Table {
    private customerCode: number;
    private companyCode: number;
    private columns: Column[];

    constructor() {
        this.columns = [];
    }

    public setColumns( columns: Column[] ): void {
        this.columns = columns;
    }

    public addColumn( column: Column ): void {
        this.columns.push( column );
    }

    public getColumn( key: string ): Column {
        let answer;
        this.columns.forEach( ( column: Column ) => {
            if ( column.getKey() === key ) {
                answer = column;
            }
        });
        return answer;
    }

    public getColumns(): Column[] {
        return this.columns;
    }

    public setCustomerCode( _customerCode: number ): void {
        this.customerCode = _customerCode;
    }

    public setCompanyCode( _companyCode: number ): void {
        this.companyCode = _companyCode;
    }
}
