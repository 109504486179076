import { Component, EventEmitter, HostBinding, Input, Output, Optional, Inject } from '@angular/core';

@Component({
    selector: 'cmx-tab',
    styleUrls: ['./../../../../../../scss/cmx-tabs/v4/cmx-tabs.component.scss'],
    templateUrl: './cmx-tab.component.html',
})

export class CmxTabComponent {
    @HostBinding('class.cmx-tab') public cmxTab: string = 'true';

    @Input()
    public title: string;

    @Input()
    public id: any;

    @Input()
    public rtl: boolean;

    @Input()
    set active(value: boolean) {
        if (value !== undefined) {
            this._active = value;
            this.activation.emit(this._active);
        }
    }
    get active(): boolean {
        return this._active;
    }
    public _active: boolean = false;

    @Output()
    public activation: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        @Optional() @Inject('RTL') isRTL: boolean,
    ) {
        if (isRTL != undefined) {
            this.rtl = isRTL;
        }
    }
}
