import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { COUNTRY_CODE } from '../models/country-config/countries';
import { ICountryConfig } from '../models/country-config/country-config.interface';
import { CmxHttpClient } from '../services/api.service';

// INTERFACES

@Injectable()
export class CountryConfigService {
  constructor(private http: CmxHttpClient) {}

  getCountryConfig(countryCode: COUNTRY_CODE): Observable<ICountryConfig> {
    return this.http.get<ICountryConfig>(`/v5/secm/console/countryconfig?countryCode=${countryCode}`);
  }
}
