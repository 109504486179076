import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CmxHttpClient } from '../services/api.service';
import { createHttpParams } from '../shared/helpers';

import { IDestinationsRequest } from '../models/destinations/shipping-destinations-request';
import { IDestinationsResponse } from '../models/destinations/shipping-destinations-response';

@Injectable()
export class DestinationsService {
  constructor(private http: CmxHttpClient) {}

  getDestinations(request: IDestinationsRequest): Observable<IDestinationsResponse> {
    return this.http.get<IDestinationsResponse>(`/v1/shm/shippings/destinations`, {
      params: createHttpParams(request),
    });
  }

  getDestinationsV2(request: IDestinationsRequest): Observable<IDestinationsResponse> {
    return this.http.get<IDestinationsResponse>(`/v2/shm/shippings/destinations`, {
      params: createHttpParams(request),
    });
  }
}
