import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

// ! Do not remove Observable import.. Needed for package build
// tslint:disable-next-line: no-unused-variable
import { Observable } from 'rxjs';

import { TokenRefreshResponse, TokenResponse } from '../models/auth';

export interface ApiConfigI {
  url: string;
  key: string;
  appCode: string;
}

@Injectable()
export class AuthService {
  constructor(private http: HttpClient) {}

  public refreshToken(apiConfig: ApiConfigI, refreshToken: string) {
    const body = new HttpParams()
      .set('grant_type', 'refresh_token')
      .set('scope', 'security')
      .set('refresh_token', refreshToken)
      .set('client_id', apiConfig.key);
    return this.http.post<TokenRefreshResponse>(apiConfig.url, body.toString(), {
      headers: new HttpHeaders().append('Content-Type', 'application/x-www-form-urlencoded'),
    });
  }

  public signIn(apiConfig: ApiConfigI, email: string, password: string) {
    const body = new HttpParams()
      .set('grant_type', 'password')
      .set('scope', 'security')
      .set('username', email)
      .set('password', password)
      .set('client_id', apiConfig.key);
    return this.http.post<TokenResponse>(apiConfig.url, body.toString(), {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/x-www-form-urlencoded')
        .append('App-Code', apiConfig.appCode),
    });
  }
}
