/**
 * Standard format of a catenable link.  Contains local URLs, often to API calls.
 */
export interface Link {
  self: string;
  next?: string;
  previous?: string;
  status?: string;
  address?: string;

  businessLines?: string;
  contactRequests?: string;
  requested?: string;
  quoted?: string;
  created?: string;
  configuration?: string;
}
