import { Injectable } from '@angular/core';
import { CmxHttpClient } from './http-client.service';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})

export class ExternalsystemsService {

  constructor(private cmxHttp: CmxHttpClient) { }

  getSettings(externalSystemCode: string) {
    const uri = `v4/int/externalsystems/settings?externalSystemCode=${externalSystemCode}`;
    return this.cmxHttp.get(uri).pipe(map((res) => res as {[key: string]: any} ['settings']));
  }

  userExternalReferencesInitialSetup(settings: any) {
    return this.cmxHttp.post('v6/secm/userexternalreferences', settings);
  }
}
