export class DCMConstants {
    public static DCM_APPLICATIONS: string[] =
        [
            'CustomerInfo_App',
            'QuotationPricing_App',
            'INVOICE_PAYMENTS',
            'OrderProductCat_App',
            'Foreman_App',
            'DeliverySchedule_App',
            'UserProvisioning_App',
            'Cust_Dashboards_App',
            'Document_Center_App',
            'OneClickOrder_App',
            'ServiceCenter_App',
            'Construagil_App'
        ];
    public static ACCOUNT_VALIDATION_CODE = 'accountValidation';
    public static USER_REGISTRATION_CODE = 'userRegistration';
    public static DCM_APPLICATION_CODE = 'UserProvisioning_App';
    public static DCM_LOYALTY_PROGRAM_CODE = 'CMX_LOYALTY_PROG';
    public static CMX_LOYALTY_PROGRAM_NAME = 'CEMEX al Punto';
    public static DCM_ROLE_CODE = 'DCM_USER';
    public static DCM_USER_MANAGEMENT_APP_CODE = 'UserProvisioning_App';
    public static ANALYTICS_APP_CODE = 'Cust_Dashboards_App';
    public static ONE_CLICK_ORDER_APP_CODE = 'OneClickOrder_App';
    public static ONE_CLICK_ORDER_ROLE_CODE = 'OPC_ONE_CLICK';
    public static REQUIRED_ROLES_FOR_ONE_CLICK_ORDER = ['OPC_CUST_OWNER', 'OPC_PO_MGR', 'OPC_FORMAN'];
    public static DCM_SUPER_USER_CODE = 'SECMORGROOT';
    public static CMX_INTEGRATION_CHAT = 'CMX_INTEGRATION_CHAT';
    public static GOOGLE_RECAPTCHA_KEY = '6LdhExwUAAAAAJPyJayFlhzl3C54hjUn2YIy6-1S';
    public static REGULAR_EXPRESSIONS = {
        OnlyText: '[a-zA-ZñÑáéíóúÁÉÍÓÚ]+',
        OnlyTextWithSpaces: '^[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9_]+( [a-zA-ZñÑáéíóúÁÉÍÓÚ0-9_]+)*$',
        NoSpecialChars: '[^±!@£$%^&*_+§¡!€#¢§¶¬"°•ªº{}()«\\/=<>?¿:;]*',
        PhoneNumber: '^[0-9]*$',
        PasswordExp: '[^+\\\\]*',
        Email: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$'
    };

    public static ADD_COUNTLY_TRACKING = 'ADD_COUNTLY_TRACKING';
    public static OIM_APP_KEY = 'CXGODIGITALADMIN';
    public static PRIVACY_URL_CO = 'https://www.cemexcolombia.com/legal';
    public static PRIVACY_URL_DE = 'http://www.cemex.de/Datenschutzrichtlinien.aspx';
    public static PRIVACY_URL_PA = 'http://www.cemexpanama.com/PoliticasDePrivacidad.aspx';
    public static PRIVACY_URL_NI = 'https://www.cemexnicaragua.com/legal';
    public static PRIVACY_URL_FR = 'http://www.cemex.fr/ProtectiondesDonnees.aspx';
    public static PRIVACY_URL_CR = 'http://www.cemexcostarica.com/PoliticasDePrivacidad.aspx';
    public static PRIVACY_URL = 'https://www.cemex.com/privacy-policy';

    public static USER_TYPE_CAT = {
        Customer: 'C',
        Employee: 'E',
    };

    public static USER_SORTING_PROPERTIES = {
        FirstName: 'firstName',
        LastLogin: 'lastLogin',
    };

    public static AGREEMENT_CAT = {
        Legal: 'LGL',
        PrivacyPolicyDriveApp: 'PRIV-DRIVERAPP',
        TermsOfUse: 'TOU',
        PrivacyPolicy: 'PRP',
        PrivacyPolicy2: 'PRIV',
        GDPR: 'GDPR',
        MKT: 'MKT'
    };

    public static APPLICATION_CODES = {
        DCMWebTool_App: 'DCMWebTool_App'
    };

    public static SECM_APP_PARAMETERS = {
        DigitalAdmin_ReminderWeb: 'SECM_DIGITALADMIN_REMINDER_WEB',
        DigitalAdmin_ReminderImgUrl: 'SECM_DIGITALADMIN_REMINDER_IMGURL',
        Maintenance_DAReminderDays: 'SECM_MAINTENANCE_DAREMINDER_DAYS',
        Template_CemexGoLink: 'SECM_TEMPLATE_CEMEXGO_LINK',
        ChangePasswordDisabled: 'SECM_ACCOUNTTYPE_CHANGEPASSWORD_DISABLED',
        UserRegistration_SystemSource: 'GL_CTM_JST_POD_SYSTEMSOURCE',
        VerificacionCodeExpiration: 'SECM_VERIFICATIONCODE_EXPIRATION_MINUTES',
        UserRegistration_FindAccount: 'SECM_USERREGISTRATION_FINDYOURACCOUNT_IMGURL',
        UserRegistration_DefaultDateFormat: 'DEFAULT_DATE_FORMAT'
    };

    public static DIGITALADMIN_SETTINGS = {
        DA_RoleCode: 'SECMORGROOT',
        DAReminder_MessageCode: 'DAWELCOM002',
        DA_No_Login_Days: 'SECM_MAINTENANCE_DAREMINDER_NOLOGIN_DAYS'
    };

    public static USER_PROCESSES = {
        DateOfWebReminder: 'DWR',
        SelfServiceRegistration: 'SRS',
        FirstLoginNotificationSettings: 'FLNS'

    };

    public static SERVICES_QUICK_LINKS = {
        OrderProductCat_App: [
            {
                linkUrl: '/ordersnproduct/app/new-order/business-line',
                linkName: 'views.dashboard.opc.createOrder'
            }
        ]
    };

    public static SOUTH_CENTRAL_AND_CARIBBEAN_COUNTRIES = [
        'CO', 'CR', 'DO', 'GT', 'NI', 'PA', 'PR', 'PE', 'SV'
    ];

    public static INDUSTRIALS_SEGMENTATION_CODE = 'INDUSTRIALS';

    public static HAULER_CODE = 'Z';

    public static PASSWORD_MAXLENGTH = 20;
    public static USER_MAXLENGTH = 50;
    public static DCM_APPLICATION_INVOICE_PAYMENTS = 'INVOICE_PAYMENTS';
    public static DCM_APPLICATION_ANALYTICS = 'Cust_Dashboards_App';
    public static DCM_APPLICATION_CUSTOMER_INFORMATION = 'CustomerInfo_App';
    public static GOOGLE_GEOCODING_KEY = 'AIzaSyDEdeu3L07t0luJU_KrtS12Hq2EZiuM_Bw';
    public static DCM_APPLICATIONS_SIGN_ON: any[] = [
      { applicationCode: 'CustomerInfo_App', colum: 3},
      { applicationCode: 'INVOICE_PAYMENTS', colum: 1},
      { applicationCode: 'UserProvisioning_App', colum: 1},
      { applicationCode: 'OrderProductCat_App', colum: 2},
      { applicationCode: 'Cust_Dashboards_App', colum: 2},
    ];
    public static DCM_APPLICATIONS_SIGN_ON_TOW_COLUMS: any[] = [
      { applicationCode: 'INVOICE_PAYMENTS', colum: 1},
      { applicationCode: 'UserProvisioning_App', colum: 1},
      { applicationCode: 'OrderProductCat_App', colum: 2},
      { applicationCode: 'Cust_Dashboards_App', colum: 2},
      { applicationCode: 'CustomerInfo_App', colum: 2},
    ];
    public static REQUEST_STATUS_ALL = 'N,A,P,R';

    public static WALKME_LANGUAGE_EN  = ['en-GB', 'en-AE', 'en-EG', 'en-PR', 'he-IL', 'ar-AE', 'ar-EG'];
    public static WALKME_LANGUAGE_ES = ['es-CR', 'es-DO', 'es-GT', 'es-PA', 'es-PE', 'es-PR', 'es-SV'];
}
