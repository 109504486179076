<nav
  id="navHeader"
  *ngIf="version === 2"
  class="cmx-main-nav-header"
  [ngClass]="{ 'bg__bright-green': isBlueGreen() }"
  [dir]="rtl ? 'rtl' : 'ltr'"
>
  <div class="cmx-header-logo">
    <img
      id="cmx-header-logo-link"
      class="cmx-header-logo__link"
      [ngClass]="userCountry == 'IL' ? 'cmx-logo-il' : 'cmx-logo'"
      (click)="gotoHome()"
      src="{{ 'https://www.cemexgo.com/cdn/logos/svg/' + logo }}"
    />
  </div>

  <div class="cmx-main-nav-header__items">
    <!-- FEEDBACK BUTTON -->

    <div class="cmx-main-nav-header__item user-menu" *ngIf="_formLangUrl && isValidUser">
      <div class="cmx-main-nav-header__item-feeback">
        <div class="cmx-main-nav-header__icon-label hidden-xs-down" id="cmx-header-feedback">
          {{ _translation.pt('views.header.feedbackDialog.feedBackTitle') }}
        </div>
      </div>
    </div>

    <!-- END FEEDBACK BUTTON -->
    <div class="cmx-main-nav-header__item-separator" *ngIf="_formLangUrl && isValidUser"></div>

    <!-- USER-NOTIFICATIONS -->
    <div
      class="cmx-main-nav-header__item nav-notifications-item"
      [class.cursor-auto]="!isValidUser"
      *ngIf="_enableUserNotifications"
    >
      <div
        class="cmx-user-notifications popout__toggle"

        data-popout-target="#userNotificationsPopout"
        (click)="showUserNotifications()"
      >
        <div
          class="cmx-main-nav-header__icon-label hidden-xs-down"
          id="userNotificationsPopoutButton"
          [title]="_translation.pt('views.header.lbl-notifications')"
        >
          <!-- notification-bell-filled-white -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            version="1.1"
            id="userNotificationsIcon"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            style="enable-background:new 0 0 512 512;"
            xml:space="preserve"
            width="30px"
            height="30px"
          >
            <g>
              <g>
                <g>
                  <path
                    d="M467.812,431.851l-36.629-61.056c-16.917-28.181-25.856-60.459-25.856-93.312V224c0-67.52-45.056-124.629-106.667-143.04    V42.667C298.66,19.136,279.524,0,255.993,0s-42.667,19.136-42.667,42.667V80.96C151.716,99.371,106.66,156.48,106.66,224v53.483    c0,32.853-8.939,65.109-25.835,93.291l-36.629,61.056c-1.984,3.307-2.027,7.403-0.128,10.752c1.899,3.349,5.419,5.419,9.259,5.419    H458.66c3.84,0,7.381-2.069,9.28-5.397C469.839,439.275,469.775,435.136,467.812,431.851z"
                    data-original="#000000"
                    class="active-path"
                    data-old_color="#000000"
                    fill="#FFFFFF"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M188.815,469.333C200.847,494.464,226.319,512,255.993,512s55.147-17.536,67.179-42.667H188.815z"
                    data-original="#000000"
                    class="active-path"
                    data-old_color="#000000"
                    fill="#FFFFFF"
                  />
                </g>
              </g>
            </g>
          </svg>
        </div>

        <div
          class="cmx-main-nav-header__icon"
          *ngIf="_userNotificationUnreadCount > 0"
          [class.cmx-user-notifications__top-nav__alert]="_userNotificationUnreadCount"
          [class.cmx-main-nav-header__icon--inverted]="!_userNotificationUnreadCount"
        >
          {{ _userNotificationUnreadCount }}
        </div>
      </div>
      <div
        id="userNotificationsPopout"
        class="cmx-notifications__container popout popout--bottom-left"
        [class.hidden]="!_showUserNotifications"
        (clickOutside)="clickUserNotificationOutSide($event)"
      >
        <div *ngIf="_userNotificationTotalCount == 0">
          <a class="cmx-user-notifications__main-call-to-action" (click)="onAllNotification()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              version="1.1"
              id="Capa_1"
              x="0px"
              y="0px"
              viewBox="0 0 512 512"
              style="enable-background:new 0 0 512 512;"
              xml:space="preserve"
              width="20px"
              height="20px"
              class=""
            >
              <g>
                <g>
                  <g>
                    <path
                      d="M467.812,431.851l-36.629-61.056c-16.917-28.181-25.856-60.459-25.856-93.312V224c0-67.52-45.056-124.629-106.667-143.04    V42.667C298.66,19.136,279.524,0,255.993,0s-42.667,19.136-42.667,42.667V80.96C151.716,99.371,106.66,156.48,106.66,224v53.483    c0,32.853-8.939,65.109-25.835,93.291l-36.629,61.056c-1.984,3.307-2.027,7.403-0.128,10.752c1.899,3.349,5.419,5.419,9.259,5.419    H458.66c3.84,0,7.381-2.069,9.28-5.397C469.839,439.275,469.775,435.136,467.812,431.851z"
                      data-original="#000000"
                      class="active-path"
                      data-old_color="#000000"
                      fill="#D6D6D6"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M188.815,469.333C200.847,494.464,226.319,512,255.993,512s55.147-17.536,67.179-42.667H188.815z"
                      data-original="#000000"
                      class="active-path"
                      data-old_color="#000000"
                      fill="#D6D6D6"
                    />
                  </g>
                </g>
              </g>
            </svg>

            {{ _translation.pt('views.header.no-notifications') }}
          </a>
        </div>

        <div class="cmx-notifications__list">
          <div
            class="cmx-user-notifications__entry"
            *ngFor="let notification of _userNotificationShortList; index as i"
            id="userNotificationEntry{{ i }}"
            [ngClass]="{
              'cmx-user-notifications__entry-data-new': notification.statusCode === 'N',
              'cmx-user-notifications__entry-data-seen': notification.statusCode === 'V',
              'cmx-user-notifications__entry-data-read': notification.statusCode === 'R'
            }"
            (click)="showUserNotificationDetail(notification.notificationId); openNotificationDetailDialog()"
          >
            <div class="cmx-user-notifications__entry-icon">
              <div [ngSwitch]="notification.processCode">
                <div *ngSwitchCase="'S1'" class="cmx-user-notifications__entry-icon-service">
                  <span class="cmx-icon-module-customer-information font-true-blue" aria-hidden="true"></span>
                </div>
                <div *ngSwitchCase="'S2'">
                  <span class="cmx-icon-module-order-product-catalogue font-true-blue" aria-hidden="true"></span>
                </div>

                <div *ngSwitchCase="'S3'">
                  <span class="cmx-icon-module-track font-true-blue" aria-hidden="true"></span>
                </div>

                <div *ngSwitchCase="'S4'">
                  <span class="cmx-icon-module-invoices-payments font-true-blue" aria-hidden="true"></span>
                </div>

                <div *ngSwitchCase="'S5'">
                  <span class="cmx-icon-module-user-management font-true-blue" aria-hidden="true"></span>
                </div>
              </div>
            </div>

            <div
              class="cmx-user-notifications__entry-data"
              [ngClass]="{
                'cmx-user-notifications__status-new': notification.statusCode === 'N',
                'cmx-user-notifications__status-seen': notification.statusCode === 'V',
                'cmx-user-notifications__status-read': notification.statusCode === 'R'
              }"
            >
              <div class="cmx-user-notifications__entry-proc-date-title">
                <div class="cmx-user-notifications__entry-process">
                  {{ notification.descProcessCode }} - {{ notification.descSubProcessCode }}
                </div>

                <div class="cmx-user-notifications__entry-proc-date-sep">&nbsp;</div>

                <div fxLayout="row" fxLayoutAlign="end center" class="cmx-user-notifications__entry-date">
                  {{ notification.isToday ? notification.localTime : notification.localDate }}
                </div>
              </div>

              <h4 class="cmx-user-notifications__entry-title">{{ notification.summary }}</h4>
            </div>
          </div>
        </div>
        <a
          class="cmx-user-notifications__main-call-to-action"
          (click)="goToUserNotifications()"
          *ngIf="_userNotificationTotalCount > 0"
        >
          {{ _translation.pt('views.header.all-notifications') }}
        </a>
      </div>
    </div>
    <div class="cmx-main-nav-header__item-separator" *ngIf="_enableUserNotifications"></div>
    <!-- END USER-NOTIFICATIONS -->

    <!-- START APP NAVIGATION CODE -->
    <div class="cmx-main-nav-header__item cmx-console-menu" *ngIf="isValidUser">
      <div
        id="consolesPopoutButton"
        class="popout__toggle"
        data-popout-target="#consolesPopout"
        (click)="toggleAppNavigation()"
      >
        <div class="cmx-main-nav-header__icon" aria-hidden="true">
          <span class="cmx-icon-modules-internal-consoles font-true-white" aria-hidden="true"></span>
        </div>
      </div>
      <div
        id="consolesPopout"
        class="cmx-console-menu__popout popout popout--bottom-left"
        [class.hidden]="!showAppNavigation"
        (clickOutside)="clickAppNavigationOutside($event)"
      >
        <h3 class="cmx-console-menu__heading">CEMEX Apps</h3>
        <ul class="cmx-console-menu__list">
          <li class="cmx-console-menu__item" role="button">
            <a class="cmx-console-menu__item-link" target="_blank" [href]="developerPortalUrl">
              <div class="cmx-console-menu__item-icon">
                <span class="cmx-icon-brakets cmx-console-menu__item-inner-icon" aria-hidden="true"></span>
              </div>
              <span class="cmx-console-menu__item-label"> {{ _translation.pt('views.header.developerCenter') }} </span>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="cmx-main-nav-header__item-separator"></div>
    <!-- END APP NAVIGATION CODE -->

    <!-- USER INFO -->
    <div class="cmx-main-nav-header__item user-menu" *ngIf="_userProfile">
      <div class="user-menu-wrapper">
        <div
          id="cmx-header-profile-menu"
          (click)="showProfile()"
          class="cmx-user-menu__icon popout__toggle"
          data-popout-target="#userMenuPopout"
        >
          <div class="cmx-main-nav-header__icon cmx-main-nav-header__icon--inverted">
            {{ _userInitials | uppercase }}
          </div>
        </div>
        <div
          id="userMenuPopout"
          [class.open]="_showUserProfile"
          (clickOutside)="clickOutside($event)"
          class="cmx-user-menu__container popout popout--bottom-left popout--with-border"
        >
          <div class="cmx-user-menu__user" data-tid="cmx-nav-header-btn-myprofile" id="cmx-nav-header-btn-myprofile"
          id="cmx-header-profile-link" [ngClass]="(disabledProfile || !isValidUser || showMyProfile) ? 'cursor-auto' : ''"
          (click)="!showMyProfile ? goToProfile() : null">
            <div class="cmx-main-nav-header__icon cmx-main-nav-header__icon--regular cmx-user-menu__user-icon">
              {{ _userInitials | uppercase }}
            </div>
            <div class="cmx-user-menu__user-details" data-tid="cmx-nav-header-btn-myprofile" id="cmx-nav-header-btn-myprofile">
              <h4 class="cmx-user-menu__user-name" data-tid="cmx-nav-header-btn-myprofile"
              id="cmx-nav-header-btn-myprofile">{{ _userFullName }}</h4>
              <span class="cmx-user-menu__user-email" data-tid="cmx-nav-header-btn-myprofile"
              id="cmx-nav-header-btn-myprofile">{{ _userProfile?.userAccount }}</span>
            </div>
            <!-- end .user-menu-details -->
          </div>
          <div class="cmx-user-menu__settings" *ngIf="!disabledProfile && isValidUser && showMyProfile">
            <button cmx-link id="cmx-nav-header-btn-myprofile" data-tid="cmx-nav-header-btn-myprofile" (click)="goToProfile()">
              {{ _translation.pt('views.header.lbl_myprofile') }}
            </button>
          </div>
          <!-- end .user-menu__my-profile -->
          <div *ngIf="useLanguageSelector && isValidUser">
            <div class="cmx-language accordion open">
              <div
                (click)="_showLangList = !_showLangList"
                class="cmx-language__heading accordion__heading"
                [class.cmx-language__heading--up]="_showLangList"
                [class.cmx-language__heading--down]="!_showLangList"
                role="button"
              >
                {{ _translation.pt('views.header.lbl_language') }}
              </div>
              <div *ngIf="_showLangList" class="cmx-language__options accordion__data">
                <ul class="cmx-language__options-list">
                  <ng-container *ngFor="let language of _languages">
                    <li
                      class="cmx-language__options-item"
                      [class.active]="language.isSelected"
                      role="button"
                      (click)="selectLang(language)"
                    >
                      {{ language.languageName }}
                    </li>
                  </ng-container>
                </ul>
              </div>
              <!-- end .language-selector -->
            </div>
          </div>
          <div class="cmx-user-menu__settings" *ngIf="!useLanguageSelector && isValidUser">
            <button cmx-link id="cmx-header-settings-link" (click)="goToSettings()">
              {{ _translation.pt('views.header.lbl_settings') }}
            </button>
          </div>
          <!-- end .user-menu__settings -->
          <div class="cmx-user-menu__settings" *ngIf="showCountrySupportContact && _countryConfig && isValidUser">
            <button cmx-link id="cmx-header-country-support-button" (click)="openCountrySupportContactModal()">
              {{ _translation.pt('views.header.lbl_contact') }}
            </button>
          </div>
          <!-- end .user-support_contact -->
          <div class="cmx-user-menu__sign-out">
            <button cmx-link id="cmx-header-logout-link" (click)="logout()" class="cmx-user-menu__sign-out-link">
              {{ _translation.pt('views.header.lbl_logout') }}
            </button>
          </div>
          <!-- end .user-menu__sign-out -->
          <!-- end .user-menu-container -->
        </div>
      </div>
      <!-- END USER INFO -->
    </div>
  </div>
  <div class="cmx-sub-nav-loader"></div>
</nav>

<cwc-header
  id="navHeader"
  *ngIf="version === 3"
  [showMenuButton]="isValidUser ? true : false"
  [menuButtonLabel]="menuButtonLabel"
  [variant]="variant ? variant : userCountry == 'IL' ? 'negative' : 'positive'"
  [dir]="rtl ? 'rtl' : 'ltr'"
  (cwcLogoClick)="gotoHome()"
  class="cmx-nav-header__component {{ variant }}"
  [ngClass]="{ 'cmx-nav-header__modal-open': isModalOpen }"
  [brandLogo]="userCountry == 'IL' ? 'cemex_logo_readymix_il' : 'cemex_logo'"
  (cwcSidenavToggle)="sidenavToggleClick()"
>
  <div
    class="cmx-nav-header__section cmx-nav-header__section--{{ userCountry == 'IL' ? 'il-' : '' }}separated"
    *ngIf="isValidUser"
  >
    <cwc-popover #helpPopover [rtl]="rtl ? true : false" [visibilityHidden]="false">
      <cwc-button slot="target" variant="link" white id="headerHelpButton" class="cwc-button--block">
        <span class="cmx-nav-header__help-button cmx-nav-header{{ userCountry == 'IL' ? '-il' : '' }}__label-help">
          {{ _translation.pt('views.global.need_help') }}
          <cwc-icon
            name="arrow-down-single"
            size="12px"
            [color]="userCountry == 'IL' ? 'true-white' : 'bright-blue-dark'"
          ></cwc-icon>
        </span>
        <span class="cmx-nav-header__icon cmx-nav-header__icon--help">
          <cwc-icon
            name="module-help-support"
            [color]="variant === 'negative' ? 'true-blue' : 'true-white'"
            size="1.5rem"
          ></cwc-icon>
        </span>
      </cwc-button>
      <div slot="content" class="cmx-nav-header__help-popover" id="headerHelpPopout">
        <div class="cmx-nav-header__help-sc" *ngIf="_showServiceCenter" (click)="goToSupportCenter()">
          <div class="cmx-nav-header__help-sc-icon">
            <cwc-icon
              name="support-center"
              [color]="variant === 'negative' ? 'true-blue' : 'true-white'"
              size="1.5rem"
            ></cwc-icon>
          </div>
          <div class="cmx-nav-header__help-sc-text">
            <h4>{{ _translation.pt('views.sidebar.ServiceCenter_App') }}</h4>
          </div>
        </div>
        <ul class="cmx-nav-header__help-menu" role="navigation">
          <li class="cmx-nav-header__help-menu-item">
            <cwc-button variant="link" (cwcClick)="feedbackClick()">
              {{ _translation.pt('views.header.feedbackDialog.feedBackTitle') }}
            </cwc-button>
          </li>
          <!-- end .user-menu__settings -->
          <li class="cmx-nav-header__help-menu-item" *ngIf="showCountrySupportContact && _countryConfig">
            <cwc-button
              variant="link"
              id="cmx-header-country-support-button"
              (cwcClick)="openCountrySupportContactModal()"
            >
              {{ _translation.pt('views.header.lbl_contact') }}
            </cwc-button>
          </li>
          <li class="cmx-nav-header__help-menu-item" id="cmx-header-walkme-button">
            <!-- we leave this li to accept the walkme button -->
            <!-- require this selector "div#cmx-header-feedback,div.cmx-feedback" and it need to have textContent-->
            <div id="cmx-header-feedback" class="btn-walkme">&nbsp;</div>
          </li>
        </ul>
      </div>
    </cwc-popover>
  </div>
  <div class="cmx-nav-header__section">
    <div class="cmx-nav-header__section-item" *ngIf="_enableUserNotifications && isValidUser">
      <span class="cmx-nav-header__icon-alert" *ngIf="_userNotificationUnreadCount > 0"></span>
      <!-- notifications -->
      <cwc-popover (cwcShow)="showUserNotifications()" #notificationsPopover [rtl]="rtl ? true : false">
        <cwc-button
          [title]="_translation.pt('views.header.lbl-notifications')"
          slot="target"
          variant="link"
          white
          id="userNotificationsPopoutButton"
          disabled="{{ isValidUser ? false : true }}"
        >
          <span class="cmx-nav-header__icon cmx-nav-header__icon--notifications">
            <cwc-icon
              name="notification"
              [color]="variant === 'negative' ? 'true-blue' : 'true-white'"
              size="1.5rem"
              [class.cursor-auto]="!isValidUser"
            ></cwc-icon>
          </span>
        </cwc-button>
        <div
          slot="content"
          class="cmx-nav-header__notifications-popover"
          id="userNotificationsPopout"
          *ngIf="isValidUser"
        >
          <div class="cmx-nav-header__notifications-loading" *ngIf="!_userNotificationsFirstLoad">
            <cwc-loader size="medium">
              <span slot="loading">{{ _translation.pt('views.notifications.spinner.title.loading') }}</span>
              <span slot="delay">{{ _translation.pt('views.notifications.spinner.title.delay') }}</span>
              <span slot="error">
                {{ _translation.pt('views.notifications.spinner.title.error') + ' ' }}
                <a href="#" onclick="location.reload()">{{
                  _translation.pt('views.notifications.spinner.title.error_link_refresh')
                }}</a>
              </span>
            </cwc-loader>
          </div>
          <div
            class="cmx-nav-header__notifications-empty"
            *ngIf="_userNotificationsFirstLoad && _userNotificationTotalCount === 0"
            (click)="onAllNotification()"
          >
            <cwc-icon-animated variant="informative" size="80"></cwc-icon-animated>
            {{ _translation.pt('views.header.no-notifications') }}
          </div>
          <div class="cmx-nav-header__notifications-list" *ngIf="_userNotificationShortList.length > 0">
            <h3 class="cmx-nav-header__notifications-heading">
              {{ _translation.pt('views.header.notificationsHeading') }}
            </h3>
            <div
              *ngFor="let notification of _userNotificationShortList; index as i"
              id="userNotificationEntry{{ i }}"
              class="{{
                'cmx-nav-header__notifications-entry cmx-nav-header__notifications-entry--' + notification.statusCode
              }}"
              (click)="showUserNotificationDetail(notification.notificationId); openNotificationDetailDialog()"
              [ngClass]="{
                'cmx-nav-header__notifications-entry--new': notification.statusCode === 'N',
                'cmx-nav-header__notifications-entry--seen': notification.statusCode === 'V',
                'cmx-nav-header__notifications-entry--read': notification.statusCode === 'R'
              }"
              role="button"
            >
              <div class="cmx-nav-header__notifications-entry-icon">
                <cwc-icon size="24px" [name]="_serviceIcon[notification.processCode]"></cwc-icon>
              </div>
              <div class="cmx-nav-header__notifications-entry-body">
                <div class="cmx-nav-header__notifications-entry-process">{{ notification.descProcessCode }}</div>
                <div class="cmx-nav-header__notifications-entry-description">{{ notification.descSubProcessCode }}</div>
                <div class="cmx-nav-header__notifications-entry-date">
                  {{ notification.isToday ? notification.localTime : notification.localDate }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="cmx-nav-header__notifications-footer"
            *ngIf="_userNotificationsFirstLoad && _userNotificationTotalCount > 0"
          >
            <cwc-button variant="link" (cwcClick)="goToUserNotifications()">
              {{ _translation.pt('views.header.all-notifications') }}
            </cwc-button>
          </div>
        </div>
      </cwc-popover>
    </div>
    <div class="cmx-nav-header__section-item" *ngIf="isValidUser">
      <!--consoles navigation-->
      <cwc-popover #consolesPopover [rtl]="rtl ? true : false">
        <cwc-button slot="target" variant="link" white id="consolesPopoutButton">
          <span class="cmx-nav-header__icon cmx-nav-header__icon--consoles">
            <cwc-icon
              name="modules-internal-consoles"
              [color]="variant === 'negative' ? 'true-blue' : 'true-white'"
              size="1.25rem"
            ></cwc-icon>
          </span>
        </cwc-button>
        <div slot="content" class="cmx-nav-header__apps-popover" id="consolesPopout">
          <h3 class="cmx-nav-header__apps-heading">CEMEX Apps</h3>
          <ul class="cmx-nav-header__apps-list">
            <li class="cmx-nav-header__apps-item" role="button">
              <a #varDeveloperPortalUrl class="hide-link" target="_blank" [href]="developerPortalUrl"></a>
              <cwc-button variant="link" (cwcClick)="varDeveloperPortalUrl.click(); goToDeveloperPortal()">
                <div class="cmx-nav-header__apps-link">
                  <div class="cmx-nav-header__apps-icon-wrapper">
                    <cwc-icon name="brakets" color="true-white" aria-hidden="true"></cwc-icon>
                  </div>
                  <span class="cmx-nav-header__apps-label">
                    {{ _translation.pt('views.header.developerCenter') }}
                  </span>
                </div>
              </cwc-button>
            </li>
          </ul>
        </div>
      </cwc-popover>
    </div>
    <div class="cmx-nav-header__section-item">
      <!-- user menu -->
      <cwc-popover *ngIf="_userProfile" #profilePopover [rtl]="rtl ? true : false">
        <cwc-avatar
          id="cmx-header-profile-menu"
          class="cmx-nav-header__user-avatar"
          slot="target"
          size="small"
          [name]="_userFullName"
          [variant]="'positive'"
        >
        </cwc-avatar>
        <div slot="content" class="cmx-nav-header__user-popover {{ userCountry }}" id="userMenuPopout">
          <div class="cmx-nav-header__user-profile cmx-nav-header__user-item" id="cmx-header-profile-link"
          [ngClass]="(disabledProfile || !isValidUser || showMyProfile) ? 'cursor-auto' : ''"
          (click)="!showMyProfile ? goToProfile() : null"
          role="button">
            <div class="cmx-nav-header__user-profile-avatar" data-tid="cmx-nav-header-btn-myprofile"
            id="cmx-nav-header-btn-myprofile">
              <cwc-avatar size="small" [name]="_userFullName" [variant]="'negative'"></cwc-avatar>
            </div>
            <div class="cmx-nav-header__user-profile-details" data-tid="cmx-nav-header-btn-myprofile"
            id="cmx-nav-header-btn-myprofile">
              <h4 class="cmx-nav-header__user-name">{{ _userFullName }}</h4>
              <span class="cmx-nav-header__user-email">{{ _userProfile?.userAccount }}</span>
            </div>
          </div>
          <div class="cmx-nav-header__user-my-profile cmx-nav-header__user-item" *ngIf="!disabledProfile && isValidUser && showMyProfile">
            <cwc-button variant="link" id="cmx-header-profile-link" (cwcClick)="goToProfile()">
              {{ _translation.pt('views.header.lbl_myprofile') }}
            </cwc-button>
          </div>
          <div class="cmx-nav-header__user-settings cmx-nav-header__user-item" *ngIf="!useLanguageSelector && isValidUser">
            <cwc-button variant="link" id="cmx-header-settings-link" (cwcClick)="goToSettings()">
              {{ _translation.pt('views.header.lbl_settings') }}
            </cwc-button>
          </div>
          <div class="cmx-nav-header__user-sign-out cmx-nav-header__user-item">
            <cwc-button variant="link" id="cmx-header-logout-link" (cwcClick)="logout()">
              {{ _translation.pt('views.header.lbl_logout') }}
            </cwc-button>
          </div>
        </div>
      </cwc-popover>
    </div>
  </div>
</cwc-header>

<div
  id="feedbackDetailDialog"
  class="cmx-nav-header__feedback-dialog {{ feedbackDialogOpen ? 'cmx-nav-header__feedback-dialog--visible' : '' }}"
  *ngIf="_formLangUrl"
  (click)="closeFeedbackDialog()"
>
  <div class="cmx-nav-header__feedback-dialog-wrap">
    <button class="cmx-nav-header__feedback-form-close" (click)="closeFeedbackDialog()">
      <cwc-icon name="close" size="24px"></cwc-icon>
    </button>
    <div [id]="qualtricsId"></div>
    <div id="qualtricsEvergreenFeedback" class="cmx-nav-header__feedback-dialog-scroller"></div>
  </div>
</div>

<cwc-modal #notificationDetailDialog overlayZIndex="1500" [rtl]="rtl ? true : false" id="notificationDetailDialog">
  <div
    class="cmx-nav-header__notifications-modal {{
      _userNotificationLoading ? 'cmx-nav-header__notifications-modal--loading' : ''
    }}"
  >
    <div class="cmx-nav-header__notifications-loader"><cwc-loader></cwc-loader></div>
    <div
      class="cmx-nav-header__notifications-modal-detail"
      [innerHTML]="_userNotificationContent | safe"
      id="notificationDetailDialogContent"
    ></div>
  </div>
</cwc-modal>

<cwc-modal #countrySupportContactModal overlayZIndex="1500" [rtl]="rtl ? true : false" id="countrySupportContactModal">
  <h2 slot="header" class="cmx-nav-header__contact-heading">
    {{ _translation.pt('views.header.modal.contactTitle') }}
  </h2>
  <cwc-grid class="cmx-nav-header__contact-content">
    <cwc-cell class="cmx-nav-header__contact-content-item" colspan="6" mobileColspan="12" *ngIf="emails.length > 0">
      <div class="cmx-nav-header__contact-icon-wrapper">
        <cwc-icon class="cmx-nav-header__contact-icon" name="e-mail" size="76px"></cwc-icon>
      </div>
      <div *ngFor="let email of emails; let i = index" class="cmx-dialog-content__label">
        <a class="cmx-nav-header__contact-link" href="mailto:{{ email }}">{{ email }}</a>
      </div>
    </cwc-cell>
    <cwc-cell class="cmx-nav-header__contact-content-item" colspan="6" mobileColspan="12" *ngIf="phones.length > 0">
      <div class="cmx-nav-header__contact-icon-wrapper">
        <cwc-icon class="cmx-nav-header__contact-icon" name="phone" size="76px"></cwc-icon>
      </div>
      <div *ngFor="let phone of phones; let i = index" [dir]="rtl ? 'rtl' : 'ltr'">
        <div class="cmx-dialog-content__label">
          <a class="cmx-nav-header__contact-link" href="tel:{{ phone }}">{{ phone }}</a>
        </div>
      </div>
    </cwc-cell>
    <cwc-cell *ngIf="userCountry === 'IL' && phones.length > 0" colspan="12">
      <div id="cmx-header-dialog-schedule">
        <div [dir]="rtl ? 'rtl' : 'ltr'" class="cmx-dialog-content__label cmx-dialog-content__label-support">
          {{ _translation.pt('views.header.modal.il.contact') }}
        </div>
        <br />
        <span [dir]="rtl ? 'rtl' : 'ltr'" class="cmx-dialog-content__label cmx-dialog-content__label-support">
          {{ _translation.pt('views.header.modal.il.openHours') }}
          {{ _translation.pt('views.header.modal.il.schedule') }}
        </span>
        <br />
        <span [dir]="rtl ? 'rtl' : 'ltr'" class="cmx-dialog-content__label cmx-dialog-content__label-support">
          {{ _translation.pt('views.header.modal.il.contactService') }}
        </span>
      </div>
    </cwc-cell>
  </cwc-grid>
</cwc-modal>
