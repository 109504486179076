import {
  Component,
  Input,
  forwardRef,
  ChangeDetectorRef,
  Injector,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from "@angular/core";
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NgControl,
  Validators,
} from "@angular/forms";

import { Subscription, Subject } from "rxjs";

import { BasicInput } from "./basic-input";
import { NgAutonumericComponent } from "./autonumeric.component";

export type TextAlign = "left" | "right" | "center";

export const INPUT_NUMBER_VALUE_ACCESSOR: any = {
  multi: true,
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => NumberInputComponent),
};

@Component({
  providers: [INPUT_NUMBER_VALUE_ACCESSOR],
  // tslint:disable-next-line
  selector: "number-input",
  styleUrls: [
    "./../../../../../../../../../scssv4/cmx-components/cmx-form-field/v4/cmx-form-field.component.scss",
  ],
  templateUrl: "./number-input.component.html",
})
export class NumberInputComponent
  extends BasicInput
  implements ControlValueAccessor, AfterViewInit
{
  @ViewChild("autoElement") public autoElement: NgAutonumericComponent;

  @Input()
  get min(): number {
    return this._min;
  }
  set min($value: number) {
    if ($value !== undefined && typeof $value === "number") {
      this._min = $value === -Infinity ? Number.MIN_SAFE_INTEGER : $value;
      this.resetValidatorsMinMax();
    }
  }

  @Input()
  get max(): number {
    return this._max;
  }
  set max($value: number) {
    if ($value !== undefined && typeof $value === "number") {
      this._max = $value === Infinity ? Number.MAX_SAFE_INTEGER : $value;
      this.defaultConfig.maximumValue = this._max;
      this.resetValidatorsMinMax();
      this.autoElement.forceRefresh();
    }
  }

  @Input() public step = 1;

  @Input()
  get value(): any {
    // tslint:disable-next-line:no-null-keyword
    return this._value !== null ? Number(this._value) : null;
  }
  set value(newValue: any) {
    if (
      typeof newValue === "number" ||
      typeof newValue === "string" ||
      newValue === null
    ) {
      this.resetValidatorsMinMax();
      this._value = newValue;
      this.onChange(newValue);
      this.propagateChange(newValue);
      this.propagateTouch();
      this.ref.markForCheck();
      this.autoElement.forceRefresh();
    }
  }

  @Input() public rtl = false;

  @Input()
  get thousandSeparator(): any {
    return this._thousandSeparator;
  }
  set thousandSeparator(newValue: any) {
    this._thousandSeparator = newValue;
    this.defaultConfig.digitGroupSeparator = newValue;
    if (newValue === this.decimalSeparator) {
      this.defaultConfig.decimalCharacter = "·";
    }
    this.autoElement.forceRefresh();
  }

  @Input()
  get decimalSeparator(): any {
    return this._decimalSeparator;
  }
  set decimalSeparator(newValue: any) {
    if (newValue === this.thousandSeparator) {
      this.defaultConfig.digitGroupSeparator = "·";
    }
    this._decimalSeparator = newValue;
    this.defaultConfig.decimalCharacter = newValue;
    this.autoElement.forceRefresh();
  }

  @Input()
  get decimalNumber(): number {
    return this._decimalNumber;
  }
  set decimalNumber(newValue: number) {
    this._decimalNumber =
      newValue !== undefined && newValue !== null ? newValue : 0;
    this.defaultConfig.decimalPlaces = this._decimalNumber;
    this.autoElement.forceRefresh();
  }

  @Input()
  set disabled(newValue: boolean) {
    this._disabled = newValue;
    this.autoElement.forceRefresh();
  }
  get disabled(): boolean {
    return this._disabled;
  }

  @Input() public align: TextAlign = "center";

  @Input() public hideControls = false;

  @Input()
  set prefix(newValue: string) {
    if (newValue) {
      this.defaultConfig.currencySymbol = newValue;
      this.defaultConfig.currencySymbolPlacement = "p";
      this.autoElement.forceRefresh();
    }
  }

  @Input()
  set suffix(newValue: string) {
    if (newValue) {
      this.defaultConfig.currencySymbol = newValue;
      this.defaultConfig.currencySymbolPlacement = "s";
      this.autoElement.forceRefresh();
    }
  }

  public valueChanged: Subject<number> = new Subject<number>();
  public subscription: Subscription;
  public minPassed: Subject<number> = new Subject<number>();
  public minSubscription: Subscription;
  public formatValueSubj: Subject<number> = new Subject<number>();
  public formatSub: Subscription;
  public _value: any = 0;
  public _max: number = Number.MAX_SAFE_INTEGER;
  public _thousandSeparator = ",";
  public _decimalSeparator = ".";
  public defaultConfig: any = {
    currencySymbol: "",
    currencySymbolPlacement: "s",
    decimalCharacter: this._decimalSeparator,
    decimalPlaces: 2,
    digitGroupSeparator: this._thousandSeparator,
    emptyInputBehavior: "null",
    maximumValue: this._max,
    minimumValue: 0,
    readOnly: false,
    showWarnings: false,
  };

  _decimalNumber = 0;
  _min = 0;
  _disabled = false;

  constructor(
    private ref: ChangeDetectorRef,
    public injector: Injector,
    element: ElementRef
  ) {
    super();
  }

  public onChange: any = (_: any) => {
    //
  };

  public onTouched: any = () => {
    //
  };

  public writeValue(value: any): void {
    this.value = value;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
    this.onChange(this.value);
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public touched(): void {
    this.onTouched();
  }

  public setDisabledState(value: boolean): void {
    this._disabled = value;
    this.defaultConfig.readOnly = value;
    this.autoElement.forceRefresh();
  }

  public onChangeValue(value: any): void {
    this.value = value;
    this.valueChanged.next(value);
  }

  public increaseValue(): void {
    const decimals: number =
      this.decimalNumber !== undefined ? this.decimalNumber : 0;
    if (
      typeof this.max !== "undefined" &&
      parseFloat(parseFloat(this.value).toFixed(decimals)) + this.step <=
        this.max
    ) {
      this.value = this.value = parseFloat(this.value) + this.step;
    } else {
      this.value = this.max;
    }
  }

  public decreaseValue(): void {
    const decimals: number =
      this.decimalNumber !== undefined ? this.decimalNumber : 0;
    if (
      parseFloat(parseFloat(this.value).toFixed(decimals)) - this.step <
      this.min
    ) {
      this.value = this.min;
    } else if (
      typeof this.max !== "undefined" &&
      parseFloat(parseFloat(this.value).toFixed(decimals)) - this.step >
        this.max
    ) {
      this.value = this.max;
    } else {
      this.value = parseFloat(this.value) - this.step;
    }
  }

  public propagateChange: (value: any) => void = (value) => {
    //
  };

  public propagateTouch: () => any = () => {
    //
  };

  public ngAfterViewInit(): void {
    //
  }

  public fakeValidator(activate: boolean): void {
    const ngControl: NgControl = this.injector.get(NgControl);
    if (activate) {
      ngControl.control.setErrors({ incorrect: true });
    } else {
      ngControl.control.setErrors(undefined);
    }
  }

  private resetValidatorsMinMax(): void {
    const ngControl: NgControl = this.injector.get(NgControl);
    if (ngControl && ngControl.control) {
      ngControl.control.setValidators([
        Validators.min(this.min),
        Validators.max(this.max),
      ]);
      setTimeout(() => {
        ngControl.control.updateValueAndValidity();
      });
    }
  }
}
