import { Moment } from 'moment/moment';
import { Customer } from '../auth';
import { OrderItem } from '../order-items';
import { ShippingCondition } from '../shipping-conditions/shipping-condition';
import { Contact } from './contact';
import { Jobsite } from './jobsite';
import { OrderStatus } from './order-status';
import { OrderType } from './order-type';
import { PointOfDelivery } from './point-of-delivery';
import { ISalesArea } from './sales-area';
import { User } from './user';

export interface Order {
  contact: Contact;
  createdDateTime: string;
  customer: Customer;
  draftDateTime: string;
  instructions: string;
  isNew?: boolean;
  items: OrderItem[];
  jobsite: Jobsite;
  orderId: number;
  orderName: string;
  orderType: OrderType;
  orderCode?: string;
  messages?: string;
  pointOfDelivery: PointOfDelivery;
  programmedDateTime: string;
  purchaseOrder: string;
  requestedDateTime: string;
  salesArea: ISalesArea;
  shippingCondition: ShippingCondition;
  status: OrderStatus;
  totalAmount: number;
  totalQuantity: number;
  updatedDateTime: string;
  user: User;
  // tslint:disable-next-line:no-any
  lastChangedBy?: any;
  viewedAt?: string;
  validCancelationDateTime?: Moment;
  validModificationDateTime?: Moment;
  _selected?: boolean;
}
