import { Link } from './link';

/**
 * Payment term used for a job-site or order-item
 */
export interface PaymentTerm {
  paymentTermId: number;
  paymentTermCode?: string;
  /**
   * Description is to be shown for Colombia
   */
  paymentTermDesc?: string;
  paymentTermDefault?: boolean;
  paymentTermType?: PaymentTermType;
  links?: Link | Link[];
  /**
   * Applies only to a payment-term for a customer
   */
  customerId?: number;
}

/**
 * A description of a type of payment-term, as returned by the API
 */
export interface PaymentTermType {
  paymentTermTypeId: number;
  paymentTermTypeCode: string;
  paymentTermTypeDesc: string;
}
