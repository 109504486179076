export class SharedFunctions {
    public filterData(items: any[], filter: { [x: string]: string; jobsiteCode?: string; jobsiteDesc?: string; customerCode?: string; customerDesc?: string; vat?: string; fullName?: string; userAccount?: string; cityDesc?: string; requesterName?: string; }) {
        if (filter && Array.isArray(items)) {
            const filterKeys = Object.keys(filter);
            return items.filter((item) => {
                return filterKeys.some((keyName) => {
                    const value = filter[keyName].replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
                    return new RegExp(value, 'gi').test(item[keyName]) || value === '';
                });
            });
        } else {
            return items;
        }
    }

    public getUserLanguageByCountry(countryCode: string):string {
        const countryName: string = '';
        if (countryCode === null || countryCode === '' || countryCode === undefined) { return countryName; }
        const countryCodeFormatted = countryCode.trim().toUpperCase();
        return countryCodeFormatted === 'MX' ? 'views.header.spanish' : 'views.header.english';
    }

    public getCountryName(countryCode: string): string {
        let countryName: string = '';
        if (countryCode === null || countryCode === '' || countryCode === undefined) { return countryName; }

        const countryCodeFormatted = countryCode.trim().toUpperCase();

        if (countryCodeFormatted === 'MX') {
            countryName = 'views.global.mxLabel';
        } else {
            countryName = 'views.global.usLabel';
        }

        return countryName;
    }

    public getStatusName(statusCode: string): string {
        if (statusCode === 'N' || statusCode === 'New') {
            return 'views.global.statusNew';
        } else if (statusCode === 'A' || statusCode === 'Approved') {
            return 'views.global.statusApproved';
        } else if (statusCode === 'R' || statusCode === 'Rejected') {
            return 'views.global.statusRejected';
        } else if (statusCode === 'P' || statusCode === 'Pending') {
            return 'views.global.statusPending';
        } else if (statusCode === 'C' || statusCode === 'Cancelled') {
            return 'views.global.statusCancelled';
        } else {
            return '';
        }
    }

    public getRequestTypeName(requestType: string): string {
      if (requestType === 'R' || requestType === 'Role') {
        return 'views.requestDetailModal.lblRole';
      } else if (requestType === 'D' || requestType === 'Data') {
        return 'views.requestDetailModal.lblData';
      } else if (requestType === 'S') {
        return 'views.requestDetailModal.lblNewAccess';
      } else { return ''; }
    }

    public formatRolesString(rolesList: string): string {
        let formattedRolesList: string = '';
        const roles: string[] = rolesList.split(',');
        if (roles.length > 0) {
            roles.forEach((role) => {
                if (role) {
                    formattedRolesList += role + ', ';
                }
            });
        }

        if (formattedRolesList.slice(-1) === ' ') {
            formattedRolesList = formattedRolesList.substr(0, formattedRolesList.length - 2);
        }

        return formattedRolesList;
    }
}
