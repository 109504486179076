import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { FlexLayoutModule } from "@angular/flex-layout";
import { CmxStepsComponent } from "./components/cmx-steps.component";
import { CmxStepComponent } from "./components/cmx-step.component";
import { CmxButtonModule } from "src/app/cmx-button/v4/src/cmx-button.module";

@NgModule({
  declarations: [CmxStepsComponent, CmxStepComponent],
  exports: [CmxStepsComponent, CmxStepComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    CmxButtonModule,
  ],
})
export class CmxStepsModule {}
