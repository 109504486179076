<ul class="breadcrumb" dir="rtl ? 'rtl': 'ltr'">
  <li *ngFor="let breadcrumb of breadCrumbs" class="breadcrumb__item" (click)="onClick(breadcrumb)">
    <span *ngIf="breadcrumb?.link !== undefined">
      <a *ngIf="breadcrumb?.param !== undefined" class="breadcrumb__link" [routerLink]="breadcrumb.link"
        [queryParams]="{id: breadcrumb.param}">{{ translationService.pt(breadcrumb.item) }}</a>
      <a *ngIf="breadcrumb?.param === undefined" class="breadcrumb__link" [routerLink]="breadcrumb.link">
        {{ translationService.pt(breadcrumb.item) }}</a>
    </span>
    {{ breadcrumb?.link == undefined ? translationService.pt(breadcrumb.item) : ""}}
  </li>
</ul>
