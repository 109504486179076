import {
  Component,
  AfterContentInit,
  OnDestroy,
  forwardRef,
  Input,
  Output,
  EventEmitter,
  Renderer2,
  ElementRef,
  ViewChild,
  ContentChildren,
  QueryList,
  HostListener,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

import { Subscription } from "rxjs";

import { CmxOptionComponent } from "./../cmx-option/cmx-option.component";
import { CmxOptionSelectEvent } from "../../models/cmx-select.classes";

export const CMX_SELECT_VALUE_ACCESSOR: any = {
  multi: true,
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CmxSelectComponent),
};

export interface ICmxSelectTexts {
  searchEmptyResult: string;
}

@Component({
  providers: [CMX_SELECT_VALUE_ACCESSOR],
  selector: "cmx-select",
  styleUrls: [
    "./../../../../../../../../../scssv4/cmx-components/cmx-form-field/v4/cmx-select.component.scss",
  ],
  templateUrl: "./cmx-select.component.html",
})
export class CmxSelectComponent
  implements AfterContentInit, OnDestroy, ControlValueAccessor
{
  @Output() public valueChange: EventEmitter<any> = new EventEmitter<any>();
  @ContentChildren(CmxOptionComponent)
  public optionsQueryList: QueryList<CmxOptionComponent>;
  public showOptions = false;
  public viewportHeight = 0;
  public searchValue = "";
  public _rtl = false;
  public _forceTop: boolean;
  public resultsCount = 0;
  private selectedValues: any[] = new Array<any>();
  @ViewChild("itemsPositioner") private itemsPositioner: ElementRef;
  private childHeight = 48;
  private selectedOption: CmxOptionComponent;
  private selectedOptions: string[] = new Array<string>();
  private originalPlaceholder: string;
  private _value: any;
  private _placeholder: string;
  private _disabled = false;
  private _search = false;
  private _searchPlaceholder: string;
  private optionsChangesSubscription: Subscription;
  private optionsSubscriptions: Subscription[] = [];
  private _texts: ICmxSelectTexts;
  private _selectedItem: CmxOptionComponent;
  private _isMultipleSelect: boolean;
  constructor(private renderer: Renderer2, private elRef: ElementRef) {}
  @HostListener("mouseover")
  public onHover(): void {
    if (this.disabled) {
      this.renderer.setStyle(
        this.elRef.nativeElement,
        "border",
        "1px solid #B0B0B0"
      );
    }
  }
  @Input()
  set value($value: any) {
    if ($value !== undefined && $value !== "") {
      if (this.optionsQueryList) {
        this.UpdateValues(
          undefined,
          ($val: any) => {
            if ($val !== undefined) {
              this._value = $val;
              this.propagateChange(this._value);
              this.propagateTouch();
              this.updatePlaceHolder();
              this.valueChange.emit(this._value);
            }
          },
          $value
        );
      } else {
        this._value = $value;
        this.valueChange.emit(this._value);
      }
    }
  }
  get value(): any {
    return this._value;
  }

  @Input()
  set disabled($value: boolean) {
    if ($value !== undefined) {
      this._disabled = $value;
    }
    if ($value) {
      this.renderer.setStyle(
        this.elRef.nativeElement,
        "backgroundColor",
        "#D6D6D6"
      );
      this.renderer.setStyle(this.elRef.nativeElement, "cursor", "not-allowed");
    } else {
      this.renderer.setStyle(this.elRef.nativeElement, "cursor", "pointer");
    }
  }
  get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  set placeholder($value: string) {
    if ($value !== undefined) {
      this._placeholder = $value;
    }
  }
  get placeholder(): string {
    return this._placeholder;
  }

  @Input()
  set texts($value: ICmxSelectTexts) {
    if ($value !== undefined) {
      this._texts = $value;
    }
  }
  get texts(): ICmxSelectTexts {
    return this._texts;
  }

  @Input()
  set search($value: boolean) {
    if ($value !== undefined) {
      this._search = $value;
    }
  }
  get search(): boolean {
    return this._search;
  }

  @Input()
  set searchPlaceholder($value: string) {
    if ($value !== undefined) {
      this._searchPlaceholder = $value;
    }
  }
  get searchPlaceholder(): string {
    return this._searchPlaceholder;
  }

  @Input()
  set rtl($value: boolean) {
    if ($value !== undefined) {
      this._rtl = $value;
    }
  }
  get rtl(): boolean {
    return this._rtl;
  }

  @Input()
  set forceTop($value: boolean) {
    this._forceTop = $value;
  }
  get forceTop(): boolean {
    return this._forceTop;
  }

  public ngAfterContentInit(): void {
    if (this.placeholder) {
      this.originalPlaceholder = this.placeholder;
    }
    this.firstProcessOptions();
    this.optionsChangesSubscription = this.optionsQueryList.changes.subscribe(
      () => {
        this.processOptions();
        this.UpdateValues(
          undefined,
          ($val: any) => {
            if ($val !== undefined) {
              this._value = $val;
              this.updatePlaceHolder();
              this.propagateTouch();
              this.valueChange.emit(this._value);
            }
          },
          this.value
        );
      }
    );
    this.writeValue(this._value);

    let counter = 0;
    this.optionsQueryList.forEach((item: CmxOptionComponent) => {
      item.selectedid = counter;
      counter++;
    });
    if (this.optionsQueryList) {
      this._isMultipleSelect = this.isMultipelSelect();
    }
    this.UpdateValues(
      undefined,
      ($val: any) => {
        if ($val !== undefined) {
          this._value = $val;
          this.propagateChange($val);
          this.propagateTouch();
          this.updatePlaceHolder();
          this.valueChange.emit($val);
        }
      },
      this.value
    );
  }

  public ngOnDestroy(): void {
    this.optionsChangesSubscription.unsubscribe();
    this.dropSubscriptions();
  }

  public propagateChange: (value: any) => void = (value) => {
    //
  };

  public propagateTouch: () => any = () => {
    //
  };

  /**
   * @description Registers the control's value.
   * @param $value Value given to the cmx-select.
   */
  public writeValue($value: any): void {
    if ($value !== undefined && $value !== "") {
      this.value = $value;
      this.valueChange.emit(this._value);
    }
  }

  /**
   * @description Registers a callback function to be invoked when the control's value changes from user input.
   * @param $fn Function that the change event will execute.
   */
  public registerOnChange($fn: any): void {
    this.propagateChange = $fn;
  }

  /**
   * @description Registers a callback function to be invoked when the control is blurred by the user.
   * @param $fn Function that the touched event will execute.
   */
  public registerOnTouched($fn: any): void {
    this.propagateTouch = $fn;
  }

  /**
   * @description Registers the disabled state to the form control.
   * @param $isDisabled Value that determines if the control will be disabled.
   */
  public setDisabledState($isDisabled: boolean): void {
    this.disabled = $isDisabled;
  }

  public toggleOptions(): void {
    if (
      !this.disabled &&
      this.optionsQueryList &&
      this.optionsQueryList.length > 0
    ) {
      this.showOptions = !this.showOptions;
      const bodyElement: Element = document.getElementsByTagName("BODY")[0];
      if (this.showOptions) {
        setTimeout(() => {
          this.isInsideViewport();
        }, 10);
      } else {
        this.renderer.setStyle(bodyElement, "overflow", undefined);
        this.searchValue = "";
        this.optionsQueryList.map(
          (option: CmxOptionComponent) => (option.hide = false)
        );
      }
    }
    this.setResultsCount();
  }

  /**
   * @description Sets the selected option
   * @param $event Value given to the control.
   */
  public filterSelectOptions($event: string): void {
    this.searchValue = $event;
    if (this.optionsQueryList.length > 0 && this.searchValue.length > 0) {
      this.optionsQueryList.forEach(
        (option: CmxOptionComponent) =>
          (option.hide = !(
            option.getViewValue() !== undefined &&
            option
              .getViewValue()
              .toLowerCase()
              .includes(this.searchValue.toLowerCase())
          ))
      );
    } else {
      this.optionsQueryList.forEach(
        (option: CmxOptionComponent) => (option.hide = false)
      );
    }
    this.setResultsCount();
  }

  @HostListener("click", ["$event"])
  public open(event: any): void {
    const id: any = event.target.getAttribute("selectedid");
    if (event.target.className.indexOf("select-option") !== -1) {
      this.setselectedItem(id);
    }
  }

  private UpdateValues(
    $SelectedOption?: CmxOptionComponent,
    callback?: any,
    val?: any
  ): void {
    if (this.optionsQueryList) {
      this._isMultipleSelect = this.isMultipelSelect();
      if ($SelectedOption) {
        let optionIndex: any;
        if (!$SelectedOption.isSelectAll) {
          optionIndex = this.selectedValues.findIndex(
            (x: string) => x === $SelectedOption.value
          );
          if ($SelectedOption.selected && optionIndex < 0) {
            this.selectedValues.push($SelectedOption.value);
          } else if (!$SelectedOption.selected && optionIndex > -1) {
            this.selectedValues.splice(optionIndex, 1);
          }
        }
        this._value = this.selectedValues;
        this.propagateChange(this._value);
        this.propagateTouch();
        this.valueChange.emit(this._value);
      } else {
        let index: any;
        // selecting options from values
        if (val && val instanceof Array) {
          val.forEach((element: any) => {
            index = this.optionsQueryList
              .toArray()
              .findIndex((x: CmxOptionComponent) => x.value === element);
            if (index > -1) {
              this.optionsQueryList.toArray()[index].select(undefined, true);
            }
          });
        } else {
          if (val) {
            index = this.optionsQueryList
              .toArray()
              .findIndex((x: CmxOptionComponent) => x.value === val);
            if (index > -1) {
              this.optionsQueryList.toArray()[index].select(undefined, true);
            }
          }
        }
        const selectedValuesAux: any[] = new Array<any>();
        let optionIndex: any;
        this.optionsQueryList.forEach(($option: CmxOptionComponent) => {
          if (!$option.isSelectAll) {
            optionIndex = selectedValuesAux.findIndex(
              (x: string) => x === $option.value
            );
            if ($option.selected && optionIndex < 0) {
              selectedValuesAux.push($option.value);
            } else if (!$option.selected && optionIndex > -1) {
              selectedValuesAux.splice(optionIndex, 1);
            }
          }
        });
        this.selectedValues = selectedValuesAux;
        this.updatePlaceHolder();
        let valParam: any;
        if (this._isMultipleSelect) {
          valParam = this.selectedValues;
        } else {
          valParam = this.selectedValues[0];
        }
        if (callback) {
          callback(valParam);
        }
      }
    }
  }

  /**
   * @description Sets the selected option based on a value.
   * If no option can be found with the designated value, the select trigger is cleared.
   * @param $value Value given to the control.
   */
  private setSelectionByValue($value: any): void {
    if (this.optionsQueryList.length > 0) {
      this.selectOption($value, this.optionsQueryList.toArray());
    } else {
      const optionsListSubscription: Subscription =
        this.optionsQueryList.changes.subscribe(($opts: any) => {
          const $optionsArray: CmxOptionComponent[] = $opts.toArray();
          this.selectOption($value, $optionsArray);
          optionsListSubscription.unsubscribe();
        });
    }
  }

  private selectOption(
    $value: any,
    $optionsArray: CmxOptionComponent[],
    emitEvent: boolean = true
  ): void {
    const optionIndex: number = $optionsArray.findIndex(
      ($option: CmxOptionComponent) => $option.value === $value
    );
    if (optionIndex > -1) {
      $optionsArray[optionIndex].select(emitEvent);
      this.selectedOption = $optionsArray[optionIndex];
      this.placeholder = this.selectedOption.getViewValue();
    } else {
      this.clearSelection();
    }
  }

  private listenToOptions(): void {
    this.optionsQueryList.forEach(($option: CmxOptionComponent) => {
      const $sub: any = $option.optionSelected.subscribe(
        ($event: CmxOptionSelectEvent) => {
          if ($event.isUserInput && this.value !== $option) {
            this.onSelect($option);
          }
        }
      );
      this.optionsSubscriptions.push($sub);
    });
  }

  private firstListenToOptions(): void {
    this.optionsQueryList.forEach(($option: CmxOptionComponent) => {
      const $sub: any = $option.optionSelected.subscribe(
        ($event: CmxOptionSelectEvent) => {
          if ($event.isUserInput && this.value !== $option) {
            this.onSelect($option);
          }
        }
      );
      this.optionsSubscriptions.push($sub);
    });
  }

  /**
   * @description When a new option is selected, deselects the others and closes the panel.
   * @param $option Reference of a CmxOptionComponent object.
   */
  private onSelect($option: CmxOptionComponent): void {
    this.selectedOption = $option;
    if (!$option.multipleSelect) {
      if (this.showOptions) {
        this.toggleOptions();
      }
      this.deselectOptions($option);
    }
    if ($option.isSelectAll && $option.selected) {
      this.selectAllOptions();
    } else if ($option.isSelectAll && !$option.selected) {
      this.deselectOptions();
    }
    this.UpdateValues(undefined, ($valParam: any) => {
      this.selectedOption = $option;
      this._value = $valParam;
      this.propagateChange($valParam);
      this.propagateTouch();
      this.valueChange.emit($valParam);
    });
  }

  /**
   * @description Clears the select trigger and deselects every option in the list.
   */
  private clearSelection(): void {
    this._value = undefined;
    this.selectedOption = undefined;
    this.placeholder = this.originalPlaceholder;
    this.deselectOptions();
  }

  /**
   * @description Deselect each option that doesn't match the current selection
   */
  private deselectOptions(option?: CmxOptionComponent): void {
    if (option) {
      this.optionsQueryList.forEach(($option: CmxOptionComponent) => {
        if (option.value !== $option.value && this.selectedOption !== $option) {
          $option.deselect();
        }
      });
    } else {
      this.optionsQueryList.forEach(($option: CmxOptionComponent) => {
        $option.deselect();
      });
    }
    this.updatePlaceHolder();
  }
  /**
   * @description Select all options
   */
  private selectAllOptions(): void {
    this.placeholder = "";
    this.optionsQueryList.forEach(($option: CmxOptionComponent) => {
      $option.selected = true;
    });
    this.updatePlaceHolder();
  }

  public close($event: Event): void {
    $event.stopPropagation();
    this.showOptions = false;
    this.searchValue = "";
    this.optionsQueryList.forEach(
      (option: CmxOptionComponent) => (option.hide = false)
    );
    const bodyElement: Element = document.getElementsByTagName("BODY")[0];
    this.renderer.setStyle(bodyElement, "overflow", undefined);
    this.setResultsCount();
  }

  private processOptions(): void {
    if (this.optionsQueryList.length > 0 && this.optionsQueryList.length < 4) {
      this.viewportHeight = this.childHeight * this.optionsQueryList.length;
    } else if (this.optionsQueryList.length >= 4) {
      this.viewportHeight = this.childHeight * 4;
    }
    this.dropSubscriptions();
    this.listenToOptions();
  }
  private firstProcessOptions(): void {
    if (this.optionsQueryList.length > 0 && this.optionsQueryList.length < 4) {
      this.viewportHeight = this.childHeight * this.optionsQueryList.length;
    } else if (this.optionsQueryList.length >= 4) {
      this.viewportHeight = this.childHeight * 4;
    }
    this.dropSubscriptions();
    this.firstListenToOptions();
  }

  private updatePlaceHolder(): void {
    this.selectedOptions = new Array<string>();
    let optionIndex: any;
    this.optionsQueryList.forEach(($option: CmxOptionComponent) => {
      if (!$option.isSelectAll) {
        optionIndex = this.selectedOptions.findIndex(
          (x: string) => x === $option.getViewValue()
        );
        if ($option.selected && optionIndex < 0) {
          this.selectedOptions.push($option.getViewValue());
        } else if (!$option.selected && optionIndex > -1) {
          this.selectedOptions.splice(optionIndex, 1);
        }
      }
    });

    if (this.selectedOptions.length > 0) {
      this.placeholder = this.selectedOptions.join();
    } else {
      this.placeholder = this.originalPlaceholder;
    }
  }

  private dropSubscriptions(): void {
    this.optionsSubscriptions.forEach(($sub: Subscription) => {
      $sub.unsubscribe();
    });
    this.optionsSubscriptions = [];
  }

  private setResultsCount(): void {
    this.resultsCount = this.optionsQueryList.filter(
      (option: CmxOptionComponent) => option.hide === false
    ).length;
  }

  private isInsideViewport(): void {
    // clearing
    this.renderer.setStyle(
      this.itemsPositioner.nativeElement,
      "left",
      undefined
    );
    this.renderer.setStyle(
      this.itemsPositioner.nativeElement,
      "top",
      undefined
    );
    // calculations
    const rect: ClientRect =
      this.itemsPositioner.nativeElement.getBoundingClientRect();
    const screenHeight: number = window.innerHeight;
    const screenWidth: number = window.innerWidth;
    if (this._forceTop) {
      const pxValue: number = Math.abs(
        rect.height - rect.bottom + rect.bottom + 45
      );
      this.renderer.setStyle(
        this.itemsPositioner.nativeElement,
        "top",
        -1 * pxValue + "px"
      );
      return;
    }
    if (rect.right > screenWidth) {
      // move to the left
      const pxValue: number = Math.abs(rect.right - screenWidth);
      this.renderer.setStyle(
        this.itemsPositioner.nativeElement,
        "left",
        -1 * pxValue + "px"
      );
    }
    if (rect.bottom > screenHeight) {
      // move to the top
      const pxValue: number = Math.abs(rect.bottom - screenHeight);
      this.renderer.setStyle(
        this.itemsPositioner.nativeElement,
        "top",
        -1 * pxValue + "px"
      );
    }
  }

  private setselectedItem(id: any): void {
    if (
      this._selectedItem !== undefined &&
      this._selectedItem.selectedid !== Number(id)
    ) {
      this._selectedItem.selected = false;
    }
    this._selectedItem = this.optionsQueryList.find(
      (item: CmxOptionComponent) => item.selectedid === Number(id)
    );
  }

  private isMultipelSelect(): boolean {
    if (this.optionsQueryList) {
      const arrAux: CmxOptionComponent[] = this.optionsQueryList.filter(
        (item: CmxOptionComponent) => {
          return item.multipleSelect === true;
        }
      );
      return arrAux.length > 0 ? true : false;
    } else {
      return false;
    }
  }
}
