import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable, Subject, fromEvent } from 'rxjs';
import { CookiebotUserService } from './cmx-cookiebot-user.service';
import { CookiebotData, CookiebotUser } from '../models';
import { CookiebotConstants } from '../core/cmx-cookiebot.constants';
import { isPlatformBrowser } from '@angular/common';
import { CookiebotConfig } from '../models/cmx-cookiebot.config';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { SessionService } from 'src/app/angular-services-v8/session.service';

function getWindow(): any {
  return window;
}

@Injectable({
  providedIn: 'root'
})

export class CookiebotService {
  cookiebot: any;
  onAccept$: Observable<any>;
  onLoad$: Observable<any>;
  onConsentReady$: Observable<any>;
  onDecline$: Observable<any>;
  onDialogInit$: Observable<any>;
  onDialogDisplay$: Observable<any>;
  onTagsExecuted$: Observable<any>;
  private clickedButtonAccept = false;
  private clickedButtonDeny = false;
  private clickedButtonCustomize = false;
  private clickedButtonWithdraw = false;
  private widgetEnabled = false;
  private country: string;
  private administrativeArea: string;
  private serviceError = '';
  private typeError = '';

  private readonly onAcceptCallback: Subject<void> = new Subject<void>();
  onAcceptCallback$ = this.onAcceptCallback.asObservable();

  private readonly onDeclineCallback: Subject<void> = new Subject<void>();
  onDeclineCallback$ = this.onDeclineCallback.asObservable();

  private readonly onDialogDisplayCallback: Subject<void> = new Subject<void>();
  onDialogDisplayCallback$ = this.onDialogDisplayCallback.asObservable();

  private readonly onDialogInitCallback: Subject<void> = new Subject<void>();
  onDialogInitCallback$ = this.onDialogInitCallback.asObservable();

  private readonly onLoadCallback: Subject<void> = new Subject<void>();
  onLoadCallback$ = this.onLoadCallback.asObservable();

  private readonly onServiceReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  onServiceReady$ = this.onServiceReady.asObservable();

  private readonly onTagsExecutedCallback: Subject<void> = new Subject<void>();
  onTagsExecutedCallback$ = this.onTagsExecutedCallback.asObservable();

  private readonly window: any = new Subject<void>();

  private loadScript: boolean;
  private cbId: string;

  public router: Router;

  constructor(
    private cookiebotConfig: CookiebotConfig,
    private cookiebotUserService: CookiebotUserService,
    @Inject(PLATFORM_ID) private platformId: object,
    private sessionService: SessionService,
    private injector: Injector
  ) {
    this.widgetEnabled = this.cookiebotConfig.widgetEnabled;
    if (isPlatformBrowser(this.platformId)) {
      this.window = getWindow();
      this._verifyConfig();
    }

  }

  init(): Promise<void> {
    return new Promise<void>((resolve) => {
      if (!this.loadScript || !this.cbId) {
        this.onServiceReady.next(false);
        return resolve();
      }

      // CGP-4839: Check if Cypress is running
      const isCypressActive = sessionStorage.getItem('__cy');
      if (isCypressActive && isCypressActive != '') {
        this.typeError = 'Cypress';
        this.serviceError = 'Cypress is running, skipping Cookiebot script injection.';
        this.onServiceReady.next(false);
        return resolve();
      }

      try {
        const cookiebotScript = document.getElementById('Cookiebot');
        if (!cookiebotScript) {
          this.window.document.head.prepend(this._buildScriptTag());
        }
      } catch (e: any) {
        this.typeError = 'Cookiebot';
        this.serviceError = e.message;
        this.onServiceReady.next(false);
        return resolve();
      }

      let mutationObserver = null;

      const scriptInjectionTimeout = setTimeout(() => {
        if(this.typeError === '') {
          this.typeError = 'Cookiebot';
          this.serviceError = 'Timed out';
          this.onServiceReady.next(false);
        }
        mutationObserver.disconnect();
      }, 5000);

      mutationObserver = new MutationObserver((mutationsList, observer) => {
        if (!(this.window.Cookiebot instanceof HTMLElement) && this.window.Cookiebot) {
          this.cookiebot = this.window.Cookiebot;
          this._setUpCallbacks();
          this._setUpEventHandlers();
          this._executeControlFunctions();
          observer.disconnect();
          clearTimeout(scriptInjectionTimeout);
        }
      });
      mutationObserver.observe(this.window.document.head, { childList: true, subtree: true });
      return resolve();
    });
  }

  public getLoadScript(): boolean {
    return this.loadScript;
  }

  private _buildScriptTag(): HTMLScriptElement {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'Cookiebot';
    script.src = CookiebotConstants.URL_COOKIEBOT;
    script.setAttribute('data-cbid', this.cbId);
    script.setAttribute('data-blockingmode', 'auto');
    script.setAttribute('data-widget-enabled', this.widgetEnabled ? 'true' : 'false');
    script.onerror = (error) => {
      this.typeError = 'Cookiebot';
      this.serviceError = 'Cookiebot script not loaded';
      this.onServiceReady.next(false);
    };
    return script;
  }

  private _setUpCallbacks(): void {
    this.window.CookiebotCallback_OnAccept = () => {
      this.onAcceptCallback.next();
    };

    this.window.CookiebotCallback_OnDecline = () => {
      this.onDeclineCallback.next();
    };

    this.window.CookiebotCallback_OnDialogDisplay = () => {
      this.onDialogDisplayCallback.next();
    };

    this.window.CookiebotCallback_OnDialogInit = () => {
      this.onDialogInitCallback.next();
    };

    this.window.CookiebotCallback_OnLoad = () => {
      this.onLoadCallback.next();
    };

    this.window.CookiebotCallback_OnTagsExecuted = () => {
      this.onTagsExecutedCallback.next();
    };
  }

  private _setUpEventHandlers(): void {
    this.onAccept$ = fromEvent(this.window, 'CookiebotOnAccept');
    this.onConsentReady$ = fromEvent(this.window, 'CookiebotOnConsentReady');
    this.onDecline$ = fromEvent(this.window, 'CookiebotOnDecline');
    this.onDialogInit$ = fromEvent(this.window, 'CookiebotOnDialogInit');
    this.onDialogDisplay$ = fromEvent(this.window, 'CookiebotOnDialogDisplay');
    this.onLoad$ = fromEvent(this.window, 'CookiebotOnLoad');
    this.onTagsExecuted$ = fromEvent(this.window, 'CookiebotOnTagsExecuted');
  }

  private _verifyConfig(): void {
    this.loadScript = CookiebotConstants.CX_ENABLE_COOKIEBOT;
    this.cbId = CookiebotConstants.COOKIEBOT_KEY;

    if (this.loadScript === false) {
      return;
    }

    if (!this.cbId) {
      console.log (
        'Wrong cbId config value. Please provide a correct value in the Cookiebot config'
      );
    }
  }

  private _executeControlFunctions(): void {
    const dataString = this.window.localStorage.getItem('cookiebotData');
    if (dataString) {
      const currentUrl = this.window.location.href;
      const data: CookiebotData = JSON.parse(dataString);
      const url = data.reloadPages.find(page => currentUrl.includes(page));
      if (url) {
        this.window.localStorage.removeItem('cookiebotData');
        const index = data.reloadPages.indexOf(url);
        data.reloadPages.splice(index, 1);
        this.window.localStorage.setItem('cookiebotData', JSON.stringify(data));
        this.window.location.reload();
      }
    }

    this.onDialogDisplayCallback.subscribe(() => {
      document.getElementById('CybotCookiebotDialogFooter').addEventListener('click', (event) => {
        const target = event.target as HTMLElement;
        if (target.id === 'CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll') {
          this.clickedButtonAccept = true;
        }
        if (target.id === 'CybotCookiebotDialogBodyButtonDecline') {
          this.clickedButtonDeny = true;
        }
        if (target.id === 'CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection') {
          this.clickedButtonCustomize = true;
        }
      });
    });

    this.onAcceptCallback.subscribe(() => {
      this.clickedButtonAccept = false;
      this.clickedButtonCustomize = false;
    });

    this.onDeclineCallback.subscribe(() => {
      this.clickedButtonDeny = false;
      this.clickedButtonCustomize = false;
      if(this.clickedButtonWithdraw) {
        this.clickedButtonWithdraw = false;
        this.setCookiebotData();
        setTimeout(() => {
          this.window.location.reload();
        }, 2000);
      }
    });

    this.onDialogInitCallback.subscribe(() => {
      if ((this.cookiebot && (this.cookiebot.consent.stamp === 0 || this.cookiebot.consent.stamp === '0'))
        && this.window.location.pathname.includes('public')) {
        this.cookiebot.submitCustomConsent(!1, !1, !1);
        const subscribeConsent = this.onConsentReady$.subscribe(() => {
          subscribeConsent.unsubscribe();
          this.cookiebot.deleteConsentCookie();
        });
      }
    });

    if (this.widgetEnabled) {
      const subscribeLoad = this.onLoad$.subscribe(() => {
        this.observeWidget();
        this.detectUrlChanges();
        subscribeLoad.unsubscribe();
      });

      this.onConsentReady$.subscribe(() => {
        if (this.clickedButtonDeny || this.clickedButtonCustomize || this.clickedButtonWithdraw) {
          this.setCookiebotData();
          setTimeout(() => {
            this.window.location.reload();
          }, 1000);
        }

        if (!this.clickedButtonDeny && !this.clickedButtonCustomize && !this.clickedButtonWithdraw) {
          this.saveConsent();
        }
      });
    }
  }

  public saveConsent(): void {
    const dataString = this.window.localStorage.getItem('cookiebotData');
    if (!dataString) {
      return;
    }

    const cookiebotData: CookiebotData = JSON.parse(dataString);
    if (!cookiebotData.saveConsent && !this.clickedButtonAccept) {
      return;
    }

    const user = this.sessionService?.profile;
    if (!user) {
      return;
    }

    const concent = this.getConsent();
    if (!concent) {
      return;
    }

    const userData: CookiebotUser = {
      ExecutedBy: user.userAccount,
      ChangeType: 'LOGIN',
      ChangeEntityId: user.userId,
      ChangeEntityTable: null,
      Action: 'CookieConsent',
      Notes: JSON.stringify(concent),
    };

    this.cookiebotUserService.saveConsentLogger(userData).subscribe((response: any) => {
      this.window.localStorage.removeItem('cookiebotData');
      const data: CookiebotData = {
        reloadPages: CookiebotConstants.RELOAD_PAGES,
        saveConsent: false,
      };
      this.window.localStorage.setItem('cookiebotData', JSON.stringify(data));
      this.window.sessionStorage.setItem('canReloadPage', 'true');
    }, error => {
      console.log('Error while saving consent log: ', error);
    });
  }

  private detectUrlChanges() {
    this.router = this.injector.get(Router);
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
      this.observeWidget();
    });
  }

  private disabledCookiebotWidget(cookiebotWidgets: any) {
    if (cookiebotWidgets.length > 1) {
      for (let index = cookiebotWidgets.length - 1; index > 0; index--) {
        cookiebotWidgets[index].remove();
      }
    }

    if (cookiebotWidgets.length === 1) {
      const hiddenWidget = CookiebotConstants.PAGES_WIDGET_ENABLED.indexOf(this.window.location.pathname) > -1 ? false : true;
      cookiebotWidgets[0].hidden = hiddenWidget;
      if (!hiddenWidget) {
        cookiebotWidgets[0].addEventListener('click', (event) => {
          const target = event.target as HTMLElement;
          if (target.id === 'CookiebotWidget-btn-withdraw') {
            this.clickedButtonWithdraw = true;
          }
        });
      }
    }
  }

  private getWidgets() {
    return document.querySelectorAll('[id="CookiebotWidget"]');
  }

  private observeWidget() {
    const widget = this.getWidgets();
    if (widget.length > 0) {
      this.disabledCookiebotWidget(widget);
    }
    const mutationObserver = new MutationObserver((mutationsList, observer) => {
      const widgets = this.getWidgets();
      if (widgets.length > 0) {
        this.disabledCookiebotWidget(widgets);
      }
    });
    const config = { childList: true, subtree: true };
    mutationObserver.observe(document.body, config);
    setTimeout(() => {
      mutationObserver.disconnect();
    }, 10000);
  }

  private setCookiebotData(): void {
    const user = this.sessionService?.profile;
    if (user) {
      const data: CookiebotData = {
        reloadPages: CookiebotConstants.RELOAD_PAGES,
        saveConsent: true,
      };
      this.window.localStorage.setItem('cookiebotData', JSON.stringify(data));
    }
  }

  public getConsent(): any {
    if (!this.cookiebot || !this.cookiebot.consent || this.cookiebot.consent.stamp === 0 || this.cookiebot.consent.stamp === '0') {
      return null;
    }

    return {
      consent: this.cookiebot.consent,
      consentUTC: this.cookiebot.consentUTC
    };
  }

  public getErrorScriptCookiebot(userName: string) {
    return {
      error: this.typeError,
      message: this.serviceError,
      userName: userName,
      browser: this.getBrowserInfo(),
      maps: {
        country: this.country,
        administrativeArea: this.administrativeArea
      }
    };
  }

  private getBrowserInfo () {
    var ua= navigator.userAgent, tem,
    M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if(/trident/i.test(M[1])){
        tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE '+(tem[1] || '');
    }
    if(M[1]=== 'Chrome'){
        tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
        if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
    if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
    return M.join(' ');
  };

  public setPosition(country: string, administrativeArea: string) {
    this.country = country;
    this.administrativeArea = administrativeArea;
  }

}
