import { Injectable, Inject, Optional } from "@angular/core";

@Injectable()
export class DateNamesService {
    public months: string[] = undefined;
    public monthsShort: string[] = undefined;
    public daysShort: string[] = undefined;

    constructor(@Inject("TRANSLATION_LANGUAGES") @Optional() private languagesList: any[]) {
        //
    }

    public getMonthName(month: number): string {
        if (this.months != undefined) {
            return this.months[month];
        } else {
            let $text: any;
            const $languageToUse: any = this.findLanguage();
            const $months: string[] = $languageToUse.monthNames.replace(/ /g, "").split(",");
            $text = $months[month];
            return $text;
        }
    }

    /**
     * @description Retrieve an array of the months with short names.
     * @return An Array of array of strings.
     */
    public getMonthShortNames(): string[][] {
        const shortMonths: any[] = [[], [], [], []];
        let months: string[] = [];
        if (this.monthsShort != undefined) {
            months = this.monthsShort;
        } else {
            const languageToUse: any = this.findLanguage();
            months = languageToUse.shortDayMonths.replace(/ /g, "").split(",");
        }
        let counter: number = 0;
        months.forEach((month: string) => {
            if (shortMonths[counter] && shortMonths[counter].length === 3) {
                counter++;
            }
            shortMonths[counter].push(month);
        });
        return shortMonths;
    }

    /**
     * @description Retrieve an array of the week days
     * @param startIndex A number to set a specific day at the start of the week, 0 = Sunday.
     * @return An string array.
     */
    public getWeekdays(startIndex: number = 0): string[] {
        if (this.daysShort != undefined) {
            let localCopy: string[] = this.daysShort.join(",").split(",");
            const splitedDays: string[] = localCopy.splice(0, startIndex);
            localCopy = localCopy.concat(splitedDays);
            return localCopy;
        } else {
            const languageToUse: any = this.findLanguage();
            let days: string[] = languageToUse.shortDayNames.replace(/ /g, "").split(",");
            const splitDays: string[] = days.splice(0, startIndex);
            days = days.concat(splitDays);
            return days;
        }
    }

    private findLanguage(): any {
        const $storedLanguage: string = localStorage.getItem("language");
        // tslint:disable
        let $languageToUse = {
            "languageId": 8,
            "languageName": "English US",
            "languageCountry": "United States",
            "languageISO": "en_US",
            "currencySymbol": "$",
            "currencyFormat": "%u%n",
            "currencySymbolFloat": "Left",
            "currencyName": "dollar",
            "formatDate": "MMM DD, YYYY",
            "formatTime": "hh:mm A",
            "decimalSeparator": ".",
            "decimalNumbers": 2,
            "thousandSeparator": ",",
            "textFloat": "left",
            "monthNames": "January,February,March,April,May,June,July,August,September,October,November,December",
            "dayNames": "Sunday,Monday,Tuesday,Wednesday,Thursday,Friday,Saturday",
            "countryCode": "US",
            "shortDayNames": "Sun,Mon,Tue,Wed,Thu,Fri,Sat",
            "shortDayMonths": "Jan,Feb,Mar,Apr,May,Jun,Jul,Aug,Sep,Oct,Nov,Dec",
            "shortDayNames2": "Su,Mo,Tu,We,Th,Fr,Sa",
            "hoursCode": "Hr",
            "minutesCode": "Min",
            "secondsCode": "Sec",
            "decimalNumbersMoney": 2
        };
        // tslint:enable
        this.languagesList =  window as {[key: string]: any;}['CMX_LANGUAGES'];
        this.languagesList = this.languagesList['CMX_LANGUAGES'];

        if (this.languagesList != undefined && $storedLanguage != undefined) {
            this.languagesList.forEach(($language: any) => {
                if ($language.languageISO === $storedLanguage ||
                $language.countryCode.toLowerCase() === $storedLanguage.toLowerCase() ||
                $language.languageISO.includes($storedLanguage)) {
                    $languageToUse = $language;
                }
            });
        }
        return $languageToUse;
    }
}
