import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    Output,
    forwardRef,
    HostBinding,
    Optional,
    Inject,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export const CMX_CHECKBOX_VALUE_ACCESSOR: any = {
    multi: true,
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CmxCheckboxComponent),
};

@Component({
    providers: [
        CMX_CHECKBOX_VALUE_ACCESSOR,
    ],
    selector: 'cmx-checkbox',
   //styleUrls: [ './../../../../../scssv4/cmx-components/cmx-checkbox/v4/cmx-checkbox.component.scss' ],
   styleUrls: ['./cmx-checkbox.component.scss'],
    templateUrl: './cmx-checkbox.component.html',
})
export class CmxCheckboxComponent implements ControlValueAccessor {
    @HostBinding('attr.dir') public dirAttr: string = 'auto';

    @Input()
    get checked(): boolean {
        return this._checked;
    }
    set checked($value: boolean ) {
      if ($value === true || $value === false ) {
          this._checked = $value;
          this.propagateChange($value);
          this.propagateTouch();
      }
    }

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }
    set disabled($value: boolean) {
        if ($value !== undefined && $value !== null) {
            this._disabled = $value;
        }
    }

    @Input()
    get error(): boolean {
        return this._error;
    }
    set error($value: boolean) {
        if ($value !== undefined && $value !== null) {
            this._error = $value;
        }
    }

    @Input()
    get required(): boolean {
        return this._required;
    }
    set required($value: boolean) {
        this._required = $value !== undefined && $value !== null;
    }

    @Input()
    get value(): boolean {
        return this._value;
    }
    set value($value: boolean) {
        this._value = $value;
    }

    @Input()
    get rtl(): boolean {
        return this._rtl;
    }
    set rtl($value: boolean) {
        if ($value !== undefined && $value !== null) {
            this._rtl = $value;
            this.dirAttr = $value ? 'rtl' : 'ltr';
        }
    }

    @Output() public change: EventEmitter<boolean> = new EventEmitter<boolean>();

    private _checked: boolean = false;
    private _disabled: boolean = false;
    private _error: boolean = false;
    private _rtl: boolean = false;
    private _required: boolean = false;
    private _value: any = undefined;

    constructor(@Optional() @Inject('RTL') isRTL: boolean) {
        if (isRTL != undefined) {
            this.rtl = isRTL;
        }
    }

    // controlValueAccessor public methods
    public propagateChange: (value: any) => void = (value) => {
        // no-op
    }

    public propagateTouch: () => any = () => {
        // no-op
    }

    public writeValue($value: boolean): void {
        if ($value !== undefined || $value !== null) {
            this._checked = $value;
        }
    }

    public registerOnChange($fn: any): void {
        this.propagateChange = $fn;
    }

    public registerOnTouched($fn: any): void {
        this.propagateTouch = $fn;
    }

    public setDisabledState($isDisabled: boolean): void {
        this.disabled = $isDisabled;
    }

  /**
   * Handles click events on the component.
   * @param event
   */
  @HostListener('click', ['$event']) public toggleCheckHost(event: MouseEvent): void {
      event.preventDefault();
      event.stopPropagation();
      this.toggleCheck();
    }

  /**
   * Toggles the "checked" state of the component.  Called in response to click-events
   * handled by the HostListener.
   */
  private toggleCheck(): void {
        if (this.disabled === false) {
            this.checked = !this.checked;
            this.change.emit(this.checked);
        }
    }

}
