<app-dashboard-card [confined]="true" [padding]="false">
  <ng-container cardHeaderLeft>
    <div>
      <cwc-picker
      (cwcChange)="onJobsiteChange($event.detail)"
      unwrap
      filter
      data-testid="jobsite-selection"
      variant="no-border"
      [placeholder]="jobsitesPlaceholder"
      [filterPlaceholder]="searchPlaceholder"
      [filterEmptyMessage]="searchEmptyMessage"
      [emptyMessage]="emptyMessage"
      class="p-all-reg"
      [footerCancelButtonLabel]="pickerLabelCancel"
      required
      design-version="v2"
      label="{{jobsitesPlaceholder}}"
      *ngIf="(this.status$ | async) !== Status.NOJOBSITES"
    >
      <cwc-picker-option
        *ngFor="
          let jobsite of (jobsites$ | async);
          trackBy: ('id' | cwcTrackBy);
          let i = index
        "
        [value]="jobsite.id"
        [selected]="i === 0"
      >
        {{ jobsite.name }}
      </cwc-picker-option>
    </cwc-picker>
    </div>
  </ng-container>
  <ng-container cardHeaderRight>
    <cwc-picker [ngClass]="{'hidden': activeTab === Tabs.Active}"
      #dateRangePicker
      class="p-all-reg"
      (cwcChange)="onDateRangeChange($event.detail)"
      unwrap
      variant="no-border"
      [placeholder]="datePlaceholder"
      data-testid="date-range-selection"
      [footerCancelButtonLabel]="pickerLabelCancel"
      required
      design-version="v2"
      id="cmx-dashboard-loads-date-range-picker"
    >
      <cwc-picker-option
        *ngFor="let opt of dateRanges; let i = index"
        [value]="opt"
        [selected]="i === 0"
        >{{ opt.label }}</cwc-picker-option
      >
    </cwc-picker>
  </ng-container>
  <div cardBody class="loads-content">
    <ng-container
      *ngIf="(this.status$ | async) !== (Status.PENDING || Status.NOJOBSITES); else loadingState"
    >
      <ng-container *ngIf="(this.status$ | async) !== Status.NOJOBSITES; else noJobsites">
        <cwc-tabs
          (cwcTabActivated)="onTabChanged($event.detail)"
          variant="heading"
        >
          <cwc-tab [name]="Tabs.Active" [active]="activeTab === Tabs.Active" id="cmx-dashboard-loads-tab-active" (cwcTabClicked)="createCountlyEvent(Tabs.Active)">{{
            tabName(Tabs.Active)
          }}</cwc-tab>
          <cwc-tab
            [name]="Tabs.Completed"
            [active]="activeTab === Tabs.Completed"
            id="cmx-dashboard-loads-tab-complete"
            (cwcTabClicked)="createCountlyEvent(Tabs.Completed)"
            >{{ tabName(Tabs.Completed) }}</cwc-tab
          >
        </cwc-tabs>
        <cwc-table-wrapper *ngIf="(loads$ | async).length > 0; else emptyResult" class="loads-table">
          <table class="data">
            <thead>
              <tr>
                <th>{{ columnLabel(0) }}</th>
                <th>{{ columnLabel(1) }}</th>
                <th>{{ columnLabel(2) }}</th>
                <th>{{ columnLabel(3) }}</th>
                <ng-container *ngIf="extended">
                  <th>{{ columnLabel(4) }}</th>
                  <th>{{ columnLabel(5) }}</th>
                </ng-container>
                
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let load of (loads$ | async);
                  trackBy: ('orderId' | cwcTrackBy)
                "
                attr.data-testid="load-row-{{ load.orderId }}"
              >
                <td class="order-col">{{ load.orderCode }}</td>
                <td>{{ load.load }}</td>
                <td class="no-wrap">{{ load.volume }}</td>
                <td class="product-ellipses">
                  <ng-container *ngIf="load.products?.length > 1; else single">
                    <cwc-popover variant="select" design-version="v2">
                      <span
                        [ngClass]="{
                          'col-active': load.products?.length > 1
                        }"
                        slot="target"
                      >
                        {{ load.products.length }}&nbsp;{{ productsLabel }}
                      </span>
                      <div slot="content">
                        <ng-container
                          *ngTemplateOutlet="
                            productsTable;
                            context: { products: load.products }
                          "
                        ></ng-container>
                      </div>
                    </cwc-popover>
                  </ng-container>
                  <ng-template #single>
                    <span *ngIf="load.products">
                      {{ load.products[0].productDesc }}
                    </span>
                  </ng-template>
                </td>
                <ng-container *ngIf="extended">
                  <td class="word-break">{{ load.purchaseOrder }}</td>
                  <td class="ellipses" title="{{ load.instructionsDesc }}">{{ load.instructionsDesc }}</td>
                </ng-container>
              </tr>
            </tbody>
          </table>
        </cwc-table-wrapper>
        <ng-template #emptyResult>
          <cwc-empty-result icon="information-rounded-fill" variant="flat">
            {{ emptyListText }}
          </cwc-empty-result>
        </ng-template>
      </ng-container>
    </ng-container>
    <ng-template #loadingState>
      <div class="loader-wrapper"><cwc-loader size="medium"></cwc-loader></div>
    </ng-template>
    <ng-template #noJobsites>
      <cwc-empty-result icon="information-rounded-fill" variant="flat">
        {{ emptyMessage }}
      </cwc-empty-result>
    </ng-template>
  </div>
</app-dashboard-card>

<ng-template #productsTable let-products="products">
  <table class="products-table">
    <thead>
      <tr>
        <th>{{ productsTableLabels[0] }}</th>
        <th class="align-right">{{ productsTableLabels[1] }}</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let product of products">
        <tr>
          <td>{{ product.productDesc }}</td>
          <td class="align-right no-wrap">{{ product.value + ' ' + product.unitDesc}}</td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</ng-template>
