import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { DocumentService } from '../../../services';
import { DomSanitizer } from '@angular/platform-browser';
import { DCMConstants } from '../../../helpers/DCM.constants';
import { Subscription } from 'rxjs';
import { WalkmeService } from '../../../services/walkme.service';
import { SessionService } from 'src/app/angular-services-v8/session.service';

@Component({
  selector: 'dareminder-modal',
  templateUrl: './dareminder.modal.html',
  styleUrls: ['./dareminder.modal.scss']
})
export class DareminderModal implements OnInit {

  @Input() imgUrl: string;
  @Input() cemexgoLink: string;

  public dareminder = false;
  public body;
  private subscriptions: Array <Subscription> = [];

  constructor(
    private documentService: DocumentService,
    private walkmeService: WalkmeService,
    private sessionService: SessionService,
    private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
  }

  public dareminderClosed() {
    this.dareminder = false;
    this.walkmeService.initializeService();
  };

  public open() {
    const userCountryCode = this.sessionService.userCountry.trim();
    try {
      const parameters = [
        {
          parameterName: DCMConstants.SECM_APP_PARAMETERS.DigitalAdmin_ReminderImgUrl,
          parameterValue: this.imgUrl.replace('{CountryCode}', userCountryCode)
        },
        {
          parameterName: DCMConstants.SECM_APP_PARAMETERS.Template_CemexGoLink,
          parameterValue: this.cemexgoLink
        }
      ];

      this.subscriptions.push(this.documentService.getDocumentLanguageWithParameters(
      DCMConstants.DIGITALADMIN_SETTINGS.DAReminder_MessageCode,
      userCountryCode,
      false,
      parameters).subscribe((response) => {
        try {
          const _body = response[0].htmlDocument.toString();
          this.body = this.sanitizer.bypassSecurityTrustHtml(_body);
          this.dareminder = true;
        } catch {
          this.walkmeService.initializeService();
        }
      }));
    } catch {
      this.walkmeService.initializeService();
    }
  }

  public close() {
    this.dareminder = false;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s?.unsubscribe());
  }
}
