<div class="cmx-datepicker">
    <div class="cmx-datepicker__header">
        <button cmx-link-icon srText="Previous Year" [disabled]="currentYear - 1 < min.getFullYear()" (click)="previousYear()">
            <span class="cmx-icon-straight-arrow-left" aria-hidden="true"></span>
        </button>
        <div class="cmx-datepicker__current-period">
            <button cmx-link (click)="monthToggle()">
                {{ currentMonth }}
            </button>
            <button cmx-link (click)="yearToggle()">
                {{ currentYear }}
            </button>
        </div>
        <button cmx-link-icon srText="Next Year" [disabled]="currentYear + 1 > max.getFullYear()" (click)="nextYear()">
            <span class="cmx-icon-straight-arrow-right" aria-hidden="true"></span>
        </button>
    </div>
    <!-- end .cmx-datepicker__header -->
    <div class="cmx-datepicker__body">
        <table class="cmx-datepicker__days-grid">
            <tbody>
                <ng-container *ngFor="let row of monthsShort">
                    <tr class="cmx-datepicker__months-grid-row">
                        <ng-container *ngFor="let col of row">
                            <td class="cmx-datepicker__months-grid-cell">
                                <button class="cmx-datepicker__month" (click)="selectMonth(col)" [disabled]="col.disabled" [class.cmx-active-date]="!col.disabled && currentDate.getMonth() === col.number">
                                    {{ col.label }}
                                </button>
                            </td>
                        </ng-container>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
    <!-- end .cmx-datepicker__body -->
</div>