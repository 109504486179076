import { Component, EventEmitter, Input, Output } from '@angular/core';
@Component({
  selector: 'user-notifications-group',
  templateUrl: 'user-notifications-group.component.html',
  styleUrls: ['user-notifications-group.component.scss'],
})
export class UserNotificationsGroupComponent {
  @Input() userNotificationList: any[];
  @Input() subtitleTranslation: string;
  @Input() shareNotificationTranslation: string;
  @Output() showUserNotificationDetail: EventEmitter<any> = new EventEmitter();
  @Output() showUserNotificationShare: EventEmitter<any> = new EventEmitter();

  constructor() { }

  userNotificationShare(notification: any) {
    this.showUserNotificationShare.emit(notification);
  }

  userNotificationDetail(notification: any) {
    this.showUserNotificationDetail.emit(notification);
  }

  public statusCodes = {
    N: 'user-notifications__entry-data-new',
    V: 'user-notifications__entry-data-seen',
    R: 'user-notifications__entry-data-read',
  }

  public processCodes = {
    'S1': 'cmx-icon-module-customer-information',
    'S2': 'cmx-icon-module-order-product-catalogue',
    'S3': 'cmx-icon-module-track',
    'S4': 'cmx-icon-module-invoices-payments',
    'S5': 'cmx-icon-module-user-management',
  }

}
