import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  HostListener,
  OnInit,
  Inject,
  Optional,
  PLATFORM_ID,
} from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ISpinnerTags } from './cmx-spinner.module';
import { isPlatformBrowser } from '@angular/common';
import { DomSanitizer, SafeStyle, SafeHtml } from '@angular/platform-browser';
import { LoaderTags } from '../../../../../shared/shared.module';
export enum WaitingStatus {
  Wait,
  TooLong,
  Error,
  NoConnection,
  CustomError,
}

@Component({
  selector: 'cmx-spinner',
  styleUrls: [
    './../../../../../../../scssv4/cmx-components/cmx-spinner/v4/cmx-spinner.component.scss',
    './cmx-spinner.component.scss',
  ],
  templateUrl: 'cmx-spinner.component.html',
})
export class CmxSpinnerComponent {
  lt = new LoaderTags();
  @Input() set size(value: SpinnerSize) {
    this._size = value;
    switch (this._size) {
      case 'Small':
        this.errorSignStyle =
          this.sanitizer.bypassSecurityTrustStyle('font-size: 1.5rem');
        this.sizeClass = 'cmx-spinner__small';
        break;
      case 'Moderate':
        this.errorSignStyle =
          this.sanitizer.bypassSecurityTrustStyle('font-size: 3rem');
        this.sizeClass = 'cmx-spinner__moderate';
        break;
      case 'Big':
        this.errorSignStyle =
          this.sanitizer.bypassSecurityTrustStyle('font-size: 5.25rem');
        this.sizeClass = 'cmx-spinner__big';
        break;
      case 'Full':
        this.errorSignStyle =
          this.sanitizer.bypassSecurityTrustStyle('font-size: 5.25rem');
        this.sizeClass = 'cmx-spinner__full';
        break;
      default:
        this.errorSignStyle =
          this.sanitizer.bypassSecurityTrustStyle('font-size: 1.5rem');
        this.sizeClass = 'cmx-spinner__small';
    }
  }
  get size(): SpinnerSize {
    return this._size;
  }

  @Input()
  get show(): boolean | Promise<any> {
    return this._show;
  }
  set show($value: boolean | Promise<any>) {
    this.showLoader($value);
  }

  @Input()
  public errorMessage?: string;

  @Input()
  public error?: boolean;

  @Input()
  public icon?: string;

  @Input()
  public tags: ISpinnerTags | any;

  private set status(value: WaitingStatus) {
    this._waitingStatus = value;

    switch (this._waitingStatus) {
      case WaitingStatus.CustomError:
        this.waitMessage = this.sanitizer.bypassSecurityTrustHtml(
          this.errorMessage || ''
        );
        break;
      case WaitingStatus.Wait:
        this.waitMessage = this.sanitizer.bypassSecurityTrustHtml(
          this.tags.please_wait
        );
        break;
      case WaitingStatus.TooLong:
        this.waitMessage = this.sanitizer.bypassSecurityTrustHtml(
          this.tags.taking_longer_than_expected
        );

        break;
      case WaitingStatus.Error:
        this.waitMessage = this.sanitizer.bypassSecurityTrustHtml(
          this.tags.error
        );
        break;
      case WaitingStatus.NoConnection:
        this.waitMessage = this.sanitizer.bypassSecurityTrustHtml(
          this.tags.no_connection
        );
        break;
      default:
        this.waitMessage = '';
        console.warn('Unexpected WaitingStatus');
    }
  }
  private get status(): WaitingStatus {
    return this._waitingStatus;
  }

  public isLoading = false;
  public sizeClass = '';
  public errorSignStyle: SafeStyle;
  public waitMessage: SafeHtml = '';

  private window: any;
  private _show: false;
  private _size: SpinnerSize;
  private _waitingStatus: WaitingStatus;
  private timerSubscription: Subscription;

  constructor(
    @Optional() @Inject('SpinnerTags') private tagsProvider: ISpinnerTags,
    @Inject(PLATFORM_ID) private platformId: any,

    private sanitizer: DomSanitizer
  ) {
    if (isPlatformBrowser(platformId)) {
      this.window = window;
    }
    if (tagsProvider !== undefined && tagsProvider !== null) {
      this.tags = this.tagsProvider;
    }

    this.icon = 'cmx-icon-warning-rounded-fill';
  }

  private showLoader(value: boolean | Promise<any>): void {
    if (typeof value === 'boolean') {
      this.isLoading = value;
      if (value) {
        this.count();
      } else {
        if (this.timerSubscription) {
          this.timerSubscription.unsubscribe();
        }
      }
    } else if (value && typeof value.then === 'function') {
      this.isLoading = true;
      this.count();

      value
        .then((x: any) => (this.isLoading = false))
        .catch((x: any) => (this.status = WaitingStatus.Error));
    }
  }

  private count(): void {
    const seconds = 60;
    this.timerSubscription = timer(1000, 1000)
      .pipe(
        map((i: number) => seconds - i),
        take(seconds + 1)
      )
      .subscribe((i: number) => {
        if (this.error) {
          this.status = WaitingStatus.CustomError;
        } else if (!this.window.navigator.onLine) {
          this.status = WaitingStatus.NoConnection;
        } else if (i > 50) {
          this.tags = { please_wait: this.lt['please_wait'] };
          this.status = WaitingStatus.Wait;
        } else if (i > 0 && i < 50) {
          this.status = WaitingStatus.TooLong;
        } else if (i === 0) {
          this.status = WaitingStatus.Error;
          this.error = true;
          this.timerSubscription.unsubscribe();
        }
      });
  }
}

export type SpinnerSize = 'Small' | 'Moderate' | 'Big' | 'Full';
