import { Directive, HostBinding } from '@angular/core';

@Directive({
    selector: 'cmx-table-item, [cmx-table-item]',
})
export class CmxTableItemDirective {
    @HostBinding( 'class.cmx-table-item' ) public cmxTableItemClass = true;

    constructor() { }
}
