import { Injectable } from '@angular/core';
import {
  Headers,
  Http,
  Request,
  RequestOptions,
  RequestOptionsArgs
} from '@angular/http';

import { Observable } from 'rxjs';

import { ProjectSettings } from './project.settings';

declare var global: any;

@Injectable()
export class HttpCemex {
  private _validSettings = false;

  constructor(protected http: Http, private projectSettings: ProjectSettings) {
    this._validSettings = this.validateProjectSettings();
  }

  public generateEndpoint(apiEndpoint: string): string {
    return this.projectSettings.generateEndpoint(apiEndpoint);
  }

  get clientId(): string {
    return this.projectSettings.clientId;
  }

  get appCode(): string {
    return this.projectSettings.appCode;
  }

  public request(url: string | Request, options?: RequestOptionsArgs) {
    const loptions: RequestOptionsArgs = this.createAuthorizationHeader(
      options
    );
    return this.http.request(url, loptions);
  }

  public get(
    url: string,
    options?: RequestOptionsArgs,
    overrideHeader?: boolean
  ) {
    const loptions: RequestOptionsArgs = this.createAuthorizationHeader(
      options,
      overrideHeader
    );
    return this.http.get(url, loptions);
  }

  public post(
    url: string,
    body: any,
    options?: RequestOptionsArgs,
    overrideHeader?: boolean
  ): any {
    if (!this._validSettings) {
      const message =
        'Set missing environment variables of API_HOST, API_ORG, API_ENV, APP_CODE, CLIENT_ID';
      return Observable.throw(new Error(message));
    }

    const loptions: RequestOptionsArgs = this.createAuthorizationHeader(
      options,
      overrideHeader
    );
    return this.http.post(url, body, loptions);
  }

  public put(
    url: string,
    body: any,
    options?: RequestOptionsArgs,
    overrideHeader?: boolean
  ) {
    const loptions: RequestOptionsArgs = this.createAuthorizationHeader(
      options,
      overrideHeader
    );
    return this.http.put(url, body, loptions);
  }

  public patch(
    url: string,
    body: any,
    options?: RequestOptionsArgs,
    overrideHeader?: boolean
  ) {
    const loptions: RequestOptionsArgs = this.createAuthorizationHeader(
      options,
      overrideHeader
    );
    return this.http.patch(url, body, loptions);
  }

  public delete(
    url: string,
    options?: RequestOptionsArgs,
    overrideHeader?: boolean
  ) {
    const loptions: RequestOptionsArgs = this.createAuthorizationHeader(
      options,
      overrideHeader
    );
    return this.http.delete(url, loptions);
  }

  public head(
    url: string,
    options?: RequestOptionsArgs,
    overrideHeader?: boolean
  ) {
    const loptions: RequestOptionsArgs = this.createAuthorizationHeader(
      options,
      overrideHeader
    );
    return this.http.head(url, loptions);
  }

  public isRunningOnBrowser(): boolean {
    return (global as any) !== null && (global as any) !== undefined;
  }

  public encrypt(str: string) {
    return window.btoa(unescape(encodeURIComponent(str)));
  }

  public decrypt(str: string) {
    return str ? decodeURIComponent(escape(window.atob(str))) : str;
  }

  public setProjectSettingsNewKeys(newKeys: any): void {
    this.projectSettings.setAppKeys(newKeys);
  }

  protected createAuthorizationHeader(
    options: RequestOptionsArgs,
    overrideHeader?: boolean
  ): RequestOptionsArgs {
    const containsHeader =
      options && options.headers && options.headers.keys().length > 0;
    const result: RequestOptionsArgs = options
      ? Object.assign({}, options)
      : new RequestOptions();
    const headers = new Headers();
    const language = this.isRunningOnBrowser()
      ? (window as any).localStorage.getItem('language') || 'en'
      : 'en';

    headers.append('Accept', 'application/json');
    headers.append('X-IBM-Client-Id', this.projectSettings.clientId);
    headers.append('App-Code', this.projectSettings.appCode);
    headers.append('Accept-Language', language);

    const data  = this.getDataSession();
    const accessToken = data ? data.oauth2?.access_token : undefined;
    const isContainsToken = accessToken && accessToken !== undefined;
    if (isContainsToken) {
      headers.append('Authorization', 'Bearer ' + accessToken);
    }

    const jwtApp =  data ? data.jwt : undefined;
    if (this.isRunningOnBrowser() && jwtApp && jwtApp !== undefined) {
      headers.append('jwt', jwtApp);
    }

    // overwrite existing headers or add new headers
    const headerKeys = containsHeader ? options.headers.keys() : [];
    for (const keyHeader of headerKeys) {
      headers.set(keyHeader, options.headers.get(keyHeader));
    }

    result.headers = headers;

    if (overrideHeader && containsHeader) {
      result.headers = options.headers;
    }

    return result;
  }

  private validateProjectSettings(): boolean {
    const settings = this.projectSettings;
    return (
      settings.apiBasePath !== undefined &&
      settings.apiEnv !== undefined &&
      settings.apiOrg !== undefined &&
      settings.appCode !== undefined &&
      settings.clientId !== undefined
    );
  }

  private getDataSession( ){
    let data = sessionStorage.getItem("_cmx_");
    return data ?  JSON.parse(this.decrypt(data)) : {} ;
  }

}
