import { TranslationService } from 'src/app/angular-localization-v7/services/translation.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'several-error',
  templateUrl: './several-error.component.html',
  styleUrls: ['./several-error.component.scss']
})
export class SeveralErrorComponent {

  constructor(
    public translationService: TranslationService,
    private router: Router
  ) {}

  public goHome() {
    this.router.navigate(['/']);
  }
}
