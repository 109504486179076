import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CmxNavHeaderComponent } from './cmx-nav-header.component';
import { ClickOutsideDirective } from './click-outside.directive';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CmxDialogModule } from '../../../../../../cmx-dialog/v7/projects/cmx-dialog-v7/src/lib/cmx-dialog.module';
import { CmxButtonModule } from '../../../../../../cmx-button/v4/src/cmx-button.module';
import { safepipe } from './pipes/safepipe.pipe';
import { UserNotificationService } from './services/user-notification.service';
import { CmxWebComponentsModule } from '@cmx-web-components/angular';

@NgModule({
  declarations: [CmxNavHeaderComponent, ClickOutsideDirective, safepipe],
  exports: [CmxNavHeaderComponent],
  providers: [UserNotificationService],
  imports: [
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    CmxDialogModule,
    CmxButtonModule,
    CmxWebComponentsModule.forRoot(),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class CmxNavHeaderModule {}
