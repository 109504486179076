import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatbotComponent } from './chatbot.component';
import { StoreService } from './services/store.service';
import { ScriptService } from './services/script.service';
import { SessionService } from '../../../../../../angular-services-v8/session.service';

@NgModule({
  declarations: [ChatbotComponent],
  exports: [ChatbotComponent],
  imports: [CommonModule],
  providers: [StoreService, ScriptService, SessionService],
})
export class CmxChatbotModule { }
