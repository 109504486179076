import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { CmxButtonComponent } from "./components/cmx-button/cmx-button.component";
import { CmxButtonIconComponent } from "./components/cmx-button-icon/cmx-button-icon.component";
import { CmxLinkComponent } from "./components/cmx-link/cmx-link.component";
import { CmxLinkIconComponent } from "./components/cmx-link-icon/cmx-link-icon.component";
import { ButtonRippleEffectDirective } from "./directives/button-ripple-effect.directive";

@NgModule({
    declarations: [
        CmxButtonComponent,
        CmxButtonIconComponent,
        CmxLinkComponent,
        CmxLinkIconComponent,
        ButtonRippleEffectDirective,
    ],
    exports: [
        CmxButtonComponent,
        CmxButtonIconComponent,
        CmxLinkComponent,
        CmxLinkIconComponent,
        ButtonRippleEffectDirective,
    ],
})
export class CmxButtonModule {}
