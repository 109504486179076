<cmx-dialog [width]="width" [minWidth]="minWidth" [maxWidth]="maxWidth" [height]="height" [minHeight]="minHeight" [maxHeight]="maxHeight"
    [backgroundColor]="backgroundColor" [enableClose]="enableClose" [enableHeader]="enableHeader" [enableFooter]="enableFooter"
    class="cmx-dialog-confirmation__container" [rtl]="rtl">

    <cmx-dialog-body class="cmx-dialog-confirmation__container__body">
        <div class="cmx-dialog-confirmation__container__body__icon">
            <!-- success -->
            <div *ngIf="type == confirmationType.Success" class="cmx-icon-accepted-ok cmx-dialog-confirmation__container__body__icon--success"></div>
            <!-- warning -->
            <div *ngIf="type == confirmationType.Warning" class="cmx-icon-warning cmx-dialog-confirmation__container__body__icon--warning"></div>
            <!-- error -->
            <div *ngIf="type == confirmationType.Error" class="cmx-icon-error cmx-dialog-confirmation__container__body__icon--error"></div>            
        </div>
        <div class="cmx-dialog-confirmation__container__adjustment">
            <span class="cmx-dialog-confirmation__container__adjustment__message">
                {{ message }}
            </span>
            <span *ngIf="type == confirmationType.Error" class="cmx-dialog-confirmation__container__adjustment__message-detail">
                {{ messageDetail }}
            </span>
        </div>
    </cmx-dialog-body>
    
    <cmx-dialog-footer class="cmx-dialog-confirmation__container__footer">
        <button cmx-button (click)="ok_click()"> {{ _okLabel }} </button>
    </cmx-dialog-footer>
</cmx-dialog>