import { Inject, Injectable, Optional } from '@angular/core';
import { Headers, RequestOptions, Response } from '@angular/http';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { SessionServiceConstants } from 'src/app/constants-v2/src/session-service.constants';
import { Broadcaster } from 'src/app/events-v7/projects/events-v7/src/lib/broadcaster.event';
import {
  IApplicationMenu,
  ICustomer,
  ILegalEntity,
  IUserProfile
} from '../angular-types-v2/index.interface';

import { HttpCemex } from './http.service';
import { Profile } from './models/profile';
import { Customer } from './models/customer';
import { User } from './models/user';
import { Role } from './models/role';
import { Application } from './models/application';
import { LoginBody } from './models/login';

export enum Regions {
  AMEA = 'AMEA',
  EU = 'Europe',
  MX = 'Mexico',
  US = 'US',
  SCACC = 'SCA&CC',
}

@Injectable()
export class SessionService {
  public static LOGIN_SUCCESS_EVENT = 'LOGIN_SUCCESS_EVENT';
  public static LOGIN_FAIL_EVENT = 'LOGIN_FAIL_EVENT';
  public static LOGIN_LOGOUT_EVENT = 'LOGIN_LOGOUT_EVENT';
  public static AUTH_TOKEN_VERSION = 'auth_token_version';

  private siteDomain = 'SITE_DOMAIN';
  private _siteDomain = window[this.siteDomain] || '';
  private _endpoint: string;
  private _menuApplication = new BehaviorSubject<IApplicationMenu[]>([]);
  private _currentLegalEntity = new BehaviorSubject<ILegalEntity>(null);
  private _userProfile: IUserProfile = null;
  private _beforeLogout: Observable<any> = null;

  private scaccCountries = ['CO', 'CR', 'DO', 'GT', 'NI', 'PA', 'PE', 'PR', 'SV'];
  private europeCountries = ['CZ', 'DE', 'ES', 'FR', 'GB', 'PL'];
  private ameaCountries = ['AE', 'EG', 'IL', 'PH'];

  constructor(
    protected http: HttpCemex,
    protected eventBroadcaster: Broadcaster,
    @Inject(SessionService.AUTH_TOKEN_VERSION)
    @Optional()
    private authTokenVersion: string
  ) {
    if (authTokenVersion) {
      switch (authTokenVersion.toLocaleLowerCase()) {
        case 'v6':
          this._endpoint = SessionServiceConstants.LOGIN_TOKEN_V6;
          break;
        default:
          this._endpoint = SessionServiceConstants.LOGIN_TOKEN_DEFAULT;
      }
    } else {
      this._endpoint = SessionServiceConstants.LOGIN_TOKEN_DEFAULT;
    }
    if (
      localStorage.getItem('language') === undefined ||
      (localStorage.getItem('language') === null &&
        (sessionStorage.getItem('language') !== undefined && sessionStorage.getItem('language') !== null))
    ) {
      localStorage.setItem('language', sessionStorage.getItem('language'));
    }
  }

  public get menuApplicationItems(): Observable<IApplicationMenu[]> {
    if (this._menuApplication.getValue().length === 0) {
      this.reloadAppMenuItems();
    }
    return this._menuApplication.asObservable();
  }

  public menuApplicationItemsParam(applicationCode?: string): Observable<IApplicationMenu[]> {
    if (this._menuApplication.getValue().length === 0) {
      this.reloadAppMenuItems(applicationCode);
    }
    return this._menuApplication.asObservable();
  }

  public setLegalEntity(value: ILegalEntity) {
    sessionStorage.setItem('user_legal_entity', JSON.stringify(value));
    this.eventBroadcaster.broadcast(Broadcaster.DCM_LEGAL_ENTITY_CHANGE, JSON.stringify(value));
    this._currentLegalEntity.next(value);
  }

  public get currentLegalEntity(): Observable<ILegalEntity> {
    return this._currentLegalEntity.asObservable();
  }

  public get isLoggedIn(): boolean {
    const accessToken = sessionStorage.getItem('access_token');
    const jwtToken = sessionStorage.getItem('jwt');
    return (
      accessToken !== null &&
      accessToken !== undefined &&
      accessToken !== '' &&
      jwtToken != null &&
      jwtToken !== undefined &&
      jwtToken !== ''
    );
  }

  public logout(): Observable<{
    success: boolean;
    message: string;
  }> {
    return new Observable(observer => {
      if (this._beforeLogout) {
        this._beforeLogout.subscribe(
          result => {
            this.logoutApp();
            observer.next({
              message: 'success',
              success: true
            });
          },
          error => {
            observer.next({
              message: error,
              success: false
            });
          }
        );
      } else {
        this.logoutApp();
        observer.next({
          message: 'success',
          success: true
        });
      }

      return observer;
    });
  }

  public login(
    user: string,
    password: string,
    generateCookie?: boolean,
    appName?: string,
    validateTempPassword?: boolean,
    grant_type?: string,
    scope?: string,
    userInformation?: string,
    include?: string,
    mprCode?: boolean,
    consent?: any
  ): Promise<any> {
    this.clean();
    const options = new RequestOptions({
      headers: new Headers({
        'Content-Type': 'application/json',
        accept: 'text/plain, */*'
      })
    });
    mprCode ? options.headers.append('App-Code','MPR') : null;
    const endpoint = this.http.generateEndpoint(this._endpoint);
    // const urlPost = this.generateUrlString(user, password, appName);
    const urlPost = this.generateJsonBody(user, password, appName, grant_type, scope, userInformation, include, consent);
    return this.http
      .post(endpoint, urlPost, options)
      .toPromise()
      .then(async response => {
        const data = response.json();
        const userId =
          this.authTokenVersion === 'v6'
            ? data.userId
              ? data.userId
              : data.profile.userId
            : data.profile.userId;
        this.processDataFromLogin(data, generateCookie);

        this.eventBroadcaster.broadcast(SessionService.LOGIN_SUCCESS_EVENT);

        if (
          validateTempPassword &&
          data.profile &&
          data.profile.hasTemporaryPassword
        ) {
          return Promise.resolve(data);
        }
      })
      .catch(error => {
        console.error(error);
        this.eventBroadcaster.broadcast(SessionService.LOGIN_FAIL_EVENT);
        return Promise.reject(error);
      });
  }

  public get userRegion(): string {
    return sessionStorage.getItem('region') || 'US';
  }

  public get userCountry(): string {
    return sessionStorage.getItem('country') || '';
  }

  public get userProfile(): IUserProfile {
    if (!this._userProfile) {
      this.setUserProfile();
    }

    return this._userProfile;
  }

  public getLegalEntities(
    fetch?: number,
    page?: number,
    legalEntityCode?: string,
  ): Observable<ILegalEntity[]> {
    let endpoint = this.http.generateEndpoint(SessionServiceConstants.USER_LEGAL_ENTITIES);
    endpoint = this.addQueryParams(endpoint, fetch, page, legalEntityCode);

    return this.http.get(endpoint).pipe(
      map((response: Response) => {
        let responseMap = [];
        const tmpJson = response.json();
        if (tmpJson) {
          responseMap = response.json();
        }
        return responseMap;
      }),
      catchError(e => Observable.throw(this.handleError(e)))
    );
  }

  public setBeforeLogout(method: Observable<any>): void {
    this._beforeLogout = method;
  }

  public getBeforeLogout(): Observable<any> {
    return this._beforeLogout;
  }

  public reloadAppMenuItems(applicationCode?: string): void {
    this.getDCMApplications(applicationCode).subscribe(result => {
      this._menuApplication.next(result);
    });
  }

  public createCookie(name: string, value: string, days?: number) {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = date.toUTCString();
    } else {
      days = 0;
    }

    const cookie = `${name}=${value};expires=${expires};domain=${this._siteDomain};path=/`;
    document.cookie = cookie;
  }

  public readCookie(name: string): string {
    const ca = document.cookie.split(';');
    const caLen: number = ca.length;
    const cookieName = `${name}=`;
    let c: string;
    for (let i = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) === 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return null;
  }

  public clearCookie(name) {
    this.createCookie(name, '', -1);
  }

  public setUserProfile(): void {
    const strProfile: string = sessionStorage.getItem('user_profile');
    const tmpProfile: IUserProfile = this.convertJsonTo<IUserProfile>(strProfile);
    if (tmpProfile) {
      const strCustomer: string = sessionStorage.getItem('user_customer');
      const userCustomer = this.convertJsonTo<ICustomer>(strCustomer);
      if (userCustomer) {
        tmpProfile.customer = userCustomer;
      } else {
        console.warn('Exception at parsing User Customer from sessionStore');
      }

      this._userProfile = tmpProfile;
    } else {
      console.warn('Exception at parsing User Profile from sessionStore');
    }
  }

  public clean(): void {
    this.clearCookie('CemexGoRegion');
    this.clearCookie('up_session');
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('refresh_token');
    sessionStorage.removeItem('expires_in');
    sessionStorage.removeItem('jwt');
    sessionStorage.removeItem('region');
    sessionStorage.removeItem('user_profile');
    sessionStorage.removeItem('user_customer');
    sessionStorage.removeItem('user_legal_entity');
    sessionStorage.removeItem('user_applications');
    sessionStorage.removeItem('sessionId');
    // patch-fix for react
    sessionStorage.removeItem('auth_token');
    sessionStorage.removeItem('userInfo');
    sessionStorage.removeItem('country');
    sessionStorage.removeItem('token_data');
    sessionStorage.removeItem('applications');
    sessionStorage.removeItem('role');
    sessionStorage.removeItem('username');

    // new verssion API ( v6 )
    sessionStorage.removeItem('userAuthV6');
    sessionStorage.removeItem('userInfoV6');
    sessionStorage.removeItem('userProfileV6');
    sessionStorage.removeItem('userCustomerV6');
    sessionStorage.removeItem('userApplicationsV6');
    sessionStorage.removeItem('userRolesV6');
  }

  public generateUrlString(
    user: string,
    password: string,
    appName?: string
  ): string {
    const include = '&include=userinfo,profile,applications,roles,customers,oldVersion';
    let url =
      'grant_type=password&scope=security&username=' +
      user +
      '&password=' +
      encodeURIComponent(password) +
      '&client_id=' +
      this.http.clientId;

    if (appName) { url = url + '&app_name=' + appName; }
    if (this.authTokenVersion) {
      if (this.authTokenVersion.toLowerCase() === 'v6') {
        url = `${url}&userinformation=false${include}`;
      }
    }
    return url;
  }

  public generateJsonBody(
    userName: string,
    password: string,
    appName?: string,
    grant_type?: string,
    scope?: string,
    userInformation?: string,
    include?: string,
    consent?: any
  ): string {
    const body = {
    grant_type : grant_type != null ? grant_type : 'password' ,
    scope : scope != null ? scope : 'security',
    username : userName,
    password : password,
    app_name : appName,
    userInformation : userInformation != null ? userInformation : 'false',
    include : include != null ? include : 'userinfo,profile,applications,roles,customers,oldVersion',
    consent : consent
  };


    return JSON.stringify(body);
  }

  public processDataFromLogin(data: any, generateCookie?: boolean): void {
    // session cookie
    if (generateCookie) {
      const sessionCookie = {
        auth: data.oauth2.access_token,
        expires_in: data.oauth2.expires_in,
        jwt: data.jwt,
        refresh_token: data.oauth2.refresh_token,
        region: data.oauth2.region,
      };
      this.createCookie('up_session', JSON.stringify(sessionCookie), null);
    }

    this.createCookie('CemexGoRegion', data.oauth2.region, null);

    sessionStorage.setItem('access_token', data.oauth2.access_token);
    sessionStorage.setItem('refresh_token', data.oauth2.refresh_token);
    sessionStorage.setItem('expires_in', data.oauth2.expires_in);
    sessionStorage.setItem('jwt', data.jwt);
    sessionStorage.setItem('auth_token', data.oauth2.access_token);
    sessionStorage.setItem('user_profile', JSON.stringify(data.profile));
    sessionStorage.setItem('user_customer', JSON.stringify(data.customer));
    sessionStorage.setItem('user_applications', JSON.stringify(data.applications));
    sessionStorage.setItem('sessionId', data.profile.userId);

    // react
    sessionStorage.setItem('userInfo', JSON.stringify(data));
    sessionStorage.setItem('country', data.country);
    sessionStorage.setItem('token_data', JSON.stringify(data));
    sessionStorage.setItem('applications', JSON.stringify(data.applications));

    sessionStorage.setItem('region', data.oauth2.region);
    sessionStorage.setItem('role', data.role);
    sessionStorage.setItem('username', data.profile.userAccount);

    this.setUserProfile();

    // call API V6 ( New Model)
    const accessToKen = {
      oauth2: data.oauth2,
      // tslint:disable-next-line:object-literal-sort-keys
      jwt: data.jwt,
      userId: data.userId ? data.userId : data.profile.userId,
    };
    const encryptData = this.encrypt(JSON.stringify(accessToKen));
    sessionStorage.setItem('userAuthV6', encryptData);

    if (data._users) {
      const firstUser = data._users[0];
      const user: User = {
        accountType: firstUser.accountType,
        countryCode: firstUser.country.countryCode,
        customerId: (firstUser.customer) ? firstUser.customer.customerId : 0,
        firstName: firstUser.firstName,
        fullName: firstUser.fullName,
        lastName: firstUser.lastName,
        phoneNumber: firstUser.phoneNumber,
        userAccount: firstUser.userAccount,
        userId: firstUser.userId,
        userSelectedId: firstUser.userSelectedId,
        userStatus: firstUser.userStatus,
        userType: firstUser.userType,
      };
      const encryptUser = this.encrypt(JSON.stringify(user));
      sessionStorage.setItem('userInfoV6', encryptUser);
    }

    if (data._profile) {
      const dataProfile = data._profile;
      const profile: Profile = {
        allowEmailUpdates: dataProfile.allowEmailUpdates,
        allowInformationShare: dataProfile.allowInformationShare,
        hasTemporaryPassword: dataProfile.hasTemporaryPassword,
        userEmail: dataProfile.userEmail,
        userId: dataProfile.userId,
        userPosition: dataProfile.userPosition,
        userProfileId: dataProfile.userProfileId,
      };
      const encryptProfile = this.encrypt(JSON.stringify(profile));
      sessionStorage.setItem('userProfileV6', encryptProfile);
    }

    if (data._customers) {
      const dataCustomer = data._customers;
      const customer: Customer = {
        countryCode: dataCustomer.country.countryCode,
        customerCode: dataCustomer.customerCode,
        customerCodeLZR: dataCustomer.customerCodeLZR,
        customerDesc: dataCustomer.customerDesc,
        customerDesc2: dataCustomer.customerDesc2,
        customerId: dataCustomer.customerId,
        email: dataCustomer.email,
        faxNumber: dataCustomer.faxNumber,
        phoneNumber: dataCustomer.phoneNumber,
        regionId: dataCustomer.region.regionId,
        vAT: dataCustomer.vAT,
      };
      const encryptcustomer = this.encrypt(JSON.stringify(customer));
      sessionStorage.setItem('userCustomerV6', encryptcustomer);
    }

    if (data._applications) {
      const apps: Application[] = data._applications.map((app) => {
        return {
          applicationCode: app.applicationCode,
          applicationId: app.applicationId,
          applicationType: app.applicationType,
          isVisible: app.isVisible,
        };
      });
      const encryptApplications = this.encrypt(JSON.stringify(apps));
      sessionStorage.setItem('userApplicationsV6', encryptApplications);
    }
    if (data._roles) {
      const roles: Role[] = data._roles.map((role) => {
        return {
          applicationId: role.application.applicationId,
          isInternal: role.isInternal,
          isOptional: role.isOptional,
          isVisible: role.isVisible,
          roleCode: role.roleCode,
          roleId: role.roleId,
          roleType: role.roleType,
        };
      });
      const encryptRoles = this.encrypt(JSON.stringify(roles));
      sessionStorage.setItem('userRolesV6', encryptRoles);
    }
  }

  public getDCMApplications(applicationCode?: string): Observable<IApplicationMenu[]> {
    let endpoint = this.http.generateEndpoint(SessionServiceConstants.USER_APPLICATION_MENU);
    if (applicationCode) {
      endpoint = endpoint + '?applicationCode=' + applicationCode;
    }
    return this.http.get(endpoint).pipe(
      map((response: Response) => {
        const tmpJson = response.json();
        if (!tmpJson || tmpJson === undefined) {
          console.warn('Configuration of menu application is empty!!');
          return [];
        }
        const responseMap: IApplicationMenu[] = response.json() as IApplicationMenu[];
        return responseMap;
      }),
      catchError((e: any) => Observable.throw(this.handleError(e)))
    );
  }

  public convertJsonTo<T>(obj: string): T {
    try {
      const tmpJson = JSON.parse(obj);
      return tmpJson as T;
    } catch (e) {
      console.warn('Exception at parsing object from sessionStore:', e);
    }

    return null;
  }

  public get customer(): ICustomer {
    let result: ICustomer;
    const str: string = sessionStorage.getItem('user_customer');
    if (str) {
      try {
        const profile = JSON.parse(str);
        result = profile as ICustomer;
      } catch (e) {
        console.warn(
          'Exception at parsing user_customer from sessionStore:',
          e
        );
        return null;
      }
    } else {
      console.warn('There is no user customer at session storage');
    }

    return result;
  }

  public handleError(error: Response | any): any {
    // In a real world app, we might use a remote logging infrastructure
    let errMsg: string;
    if (error instanceof Response) {
      const body = error.json() || '';
      errMsg = `${error.status} - ${error.statusText || ''}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(error);
    return throwError(error);
  }

  public addQueryParams(endpoint: string, fetch?: number, page?: number, legalEntityCode?: string) {
    const validPagination: boolean = Number.isInteger(fetch) && Number.isInteger(page);

    endpoint += validPagination ? '?fetch=' + fetch + '&page=' + page : '';
    endpoint +=
      legalEntityCode && validPagination
        ? '&legalEntityCode=' + legalEntityCode
        : legalEntityCode && !validPagination
          ? '?legalEntityCode=' + legalEntityCode
          : '';

    return endpoint;
  }

  public logoutApp(): void {
    this.clean();
    this.eventBroadcaster.broadcast(Broadcaster.DCM_APP_LOGOUT);
  }

  public get userAuth() {
    return JSON.parse(this.decrypt(sessionStorage.getItem('userAuthV6')));
  }

  public get userInfo() {
    return JSON.parse(this.decrypt(sessionStorage.getItem('userInfoV6')));
  }

  public get userProfileV6() {
    return JSON.parse(this.decrypt(sessionStorage.getItem('userProfileV6')));
  }

  public get userCustomer() {
    return JSON.parse(this.decrypt(sessionStorage.getItem('userCustomerV6')));
  }

  public get userApplications() {
    return JSON.parse(this.decrypt(sessionStorage.getItem('userApplicationsV6')));
  }

  public get userRoles() {
    return JSON.parse(this.decrypt(sessionStorage.getItem('userRolesV6')));
  }

  public getUserRegionByCountry(countryCode?: string): string {
    if (!countryCode) {
      countryCode = sessionStorage.getItem('country');
    }
    let region = '';
    if (countryCode === 'MX') {
      region = Regions.MX;
    } else if (countryCode === 'US') {
      region = Regions.US;
    } else if (!!this.scaccCountries.find((c) => c === countryCode)) {
      region = Regions.SCACC;
    } else if (!!this.europeCountries.find((c) => c === countryCode)) {
      region = Regions.EU;
    } else if (!!this.ameaCountries.find((c) => c === countryCode)) {
      region = Regions.AMEA;
    }
    return region;
  }

  public get userSegment(): string {
    return sessionStorage.getItem('userSegment') || '';
  }

  public get userSubsegment(): string {
    return sessionStorage.getItem('userSubsegment') || '';
  }

  public encrypt(str) {
    return window.btoa(unescape(encodeURIComponent(str)));
  }

  public decrypt(str) {
    return str ? decodeURIComponent(escape(window.atob(str))) : str;
  }

}
