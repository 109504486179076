import { HttpEvent, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface CustomHeaders {
  [name: string]: string | string[];
}

export enum ResponseContentType {
  ArrayBuffer = 'arraybuffer',
  Blob = 'blob',
  JSON = 'json',
  Text = 'text',
}

export class CmxHttpOptions {
  headers?: CustomHeaders;
  params?:
    | HttpParams
    | {
        // tslint:disable-next-line:trailing-comma
        [param: string]: string | string[]
      };
  // tslint:disable-next-line:no-any
  responseType?: any = ResponseContentType.JSON;
  // tslint:disable-next-line:whitespace
  isHeaderResponse? = false;
}

export abstract class ICmxHttpClient {
  abstract get<T>(apiPath: string, options?: CmxHttpOptions): Observable<T>;
  // tslint:disable-next-line:no-any
  abstract post<T>(apiPath: string, body: any | null, options?: CmxHttpOptions): Observable<T>;
  // tslint:disable-next-line:no-any
  abstract patch<T>(apiPath: string, body: any | null, options?: CmxHttpOptions): Observable<T>;
  // tslint:disable-next-line:no-any
  abstract put<T>(apiPath: string, body: any | null, options?: CmxHttpOptions): Observable<T>;
  abstract delete<T>(apiPath: string, options?: CmxHttpOptions): Observable<T>;
  // tslint:disable-next-line:no-any
  abstract upload<T>(apiPath: string, options?: CmxHttpOptions): Observable<HttpEvent<T>>;
}
