import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CmxHttpClient } from '../services/api.service';
import { createHttpParams } from '../shared/helpers';

// INTERFACES
import { CatalogsRequest } from '../models/catalogs/catalogs-request';
import { CatalogsResponse } from '../models/catalogs/catalogs-response';

@Injectable()
export class CatalogsService {
  constructor(private http: CmxHttpClient) {}

  getCatalogs(payload: CatalogsRequest): Observable<CatalogsResponse> {
    const httpParams = payload ? createHttpParams(payload) : new HttpParams();
    return this.http.get<CatalogsResponse>(`/v1/sm/catalogs`, {
      params: httpParams,
    });
  }
}
