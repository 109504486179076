import { Component, Input, Output, EventEmitter } from '@angular/core';

export type SorterCompareFn = (objectLeft: any, objectRight: any) => number;
export type SearchFilterFn = (
    collection: any[],
    searchValue: string,
    specificProp: string
) => any[];

@Component({
    selector: 'cmx-filter-sorter',
    styleUrls: [
        './../../../../../../../../../../scss/cmx-table/v1/cmx-filter-sorter.component.scss',
    ],
    templateUrl: './cmx-filter-sorter.component.html',
})
export class CmxFilterSorterComponent {
    public _searchValue = '';
    public _isAscending = false;
    public _isDescending = false;

    @Input()
    get collection() {
        return this._filteredCollection;
    }
    set collection(value: any[]) {
        this._filteredCollection = value;
        if (value && value !== undefined) {
            this._originalCollection = value.slice(0, value.length);
        }
    }

    set searchValue(value: any) {
        this._searchValue = value;
    }

    @Input()
    set sorterCustomFn(value: any) {
        this._compareCustomFn = value;
    }

    @Input()
    set searchFilterFn(value: SearchFilterFn) {
        this._searchFilterFn = value;
    }

    @Input()
    get rtl(): boolean {
        return this._rtl;
    }
    set rtl($value: boolean) {
        if ($value !== undefined && $value !== undefined) {
            this._rtl = $value;
        }
    }

    @Input() public specificProp: string = null;
    @Input() public id: number;
    @Input() public placeholder: string;
    @Input() public keyTitle: string;
    @Input() public keySubtitle: string;
    @Input() public keyOrderAsc: string;
    @Input() public keyOrderDesc: string;
    @Input() public enableSearch = true;
    @Output() public onSelectedFilterItem: EventEmitter<any> = new EventEmitter<any>();
    @Output() public isfiltered: EventEmitter<any> = new EventEmitter<any>();
    @Output() public inputSelected: EventEmitter<any> = new EventEmitter<any>();
    @Output() public checkboxSelected: EventEmitter<any> = new EventEmitter<any>();

    private _filteredCollection: any[] = [];
    private _originalCollection: any[];
    private _compareCustomFn: any = null;
    private _searchFilterFn: SearchFilterFn = null;
    private _rtl = false;

    constructor() { }

    get dirRtl() {
        return (this._rtl) ? 'rtl' : 'ltr';
    }

    public clickInside(event: any): void {
        // event.stopPropagation();
    }

    public reset(): void {
        this._searchValue = '';
        this._isAscending = false;
        this._isDescending = false;
        this._filteredCollection = this._originalCollection.slice(
            0, this._originalCollection.length
        );
        this.onSelectedFilterItem.emit(this._filteredCollection);
        this.isfiltered.emit(false);
    }

    public ascendingItem($event: any): void {
        this.checkboxSelected.emit({ order: 'asc', value: $event } );
        this._isAscending = $event;
        this._isDescending = false;
        this.orderAZ();
    }

    public descendingItem($event: any): void {
        this.checkboxSelected.emit({ order: 'des', value: $event } );
        this._isAscending = false;
        this._isDescending = $event;
        this.orderAZ();
    }

    public onSearch(event: any): void {
        const isAZ = this._isAscending || this._isDescending;
        this._filteredCollection = this._searchFilterFn(
            this._originalCollection, event.currentTarget.value, this.specificProp
        );
        if (isAZ) {
            this.orderAZ();
        } else {
            this.onSelectedFilterItem.emit(this._filteredCollection);
        }
        this.isfiltered.emit(this._searchValue !== '');
    }

    public resetInput(): void {
        const validate = this._isAscending || this._isDescending;
        if ((this._searchValue === '' || !this._searchValue) && !validate) {
            this.inputSelected.emit(this.id);
        }
    }

    // tslint:disable-next-line:typedef
    private orderAZ(): void {
        if (!this._compareCustomFn) {
            return;
        }
        const isAZ = this._isAscending || this._isDescending;
        if (isAZ) {
            this._filteredCollection = this.orderAscDesc();
        } else {
            this._filteredCollection = this._searchValue !== '' ? this._filteredCollection
            .slice(0, this._originalCollection.length) :
            this._originalCollection.slice(0, this._originalCollection.length);
        }

        this.onSelectedFilterItem.emit(this._filteredCollection);
        this.isfiltered.emit(true);
    }

    private orderAscDesc(): any[] {
        let sortCollection: any[];
        if (this.specificProp) {
            sortCollection = this._filteredCollection.sort(
                this._compareCustomFn(this.specificProp)
            );
            return this._isAscending ? sortCollection : sortCollection.reverse();
        } else {
            sortCollection = this._filteredCollection.sort(this._compareCustomFn);
            return this._isAscending ? sortCollection : sortCollection.reverse();
        }
    }
}
