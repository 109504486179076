import { Injectable } from '@angular/core';

@Injectable()
export class LoaderService {

  public loaderOn = false;
  public callsCounter = 0;

  constructor() {}

}
