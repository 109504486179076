import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SHIPMENT_LOCATION_TYPE_ID, ShipmentLocationTypeIdType, Unit } from '../models/common';
import { GetProductContractsReq } from '../models/product/get-product-contracts-req';
import { GetUnitsReq } from '../models/product/get-units-req';
import { ProductReq } from '../models/product/product-req';
import { ProductResp } from '../models/product/product-resp';
import { ProductsByColorReq } from '../models/product/products-by-color-req';
import { TopProductsReq } from '../models/product/top-products-req';
import { TopProductsResp } from '../models/product/top-products-resp';
import { UnitsResponse } from '../models/product/units-response';
import { UnitsResponseV2 } from '../models/product/units-response-v2';
import { CmxHttpClient } from '../services/api.service';
import { createHttpParams } from '../shared/helpers';
import { AdditionalServicesReq } from '../models/product/additional-services-req';

@Injectable()
export class ProductService {
  constructor(private http: CmxHttpClient) {}

  getTopProducts(productsReq: TopProductsReq): Observable<TopProductsResp> {
    let POParam = '';
    if (productsReq.purchaseOrder) {
      POParam = `&PONumber=${productsReq.purchaseOrder}`;
    }
    if (productsReq.productColorId) {
      POParam = `&productColorId=${productsReq.productColorId}`;
    }

    return this.http.get(
      `/v2/mm/myproducts?salesDocumentTypeId=${productsReq.salesDocumentTypeId}&productLineId=${
        productsReq.productLineId
      }&shippingConditionId=${productsReq.shippingConditionId}&shipmentLocationId=${
        productsReq.shipmentLocationId
      }.2${POParam}`,
    );
  }

  getUnitsV2(request: GetUnitsReq): Observable<UnitsResponseV2> {
    const httpParams = request ? createHttpParams(request) : new HttpParams();

    return this.http.get<UnitsResponseV2>(`/v3/mm/units`, {
      params: httpParams,
    });
  }

  getUnits(productId: number): Observable<UnitsResponse> {
    return this.http.get(`/v2/mm/productunitconversions?productId=${productId}`);
  }

  getUnit(unitId: number): Observable<Unit> {
    return this.http.get(`/v2/im/units/${unitId}`);
  }

  getProductContracts(request: GetProductContractsReq): Observable<ProductResp> {
    if (request.jobsiteId) {
      request.jobsiteId = this.concatenateShipmentLocation({
        shipmentLocationId: request.jobsiteId,
        shipmentLocationType: SHIPMENT_LOCATION_TYPE_ID.JOBSITE,
      });
    }

    if (request.pointOfDeliveryId) {
      request.pointOfDeliveryId = this.concatenateShipmentLocation({
        shipmentLocationId: request.pointOfDeliveryId,
        shipmentLocationType: SHIPMENT_LOCATION_TYPE_ID.POINT_OF_DELIVERY,
      });
    }

    const httpParams = createHttpParams(request);
    return this.http.get<ProductResp>('/v2/mm/myproducts', {
      params: httpParams,
    });
  }

  getProductsByColor(payload: ProductsByColorReq): Observable<TopProductsResp> {
    payload.shipmentLocationId = this.concatenateShipmentLocation(payload.shipmentLocation);

    /*remove shipmentLocation parameter from request params*/
    delete payload.shipmentLocation;
    const httpParams = payload ? createHttpParams(payload) : new HttpParams();
    // tslint:disable-next-line:no-any
    return this.http.get<any>(`/v2/mm/myproducts`, {
      params: httpParams,
    });
  }

  getMyProductsV3(payload: ProductsByColorReq): Observable<ProductResp> {
    payload.shipmentLocationId = this.concatenateShipmentLocation(payload.shipmentLocation);
    delete payload.shipmentLocation;

    const httpParams = payload ? createHttpParams(payload) : new HttpParams();
    return this.http.get<ProductResp>(`/v3/mm/myproducts`, {
      params: httpParams,
    });
  }

  getProduct(productReq: ProductReq): Observable<ProductResp> {
    let POParam = '';
    if (productReq.purchaseOrder !== undefined) {
      POParam = `&PONumber=${productReq.purchaseOrder}`;
    }

    return this.http.get(
      `/v2/mm/products?productCode=${productReq.productCode}&salesDocumentTypeId=${
        productReq.salesDocumentTypeId
      }&productLineId=${productReq.productLineId}&shippingConditionId=${
        productReq.shippingConditionId
      }&shipmentLocationId=${productReq.shipmentLocationId}.2${POParam}`,
    );
  }

  getSingleProduct(productId: number): Observable<ProductResp> {
    return this.http.get<ProductResp>(`/v2/mm/myproducts?productId=${productId}`);
  }

  private concatenateShipmentLocation(value: {
    shipmentLocationId: number | string
    // tslint:disable-next-line:trailing-comma
    shipmentLocationType: ShipmentLocationTypeIdType
  }): string {
    if (value) {
      return `${value.shipmentLocationId}.${value.shipmentLocationType}`;
    }
    return '';
  }

  getAdditionalServices(additionalServicesReq: AdditionalServicesReq): Observable<ProductResp> {
    return this.http.get(
      `/v3/mm/myproducts?salesDocumentTypeCode=${additionalServicesReq.salesDocumentTypeCode}&salesAreaId=${
        additionalServicesReq.salesAreaId
      }&productLineId=${additionalServicesReq.productLineId}&shippingConditionId=${
        additionalServicesReq.shippingConditionId
      } &shipmentLocationId=${additionalServicesReq.shipmentLocationId}.2`,
    );
  }
}
