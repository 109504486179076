import { Application } from './application';
export class Role {
    public roleId: number;
    public roleName: string;
    public roleCode: string;
    public roleDesc: string;
    public roleType: string;
    public assigned?: boolean;
    public isAssigned?: boolean;
    public canEdit?: boolean;
    public isVisible?: boolean;
    public applicationId?: number;
    public isChecked?: boolean;
    public isEnabled?: boolean;
    public isRequested?: boolean;
    public isPending?: boolean;
    public isOptional?: boolean;
    constructor(application: Application) {
        this.applicationId = application.applicationId;
    }
}
