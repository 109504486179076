import {
  Component,
  ElementRef,
  forwardRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  Inject,
  Optional,
  HostListener,
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { CmxDatepickerComponent } from "../../../../../../../cmx-datepicker/v4/src/components/cmx-datepicker/cmx-datepicker.component";
import { DatepickerInputTags } from "../../models/datepicker-input.classes";
import { DateFormatService } from "../../services/date-format.service";

export const CMX_DATEPICKERINPUT_VALUE_ACCESSOR: any = {
  multi: true,
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DatepickerInputComponent),
};

@Component({
  providers: [CMX_DATEPICKERINPUT_VALUE_ACCESSOR],
  // tslint:disable-next-line
  selector: "datepicker-input",
  styleUrls: [
    "./../../../../../../../../../scssv4/cmx-components/cmx-form-field/v4/datepicker-input.component.scss",
  ],
  templateUrl: "./datepicker-input.component.html",
})
export class DatepickerInputComponent implements OnInit {
  @Input()
  public title = "";
  @Input()
  public placeholder = "";

  @Input()
  public max: Date;
  @Input()
  public min: Date;
  @Input()
  public singleDateSelection = false;
  @Input()
  get rtl(): boolean {
    return this._rtl;
  }
  set rtl($value: boolean) {
    if ($value !== undefined) {
      this._rtl = $value;
    }
  }
  @Input()
  get value(): any {
    return this._value;
  }
  set value($param: any) {
    if ($param !== undefined && $param !== null) {
      this._value = $param;
      this.propagateChange(this._value);
      this.propagateTouch();
      this.datepickerValue = { value: {} };
      if (this._value.min && this._value.max) {
        if (String(this._value.min) !== String(this._value.max)) {
          this.datepickerValue.value = this._value;
          this.datepickerValue.isRange = true;
        } else {
          this.datepickerValue.value.min = this._value.min;
          this.datepickerValue.value.max = this._value.max;
          this.datepickerValue.isRange = false;
        }
      } else {
        this.datepickerValue.value.min = this._value;
        this.datepickerValue.value.max = this._value;
        this.datepickerValue.isRange = false;
      }
      this.setPlaceholder();
    }
  }
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled($value: boolean) {
    if ($value !== undefined && $value !== null) {
      this._disabled = $value;
      if (this._disabled) {
        // is disabled
        this.renderer.addClass(this.elementRef.nativeElement, "--disabled");
      } else {
        // is not disabled
        this.renderer.addClass(this.elementRef.nativeElement, "--disabled");
      }
    }
  }
  @Input()
  set tags($value: DatepickerInputTags) {
    if ($value !== undefined && $value instanceof DatepickerInputTags) {
      this._tags = $value;
      if (this._tags.datepickerTags.momentFormat) {
        this.formatter.currentLanguageConfig = {
          months: this._tags.datepickerTags.months,
          monthsShort: this._tags.datepickerTags.monthsShort,
          weekdays: this._tags.datepickerTags.daysLong,
          weekdaysMin: this._tags.datepickerTags.daysMin,
          weekdaysShort: this._tags.datepickerTags.daysShort,
        };
        this.formatter.currentLanguageConfig.secondShort = "s";
        this.formatter.currentLanguageConfig.minuteShort = "m";
        this.formatter.currentLanguageConfig.hourShort = "h";
        this.setPlaceholder();
      }
    }
  }
  get tags(): DatepickerInputTags {
    return this._tags;
  }

  @Input()
  get startWeekWith(): number {
    return this._startWeekWith;
  }
  set startWeekWith($value: number) {
    if ($value !== undefined && $value !== null) {
      this._startWeekWith = $value;
      if (this.datepickerComponent !== undefined) {
        this.datepickerComponent.startWeekWith = $value;
        this.datepickerComponent.reset();
      }
    }
  }
  @Input()
  get forbiddenWeekDays(): number[] {
    return this._forbiddenWeekDays;
  }
  set forbiddenWeekDays($value: number[]) {
    if ($value !== undefined) {
      this._forbiddenWeekDays = $value;
    }
  }
  @Input()
  get disabledDates(): Date[] {
    return this._disabledDates;
  }
  set disabledDates($value: Date[]) {
    if ($value !== undefined) {
      this._disabledDates = $value;
    }
  }

  @Output()
  public select: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  public dayClicked: EventEmitter<any> = new EventEmitter<any>();

  public datepickerTextValue = "";
  public showDatepicker = false;
  @ViewChild("datepickerPositioner")
  private datepickerPositioner: ElementRef;
  @ViewChild("cmxDatepickerComponent")
  private datepickerComponent: CmxDatepickerComponent;
  private resetFlag = true;
  private _value: any;
  private _disabled = false;
  private datepickerValue: any;
  private _rtl = false;
  private _tags: DatepickerInputTags = new DatepickerInputTags(
    "Select Date",
    "Cancel",
    "Apply"
  );
  public _startWeekWith = 0;
  private _selectedValue: any;
  private _forbiddenWeekDays: number[];
  private _disabledDates: Date[];

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private formatter: DateFormatService,
    @Optional()
    @Inject("DatepickerInputTags")
    tagsProvider: DatepickerInputTags,
    @Optional()
    @Inject("RTL")
    isRTL: boolean
  ) {
    if (isRTL !== undefined) {
      this.rtl = isRTL;
    }

    if (tagsProvider !== undefined) {
      try {
        const $aux: DatepickerInputTags = new DatepickerInputTags(
          tagsProvider.placeholder,
          tagsProvider.cancel,
          tagsProvider.apply
        );
        this.tags = $aux;
      } catch ($exception) {
        console.warn("DatepickerInputTags provider requires 'instruction'");
      }
    }
    if (this.datepickerComponent !== undefined) {
      this.datepickerComponent.startWeekWith = this._startWeekWith;
    }
  }

  @HostListener("document:keydown.escape")
  public onKeydownEscape(): any {
    if (this.showDatepicker) {
      this.toggle();
    }
  }

  @HostListener("document:keydown.enter")
  public onKeydownEnter(): any {
    if (this.showDatepicker && this._selectedValue !== undefined) {
      this.setSelection(this._selectedValue);
    }
  }

  public ngOnInit(): void {
    if (this.placeholder === "" || this.placeholder === undefined) {
      this.initializePlaceholder();
    }
  }

  // ============================================================================================
  // control value accessor methods
  // ============================================================================================

  public propagateChange: (value: any) => void = (value) => {
    //
  };

  public propagateTouch: () => any = () => {
    //
  };

  public writeValue($value: Date): void {
    if ($value !== undefined) {
      this.value = $value;
    }
  }

  public registerOnChange($fn: any): void {
    this.propagateChange = $fn;
  }

  public registerOnTouched($fn: any): void {
    this.propagateTouch = $fn;
  }

  public setDisabledState($isDisabled: boolean): void {
    this.disabled = $isDisabled;
  }

  // ============================================================================================
  // datepicker manipulation
  // ============================================================================================
  public resetDatepicker(): void {
    this._value = undefined;
    this.resetFlag = true;
    this.initializePlaceholder();
  }

  public keypressCatcher($event: Event): boolean {
    $event.preventDefault();
    this.datepickerTextValue = "";
    return false;
  }

  public toggle(): void {
    if (this.disabled === false) {
      this.showDatepicker = !this.showDatepicker;
      if (this.showDatepicker) {
        setTimeout(() => {
          this.datepickerComponent.startWeekWith = this._startWeekWith;
          this.datepickerComponent.forbiddenWeekDays = this.forbiddenWeekDays;
          this.datepickerComponent.disabledDates = this.disabledDates;
          // set value so 'calculateDays' method gets called and re-renders all days.
          this.datepickerComponent.value = this.value;
          if (this.resetFlag && this._value === undefined) {
            this.datepickerComponent.reset();
            this.resetFlag = false;
          }
          this.isInsideViewport();
        }, 10);
      }
    }
  }

  private initializePlaceholder(): void {
    this.placeholder = this.tags.placeholder;
  }

  public disableToggle($event: Event): void {
    $event.stopPropagation();
  }

  public cancelSelection(): void {
    this.showDatepicker = false;
  }

  public setSelection($event: any): void {
    this.toggle();
    this.value = $event.isRange ? $event.value : $event.value.min;
    this.select.emit($event);
  }

  public notifyDayWasClicked($event: any): void {
    this._selectedValue = $event;
    this.dayClicked.emit($event);
  }

  private isInsideViewport(): void {
    const sidePositioner: string = this.rtl ? "right" : "left";
    // clearing
    this.renderer.setStyle(
      this.datepickerPositioner.nativeElement,
      sidePositioner,
      undefined
    );
    this.renderer.setStyle(
      this.datepickerPositioner.nativeElement,
      "top",
      undefined
    );
    // calculations
    const rect: ClientRect =
      this.datepickerPositioner.nativeElement.getBoundingClientRect();
    const screenHeight: number = window.innerHeight;
    const screenWidth: number = window.innerWidth;
    const adjustment = 20;
    if (this.rtl) {
      if (rect.left < 0) {
        const pxValue: number = Math.abs(rect.left);
        this.renderer.setStyle(
          this.datepickerPositioner.nativeElement,
          sidePositioner,
          -1 * pxValue + "px"
        );
      }
    } else {
      if (rect.right > screenWidth) {
        // move to the left
        const pxValue: number = Math.abs(rect.right - screenWidth) + adjustment;
        this.renderer.setStyle(
          this.datepickerPositioner.nativeElement,
          sidePositioner,
          -1 * pxValue + "px"
        );
      }
    }
    if (rect.bottom > screenHeight) {
      // move to the top
      const pxValue: number = Math.abs(rect.bottom - screenHeight);
      this.renderer.setStyle(
        this.datepickerPositioner.nativeElement,
        "top",
        -1 * pxValue + "px"
      );
    }
  }

  /**
   * @description Formats the datepicker´s value to a readable string
   */
  private setPlaceholder(): void {
    // tslint:disable-next-line
    const momentFormat: string =
      this.tags != undefined
        ? this.tags.datepickerTags.momentFormat
        : undefined;
    if (this.datepickerValue && this.datepickerValue.isRange === true) {
      this.placeholder = this.formatter.formatRange(
        this.datepickerValue,
        momentFormat
      );
    } else if (this.datepickerValue && this.datepickerValue.isRange === false) {
      this.placeholder = this.formatter.formatSingle(
        this.datepickerValue,
        momentFormat
      );
    }
  }
}
