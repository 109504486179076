import {
  AfterContentInit,
  Component,
  ContentChildren,
  HostBinding,
  QueryList,
  Input,
  Optional,
  Inject,
} from '@angular/core';
import { CmxTabComponent } from './../cmx-tab/cmx-tab.component';
import { IChangeTabFn } from '../../interfaces/tab-property';

@Component({
  selector: 'cmx-tabs',
  styleUrls: ['./../../../../../../scssv4/components/_nav.scss'],
  templateUrl: './cmx-tabs.component.html',
})
export class CmxTabsComponent implements AfterContentInit {
  @HostBinding('class.cmx-tabs')
  public cmxTabs: string = 'true';

  @ContentChildren(CmxTabComponent)
  public tabItems: QueryList<CmxTabComponent>;

  @Input()
  public rtl: boolean;

  @Input()
  private validateChangeTab: IChangeTabFn = null;

  constructor(@Optional() @Inject('RTL') isRTL: boolean) {
    if (isRTL != undefined) {
      this.rtl = isRTL;
    }
  }

  public ngAfterContentInit(): void {
    let activeCounter: number = 0;
    let counter: number = 0;
    this.tabItems.forEach((tab: CmxTabComponent) => {
      if (tab.id === undefined) {
        tab.id = counter;
      }
      if (tab.active) {
        activeCounter++;
      }
      counter++;
    });

    if (this.tabItems.length > 0 && activeCounter === 0) {
      this.tabItems.first.active = true;
    } else if (this.tabItems.length > 0 && activeCounter > 0) {
      this.activateTab(this.tabItems.first);
    }
  }

  public activateTab(tab: CmxTabComponent): void {
    if (this.validateChangeTab) {
      const activeTab: CmxTabComponent = this.activeTab;
      const result: boolean = this.validateChangeTab({ currentTab: activeTab, nextTab: tab });
      if (result) {
        this.resetTabs();
        tab.active = true;
      }

      return;
    }

    this.resetTabs();
    tab.active = true;
  }

  private resetTabs(): void {
    this.tabItems.forEach((tab: CmxTabComponent) => {
      tab.active = false;
    });
  }

  public get activeTab(): CmxTabComponent {
    const list: CmxTabComponent[] = this.tabItems.toArray();
    for (const tab of list) {
      if (tab.active) {
        return tab;
      }
    }

    return null;
  }
}
