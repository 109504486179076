import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';

@Injectable()
export class CalendarEvents {
    // Observable items sources
    public moment: any;
    private datesSubject = new BehaviorSubject<any>({
        start: moment(),
        end: moment()
    });

    // Observable items streams
    private dates$ = this.datesSubject.asObservable();

    setDates(dates: any) {
        (<any>window).localStorage.setItem('dates', JSON.stringify(dates));
        const currentDates = this.getCurrentValue();
        if (dates.start.diff(currentDates.start, 'days') !== 0 || dates.end.diff(currentDates.end, 'days') !== 0) {
            this.datesSubject.next(dates);
        }
    }

    getDates() {
        return this.dates$;
    }

    getCurrentValue() {
        return this.datesSubject.getValue();
    }
}
