import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    // tslint:disable-next-line:pipe-naming
    name: 'filterLegalEntity',
})
export class FilterLegalEntity implements PipeTransform {
    public transform(items, searchValue: string): any {

        const result = searchValue ? items.filter(
            (item) => {
                return item.legalEntityDesc.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 ||
                    item.legalEntityTypeCode.indexOf(searchValue) > -1;
            },
        ) : items;

        return result;
    }
}
