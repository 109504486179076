import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AssignDocumentRequest, GetDocumentsRequest } from '../models/documents/documents-request';
import { GetDocumentsResponse, Upload } from '../models/documents/documents-response';
import { FileRequest } from '../models/documents/file-request';
import { DownloadedDocumentResponse, FileResponse } from '../models/documents/file-response';
import { CmxHttpClient } from '../services/api.service';
import { createHttpParams } from '../shared/helpers';

@Injectable()
export class DocumentsService {
  constructor(private http: CmxHttpClient) {}

  getDocuments(payload: GetDocumentsRequest): Observable<GetDocumentsResponse> {
    const params = payload ? createHttpParams(payload) : new HttpParams();
    return this.http.get(`/v6/ds/documents`, { params });
  }

  getDocumentsV7(payload: GetDocumentsRequest): Observable<GetDocumentsResponse> {
    const params = payload ? createHttpParams(payload) : new HttpParams();
    return this.http.get(`/v7/ds/documents`, { params });
  }

  assignDocument(documentId: number, payload: AssignDocumentRequest): Observable<Upload> {
    return this.http.patch(`/v7/ds/documents/${documentId}/assigned`, payload);
  }

  markAsCompleted(documentId: number): Observable<Upload> {
    return this.http.patch(`/v7/ds/documents/${documentId}/completed`, {});
  }

  requestFile(payload: FileRequest): Observable<FileResponse> {
    const params = payload ? createHttpParams(payload) : new HttpParams();
    return this.http.get(`/v5/ds/previewfile`, { params });
  }

  requestDocument(documentId: number): Observable<DownloadedDocumentResponse> {
    return this.http.get(`/v7/ds/documents/${documentId}/downloaded`);
  }
}
