export class Profile {
    public allowEmailUpdates: boolean;
    public allowInformationShare: boolean;
    public customerId: number;
    public firstName: string;
    public lastName: string;
    public fullName: string;
    public phoneNumber: string;
    public status: string;
    public userAccount: string;
    public userId: number;
    public userPosition: string;
    public userType: string;
    public countryAreaCode?: string;
    public languageId?: number;
    public cloudContactMethodId?: number;
    public cloudContactTypeId?: number;
    public cloudContactCode?: string;
}
