<!-- this component must be inside a div with dropdown bootstrao class 
    this component must be after a button to fire bootstrap dropdown
    this component must have dropdown-menu bootstrap class
-->

<div 
    class="dropdown-menu-container" 
    aria-labelledby="" 
    (click)="clickInside($event)" id="filtro">
    <div id="cmx-filter-sorter-search-input" *ngIf="enableSearch" dir="{{dirRtl}}" class="search-input">
        <span class="cmx-icon-magnifier-glass icon" aria-hidden="true"></span>
        <input (keyup.enter)="onSearch($event)" [(ngModel)]="_searchValue" type="text" placeholder="{{ placeholder }}" (focus)="resetInput()">
        <span class="cmx-icon-close icon-clear" (click)="reset()" aria-hidden="true"></span>
    </div>
    <div class="cmx-checkbox__container">
        <cmx-checkbox [rtl]="rtl" class="sorted__box" [ngModel]="_isAscending" (ngModelChange)="ascendingItem($event)">{{keyOrderAsc}}</cmx-checkbox>
        <br/>
        <cmx-checkbox [rtl]="rtl" class="sorted__box" [ngModel]="_isDescending" (ngModelChange)="descendingItem($event)">{{keyOrderDesc}}</cmx-checkbox>
    </div>
</div>