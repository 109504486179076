import { Injectable } from '@angular/core';
import * as moment_ from 'moment';

import { TranslationService } from './translation.service';
import fromExponential from './../fromExponential';

const moment = moment_;

export enum DateTimeActions {
  ConvertToLocal,
  NoConvertToLocal
}

@Injectable()
export class FormatterService {
  constructor() {}

  // DATES

  /**
   * @description Transforms a valid string to a date object
   * @param date The date as string
   * @returns A javascript date object
   */
  public getDateObject(date: string): Date {
    try {
      const auxDate = date.replace(/-g/, '/');
      const bits: any = auxDate.split(/\D/);
      const month = parseInt(bits[1], undefined) - 1;
      if (bits.length === 3) {
        return new Date(bits[0], month, bits[2]);
      } else {
        return new Date(bits[0], month, bits[2], bits[3], bits[4], bits[5]);
      }
    } catch ($exception) {
      return undefined;
    }
  }

  public formatDateTimeMoment(
    date: string | number | void | moment_.Moment | Date | (string | number)[] | moment_.MomentInputObject,
    languageISO: string,
    format: string,
    action: DateTimeActions = DateTimeActions.ConvertToLocal
  ) {
    if (TranslationService.currentLanguage) {
      moment.updateLocale(
        TranslationService.currentLanguage.languageISO.substr(0, 2),
        TranslationService.currentLanguage.momentConfig
      );
    }

    if (action === DateTimeActions.NoConvertToLocal) {
      return moment(date)
        .locale(languageISO.substr(0, 2))
        .format(format);
    }

    return this.utcToLocal(date)
      .locale(languageISO.substr(0, 2))
      .format(format);
  }

  /**
   * @description Format a number by TypeScript Number function
   * The static value '-u-nu-latn' is for always returns commons numbers '1,2,3,4,5,6,7,8,9,0'
   * @param numberEntry: value to format
   * @param languageISO: specifict language to format
   * @param decimalNumbers: quantity decimals to set in the format
   * @returns A value formatted
   */
  public formatNumberTS(numberEntry: number, languageISO: string, decimalNumbers: number) {
    if (isNaN(numberEntry) || numberEntry === null) {
      return 0;
    }

    return Number(
      this.round(numberEntry, decimalNumbers).toFixed(decimalNumbers)
    ).toLocaleString(languageISO + '-u-nu-latn', {
      minimumFractionDigits: numberEntry % 1 === 0 ? 0 : decimalNumbers
    });
  }

  public utcToLocal(dateEntry: moment_.MomentInput) {
    return moment.utc(dateEntry).local();
  }

  public localToUtc(date: moment_.MomentInput, format?: any) {
    if (format !== undefined) {
      return moment(date, 'YYYY-MM-DD THH:mm:ssZ')
        .utc()
        .format(format);
    } else {
      return moment(date, 'YYYY-MM-DD THH:mm:ssZ')
        .utc()
        .format();
    }
  }

  // expected time '00:00:00'
  public timeToMinutes(time: string) {
    const timeSplit = time.split(':');

    const hours = +timeSplit[0];
    const minutes = +timeSplit[1];
    const seconds = +timeSplit[2];

    return hours * 60 + minutes + this.roundToDown(seconds / 60);
  }

  // NUMBERS

  /**
   * @description Formats a number as a currency preserving its precision.
   * In order the keep the precision the number must be passed as a string
   * @param value The number value to format
   * @param numberDecimals The number of decimals to use
   * @param thousandSeparator The string character to use when separating numbers
   * @param decimalSeparator The string character to use when separating the decimal and integer part
   * @returns The number as a currency string
   */
  public formatNumber(
    value: number | string,
    numberDecimals?: number,
    thousandSeparator?: string,
    decimalSeparator?: string
  ): string {
    // tslint:disable-next-line: triple-equals
    if (value == undefined || value.toString() == '' || isNaN(value as any)) {
      console.warn('Not a valid number to format');
      return '';
    }

    // tslint:disable-next-line:triple-equals
    numberDecimals = numberDecimals != undefined ? numberDecimals : 2;
    // tslint:disable-next-line:triple-equals
    thousandSeparator =
      thousandSeparator !== undefined ? thousandSeparator : ',';
    // tslint:disable-next-line:triple-equals
    decimalSeparator = decimalSeparator !== undefined ? decimalSeparator : '.';

    const completeNumber: string = fromExponential(value);

    const sign: string = value < 0 ? '-' : '';
    const absoluteValue: string =
      value < 0 ? completeNumber.split('-')[1] : completeNumber;
    const truncatedDecimalValue: string =
      absoluteValue.split('.')[1] &&
      absoluteValue.split('.')[1].slice(0, numberDecimals);

    const integerPart: string = absoluteValue.split('.')[0];
    const floatingPart: string = this.rightPad(
      truncatedDecimalValue,
      '0',
      numberDecimals
    );

    let formattedInteger: string = integerPart;

    if (Number(integerPart) >= 1000) {
      formattedInteger = integerPart.replace(/./g, (c, i, a) => {
        return i && c !== decimalSeparator && (a.length - i) % 3 === 0
          ? thousandSeparator + c
          : c;
      });
    }

    // tslint:disable-next-line:max-line-length
    const formattedNumber = `${sign}${formattedInteger}${
      floatingPart.length > 0 ? decimalSeparator : ''
    }${floatingPart}`;

    return formattedNumber;
  }

  public round(value: number, decimals: number) {
    let type = '';
    type = 'round';
    const valueDecimals = value % 1;
    if (valueDecimals !== 0.5) {
      return Number(Math[type](value + 'e' + decimals) + 'e-' + decimals);
    } else {
      return Number(value);
    }
  }

  public roundToDown(value: number) {
    return Math.floor(value);
  }

  // STRINGS
  public removeFirstZeros(value: string): string {
    return value.replace(/^0+/, '');
  }

  public pad(text: string, wildcard: string, length: number): string {
    let result = text;
    while (result.length < length) {
      result = wildcard + result;
    }
    return result;
  }

  /**
   * @description Fills up a string with the specified character appending it to the right till lenght is achieved
   * @param text String to add padding
   * @param wildcard Character that's gonne be repeated
   * @param lenght The desired length
   */
  public rightPad(
    text: number | string | undefined,
    wildcard: string,
    length: number
  ): string {
    if (text === undefined || text === null || text === '') {
      return wildcard.repeat(length);
    }
    let str = text.toString();
    while (str.length < length) {
      str += wildcard;
    }

    return str;
  }

  public formatMeasureUnit(measureUnit: any) {
    switch (measureUnit) {
      case 'YD3':
        return 'Yd³';
      case 'M3':
        return 'M³';
      default:
        return measureUnit;
    }
  }
}
