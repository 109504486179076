import {
  Directive,
  Input,
  ComponentRef,
  ViewContainerRef,
  Injector,
  ComponentFactoryResolver,
  ApplicationRef,
  Renderer2,
  EmbeddedViewRef,
  DoCheck,
  PLATFORM_ID,
  Inject,
} from '@angular/core';
import { CmxSpinnerComponent, SpinnerSize } from './cmx-spinner.component';
import { isPlatformBrowser } from '@angular/common';
import { AfterContentInit, OnDestroy } from '@angular/core';

// tslint:disable:comment-format
/** The direct DOM manipulation assigning CSS classes is used instead of @HostBinding because the later
 * is a destructive operation and will remove the previous classes applied to the parent component of the directive.
 * Check the possible updates of this functionality here https://github.com/angular/angular/issues/7289
 */
@Directive({
  selector: '[cmxSpinner]',
})
export class CmxSpinnerDirective implements DoCheck, AfterContentInit, OnDestroy {
  @Input()
  get cmxSpinner(): boolean | Promise<any> {
    return this._cmxSpinner;
  }
  set cmxSpinner($value: boolean | Promise<any>) {
    this._cmxSpinner = $value;
    if ($value && typeof $value !== 'boolean' && typeof $value.then === 'function') {
      this.isResolved = false;
    }
  }
  @Input()
  public cmxSpinnerError?: boolean;
  @Input()
  public cmxSpinnerErrorMessage?: string;

  //TODO: Add icon and submessage

  private spinnerComponentReference: ComponentRef<CmxSpinnerComponent>;
  private spinnerComponentInstance: CmxSpinnerComponent;
  private parentElement: HTMLElement;
  private dummyInput: HTMLElement;
  private window: any;
  private _cmxSpinner: boolean | Promise<any>;
  private parentElementDisplay: string | null;
  private isActive = false;
  private isRendered = false;
  private isResolved = false;

  constructor(
    private viewContainer: ViewContainerRef,
    private injector: Injector,
    private componentFactory: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    if (isPlatformBrowser(platformId)) {
      this.window = window;
    }
  }

  public ngDoCheck(): void {
    if (typeof this.cmxSpinner === 'boolean') {
      if (this.cmxSpinner && !this.isActive) {
        this.createComponent();
      }

      if (!this.cmxSpinner && this.isActive) {
        this.destroyComponent();
      }

      if (this.cmxSpinner && this.isActive) {
        // this.spinnerComponentInstance.error = this.spinnerComponentInstance.error || this.cmxSpinnerError;
        // this.cmxSpinnerMessage !== "" && this.spinnerComponentInstance.message = this.cmxSpinnerMessage;
        // this.spinnerComponentInstance.message = this.cmxSpinnerMessage;
        //TODO: Add icon and submessage
      }
    } else if (this.cmxSpinner && typeof this.cmxSpinner.then === 'function') {
      if (this.cmxSpinner && !this.isActive && !this.isResolved) {
        this.createComponent();

        this.cmxSpinner
          .then((x: any) => {
            this.isResolved = true;
            this.destroyComponent();
          })
          .catch((x: any) => (this.spinnerComponentInstance.error = true));
      }
    }
  }

  public ngAfterContentInit(): void {
    this.isRendered = true;
  }

  public ngOnDestroy(): void {
    this.destroyComponent();
  }

  private createComponent(): void {
    if (this.isRendered) {
      this.isActive = true;

      this.spinnerComponentReference = this.componentFactory
        .resolveComponentFactory(CmxSpinnerComponent)
        .create(this.injector);
      this.spinnerComponentInstance = this.spinnerComponentReference.instance;

      this.appRef.attachView(this.spinnerComponentReference.hostView);

      const domParent: HTMLElement = this.viewContainer.element.nativeElement;
      const domElement: HTMLElement = (this.spinnerComponentReference.hostView as EmbeddedViewRef<any>).rootNodes[0];

      this.parentElement = domParent;

      this.insertComponentIntoDOM(domParent, domElement);
    }
  }

  private destroyComponent(): void {
    this.isActive = false;

    if (this.spinnerComponentReference) {
      this.renderer.removeStyle(this.parentElement, 'position');
      this.renderer.removeStyle(this.parentElement, 'visibility');
      this.spinnerComponentInstance.show = false;
      this.appRef.detachView(this.spinnerComponentReference.hostView);
      this.spinnerComponentReference.hostView.destroy();
    }

    if (this.dummyInput) {
      this.dummyInput.remove();
      this.renderer.setStyle(this.parentElement, 'display', this.parentElementDisplay);
    }
  }

  private insertComponentIntoDOM(parentElement: HTMLElement, componentElement: HTMLElement): void {
    const parentElementClientRect: ClientRect = parentElement.getBoundingClientRect() as ClientRect;

    this.spinnerComponentInstance.size = this.getSpinnerSize(
      parentElementClientRect.width,
      parentElementClientRect.height,
    );
    this.spinnerComponentInstance.show = true;
    this.spinnerComponentReference.changeDetectorRef.detectChanges();

    if (
      (parentElement.nodeName === 'INPUT' && (parentElement.getAttribute('type') || '').toUpperCase() === 'TEXT') ||
      parentElement.nodeName === 'SELECT'
    ) {
      this.insertForInput(parentElement, componentElement);
    } else if (
      ((parentElement.nodeName === 'INPUT' && parentElement.getAttribute('type')) || '').toUpperCase() === 'BUTTON' ||
      parentElement.nodeName === 'BUTTON'
    ) {
      this.insertForButton(parentElement, componentElement);
    } else {
      this.insertForElement(parentElement, componentElement, parentElementClientRect);
    }
  }

  private insertForButton(parentElement: HTMLElement, componentElement: HTMLElement): void {
    componentElement.classList.add('cmx-spinner-container', '--button');

    parentElement.insertBefore(componentElement, getElementNode(parentElement));
  }

  private insertForInput(parentElement: HTMLElement, componentElement: HTMLElement): void {
    this.dummyInput = this.renderer.createElement('div');
    const originalStyles: CSSStyleDeclaration = this.window.getComputedStyle(parentElement);
    this.parentElementDisplay = originalStyles.display;

    this.dummyInput.style.cssText = getCssText(originalStyles);
    this.dummyInput.style.position = 'relative';
    this.dummyInput.style.cursor = 'not-allowed';
    this.dummyInput.classList.add('cmx-spinner__dummy');

    parentElement.insertAdjacentElement('beforebegin', this.dummyInput);
    this.renderer.appendChild(this.dummyInput, componentElement);

    if (parentElement.nodeName === 'SELECT') {
      componentElement.classList.add('cmx-spinner-container', '--select');
    } else {
      componentElement.classList.add('cmx-spinner-container', '--input');
    }

    this.renderer.setStyle(parentElement, 'display', 'none');
  }

  private insertForElement(
    parentElement: HTMLElement,
    componentElement: HTMLElement,
    parentMeasures: ClientRect,
  ): void {
    componentElement.classList.add('cmx-spinner-container', '--element');

    parentElement.insertBefore(componentElement, getElementNode(parentElement));
    // const paddings: {x: number, y: number } = this.getPaddings(parentElement);

    if (
      parentElement.style.position === 'absolute' ||
      parentElement.style.position === 'fixed' ||
      parentElement.style.position === 'sticky'
    ) {
      //TODO: Throw a proper error
      // tslint:disable-next-line:no-string-throw
      throw 'Unsuported position. Cmx-spinner directive cannot be applied on absolute, fixed or sticky elements';
    }
    this.renderer.setStyle(parentElement, 'position', 'relative');
    this.renderer.setStyle(parentElement, 'visibility', 'hidden');

    // this.renderer.setStyle(firstElement , "width", (parentElementClientRect.width - paddings.x).toString() + "px");
    // this.renderer.setStyle(firstElement, "height", (parentElementClientRect.height - paddings.y).toString() + "px");
    this.renderer.setStyle(componentElement.firstElementChild, 'width', parentMeasures.width.toString() + 'px');
    this.renderer.setStyle(componentElement.firstElementChild, 'height', parentMeasures.height.toString() + 'px');
  }

  // private getPaddings(element: HTMLElement): {x: number, y: number} {
  //     const style: any = this.window.getComputedStyle(element);
  //     const leftPadding: number = Number(style.getPropertyValue("padding-left").slice(0, -2)) || 0;
  //     const rightPadding: number = Number(style.getPropertyValue("padding-right").slice(0, -2)) || 0;
  //     const topPadding: number = Number(style.getPropertyValue("padding-top").slice(0, -2)) || 0;
  //     const bottomPadding: number = Number(style.getPropertyValue("padding-bottom").slice(0, -2)) || 0;

  //     return { x: leftPadding + rightPadding, y: topPadding + bottomPadding };
  // }

  //TODO: UX What's the size ratio?
  private getSpinnerSize(width: number, height: number): SpinnerSize {
    if (height < 200) {
      return 'Small';
    } else if (height < 400) {
      return 'Moderate';
    } else {
      return 'Big';
    }
  }
}

const getElementNode: any = (element: HTMLElement | Node): Node | null => {
  // tslint:disable-next-line:no-angle-bracket-type-assertion
  for (const n of <Node[]>(<any>element.childNodes)) {
    if (n.nodeType === 1) {
      return n;
    }
  }

  // tslint:disable-next-line:no-null-keyword
  return null;
};

/** @description "Polyfill" for Edge */
const getCssText: any = (styles: CSSStyleDeclaration): string => {
  let cssText = '';
  //@ts-ignore
  for (const prop of styles) {
    cssText += `${prop}: ${styles[prop]}; `;
  }
  return cssText;
};
