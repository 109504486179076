import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CmxHttpClient } from '../services/api.service';
import { createHttpParams, formatEntityIdParameters } from '../shared/helpers';

// INTERFACES
import { IPointOfDeliverySalesAreasRequest } from '../models/point-of-delivery-sales-areas/point-of-delivery-sales-areas-request';
import { IPointOfDeliverySalesAreasResponse } from '../models/point-of-delivery-sales-areas/point-of-delivery-sales-areas-response';

@Injectable()
export class PointOfDeliverySalesAreasService {
  constructor(private http: CmxHttpClient) {}

  getPointOfDeliverySalesAreas(request: IPointOfDeliverySalesAreasRequest): Observable<IPointOfDeliverySalesAreasResponse> {
    const httpParams = request ? createHttpParams(request) : new HttpParams();
    return this.http.get<IPointOfDeliverySalesAreasResponse>(`/v5/sm/pointofdeliverysalesareas`, {
      params: httpParams,
    });
  }

  getAddresses(payload: IPointOfDeliverySalesAreasRequest): Observable<IPointOfDeliverySalesAreasResponse> {
    const httpParams = payload ? createHttpParams(formatEntityIdParameters(payload)) : new HttpParams();
    return this.http.get<IPointOfDeliverySalesAreasResponse>(`/v5/sm/pointofdeliverysalesareas`, {
      params: httpParams,
    });
  }
}
