import {
    Directive,
    Input,
    ComponentRef,
    ElementRef,
    ComponentFactoryResolver,
    ViewContainerRef,
    HostListener,
} from '@angular/core';
// cmx
import { CmxTooltipComponent } from '../components';

@Directive({
    selector: '[cmx-tooltip]',
})
export class CmxTooltipDirective {

    // tslint:disable-next-line:no-input-rename
    @Input('cmx-tooltip') public _content: string;
    set content( value: string ) {
        this._content = value;
    }
    get content(): string {
        return this._content;
    }

    @Input('diffYLeft') public diffYLeft: number;
    @Input('diffYRight') public diffYRight: number;

  // tslint:disable-next-line:no-input-rename
    @Input('tooltipMaxWidth') public maxWidth: number = undefined;

    @Input('rtl') public rtl: boolean;

    @Input('position')
    set position(value: string) {
        this._position = value;
    }

    private reference: ComponentRef<any>;
    private offset: number = 0;
    private _position: string;

    constructor(
        private elementRef: ElementRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        private viewContainerRef: ViewContainerRef,
    ) {
    }

    @HostListener( 'mouseenter', [ '$event' ] )
    public show( $event: Event ): void {
        // tslint:disable-next-line:typedef
        const rect = this.elementRef.nativeElement.getBoundingClientRect();
        // tslint:disable-next-line:typedef
        const options = {
            content: this.content,
            width: rect.width,
            // tslint:disable-next-line:object-literal-sort-keys
            height: rect.height,
            x: rect.left,
            y: rect.top,
            position: this._position,
        };

        // tslint:disable-next-line:typedef
        const factory = this.componentFactoryResolver.resolveComponentFactory(
            CmxTooltipComponent,
        );
        this.reference = this.viewContainerRef.createComponent( factory );
        this.reference.instance.options = options;
        this.reference.instance.rtl = this.rtl;
        this.reference.instance.diffYLeft = this.diffYLeft;
        this.reference.instance.diffYRight = this.diffYRight;
        this.reference.instance.maxWidth = this.maxWidth;
        this.reference.changeDetectorRef.detectChanges();
    }

    @HostListener( 'mouseleave', [] )
    public hide(): void {
        this.reference.destroy();
    }

    private stop( event: Event ): void {
        event.preventDefault();
        this.hide();
    }

}
