import { Jobsite } from './jobsite';
export class City {
    cityDesc: string;
    regionId?: number;
    expanded?: boolean;
    jobsitesFetched?: boolean;
    jobsites: Jobsite[];
    checked?: boolean;
    visible?: boolean;
}
