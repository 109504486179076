import { Component, Input, Output, EventEmitter, HostListener,
  ElementRef, HostBinding, AfterViewInit, ChangeDetectorRef,
} from '@angular/core';

import { CmxOptionSelectEvent } from '../../models/cmx-select.classes';

@Component({
  selector: 'cmx-option',
  styleUrls: ['./../../../../../../../../../scssv4/cmx-components/cmx-form-field/v4/cmx-select.component.scss'],
  templateUrl: './cmx-option.component.html',
})
export class CmxOptionComponent implements AfterViewInit {

  @Input()
  set value($value: any) {
      if ($value !== undefined) {
          this._value = $value;
      }
  }
  get value(): any {
      return this._value;
  }
  @Input()
  set hide($value: boolean) {
      if ($value !== undefined) {
          this._hide = $value;
          this.cmxSelectOptionClass = !this._hide;
      }
  }
  get hide(): boolean {
      return this._hide;
  }
  @Input()
  set multipleSelect($value: boolean) {
      if ($value !== undefined) {
          this._multipleSelect = $value;
      }
  }
  get multipleSelect(): boolean {
      return this._multipleSelect;
  }
  @Input()
  set selectedid(value: any) {
      this._selectedid = value;
      this.ref.detectChanges();
  }
  get selectedid(): any {
      return this._selectedid;
  }

  @Input()
  set isSelectAll(value: boolean) {
      this._isSelectAll = value;
      if (this.isSelectAll) {this.multipleSelect = true; }
  }
  get isSelectAll(): boolean {
      return this._isSelectAll;
  }

  // @Input() public isSelectAll: boolean = false;

  @Output() public optionSelected: EventEmitter<CmxOptionSelectEvent> = new EventEmitter<CmxOptionSelectEvent>();

  @HostBinding('class.cmx-select-dd__option') public cmxSelectOptionClass = true;

  public selected = false;
  private _value: any;
  private disabled = false;
  private _hide = false;
  private viewValue = '';
  private _multipleSelect = false;
  private _selectedid: any;
  private _isSelectAll: boolean;

  constructor(private elementRef: ElementRef, private ref: ChangeDetectorRef) {
      //
  }

  public ngAfterViewInit(): void {
      this.viewValue = this.elementRef.nativeElement.textContent;
      if (this.isSelectAll) {this.selected = false; }
  }

  /**
   * @description Sets the current cmx-option to be the one selected with no user input.
   */
  public select(emitEvent: boolean = true, hardSelect?: boolean): void {
      if (hardSelect) {
          this.selected = hardSelect;
      } else {
          this.selected = !this.selected;
      }
      if (emitEvent) {
          this.optionSelected.emit(new CmxOptionSelectEvent(this, false));
      }
      this.ref.detectChanges();
  }

  /**
   * @description Removes the selection of the current cmx-option.
   */
  public deselect(): void {
      this.selected = false;
  }

  public getViewValue(): string {
      return this.viewValue;
  }

  /**
   * @description Sets the current cmx-option to be the one selected given the user clicked the option.
   * @param $event HTML event generated by the user's click.
   */
  @HostListener('click', ['$event'])
  public selectViaInteraction($event: Event): void {
      $event.stopPropagation();
      if (!this.disabled) {
          this.selected = !this.selected;
          this.optionSelected.emit(new CmxOptionSelectEvent(this, true));
      }
  }
}
