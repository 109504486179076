//import './polyfills';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'zone.js/dist/zone';
export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

const providers = [
  { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] as any[] },
];

// window['API_HOST'] = environment.apiHost;
// window['API_ORG'] = environment.apiOrg;
// window['API_ENV'] = environment.apiEnv;
// window['ENV'] = environment.env;
// window['APP_CODE'] = environment.appCode;
// window['CLIENT_ID'] = environment.clientId;
// window['HOST_EUROPE'] = environment.hostEurope;
// window['HOST_AMERICA'] = environment.hostAmerica;
// window['SITE_DOMAIN'] = environment.siteDomain;
// window['COUNTLY_KEY'] = environment.countlyKey;
// window['COUNTLY_URL'] = environment.countlyUrl;
// window['SHOW_MAINTENANCE_ALERT'] = environment.showMaintenanceAlert;
// window['CMX_LOYALTY_PROGRAM_URL'] = environment.cmxLoyaltyProgramUrl;
// window['LANGUAGE'] = environment.language;
// window['TRANSLATE_URL'] = environment.translations.translateApiUrl;

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic(providers)
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
