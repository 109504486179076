import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable()
export class TruckEvents {
    // Observable items sources
    private selectedTruckSubject = new BehaviorSubject<any>(null);

    constructor() { }

    selectTruck(truck: any) {
        const currentTruck = this.truckValue();

        // Fire only if selected truck differs from the previous one
        if (currentTruck !== truck) {
            this.selectedTruckSubject.next(truck);
        }
    }

    selectedTruck() {
        return this.selectedTruckSubject.asObservable();
    }

    truckValue() {
        return this.selectedTruckSubject.getValue();
    }
}
