import { Pipe, PipeTransform } from '@angular/core';

import { FormatterService } from './../services/formatter.service';

@Pipe({
  name: 'cmxCurrencyCustom',
  pure: true
})
export class CmxCurrencyCustomPipe implements PipeTransform {
  private value = '';

  constructor(private formatter: FormatterService) {}

  public transform(
    $value: any,
    $currencySymbol: string,
    $decimalNumbers: string | number,
    $thousandSeparator: string,
    $decimalSeparator: string,
    $floatCurrencySymbolRight?: boolean
  ): string {
    try {
      if ($value !== undefined && isNaN($value) === false) {
        this.value = $value.toString();
        $decimalNumbers = parseInt($decimalNumbers.toString(), undefined);
        if ($value < 0) {
          // value is negative
          const v = parseFloat($value.toString().replace(/\-/g, ''));
          const $aux: string = this.formatter.formatNumber(
            v,
            $decimalNumbers,
            $thousandSeparator,
            $decimalSeparator
          );
          if ($floatCurrencySymbolRight) {
            this.value = '-' + $aux + $currencySymbol;
          } else {
            this.value = '-' + $currencySymbol + $aux;
          }
        } else {
          this.value =
            $currencySymbol +
            this.formatter.formatNumber(
              $value,
              $decimalNumbers,
              $thousandSeparator,
              $decimalSeparator
            );
        }
      } else {
        this.value = '';
      }
    } catch ($exception) {
      console.error('Value trying to transform: ', $value);
      console.error($exception);
      this.value = '';
    }
    return this.value;
  }
}
