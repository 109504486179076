import { HttpParams } from '@angular/common/http';
import { EntityId } from '../models/common';
import { CustomEncoder } from '../services/custom-encoder';

export const createHttpParams = (request: {}): HttpParams => {
  const customEncoder = new CustomEncoder();
  let params = new HttpParams({ encoder: customEncoder });
  Object.keys(request).map(key => {
    if (request[key] instanceof Array) {
      params = params.append(key, request[key].join(','));
    } else {
      params = params.append(key.toString(), request[key]);
    }
  });
  return params;
};

// tslint:disable-next-line:no-any
const checkIfEntityId = (e: EntityId | any) => {
  return e.entityId && e.entityTypeId;
};

export const formatEntityIdParameters = request => {
  const result = {};
  Object.keys(request).forEach(param => {
    if (request[param] instanceof Array) {
      result[param] = [];
      result[param].push(
        request[param].forEach(e => {
          return checkIfEntityId(e) ? `${e.entityId}.${e.entityTypeId}` : e;
        }),
      );
    } else if (request[param] instanceof Object) {
      result[param] = checkIfEntityId(request[param])
        ? `${request[param].entityId}.${request[param].entityTypeId}`
        : request[param];
    } else {
      result[param] = request[param];
    }
  });
  return result;
};
