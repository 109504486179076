import { NgModule } from '@angular/core';
import { CustomerInfoLauncherComponent } from './customer-info-launcher.component';
import { EnvironmentService } from '../../services';
import { SpaFunctions } from '../../helpers/functions';

@NgModule({
  imports: [],
  declarations: [CustomerInfoLauncherComponent],
  exports: [CustomerInfoLauncherComponent],
  providers: [EnvironmentService, SpaFunctions]
})
export class CustomerInfoLauncherModule {}
