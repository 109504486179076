import { Component } from '@angular/core';

@Component({
    selector: 'cmx-dialog-header',
    template: '<ng-content></ng-content>',
})
export class CmxDialogHeaderComponent {

    constructor() { }

}
