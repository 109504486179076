export type AppKeyProvider = () => string;

export interface IConnectionConfig {
    appName: string; // e.g. Track
    appKey: string | AppKeyProvider;
    appVersion: string;
    syncInterval?: number; // 0 defines no syncing, time is in seconds...
}

export enum FeatureTogglePropertyTypeDefinition {
    Boolean = 'boolean',
    Number = 'number',
    String = 'string',
}

export enum FeatureToggleType {
    Flag = 'Flag',
    Configuration = 'Configuration',
    Variant = 'Variant',
}

export interface IFeatureToggleConfig {
    features: Feature[];
    userProperties?: IFeatureTogglePropertyDefinition[];
}

export interface IFeatureTogglePropertyDefinition {
    name: string;
    type: FeatureTogglePropertyTypeDefinition;
}

export type FeatureTogglePropertyType = boolean | number | string;

export interface IFeatureToggleProperties {
    [key: string]: FeatureTogglePropertyType;
}

export type Feature = IUserFeature | IParametrizedFeature;

export interface IFeatureBase {
    name: string;
    type: FeatureToggleType;
    defaultValue: any; // boolean|number|string
    variants?: string[];
}

export interface IUserFeature extends IFeatureBase {
    isUserFeature: true;
}

export interface IParametrizedFeature extends IFeatureBase {
    isUserFeature?: false;
    properties?: IFeatureTogglePropertyDefinition[];
}

export enum UpdateStatus {
    CACHE,
    REMOTE,
}

export interface IUpdateInfo {
    hasChanges: boolean;
    status: UpdateStatus;
}
