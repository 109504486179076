
import { Directive, AfterContentInit, Input, Output,
         EventEmitter, ContentChildren, QueryList,
         forwardRef, HostBinding } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { CmxRadioChange } from './cmx-radio-change';
import { CmxRadioButtonComponent } from './cmx-radio.component';
let uniqueIdCounter: number = 0;

export const MD_RADIO_GROUP_CONTROL_VALUE_ACCESSOR: any = {
    multi: true,
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef( () => CmxRadioGroupDirective ),
};

@Directive({
    providers: [ MD_RADIO_GROUP_CONTROL_VALUE_ACCESSOR ],
    selector: 'cmx-radio-group',
})

export class CmxRadioGroupDirective implements AfterContentInit, ControlValueAccessor {

    @Output()
    public change: EventEmitter<CmxRadioChange> = new EventEmitter<CmxRadioChange>();
    @Output()
    public onChange: EventEmitter<any> = new EventEmitter<any>();

    @HostBinding('attr.role') public role: string = 'radiogroup';
    @HostBinding('class.mat-radio-group') public matRadioGroup: boolean = true;
    /* public role(): string{
        return 'radiogroup';
    } */

    @Input() public id: string = `md-radio-${uniqueIdCounter++}`;
    private _value: any = undefined;
    private _disabled: boolean = false;
    private _name: string = `md-radio-group-${ uniqueIdCounter++ }`;
    private _selected: CmxRadioButtonComponent = undefined;
    private isInitialized: boolean = false;
    @ContentChildren( forwardRef( () => CmxRadioButtonComponent ), { descendants: true } )
    private radios: QueryList<CmxRadioButtonComponent> = null;

    public controlValueAccessorChangeFn: ( value: any ) => void = ( value ) => {};
    public onTouched: () => any = () => {};


    @Input()
    get disabled(): boolean {
        return this._disabled;
    }
    set disabled( value: boolean ) {
        if ( value ) {
            this._disabled = true;
        }
    }

    @Input()
    get name(): string {
        return this._name;
    }
    set name( value: string ) {
        this._name = value;
        this.updateRadioButtonNames();
    }

    @Input()
    get value(): number {
        return this._value;
    }

    set value( newValue: number ) {
        if ( this._value !== newValue ) {
            this._value = newValue;
            this.updateSelectedRadioFromValue();
            this.checkSelectedRadioButton();
        }
    }

    @Input()
    get selected(): CmxRadioButtonComponent {
        return this._selected;
    }
    set selected( selected: CmxRadioButtonComponent ) {
        this._selected = selected;
        this.value = selected ? selected.value : null;
        this.checkSelectedRadioButton();
    }

    public touch(): void {
        if ( this.onTouched ) {
            this.onTouched();
        }
    }

    public ngAfterContentInit(): void {
        this.isInitialized = true;
    }

    public emitChangeEvent(): void {
        if ( this.isInitialized ) {
            const event: CmxRadioChange = new CmxRadioChange();
            event.source = this._selected;
            this.updateCheckIcons();
            event.value = this._value;
            this.change.emit( event );
            this.onChange.emit( event.value );
        }
    }

    // controlValueAccessor
    public writeValue( value: number ): void {
        this.value = value;
    }

    // controlValueAccessor
    public registerOnTouched( fn: any ): void {
        this.onTouched = fn;
    }

    // controlValueAccessor
    public setDisabledState( isDisabled: boolean ): void {
        this.disabled = isDisabled;
    }

    // controlValueAccessor
    public registerOnChange( fn: ( value: any ) => void ): void {
        this.controlValueAccessorChangeFn = fn;
    }

    private checkSelectedRadioButton(): void {
        if ( this.selected && !this._selected.checked ) {
            this._selected.checked = true;
        }
    }

    private updateRadioButtonNames(): void {
        if ( this.radios ) {
            this.radios.forEach( (radio: CmxRadioButtonComponent) => {
                radio.name = this.name;
            });
        }
    }

    private updateSelectedRadioFromValue(): void {
        const isAlreadySelected: boolean = this._selected != null && this._selected.value === this._value;
        if ( this.radios != null && !isAlreadySelected ) {
            this._selected = null;
            this.radios.forEach( (radio: CmxRadioButtonComponent) => {
                radio.checked = this.value === radio.value;
                if ( radio.checked ) {
                    this._selected = radio;
                }
            });
        }
    }

    private updateCheckIcons(): void {
        this.radios.forEach( (radio: CmxRadioButtonComponent) => {
            if ( radio !== this._selected ) {
                radio.checked = false;
            }
        });
    }
}
