import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { ListChartCardService } from '../list-chart-card';
import { CmxDashboardPaymentsCardService } from './cmx-dashboard-payments-card.service';
import { Status } from '../list-chart-card';

@Component({
  selector: 'cmx-dashboard-payments-card',
  templateUrl: './cmx-dashboard-payments-card.component.html',
  styleUrls: ['./cmx-dashboard-payments-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: ListChartCardService,
      useClass: CmxDashboardPaymentsCardService
    }
  ]
})
export class CmxDashboardPaymentsCardComponent implements OnInit, OnChanges {
  @Input()
  customerCode: string;
  @Input()
  companyCode: string;
  @Input()
  countryCode: string;
  @Input()
  title: string;
  @Input()
  dateRanges: Array<{
    label: string;
    from: string; // ISO String
    to: string;
  }>;

  @Input()
  statusesList: Status[];
  @Input()
  chartTotalLabel = 'Total';
  @Input()
  statusListItemOrdersLabel;

  /**
   * @var emptyListText label to show in the empty result message
   */
  @Input()
  emptyListText;

  /**
   * @var extended render also totals in local currency and link to Financial Documents
   */
  @Input()
  extended: boolean;

  @Input()
  buttonLabel: string;

  @Input()
  emptyButtonLabel: string;

  public queryOptions: Record<string, string | number>;
  ngOnInit() {
    if (this.customerCode) {
      this.queryOptions = {
        customerCode: this.customerCode,
        companyCode: this.companyCode,
        countryCode: this.countryCode
      };
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('customerCode' in changes) {
      const {
        customerCode: { currentValue: customerCode }
      } = changes;
      if (customerCode) {
        this.queryOptions = {
          customerCode: this.customerCode,
          companyCode: this.companyCode,
          countryCode: this.countryCode
        };
      }
    }
  }
}
