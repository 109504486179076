import { HttpClient, HttpEvent, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICmxHttpClient } from '../cmx-api-v7/projects/cmx-api-v7/src/lib/shared/types';

const apiEndpoint: string = window['API_HOST'];

interface CmxHttpOptions {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  observe?: 'body';
  params?:
    | HttpParams
    | {
        [param: string]: string | string[];
      };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}

@Injectable()
export class CmxHttpClient implements ICmxHttpClient {
  constructor(private httpClient: HttpClient) {}

  public get<T>(apiPath: string, options?: CmxHttpOptions) {
    return this.httpClient.get<T>(`${apiEndpoint}${apiPath}`, options);
  }

  // tslint:disable-next-line:no-any
  public post<T>(apiPath: string, body: any | null, options?: CmxHttpOptions) {
    return this.httpClient.post<T>(`${apiEndpoint}${apiPath}`, body, options);
  }

  // tslint:disable-next-line:no-any
  public patch<T>(apiPath: string, body: any | null, options?: CmxHttpOptions) {
    return this.httpClient.patch<T>(`${apiEndpoint}${apiPath}`, body, options);
  }

  // tslint:disable-next-line:no-any
  public put<T>(apiPath: string, body: any | null, options?: CmxHttpOptions) {
    return this.httpClient.put<T>(`${apiEndpoint}${apiPath}`, body, options);
  }

  public delete<T>(apiPath: string, options?: CmxHttpOptions) {
    return this.httpClient.delete<T>(`${apiEndpoint}${apiPath}`, options);
  }

  // tslint:disable-next-line:no-any
  public upload<T>(apiPath: string, options?: CmxHttpOptions) {
    return this.httpClient.request<HttpEvent<T>>('POST', `${apiEndpoint}${apiPath}`, options);
  }
}
