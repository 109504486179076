export class Json2Csv {
    // tslint:disable-next-line
    constructor() {}

    public getCsv( aux: any[] ): void {
        const fields = Object.keys( aux[ 0 ] );
        const replacer = ( key: any, value: any ) => value === undefined ? '' : value;
        const csv = aux.map( (row) => {
            return fields.map( (fieldName) => {
                return JSON.stringify( row[ fieldName ], replacer );
            }).join( ',' );
        });
        csv.unshift( fields.join( ',' ) );
        const data = csv.join( '\r\n' );
           // console.log( this.json2csv.getCsv() );
        // let data = this.json2csv.getCsv();
        const blob = new Blob( [ data ], { type: 'text/csv;charset=utf-8;' } );
        console.log('blob', blob);
        const link = document.createElement( 'a' );
        link.setAttribute( 'id', 'CsvFileLink' );
        const url = URL.createObjectURL( blob );
        link.setAttribute( 'href', url );
        link.setAttribute( 'download', 'data.csv' );
        link.style.display = 'none;';
        document.body.appendChild( link );
        link.click();
        document.body.removeChild( link );
    }

    public getCsv2( aux: any[], header: { docHeader: string; docPeriod: string; plants: string; docTitle: string; }): void {
        const fields = Object.keys( aux[ 0 ] );
        const replacer = ( key: any, value: any ) => value === undefined ? '' : value;
        const csv = aux.map( (row) => {
            return fields.map( (fieldName) => {
                return JSON.stringify( row[ fieldName ], replacer );
            }).join( ',' );
        });
        csv.unshift( fields.join( ',' ) );
        const data = header.docHeader + '\n' +
        header.docPeriod + '\n' + header.plants + '\n\n' + csv.join( '\r\n' );
           // console.log( this.json2csv.getCsv() );
        // let data = this.json2csv.getCsv();
        console.log('datajson', data);
        const blob = new Blob( [ data ], { type: 'text/csv;charset=utf-8;' } );
        console.log('blob', blob);
        const link = document.createElement( 'a' );
        link.setAttribute( 'id', 'CsvFileLink' );
        const url = URL.createObjectURL( blob );
        link.setAttribute( 'href', url );
        link.setAttribute( 'download', header.docTitle + '.csv' );
        link.style.display = 'none;';
        document.body.appendChild( link );
        link.click();
        document.body.removeChild( link );

    }
}
