import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslationService } from 'src/app/angular-localization-v7/services/translation.service';
import { DCMConstants } from 'src/app/helpers/DCM.constants';
import { UserProcess, UserProcessCat } from 'src/app/models/userProcess';
import { UserProcessService } from 'src/app/services/user-process.service';

@Component({
  selector: 'customize-notifications-modal',
  templateUrl: './customize-notifications.modal.html',
  styleUrls: ['./customize-notifications.modal.scss']
})
export class CustomizeNotificationsModal implements OnInit, OnDestroy {
  @Input() viewModal = false;
  @Input() userProcessCat: UserProcessCat[];
  @Input() userProcesses: UserProcess[];
  @Input() userId: number;
  @Output() eventModal: EventEmitter<any> = new EventEmitter<any>();

  public notificationSettingProcess = DCMConstants.USER_PROCESSES.FirstLoginNotificationSettings;

  private subscription: Subscription[] = [];

  constructor(
    private router: Router,
    public translationService: TranslationService,
    private userProcessService: UserProcessService,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription.forEach(sub => sub.unsubscribe());
  }

  public goToNotificationSettings() {
    this.viewModal = false;
    const process = this.getUserProcessByName();
    if(process) {
      this.subscription.push(this.userProcessService.deleteUserProcess(this.userId, process.processId).subscribe(()=> {
        this.userProcesses.splice(this.userProcesses.indexOf(process), 1);
        this.router.navigate(['/settings'], { queryParams: { activeTab: 'notifications' }});
      }));
    }
  }

  public closeNotificationSettingsModal() {
    this.viewModal = false;
    const process = this.getUserProcessByName();
    if(process) {
      this.subscription.push(this.userProcessService.deleteUserProcess(this.userId, process.processId).subscribe(() => {
        this.userProcesses.splice(this.userProcesses.indexOf(process), 1);
        this.eventModal.emit();
      }));
    }
  }

  private getUserProcessByName() {
    const processId = this.userProcessCat.find(p => p.processName === this.notificationSettingProcess)?.processId;
    const process = this.userProcesses.find(up => up.processId === processId);
    return process ? process : null;
  }
}
