import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CmxCurrencyCustomPipe } from './pipes/cmx-currency-custom.pipe';
import { CmxCurrencyPipe } from './pipes/cmx-currency.pipe';
import { CmxDateMomentPipe } from './pipes/cmx-date-moment.pipe';
import { CmxDatePipe } from './pipes/cmx-date.pipe';
import { CmxDateTimeMomentPipe } from './pipes/cmx-date-time-moment.pipe';
import { CmxNumberPipe } from './pipes/cmx-number.pipe';
import { CmxTimeMomentPipe } from './pipes/cmx-time-moment.pipe';

@NgModule({
  declarations: [
    CmxCurrencyCustomPipe,
    CmxCurrencyPipe,
    CmxDateMomentPipe,
    CmxDatePipe,
    CmxDateTimeMomentPipe,
    CmxNumberPipe,
    CmxTimeMomentPipe
  ],
  exports: [
    CmxCurrencyCustomPipe,
    CmxCurrencyPipe,
    CmxDateMomentPipe,
    CmxDatePipe,
    CmxDateTimeMomentPipe,
    CmxNumberPipe,
    CmxTimeMomentPipe
  ],
  imports: [CommonModule]
})
export class AngularLocalizationModule {}
