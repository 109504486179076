import { Link } from '../common';
import { Address } from '../jobsite-commercial-channel/address';

export interface Jobsite {
  jobsiteCode?: string;
  jobsiteDesc?: string;
  jobsiteId: number;
  address?: Address;
  links?: Link;
}
