export class Agreement {
    public AcceptanceAgreementId: number;
    public AgreementVersion: string;
    public AgreementVersionId: number;
    public AgreementCategory: string;
    public CountryCode: string;
    public RegionId: number;
    public AgreementSign: string;
    public DocumentHash: string;
    public DocumentPath: string;
    public AgreementStatus: boolean;
    public IsSigned: boolean;
}
