import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslationService } from '../../../../../../angular-localization-v7/services/translation.service';


@Component({
  selector: 'cmx-footer',
  styleUrls: ['./../../../../../../../../scssv4/cmx-components/cmx-footer/v4/cmx-footer.component.scss'],
  templateUrl: 'cmx-footer.component.html',
})
export class CmxFooterComponent {
  @Input() public rtl: boolean;

  // labels
  @Input() public copyright = 'Copyright &copy; 2018, CEMEX International Holding AG. All rights reserved.';
  @Input() public legal = 'Legal';
  @Input() public privacy = 'Privacy';
  @Input() public useRouting = false;

  @Input() public copyrightRoute: string;
  @Input() public legalRoute: string;
  @Input() public webLabel = 'cemex.com';
  @Input() public webUrl = 'https://www.cemex.com';

  // deprecated
  @Input() public privacyRoute: string;
  @Output() public onPrivacyLinkClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onLegalLinkClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onCopyrightLinkClicked: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private router: Router,
    public translationService: TranslationService,
    ) { }

  public goToCopyright(): void {
    this.onCopyrightLinkClicked.emit();
    if (this.copyrightRoute) {
      this.router.navigate([this.copyrightRoute]);
    }
  }

  public goToLegal(): void {
    this.onLegalLinkClicked.emit();
    if (this.legalRoute && this.useRouting) {
      this.router.navigate([this.legalRoute]);
    } else {
      window.open('/public/legal', '_blank');
    }
  }

  public goToPrivacy(): void {
    this.onPrivacyLinkClicked.emit();
    if (this.privacyRoute && this.useRouting) {
      this.router.navigate([this.privacyRoute]);
    } else {
      window.open('/public/privacy', '_blank');
    }
  }
}
