<h3>{{ subtitleTranslation }}</h3>
<div class="spacer-8"></div>
<div class="cmx-row__no-gutters">
  <ng-container *ngFor="let n of userNotificationList">
    <div class="cmx-row {{statusCodes[n.statusCode]}}">
      <div class="cmx-column__icon" (click)="showUserNotificationDetail(n)">
        <div class="cmx-column__icon-entry-icon">
          <div>
            <div [class.cmx-user-notifications__entry-icon-service]="n.processCode === 'S1'">
              <span class="{{processCodes[n.processCode]}} font-true-blue" aria-hidden="true"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="cmx-row__modulo">
        <span class="cmx-row__modulo-process" (click)="userNotificationDetail(n)">
          {{n.descProcessCode}} - {{n.descSubProcessCode}}
        </span>
        <div class="cmx-row__modulo-summary">
          <span> {{ n.summary }} </span>
        </div>
        <div class="cmx-row__modulo-row">
          <span class="cmx-row__modulo-time">{{n.isToday ? n.localTime : n.localDate}}</span>
          <div class="cmx-row__modulo-share font-bright-blue" (click)="userNotificationShare(n)">
            <span class="cmx-row__modulo-share-icon cmx-icon-share" aria-hidden="true"></span>
            {{ shareNotificationTranslation }}
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
