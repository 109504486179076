import { ApplicationDTO } from '../angular-types-v1/applicationDTO';
import { ICustomer } from '../angular-types-v2/index.interface';
export class User {
    public userId: number;
    public userAccount?: string;
    public fullName?: string;
    public firstName: string;
    public lastName: string;
    public status?: string;
    public countryCode?: string;
    public userPosition?: string;
    public customer?: ICustomer;
    public customerId: number;
    public customerDesc: string;
    public customerCode?: string;
    public phoneNumber?: string;
    public applications: ApplicationDTO[];
    public selected?: boolean;
    public checked?: boolean;
    public userSelectedId?: 'A'| 'S';
    public account: Account;
}

export class Account {
    public accountType: string;
}

