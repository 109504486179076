import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IContactRequest, IContactResponse } from '../models/contact';
import { CmxHttpClient } from '../services/api.service';
import { createHttpParams } from '../shared/helpers';
import { IContactsRequest } from '../models/contact/contacts-request.interface';

// INTERFACES

@Injectable()
export class ContactService {
  constructor(private http: CmxHttpClient) {}

  getContactsByJobsite(payload: IContactsRequest): Observable<IContactResponse> {
    const httpParams = payload ? createHttpParams(payload) : new HttpParams();
    return this.http.get<IContactResponse>(`/v7/sm/contacts`, {
      params: httpParams,
    });
  }

  getJobsiteContacts(payload: IContactRequest): Observable<IContactResponse> {
    const httpParams = payload ? createHttpParams(payload) : new HttpParams();
    return this.http.get<IContactResponse>(`/v2/crm/mycontacts`, {
      params: httpParams,
    });
  }
}
