<input
  id="tickBox"
  type="checkbox"
  [checked]="checked"
  [required]="required"
  [disabled]="disabled"
  [value]="value ? value : true"
  [class.checked]="checked"
  [class.error]="error">
<label
  for="tickBox"
  [ngClass]="{'--disabled': disabled }">
  <span>
    <ng-content></ng-content>
  </span>
</label>
