import { Injectable } from '@angular/core';
import { DCMConstants } from '../helpers/DCM.constants';
declare const WalkMeAPI: any;

@Injectable()
export class WalkmeService {
    private url = 'https://cdn.walkme.com/users/e69507999d16467bbb091803bac288ae/';
    private folder = window['WALKME_ENV_FOLDER'];
    private scriptId = 'walkme_e69507999d16467bbb091803bac288ae_https.js';
    private source = '';

    constructor() {
        this.source = this.url + this.folder + this.scriptId;
    }

    public initializeService() {
        if (document.querySelectorAll('[src="' + this.source + '"]').length === 0) {
            var walkme = document.createElement('script');
            walkme.type = 'text/javascript';
            walkme.async = true;
            walkme.src = this.source;
            var s = document.head.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(walkme, s);
            (window as any)._walkmeConfig = {
                smartLoad: true
            };
            (window as any).walkme_get_language = function () {
              let languageISO = localStorage.getItem('language') || sessionStorage.getItem('languague') || window['LANGUAGE'];
              languageISO = languageISO.replace('_', '-');
              if(DCMConstants.WALKME_LANGUAGE_EN.indexOf(languageISO) !== -1) {
                languageISO = 'en-US';
              }
              else if (DCMConstants.WALKME_LANGUAGE_ES.indexOf(languageISO) !== -1) {
                languageISO = 'es-MX';
              }
              return languageISO;
            }
        }
    }
}
