import { DatepickerTags } from "../../../../../../cmx-datepicker/v4/src/models/datepicker.classes";

export class DatepickerInputTags {
    public placeholder: string;
    public cancel: string;
    public apply: string;
    public datepickerTags: DatepickerTags;

    constructor(
        $placeholder?: string, $cancel?: string, $apply?: string,
        $months?: string[], $monthsShort?: string[], $daysShort?: string[],
        $daysLong?: string[], $daysMin?: string[], $momentFormat?: string) {
            this.placeholder = $placeholder;
            this.cancel = $cancel;
            this.apply = $apply;
            this.datepickerTags = new DatepickerTags($cancel, $apply, $months, $monthsShort,
                                                     $daysShort, $daysLong, $daysMin, $momentFormat);
    }
}
