<div class="login {{ resetPassword ? 'reset-password' : '' }} {{ viewReady ? 'ready' : '' }}" [dir]="isRTL ? 'rtl' : 'ltr'">
  <div class="login__alerts">
      <cwc-alert id="tou-issue-alert" *ngIf="touError" variant="error" [message]="this.lang['views.login.termsIssue']" [rtl]="isRTL"></cwc-alert>
      <cwc-alert id="popup-alert" *ngIf="popupBlock" variant="error" [message]="this.lang['views.login.popupBlock']" [rtl]="isRTL" ></cwc-alert><!-- [preventClose]="true" -->
      <cwc-alert *ngIf="!sessionExpired && showMaintenanceAlert" variant="warning" [message]="this.lang['view.login.lblWarnMessageTitle'] +' '+ this.lang['view.login.lblWarnMessage']" [rtl]="isRTL" (onClose)="showMaintenanceAlert = false"><!-- [preventClose]="true" --></cwc-alert>
      <cwc-alert *ngIf="sessionExpired" variant="warning" [rtl]="isRTL" [message]="this.lang['views.login.alert.sessionExpireTitle'] + ': ' + this.lang['views.login.alert.sessionExpireMsg']"></cwc-alert>
  </div>
  <language-selector *ngIf="!resetPassword" [currentLanguage]="currentLang" (languageChanged)="changeLanguage($event)" [rtl]="isRTL"></language-selector>
  <div class="login__side-left" *ngIf="resetPassword">
    <div class="login__side-left-content">
      <div class="login__side-left-content-logo">
        <div class="login__logo">
        </div>
      </div>
    </div>
  </div>
  <div class="login__info">
    <div class="login__info-content {{ resetPassword ? 'hidden' : '' }}">
      <h1 class="login__logo login__logo--{{this.currentLang.languageISO}}">{{this.lang['views.login.productBrand']}}</h1>
      <h2 class="login__info-heading">{{this.lang['views.login.productHeadingMain']}}</h2>
      <p class="login__info-copy">{{this.lang['views.login.productHeadingSecondary']}}</p>
      <ul class="login__info-advantages">
        <li class="login__info-advantage">
          <span class="login__info-advantage-icon">
            <cwc-icon name="aggregates" color="true-white" size="2rem"></cwc-icon>
          </span>
          <span class="login__info-advantage-copy">{{this.lang['views.login.productAdvantage1']}}</span>
        </li>
        <li class="login__info-advantage">
          <span class="login__info-advantage-icon">
            <cwc-icon name="options-delivery" color="true-white" size="2rem"></cwc-icon>
          </span>
          <span class="login__info-advantage-copy">{{this.lang['views.login.productAdvantage2']}}</span>
        </li>
        <li class="login__info-advantage">
          <span class="login__info-advantage-icon">
            <cwc-icon icon-code="e9bb" color="true-white" size="3.5rem"></cwc-icon>
          </span>
          <span class="login__info-advantage-copy">{{this.lang['views.login.productAdvantage3']}}</span>
        </li>
      </ul>
      <div *ngIf="countryHasLinkCreditRequest">
        <p class="login__info-copy" style="font-size:18px;margin-bottom: 0.75rem;">{{this.lang['views.login.credit_request_label_header_1']}}</p>
        <p class="login__info-copy">{{this.lang['views.login.credit_request_options_label_2']}}&nbsp;<a href="{{creditRequestLink}}" target="_blank">{{this.lang['views.login.credit_request_options_label_5']}}</a>&nbsp;{{this.lang['views.login.credit_request_options_label_4']}}</p>
      </div>
    </div>
  </div>
  <div class="login__form" *ngIf="!resetPassword">
    <div class="login__form-wrapper">
      <h2 class="login__form-heading">{{this.lang['views.login.title']}}</h2>
      <p class="login__form-summary">{{this.lang['views.login.subTitle']}}</p>
      <cmx-login
        data-tid="cmx-login-component"
        [validateTemporaryPassword]="true"
        [rtl]="isRTL"
        [title]="this.lang['views.login.title']"
        [appName]="appName"
        [urlRoute]="redirectTo"
        [showChrome]="false"
        [designVersion]="'v2'"
        (loginAttemptFail)="onLoginAttemptFail($event)"
      ></cmx-login>
      <div id="div-recaptcha" class="form-captcha" [ngClass]="{'visible-captcha':!showCaptcha}">
        <re-captcha (resolved)="resolved($event)" [siteKey]="key"></re-captcha>
      </div>
      <ng-container *ngIf="canDisplaySignUpCard">
        <div class="spacer-48" ></div>
        <cwc-card designVersion="v2" dir="{{ isRTL ? 'RTL': 'LTR' }}">
          <div class="padding-1">
            <p class="login__form-summary">{{translationService.pt("views.login.card.signup.title")}}</p>
            <div class="spacer-16"></div>
            <p class="text-disabled">{{translationService.pt("views.login.card.signup.subtitle")}}</p>
            <div class="spacer-16"></div>
            <div class="login__sing-up">
              <div class="login__sing-up_button">
                <cwc-button designVersion="v2" variant="support-block" size="small" dir="{{ isRTL ? 'rtl': 'ltr' }}"
                (cwcClick)="goToSignOn()" data-tid="cmx-login-btn-sing-on" id="cmx-login-btn-sing-on">
                  {{translationService.pt("views.login.card.signup.btn-sing-up")}}
                </cwc-button>
              </div>
            </div>
          </div>
        </cwc-card>
      </ng-container>
    </div>
  </div>
  <div *ngIf="triggerResendPasswordAlert && !enableResendPasswordBtn"
  [ngClass]="{'login__form__reset__password__alert__mobile': minScreenWidth, 'login__form__reset__password__alert': !minScreenWidth}">
    <cwc-alert id="resended-password-alert" class="w-full" variant="{{!isWarningResendPassword ?  'success' : 'warning'}}"
    [message]="!isWarningResendPassword ?  translationService.pt('views.login.alert.new-password-sent') :
    translationService.pt('views.login.alert.new-password-sent-warning')" [rtl]="isRTL">
    </cwc-alert>
  </div>
  <div class="login__form__reset__password" *ngIf="resetPassword">
    <div class="login__back">
      <cwc-button variant="link" leading-icon="left-rounded-full" (cwcClick)="goToLogin()" dir="{{isRTL ? 'rtl': 'ltr'}}">
        {{ translationService.pt("views.login.btn_login") }}
      </cwc-button>
    </div>
    <form [formGroup]="form" (ngSubmit)="reSendPassword()" class="login__form-wrapper">
      <h2 class="login__form-heading">{{translationService.pt("views.reset-password.title.mpr")}}</h2>
      <p class="login__form-summary">{{translationService.pt("views.reset-password.subtitle")}}
      </p><br>
      <p class="login__form-summary">
        {{translationService.pt("views.reset-password.subtitle.continue")}}
      </p><br>
      <cwc-input type="password" aria-describedby="resetPasswordHelp" label="Temporary Password"
        formControlName="temporaryPassword" data-tid="cmx-login-form-reset-password-field"
        id="cmx-login-form-reset-password-field" statusMessage="{{loginErrorMessage()}}" designVersion="{{designVersion}}"
        status="{{temporaryPasswordFlag ? 'error' : 'regular'}}"
        [rtl]="true ? 'false' : 'true'" [ngClass]="{'v2': designVersion === 'v2'}" class="w-full" allow-password-show="true">
      </cwc-input>
      <br>
      <div class="reset__password">
        <cwc-button disabled="{{disabledLoginTemporaryPassword()}}" data-tid="cmx-reset-password-continue-btn"
          id="cmx-login-reset-password-btn" designVersion="{{designVersion}}"
          size="{{designVersion === 'v2' ? 'small' : 'regular' }}" [variant]="minScreenWidth ? 'regular-block' : 'regular' "
          (cwcClick)="loginWithTemporaryPassword()">
          {{translationService.pt("views.reset-password.button-continue.title")}}
        </cwc-button>
      </div><br><br>
      <div id="div-recaptcha" class="form-captcha" [ngClass]="{'visible-captcha':!showReCaptcha}">
        <re-captcha (resolved)="resolved($event)" [siteKey]="key"></re-captcha>
      </div>
      <p class="login__form-summary cant-find">{{translationService.pt("views.reset-password.cannot-find-password-label")}}
      </p><br>
      <p class="login__form-summary">{{translationService.pt("views.reset-password.click-button-label")}}
      </p><br>
      <div class="reset__password">
        <cwc-button designVersion="v2" [disabled]="!enableResendPasswordBtn" [variant]="minScreenWidth ? 'support-block' : 'support' " size="small"
          dir="{{ isRTL ? 'rtl': 'ltr' }}" (click)="reSendPassword()" data-tid="cmx-reset-password-form-submit-btn"
          id="cmx-reset-password-form-submit-btn">
          {{translationService.pt("views.reset-password.button-resend-password.title")}}
        </cwc-button>
      </div><br>
      <p *ngIf="showTimerText" class="login__form-summary">
        {{translationService.pt("views.reset-password.button-continue.timer-label")}} <b>{{timerToShowLabel}}</b>
      </p><br>
    </form>
    <cwc-modal open="{{errorModal}}" dir="{{isRTL ? 'rtl': 'ltr'}}" designVersion="v2" (cwcClose)="errorModal = false">
      <div class="modal-content">
        <cwc-icon name="error" size="10rem" color="true-red" class="modal-icon"></cwc-icon>
        <div class="spacer-32"></div>
        <h3>{{ errorMsg }}</h3>
        <div class="spacer-32"></div>
      </div>
    </cwc-modal>
  </div>
  <div class="wizeline-chatbot" *ngIf="countryHasChatbot">
    <cmx-wizeline-chatbot [ngClass]="{'hasMaintenanceAlert' : !sessionExpired && showMaintenanceAlert}"></cmx-wizeline-chatbot>
  </div>
  <div class="chatbot" *ngIf="countryHasChatbotv2">
    <cmx-chatbot [ngClass]="{'hasMaintenanceAlert' : !sessionExpired && showMaintenanceAlert}"></cmx-chatbot>
  </div>
</div>
