import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment_ from 'moment';
import { ListChartCardService } from '../list-chart-card/list-chart-card.service';
import { EnvironmentConstants } from '../shared/types';
import { Status } from '../list-chart-card';
import { CmxCurrencyPipe } from '../../../../../../../angular-localization-v7/pipes/cmx-currency.pipe';
const moment = moment_;

@Injectable()
export class CmxDashboardPaymentsCardService extends ListChartCardService {
  constructor(
    private api: HttpClient,
    private currencyPipe: CmxCurrencyPipe
    ) {
    super();
  }

  public initStatusesData(statuses: Status[]) {
    this.setStatuses(statuses);
  }

  public fetchNewQuery(options: Record<string, string>) {
    const { companyCode, countryCode, customerCode } = options;
    if (!(countryCode && companyCode)) {
      throw Error('Country code and company code must be set for kpis request');
    }
    return this.fetchAccountSummaryKpis(
      customerCode,
      companyCode,
      countryCode
    ).subscribe(
      (result) => {
        this.updateStatuses(result.kpis, countryCode);
      },
      (error) => {
        console.error('error in cmx-dashboard-components: ', error);
      }
    );
  }

  private updateStatuses(kpis: Kpis[], countryCode) {
    const statuses: Status[] = [];
    for (const kpi of kpis) {
      if (
        [
          KpisDocumentTypeCode.PE,
          KpisDocumentTypeCode.PP,
          KpisDocumentTypeCode.AC,
          KpisDocumentTypeCode.OV
        ].includes(kpi.documentStatusCode as KpisDocumentTypeCode)
      ) {
        let colors = KpisColors[kpi.documentStatusCode];
        if (!colors) {
          throw Error('Unknown type of kpis object');
        }

        const countryCodeTrimmed = countryCode.trim();
        const formattedNumber = this.currencyPipe.transform( kpi.balance , countryCodeTrimmed);

        statuses.push({
          code: kpi.documentStatusCode,
          name: kpi.documentStatusDesc,
          value: kpi.numItems,
          chartColor: colors[1],
          color: colors[0],
          formattedTotal: formattedNumber
        });
      }
    }
    this.setStatuses(statuses);
  }

  private setStatuses(statuses: Status[]) {
    this.statuses$.next(statuses);
  }

  /**
   * @description Get the KPIS data of a company.
   * @param string customerCode: The code of a customer.
   * @param string companyCode: The code of a customer´s company.
   * @param string countryCode: The customer's country.
   */
  public fetchAccountSummaryKpis(
    customerCode: string,
    companyCode: string,
    countryCode: string
  ) {
    let api = `v2/arm/accountsummary/kpis?customerCode=${customerCode}&countryCode=${countryCode}`;
    if (companyCode !== '0') {
      api += '&companyCode=' + companyCode;
    }

    return this.api.get<AccountSummaryKpis>(
      EnvironmentConstants.getWorkingPath() + api
    );
  }
}

type Kpis = {
  rowOrderId: number;
  documentStatusCode?: string;
  documentStatusDesc: string;
  balance: number;
  numItems: number;
};

export type AccountSummaryKpis = {
  kpis: Kpis[];
};

enum KpisDocumentTypeCode {
  PP = 'PP',
  OV = 'OV',
  AC = 'AC',
  PE = 'PE',
  CP = 'CP'
}

// these colors are aligned with Overall Status widget in Dashboard page for I&P
const KpisColors = {
  PP: ['warning', 'bright-orange'],
  AC: ['disabled', 'disabled-grey'],
  OV: ['alert', 'true-red'],
  PE: ['disabled', 'disabled-grey'],
  CP: ['disabled', 'disabled-grey']
};
