import { Component, ElementRef, HostBinding, HostListener,
         Input, NgModule, Renderer2, ViewEncapsulation,
         Optional, Inject } from "@angular/core";
        
@Component({
    selector: "a[cmx-link], button[cmx-link]",
    styleUrls: ["./../../../../../../../scssv4/cmx-components/cmx-button/v4/cmx-button.component.scss"],
    templateUrl: "cmx-link.component.html",
})

export class CmxLinkComponent {
    @HostBinding("class.cmx-button") public cmxButtonClass: boolean = true;
    @HostBinding("class.cmx-button--link") public cmxButttonLinkClass: boolean = true;
    @HostBinding("class.cmx-button--link-medium") public cmxButttonLinkMediumClass: boolean = false;
    @HostBinding("attr.dir") public dirAttr: string = "auto";

    @Input()
    get size(): string {
        return this._size;
    }
    set size(value: string) {
        this.setButtonLinkSize(value);
    }
    private _size: string;

    @Input()
    get rtl(): boolean {
        return this._rtl;
    }
    set rtl(value: boolean) {
        this.dirAttr = value ? "rtl" : "ltr";
    }
    private _rtl: boolean;

    constructor(@Optional() @Inject("RTL") isRTL: boolean) {
        if (isRTL != undefined) {
            this.rtl = isRTL;
        }
    }

    private setButtonLinkSize(buttonSize: string): void {
        switch (buttonSize) {
            case "medium":
                this.cmxButttonLinkMediumClass = true;
                break;
            default:
                break;
        }
    }
}
