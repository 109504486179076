<div class="cmx-select-dd__placeholder" (click)="toggleOptions()">
    {{ placeholder }}
</div>
<div #itemsPositioner class="cmx-select-dd" *ngIf="showOptions">
    <div class="cmx-select-dd__filter" *ngIf="search">
        <span class="cmx-select-dd__filter-icon cmx-icon-magnifier-glass" aria-hidden="true"></span>
        <input class="cmx-select-dd__filter-input" type="search" [placeholder]="searchPlaceholder" [ngModel]="searchValue" (ngModelChange)="filterSelectOptions($event)"/>
    </div>
    <div class="cmx-select-dd__options">
        <ng-content select="cmx-option"></ng-content>
    </div>
    <div *ngIf="resultsCount === 0 && texts && texts.searchEmptyResult">
        {{ texts.searchEmptyResult }}
    </div>
</div>
<div #itemsMask class="cmx-select-dd__mask" (click)="close($event)" *ngIf="showOptions"></div>
