import {
  NgModule,
  ModuleWithProviders,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";

import { CmxCheckboxModule } from "../../../../../cmx-checkbox/v4/src/cmx-checkbox.module";
import { CmxDatepickerModule } from "../../../../../cmx-datepicker/v4/src/cmx-datepicker.module";
import { NumberInputComponent } from "./components/number-input/number-input.component";
import { CurrencyMaskModule } from "./components/number-input/currency-mask.module";
import { DateFormatService } from "./services/date-format.service";
import { CmxSelectComponent } from "./components/cmx-select/cmx-select.component";
import { CmxOptionComponent } from "./components/cmx-option/cmx-option.component";
import { DatepickerInputComponent } from "./components/datepicker-input/datepicker-input.component";
import { TimeInputComponent } from "./components/time-input/time-input.component";
import { CmxFormFieldComponent } from "./components/cmx-form-field/cmx-form-field.component";
import { ErrorMessageDirective } from "./directives/error-message.directive";
import { FormatterService } from "./services/formatter.service";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CmxDatepickerModule,
    CmxCheckboxModule,
    CurrencyMaskModule,
  ],
  exports: [
    CmxFormFieldComponent,
    CmxOptionComponent,
    CmxSelectComponent,
    DatepickerInputComponent,
    NumberInputComponent,
    TimeInputComponent,
    ErrorMessageDirective,
  ],
  declarations: [
    CmxFormFieldComponent,
    CmxOptionComponent,
    CmxSelectComponent,
    DatepickerInputComponent,
    NumberInputComponent,
    TimeInputComponent,
    ErrorMessageDirective,
  ],
  providers: [DateFormatService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class CmxFormFieldModule {
  public static forRoot(formatter: any): ModuleWithProviders<any> {
    return {
      ngModule: CmxFormFieldModule,
      providers: [
        {
          provide: FormatterService,
          useExisting: formatter,
        },
      ],
    };
  }
}
