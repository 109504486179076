import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CmxHttpClient } from './http-client.service';
import { UserProfile } from '../models';
import { ISortHeaders } from "../models/sortHeader";
import { Response } from '@angular/http';
import { HttpCemex } from '../angular-services-v8/http.service';
import { ProjectSettings } from '../angular-services-v8/project.settings';
import { DCMConstants } from '../helpers/DCM.constants';
import { ValidatePhone } from '../models/validatePhone';
import { ContactMethod } from '../models/contactMethods';
import { UserDeleteReason } from '../models/userDeleteReasons';

@Injectable()
export class UserService {
    constructor(
        private http: HttpCemex,
        private cmxHttp: CmxHttpClient,
        private projectEnv: ProjectSettings,
    ) { }

    // TODO - Typed Me
    updateUser(userId: number, payload: any) {
        const uri = `v5/secm/user/${userId}`;
        return this.cmxHttp.patch(uri, payload);
    }

    disableUser(userId: number, payload: any) {
        const uri = `v6/secm/users/${userId}/disable`;
        return this.cmxHttp.patch(uri, payload);
    }

    // TODO - Typed Me
    updateUserV6(userId: number, payload: any) {
        const uri = `v6/secm/users/${userId}`;
        return this.cmxHttp.patch(uri, payload);
    }

    createUser(payload: any) {
        return this.cmxHttp.post('v6/secm/users/', payload);
    }

    updateMyProfile(payload: any) {
        return this.cmxHttp.patch('v6/secm/users', payload);
    }

    // TODO: SPECIFIC SERVICE FOR AUDIT
    getUserActionLog(userId: number, dateFrom?: string, dateTo?: string) {
        // tslint:disable-next-line:max-line-length
        const uri = `v5/secm/audit/log?dateFrom=${dateFrom}&dateTo=${dateTo}&entityType=USER_ALL&entityId=${userId}&selfService=&pageNumber=0&pageSize=0`;
        return this.cmxHttp.get(uri);
    }

    updateFlag(userId: number, allowInfShare: boolean) {
        const uri = `v5/secm/user/${userId}/profileflags`;
        return this.cmxHttp.patch(uri, { allowInformationShare: allowInfShare })
          .pipe(map((res) => res['status']));
    }

  getUserV6(userId: number, include: string) {
    const uri = `v6/secm/users/${userId}?include=${include}`;
    return this.cmxHttp.get(uri).pipe(map((res) => {
      return res['users'] ? res['users'][0] : null;
    }));
  }

    getUserZones(userId: number) {
        const uri = `v6/secm/users/${userId}/zones`;
        return this.cmxHttp.get(uri).pipe(map((res) => res && res['zones'] ? res['zones'] : []));
    }

    runUserMaintenance(userId: number, payload: any) {
        const uri = `v6/secm/users/${userId}/maintenance`;
        return this.cmxHttp.post(uri, payload);
    }

    updateJobsite(userId: number, payload: any) {
        const uri = `v5/secm/user/${userId}/data/jobsites`;
        return this.cmxHttp.patch(uri, payload);
    }

    updateCustomers(userId: number, payload: any) {
        const uri = `v5/secm/user/${userId}/data/customers`;
        return this.cmxHttp.patch(uri, payload);
    }

    // TODO: DEPRECATE
    public signUp(userData: any): Observable<Response> {
        return this.http.post(this.projectEnv.getBaseOrgEnvPath() + 'v5/secm/user', userData, null, true)
            .pipe(map((res: Response) => res.json()),
                catchError(this.handleError));
    }

    // TODO: DEPRECATE
    public getUser(): Observable<UserProfile> {
        return this.http.get(this.projectEnv.getBaseOrgEnvPath() + 'v5/secm/user')
            .pipe(map((res: Response) => res.json()),
                catchError(this.handleError));
    }

    // TODO: DEPRECATE
    public getUserDetails(userAccount: string) {
        return this.http.get(this.projectEnv.getBaseOrgEnvPath() + 'v5/secm/users/search?userAccount=' + encodeURIComponent(userAccount))
            .pipe(map((res: Response) => res.json()),
                catchError(this.handleError));
    }

    public phoneValidation(countryCode: string, phoneNumber: string): Observable<ValidatePhone> {
      const uri = `v5/gm/mdm/phones/validated?country=${countryCode}&phone=${phoneNumber}`;
      return this.cmxHttp.get<ValidatePhone>(uri);
    }

    public emailValidation(email: string, countryCode?: string, valideteUser: boolean = true): Observable<ValidatePhone>{
      let uri =  `v5/gm/mdm/emails/validated?email=${email}&validateUser=${valideteUser}`;
      uri = !countryCode ? uri : uri+ `&countryCode=${countryCode}`
      return this.cmxHttp.get<ValidatePhone>(uri);
    }

    public getContactMethods(): Observable<ContactMethod[]>{
      const uri = '/v3/im/contacts/methods';
      return this.cmxHttp.get(uri).pipe(map((res) => res['contactMethods'] ? res['contactMethods']: []));
    }

    public getUserDeleteReasons(contactCategory: string): Observable<UserDeleteReason[]>{
      const uri =  `v3/im/contacts/deletereasons?contactCategory=${contactCategory}`;
      return this.cmxHttp.get(uri).pipe(map((res) => res['deleteReasons'] ? res['deleteReasons']: []));
    }

    private handleError(error: any) {
        return throwError(error || 'Server error');
    }

    private isSelfServiceRegistration: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private termsAndConditionsSigned: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public accessApproverChanged:boolean = false;

    public setSelfServiceRegistration(value: boolean): void {
      this.isSelfServiceRegistration.next(value);
    }

    public setTermsAndConditionsSigned(value: boolean): void {
      this.termsAndConditionsSigned.next(value);
    }

    public getSelfServiceRegistration(): Observable<boolean> {
      return this.isSelfServiceRegistration.asObservable();
    }

    public getTermsAndConditionsSigned(): Observable<boolean> {
      return this.termsAndConditionsSigned.asObservable();
    }
}

export const UserSortingHeaders: ISortHeaders = [
    {
        id: 1,
        label: 'views.userManagement.userList.sort.nameAsc',
        order: 'asc',
        property: DCMConstants.USER_SORTING_PROPERTIES.FirstName
    },
    {
        id: 2,
        label: 'views.userManagement.userList.sort.nameDesc',
        order: 'desc',
        property: DCMConstants.USER_SORTING_PROPERTIES.FirstName
    },
    {
        id: 3,
        label: 'views.userManagement.userList.sort.loginNewest',
        order: 'desc',
        property: DCMConstants.USER_SORTING_PROPERTIES.LastLogin
    },
    {
        id: 4,
        label: 'views.userManagement.userList.sort.loginOldest',
        order: 'asc',
        property: DCMConstants.USER_SORTING_PROPERTIES.LastLogin
    }
];
