<div class="page-wrapper" style="display: none"></div>

<router-outlet></router-outlet>

  <cmx-spinner #moderateSpinner [show]="loaderService.loaderOn"   size="Full"
  data-tid="full-spinner"></cmx-spinner>

<cwc-cobrowse
  *ngIf="showCobrowse"
  [license]="cobrowseKey"
  [codeMessage]="translationService.pt('views.cobrowse.code_message')"
  [requestAccessTitle]="
    translationService.pt('views.cobrowse.request_access_title')
  "
  [requestAccessMessage]="
    translationService.pt('views.cobrowse.request_access_message')
  "
  [remoteAccessTitle]="
    translationService.pt('views.cobrowse.remote_control_title')
  "
  [remoteControlMessage]="
    translationService.pt('views.cobrowse.remote_control_message')
  "
  [allowButtonText]="translationService.pt('views.cobrowse.allow_button_text')"
  [denyButtonText]="translationService.pt('views.cobrowse.deny_button_text')"
  [endSessionButtonText]="
    translationService.pt('views.cobrowse.end_session_button_text')
  "
></cwc-cobrowse>
