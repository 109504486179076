import {
    Component,
    AfterContentInit,
    OnDestroy,
    HostBinding,
    ContentChild,
    Host,
    Renderer2,
    ViewChild,
    ElementRef
} from '@angular/core';

import { Subscription } from 'rxjs';

import { CmxCheckboxFiltersComponent } from '../cmx-checkbox-filters/cmx-checkbox-filters.component';
import { CmxDatepickerFilterComponent } from '../cmx-datepicker-filter/cmx-datepicker-filter.component';
import { CmxFilterSorterComponent } from '../cxm-filter-sorter/cmx-filter-sorter.component';
import { CmxTableComponent } from '../cmx-table/cmx-table.component';

@Component({
    selector: 'cmx-header-cell',
    templateUrl: './cmx-header-cell.component.html',
    styleUrls: [ './../../../../../../../../../../scss/cmx-table/v1/cmx-header-cell.component.scss' ],
})
export class CmxHeaderCellComponent implements AfterContentInit, OnDestroy {
    @HostBinding( 'class.cmx-header-cell' ) public cmxHeaderCellClass = true;
    @HostBinding( 'class.--has-filters' ) public hasFiltersClass = false;
    @HostBinding( 'class.--active-filters' ) public activeFiltersClass = false;
    // @HostBinding('attr.dir') private dirAttr: string = 'auto';
    @ContentChild( CmxCheckboxFiltersComponent )
    public checkboxFilters: CmxCheckboxFiltersComponent;
    @ContentChild( CmxDatepickerFilterComponent )
    public datepickerFilter: CmxDatepickerFilterComponent;
    @ContentChild( CmxFilterSorterComponent )
    public filterSorter: CmxFilterSorterComponent;
    @ViewChild('filtersContainer')
    public filtersContainer: ElementRef;

    public showFilters = false;
    private filtersListener: Subscription;

    constructor(
        @Host() private parent: CmxTableComponent,
        private renderer: Renderer2,
    ) { }

    public ngAfterContentInit(): void {
        if ( this.checkboxFilters ) {
            this.hasFiltersClass = true;
            this.filtersListener = this.checkboxFilters.listenToActiveFilters().subscribe(
                ( $event ) => {
                    this.activeFiltersClass = $event;
                }
            );
        } else if ( this.datepickerFilter ) {
            this.hasFiltersClass = true;
            this.filtersListener = this.datepickerFilter.listenToActiveFilter().subscribe(
                ( $event ) => {
                    this.activeFiltersClass = $event;
                }
            );
        } else if ( this.filterSorter ) {
            this.hasFiltersClass = true;
        }
    }

    public ngOnDestroy(): void {
        if ( this.filtersListener ) {
            this.filtersListener.unsubscribe();
        }
    }

    public toggle(): void {
        if ( this.checkboxFilters || this.datepickerFilter || this.filterSorter ) {
            this.showFilters = !this.showFilters;
            if (this.showFilters) {
                setTimeout(() => this.isInsideViewport(), 100);
            }
        }
    }

    public closeFilters(): void {
        this.showFilters = false;
    }

    private isInsideViewport(): void {
        const sidePositioner: string = this.parent.rtl ? 'right' : 'left';
        // clearing
        this.renderer.setStyle(this.filtersContainer.nativeElement, sidePositioner, undefined);
        this.renderer.setStyle(this.filtersContainer.nativeElement, 'top', undefined);
        // calculations
        const rect: ClientRect = this.filtersContainer.nativeElement.getBoundingClientRect();
        const screenHeight: number = window.innerHeight;
        const screenWidth: number = window.innerWidth;
        const adjustment = 20;
        if (this.parent.rtl) {
            if (rect.left < 0) {
                const pxValue: number = Math.abs(rect.left);
                this.renderer.setStyle(
                    this.filtersContainer.nativeElement, sidePositioner, (-1 * pxValue) + 'px',
                );
            }
        } else {
            if (rect.right > screenWidth) {
                // move to the left
                const pxValue: number = Math.abs(rect.right - screenWidth) + adjustment;
                this.renderer.setStyle(
                    this.filtersContainer.nativeElement, sidePositioner, (-1 * pxValue) + 'px',
                );
            }
        }
        if (rect.bottom > screenHeight) {
            // move to the top
            const pxValue: number = Math.abs(rect.bottom - screenHeight);
            this.renderer.setStyle(
                this.filtersContainer.nativeElement, 'top', (-1 * pxValue) + 'px',
            );
        }
    }
}
