import { ModuleWithProviders, NgModule } from '@angular/core';
import { ActionLogsService } from './api/action-logs.service';

import { AddressesService } from './api/addresses.service';
import { AuthService } from './api/auth.service';
import { BusinessLinesService } from './api/business-lines.service';
import { CatalogsService } from './api/catalogs.service';
import { ContactService } from './api/contact.service';
import { CountryConfigService } from './api/country-config.service';
import { CustomerSalesAreaManagementService } from './api/customer-sales-area-management.service';
import { CustomersService } from './api/customers.service';
import { DestinationsService } from './api/destinations.service';
import { DocumentsService } from './api/documents.service';
import { DraftService } from './api/draft.service';
import { GroupingService } from './api/grouping.service';
import { HaulersService } from './api/haulers.service';
import { HoldingSalesAreaManagementService } from './api/holding-sales-area-management.service';
import { JobsiteCommercialChannelService } from './api/jobsite-commercial-channel.service';
import { JobsiteSalesAreasService } from './api/jobsite-sales-areas.service';
import { LegalEntitiesService } from './api/legal-entities.service';
import { MenuService } from './api/menu.service';
import { OpportunityBusinessLineManagementService } from './api/opportunity-business-line-management.service';
import { OpportunityContactReqManagementService } from './api/opportunity-contact-req-management.service';
import { OrderDetailService } from './api/order-detail.service';
import { OrderItemsService } from './api/order-items.service';
import { OrdersService } from './api/orders.service';
import { PaymentTermService } from './api/payment-term.service';
import { PlantsService } from './api/plants.service';
import { PointOfDeliverySalesAreasService } from './api/point-of-delivery-sales-areas.service';
import { PricesService } from './api/prices.service';
import { ProductColorService } from './api/product-color.service';
import { ProductService } from './api/product.service';
import { ProfileManagementService } from './api/profiles-management.service';
import { ProjectManagementService } from './api/project-management.service';
import { PumpingServiceService } from './api/pumping-service.service';
import { PurchaseOrderService } from './api/purchase-order.service';
import { SalesAreaService } from './api/sales-area.service';
import { SalesDocumentManagementService } from './api/sales-document-management.service';
import { SalesOfficeService } from './api/sales-office.service';
import { ShipmentLocationsService } from './api/shipment-locations.service';
import { ShippingConditionsService } from './api/shipping-conditions.service';
import { SpecialInvoiceBalanceService } from './api/special-invoice-balance.service';
import { StdLotSizeService } from './api/std-lot-size.service';
import { TimeWindowsService } from './api/time-windows.service';
import { UploadsService } from './api/uploads.service';
import { VehiclesService } from './api/vehicles.service';
import { CmxHttpClient } from './services/api.service';
import { CustomEncoder } from './services/custom-encoder';
import { ContractBalanceService } from './api/contract-balance.service';
import { ScheduleService } from './api/schedules.service';
import { AnalyticsService } from './api/analytics.service';
import { ProductLineService } from './api/product-line.service';

export interface CmxApiConfig {
  // tslint:disable-next-line:no-any
  httpClient: any;
}

@NgModule({
  imports: [],
  declarations: [],
  providers: [
    AddressesService,
    AuthService,
    BusinessLinesService,
    CatalogsService,
    ContactService,
    CustomerSalesAreaManagementService,
    CustomersService,
    CountryConfigService,
    DestinationsService,
    DraftService,
    GroupingService,
    HaulersService,
    HoldingSalesAreaManagementService,
    JobsiteCommercialChannelService,
    JobsiteSalesAreasService,
    LegalEntitiesService,
    MenuService,
    OpportunityBusinessLineManagementService,
    OpportunityContactReqManagementService,
    OrderDetailService,
    OrderItemsService,
    OrdersService,
    PlantsService,
    PointOfDeliverySalesAreasService,
    SalesOfficeService,
    SalesDocumentManagementService,
    ShipmentLocationsService,
    ProfileManagementService,
    ShippingConditionsService,
    SpecialInvoiceBalanceService,
    VehiclesService,
    ProductService,
    PaymentTermService,
    SalesAreaService,
    ScheduleService,
    TimeWindowsService,
    ProductColorService,
    PricesService,
    ProjectManagementService,
    StdLotSizeService,
    PurchaseOrderService,
    CustomEncoder,
    DocumentsService,
    UploadsService,
    ActionLogsService,
    GroupingService,
    PumpingServiceService,
    ContractBalanceService,
    AnalyticsService,
    ProductLineService,
  ],
})
export class CmxApiModule {
  static forRoot(config: CmxApiConfig): ModuleWithProviders<any> {
    return {
      ngModule: CmxApiModule,
      providers: [{ provide: CmxHttpClient, useExisting: config.httpClient }],
    };
  }
}
