<list-chart-card
  [title]="title"
  [queryOptions]="queryOptions"
  [dateRanges]="dateRanges"
  [statusesList]="statusesList"
  [chartTotalLabel]="chartTotalLabel"
  [statusListItemOrdersLabel]="statusListItemOrdersLabel"
  [emptyListText]="emptyListText"
  [datePlaceholder]="datePlaceholder"
  [renderRecentOrders]="extended"
  [recentOrdersLabels]="recentOrdersLabels"
  [pickerLabelCancel]="pickerLabelCancel"
></list-chart-card>
