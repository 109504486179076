<div class="cmx-datepicker">
    <div class="cmx-datepicker__header">
        <button cmx-link-icon srText="Previous Years Range" [disabled]="min.getFullYear() >= (yearsRange[0][0]).number" (click)="previousYearsRange()">
            <span class="cmx-icon-straight-arrow-left" aria-hidden="true"></span>
        </button>
        <div class="cmx-datepicker__current-period">
            <button cmx-link (click)="monthToggle()">
                {{ currentMonth }}
            </button>
            <button cmx-link (click)="yearToggle()">
                {{ currentYear }}
            </button>
        </div>
        <button cmx-link-icon srText="Next Years Range" [disabled]="max.getFullYear() < (yearsRange[0][0]).number + rangeIncrement" (click)="nextYearsRange()">
            <span class="cmx-icon-straight-arrow-right" aria-hidden="true"></span>
        </button>    
    </div>
    <!-- end .cmx-datepicker__header -->
    <div class="cmx-datepicker__body">
        <table class="cmx-datepicker__days-grid">
            <tbody>
                <ng-container *ngFor="let yearsRow of yearsRange">
                    <tr class="cmx-datepicker__years-grid-row">
                        <ng-container *ngFor="let year of yearsRow">
                            <td class="cmx-datepicker__years-grid-cell">
                                <button class="cmx-datepicker__year" (click)="selectYear(year)" [disabled]="year.disabled" [class.cmx-active-date]="!year.disabled && year.number === currentYear">
                                    {{ year.number }}
                                </button>
                            </td>        
                        </ng-container>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
    <!-- end .cmx-datepicker__body -->
</div>