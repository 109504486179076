import { EntityId, PaginationRequest } from '../common';
import { OrderRelationIncludeEnum } from './order-relation-include.enum';
import { OrderStatusEnum } from './order-status.enum';
import { OrderTypeCodesEnum } from './order-type-codes.enum';

export interface OrdersRequest extends PaginationRequest {
  customerId?: number;
  orderType?: OrderTypeCodesEnum[];
  orderCode?: string;
  orderStatus?: OrderStatusEnum[];
  include?: OrderRelationIncludeEnum[];
  dateFrom?: string;
  dateTo?: string;
  businessLineId?: EntityId[];
  businessLineCode?: string;
}

export interface OrdersRequestV6 extends PaginationRequest {
  customerId?: number;
  orderType?: OrderTypeCodesEnum[];
  orderCode?: string;
  orderStatus?: OrderStatusEnum[];
  include?: OrderRelationIncludeEnum[];
  dateTimeFrom?: string;
  dateTimeTo?: string;
  updatedDateTimeFrom?: string;
  updatedDateTimeTo?: string;
  businessLineId?: EntityId[];
  businessLineCode?: string;
  offsetDateTime?: number;
}
