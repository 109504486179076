/**
 * A French-style slump description, which is string-based.
 */
export interface SlumpType {
  slumpId?: number;
  // TODO: The API now differs, according to Rene [HipChat, 2018/08/31 @ 14:19].  Check for usages.  Only seems to be used in old code.
  slumpDesc?: string;
  // TODO: Verify this field-name as matching the current API.
  slumpTypeCode?: string;
}
