import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable()
export class OrderEvents {
    // Observable items sources
    private orderSelectedSubject = new BehaviorSubject<any>(null);

    // Observable items streams
    private order$ = this.orderSelectedSubject.asObservable();

    constructor() { }

    selectOrder(order: any) {
        const currentOrder = this.orderSelectedSubject.getValue();

        // Fire only if customer differs from the selected one
        if (currentOrder !== order) {
            this.orderSelectedSubject.next(order);
        }
    }

    getOrderSelected() {
        return this.order$;
    }

    currentOrderSelected() {
        return this.orderSelectedSubject.getValue();
    }
}
