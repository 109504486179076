import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WizelineChatbotComponent } from './wizeline-chatbot.component';
import { StoreService } from './services/store.service';

@NgModule({
  declarations: [WizelineChatbotComponent],
  exports: [WizelineChatbotComponent],
  imports: [CommonModule],
  providers: [StoreService],
})
export class CmxWizelineChatbotModule {}
