import { Component, ElementRef, HostBinding, HostListener,
         Input, NgModule, Renderer2, ViewEncapsulation,
         Optional, Inject } from "@angular/core";
        
@Component({
    selector: "a[cmx-button-icon], button[cmx-button-icon]",
    styleUrls: [ "./../../../../../../../scssv4/cmx-components/cmx-button/v4/cmx-button.component.scss" ],
    templateUrl: "cmx-button-icon.component.html",
})

export class CmxButtonIconComponent {
    @HostBinding("class.cmx-button") public cmxButtonClass: boolean = true;
    @HostBinding("class.cmx-button--primary") public cmxButtonPrimaryClass: boolean = false;
    @HostBinding("class.cmx-button--support") public cmxButtonSupportClass: boolean = false;
    @HostBinding("class.is-full") public isFullClass: boolean = false;
    @HostBinding("class.cmx-button__icon") public cmxButtonIconClass: boolean = true;
    @HostBinding("attr.dir") public dirAttr: string = "auto";

    @Input()
    get color(): string {
        return this._color;
    }
    set color(value: string) {
        this.setButtonColor(value);
    }
    private _color: string;

    @Input()
    get rtl(): boolean {
        return this._rtl;
    }
    set rtl(value: boolean) {
        this.dirAttr = value ? "rtl" : "ltr";
    }
    private _rtl: boolean;

    constructor(@Optional() @Inject("RTL") isRTL: boolean) {
        if (isRTL != undefined) {
            this.rtl = isRTL;
        }
    }

    private setButtonColor(buttonColor: string): void {
        switch (buttonColor) {
            case "primary":
                this.cmxButtonPrimaryClass = true;
                break;
            case "support":
                this.cmxButtonSupportClass = true;
                break;
            case "support-full":
                this.cmxButtonSupportClass = true;
                this.isFullClass = true;
                break;
            default:
                this.cmxButtonClass = true;
                break;
        }
    }
}
