import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
// cmx
import { CmxSlideToggleComponent } from './components/';

@NgModule({
    declarations: [
        CmxSlideToggleComponent,
    ],
    exports: [
        CmxSlideToggleComponent,
    ],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
    ],
})
export class CmxSlideToggleModule {
    constructor() {
        //
    }
}
