import { Status } from './status';
import { User } from './user';

export interface OrderComment {
  commentDesc: string;
  commentId?: number;
  createdDateTime?: string;
  isCustomer?: boolean;
  user?: User;
  status?: Status;
  orderRequestStatus?: string;
}
