
import { Observable } from 'rxjs/';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { FunctionGroupResponse, CustomerProfileResponse } from '../models';
import { CmxHttpClient } from './http-client.service';

@Injectable()
export class FunctionsService {

    constructor(private cmxHttp: CmxHttpClient) { }

    getFunctions(countryCode: string) {
        const uri = `v6/secm/functions?countryCode=${countryCode}&include=roles`;
        return this.cmxHttp.get<FunctionGroupResponse>(uri);
    }

    getCustomerProfiles(countryCode: string) {
        const uri = `v6/secm/customers/profiles?countryCode=${countryCode}&include=functions`;
        return this.cmxHttp.get<CustomerProfileResponse>(uri);
    }
}
