import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, ReplaySubject } from 'rxjs';

import { ILanguage } from './../interfaces/language.interface';
import { ITranslationInterface } from '../interfaces/translation.interface';
import { CmxDatePipe } from '../../../app/angular-localization-v7/pipes/cmx-date.pipe';

declare var global: any;

@Injectable()
export class TranslationService implements ITranslationInterface {
  //sx = window['LANGUAGE'].substring(0, 5);
  public static TRANSLATION_PRODUCT_PATH = 'TRANSLATION_PRODUCT_PATH';
  public static TRANSLATION_LANGUAGES = 'TRANSLATION_LANGUAGES';
  public static DEFAULT_LANGUAGE_ISO = 'DEFAULT_LANGUAGE_ISO';
  public static USE_TRANSLATION_SERVICE = 'USE_TRANSLATION_SERVICE';
  public static currentLanguage: ILanguage = undefined;

  private static translations: any = {};
  private static languages: ILanguage[] = [];

  public languageLoaded = new ReplaySubject<boolean>(1);
  private selectedLanguageSubject = new ReplaySubject<ILanguage>(1);
  private languagesSubject = new ReplaySubject<ILanguage[]>(1);
  // we can change 'languageLoaded' to 'selectedLanguageSubject' in translation resolver  ???

  constructor(
    private httpClient: HttpClient,
    @Inject(TranslationService.TRANSLATION_PRODUCT_PATH)
    @Optional()
    private productPath: string,
    @Inject(TranslationService.TRANSLATION_LANGUAGES)
    @Optional()
    private loadedLanguages: any,
    @Inject(TranslationService.DEFAULT_LANGUAGE_ISO)
    @Optional()
    private defaultLanguageISO: any, // e.g.: en_US from america region || en_UK from europe region.
    @Inject(TranslationService.USE_TRANSLATION_SERVICE)
    @Optional()
    private useTranslationService: boolean
  ) {
    if (!this.loadedLanguages) {
      console.warn(
        "The Translation Service can't init without Injected Languages."
      );
      console.warn(
        'Inject the languages from your application: > ' +
          "{ provide: 'TRANSLATION_LANGUAGES, useValue: window['CMX_LANGUAGES'] },'"
      );
      return;
    }

    if (!defaultLanguageISO) {
      console.warn('The Translation Service need defaultLanguageISO.');
    }

    TranslationService.languages = this.loadedLanguages;
    const localStorageLanguage = (global as any).localStorage.getItem(
      'language'
    );

    const allKeys = Object.keys(TranslationService.translations);
    if (allKeys.length === 0) {
      this.setLanguage(localStorageLanguage || defaultLanguageISO);
    } else {
      this.languageLoaded.next(true);
      // we can change 'languageLoaded' to 'selectedLanguageSubject' in translation resolver?
    }
  }

  /**
   * @description Gets the labels data from the server.
   * @param $lang Language code for the file
   */
  public getTranslations(lang: string): Observable<any> {
    // $lang = window['LANGUAGE'].substring(0, 5);
    let endpoint = `/translate/translate/${this.productPath}/${lang}`;
    if (
      //  this.useTranslationService &&
      window['TRANSLATE_URL'] !== undefined
    ) {
      endpoint = window['TRANSLATE_URL'] + endpoint;
    }
    return this.httpClient.get(endpoint);
  }

  /**
   * @description Observable to gets the languages that exist in the server
   */
  public getLanguages(): Observable<ILanguage[]> {
    return this.languagesSubject.asObservable();
  }

  public getSelectedLanguage(): Observable<ILanguage> {
    return this.selectedLanguageSubject.asObservable();
  }

  /**
   * @description Retrieve the public translation of a label
   * @param $textId Label id as written in the json file
   */
  public pt($textId: string) {
    const textValue = TranslationService.translations[$textId];
    if (!textValue || textValue === undefined) {
      return 'NOT:' + $textId;
    }
    return textValue;
  }

  /**
   * @description Retrieve the public translation of a label
   * @param $textId Label id as written in the json file
   */
  public getLabel($textId: string): string {
    const textValue = TranslationService.translations[$textId];
    if (!textValue || textValue === undefined) {
      return 'NOT:' + $textId;
    }
    return textValue;
  }

  /**
   * @description Retrieve language published on the server.
   * @param $languageISO Can be the country code or the language iso combination
   */
  public getLanguage($languageISO: string): ILanguage {
    if ($languageISO !== undefined && $languageISO !== null) {
      let langValue: ILanguage;

      langValue = TranslationService.languages.find(
        (language) => {
          return (
            language.languageISO === $languageISO ||
            language.countryCode.toLowerCase() === $languageISO.toLowerCase() ||
            language.languageISO.includes($languageISO)
          );
        }
      );

      // if the language cannot reached, go for default
      if (langValue === undefined) {
        console.warn('The Language cannot reached', $languageISO);
        langValue = this.getDefaultLanguage();
      }

      langValue.momentConfig = this.getMomentConfig(langValue);

      return langValue;
    } else {
      console.warn('$languageIso param is undefined.');
      return this.getDefaultLanguage();
    }
  }
  /**
   * @description Retrieve language published on the server.
   * @param $countryCode Can be the country code or the language iso combination
   */
  public getLanguageByCountryCode($countryCode: string): ILanguage {
    if ($countryCode !== undefined && $countryCode !== null) {
      let langValue: ILanguage;
      const countryLan: ILanguage[] = TranslationService.languages.filter(
        ($language) => {
          return (
            $language.countryCode.toLowerCase() === $countryCode.toLowerCase()
          );
        }
      );

      if (countryLan.length > 1) {
        const languageCode = sessionStorage.getItem('language');
        langValue = countryLan.find(($language) => {
          return (
            $language.languageISO.toLowerCase() === languageCode.toLowerCase()
          );
        });
      } else {
        langValue =
          countryLan && countryLan.length > 0 ? countryLan[0] : undefined;
      }

      // if the language cannot reached, go for default
      if (langValue === undefined) {
        console.warn('The Language cannot reached', $countryCode);
        langValue = this.getDefaultLanguage();
      }

      langValue.momentConfig = this.getMomentConfig(langValue);

      return langValue;
    } else {
      console.warn('$countryCode param is undefined.');
      return this.getDefaultLanguage();
    }
  }

  /**
   * @description Set language by languageISO or CountryCode.
   * @param $languageISO Can be the country code or the language iso combination
   */
  public setLanguage($languageISO: string) {
    // console.log(
    //   TranslationService.languages,
    //   this.loadedLanguages,
    //   this.languageLoaded
    // );
    this.selectedLanguage = this.getLanguage($languageISO);

    for (const lang of TranslationService.languages) {
      lang.isSelected = lang.languageISO === this.selectedLanguage.languageISO;
      lang.dir = lang.textFloat === 'right' ? 'rtl' : 'ltr';
      lang.rtl = lang.dir === 'rtl' ? true : false;
    }

    this.getTranslations(this.selectedLanguage.languageISO).subscribe(
      (translations) => {
        this.populateTranslation(translations);
      }
    );
  }

  public populateTranslation($result: any) {
    TranslationService.translations = $result;

    // emit changes until populate is completed
    localStorage.setItem('language', this.selectedLanguage.languageISO);

    //localStorage.setItem('language', this.sx);

    sessionStorage.setItem('language', this.selectedLanguage.languageISO);
    this.languageLoaded.next(true);
    // we can change 'languageLoaded' to 'selectedLanguageSubject' in translation resolver?
    this.selectedLanguageSubject.next(this.selectedLanguage);
    this.languagesSubject.next(TranslationService.languages);
  }

  public get selectedLanguage() {
    return TranslationService.currentLanguage;
  }

  public set selectedLanguage(language: ILanguage) {
    TranslationService.currentLanguage = language;
  }

  public getDefaultLanguage() {
    let defaultLangValue: ILanguage;

    defaultLangValue = TranslationService.languages.find(
      (language) => {
        return (
          language.languageISO === this.defaultLanguageISO ||
          language.countryCode.toLowerCase() ===
            this.defaultLanguageISO.toLowerCase() ||
          language.languageISO.includes(this.defaultLanguageISO)
        );
      }
    );

    if (defaultLangValue === undefined) {
      console.warn(
        'The Default Language cannot reached',
        this.defaultLanguageISO
      );

      defaultLangValue = TranslationService.languages[0];
      console.warn(
        'Trying get the first language',
        defaultLangValue.languageISO
      );
    } else {
      console.warn('The Default Language is', defaultLangValue.languageISO);
    }

    return defaultLangValue;
  }

  public getLanguagesByCountryCode(countryCode: string): ILanguage[] {
    const languagesByContryCode: ILanguage[] =
      TranslationService.languages.filter(
        (language) =>
          language.countryCode.toLowerCase() === countryCode.toLowerCase()
      );
    return languagesByContryCode;
  }

  private getMomentConfig(language: ILanguage) {
    return {
      months: language.monthNames.split(','),
      monthsShort: language.shortDayMonths.split(','),
      weekdays: language.dayNames.split(','),
      weekdaysMin: language.shortDayNames2.split(','),
      weekdaysShort: language.shortDayNames.split(','),
    };
  }
}
