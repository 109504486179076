import { NgModule } from '@angular/core';
import { CommonModule  } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CmxRadioButtonComponent } from './components/cmx-radio.component';
import { CmxRadioGroupDirective } from './components/cmx-radio-group.directive';

@NgModule({
    declarations: [
        CmxRadioGroupDirective, CmxRadioButtonComponent,
    ],
    exports: [
        CmxRadioGroupDirective, CmxRadioButtonComponent,
    ],
    imports: [
        CommonModule,
        FormsModule, ReactiveFormsModule,
    ],
})
export class CmxRadioModule {}
