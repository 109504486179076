import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ISpecialInvoiceBalanceRequest } from '../models/special-invoice-balance/special-invoice-balance-request';
import { ISpecialInvoiceBalanceResponse } from '../models/special-invoice-balance/special-invoice-balance-response';
import { CmxHttpClient } from '../services/api.service';
import { createHttpParams } from '../shared/helpers';

@Injectable()
export class SpecialInvoiceBalanceService {
  constructor(private http: CmxHttpClient) {}

  getSpecialInvoiceBalance(request: ISpecialInvoiceBalanceRequest): Observable<ISpecialInvoiceBalanceResponse> {
    const httpParams = request ? createHttpParams(request) : new HttpParams();
    return this.http.get<ISpecialInvoiceBalanceResponse>(`/v2/arm/specialinvoicebalance`, {
      params: httpParams,
    });
  }
}
