import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { createHttpParams } from '../shared/helpers';

// INTERFACES
import { CustomerSalesAreaManagementRequest } from '../models/customer-sales-area-management/customer-sales-area-management-request';
import { CustomerSalesAreaManagementResponse } from '../models/customer-sales-area-management/customer-sales-area-management-response';
import { CmxHttpClient } from '../services/api.service';

@Injectable()
export class CustomerSalesAreaManagementService {
  constructor(private http: CmxHttpClient) {}

  // ALL CUSTOMERS SALES AREA MANAGEMENT GET REQUEST
  getServiceAndSurchargeDoc(servicesAndSurchargesDocId: string): Observable<CustomerSalesAreaManagementResponse> {
    return this.http.get<CustomerSalesAreaManagementResponse>(
      `/v5/sm/servicesandsurchargesdocuments/${servicesAndSurchargesDocId}`,
    );
  }

  // SINGLE CUSTOMER SALE AREA MANAGEMENT GET REQUEST
  getCustomerSalesArea(
    payload: CustomerSalesAreaManagementRequest,
    customerSalesAreaId: string,
  ): Observable<CustomerSalesAreaManagementResponse> {
    const httpParams = payload ? createHttpParams(payload) : new HttpParams();
    return this.http.get<CustomerSalesAreaManagementResponse>(`/v5/sm/customersalesareas/${customerSalesAreaId}`, {
      params: httpParams,
    });
  }
}
