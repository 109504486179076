<div class="cmx-footer" [dir]="rtl ? 'rtl' : 'ltr'">
  <div>
    <p (click)="goToCopyright()" class="cmx-footer__copyright" [innerHTML]="copyright" id="cmx-footer-copyright-link"></p>
  </div>
  <p class="cmx-footer__copyright"></p>
  <ul class="cmx-footer__menu">
    <li class="cmx-footer__menu-item">
      <a (click)="goToLegal()" class="cmx-footer__menu-link" id="cmx-footer-legal-link">
        {{ translationService.pt('app.footer.legal').indexOf('NOT')===-1 ? translationService.pt('app.footer.legal') :
        legal }}
      </a>
    </li>
    <li class="cmx-footer__menu-item">
      <a (click)="goToPrivacy()" class="cmx-footer__menu-link" id="cmx-footer-privacy-link">
        {{ translationService.pt('app.footer.privacy').indexOf('NOT')===-1 ?
        translationService.pt('app.footer.privacy') : privacy }}</a>
    </li>
    <li class="cmx-footer__menu-item">
      <a class="cmx-footer__menu-link" href="{{webUrl}}" target="_blank" id="cmx-footer-cemex-link">{{webLabel}}</a>
    </li>
  </ul>
</div>