<div class="datepicker-input__toggler" (click)="toggle()">
</div>
<input class="cmx-form-group datepicker-input__input" type="text" [class.--black-placeholder]="value"
  [placeholder]="placeholder" (input)="keypressCatcher($event)" [(ngModel)]="datepickerTextValue">
<ng-content select="span.error-message"></ng-content>
<div #datepickerPositioner class="datepicker-positioner" *ngIf="showDatepicker" (click)="disableToggle( $event )">
  <cmx-datepicker #cmxDatepickerComponent [value]="value" [max]="max" [min]="min" [rtl]="rtl"
    [singleDateSelection]="singleDateSelection" (dayClicked)="notifyDayWasClicked($event)"
    (select)="setSelection($event)" (cancel)="cancelSelection()" [tags]="tags.datepickerTags"
    [startWeekWith]="_startWeekWith"></cmx-datepicker>
</div>
<div [ngClass]="{ 'datepicker-icon': !rtl, 'datepicker-icon-rtl' : rtl }">
  <span class="cmx-icon-calendar"></span>
</div>
<div class="datepicker-mask" *ngIf="showDatepicker" (click)="toggle()"></div>