import { JsonObj } from '../../../src/app/angular-types-v2';

export class EncodeDecodeJsonObjService {

    public encodeJson(jsonData: any): any {
        let data = '';
        const find = '=';
        const re = new RegExp(find, 'g');
        data = btoa(JSON.stringify(jsonData));
        data = data.replace(re, '-');
        data = data.split('').reverse().join('');
        data = btoa(data);
        data = data.replace(re, '-');
        return data;
    }

    public decodeJson(jsonData: any): any {
        let jsonObj: JsonObj;
        let data = '';
        const find = '-';
        const re = new RegExp(find, 'g');
        data = jsonData.replace(re, '=');
        data = atob(data);
        data = data.split('').reverse().join('');
        data = data.replace(re, '=');
        data = atob(data);
        jsonObj = JSON.parse(data);
        return jsonObj;
    }
}
