import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomerPaymentTermsReq } from '../models/payment-terms/customer-payment-terms-req';
import { CmxHttpClient } from '../services/api.service';
import { createHttpParams } from '../shared/helpers';
import { PaymentTerm } from '../models/common/payment-term';
@Injectable()
export class PaymentTermService {
  constructor(private http: CmxHttpClient) {}

  // tslint:disable-next-line:no-any
  getCustomerPaymentTerms(payload: CustomerPaymentTermsReq): Observable<any> {
    const httpParams = payload ? createHttpParams(payload) : new HttpParams();
    return this.http.get(`/v1/ca/paymentterms`, { params: httpParams });
  }

  // tslint:disable-next-line:no-any
  getCustomerPaymentTermsV2(payload: CustomerPaymentTermsReq): Observable<any> {
    const httpParams = payload ? createHttpParams(payload) : new HttpParams();
    return this.http.get(`/v2/ca/paymentterms`, { params: httpParams });
  }

  // tslint:disable-next-line:no-any
  getCustomerPaymentTermsV3(payload: CustomerPaymentTermsReq): Observable<any> {
    const httpParams = payload ? createHttpParams(payload) : new HttpParams();
    return this.http.get(`/v3/ca/paymentterms`, { params: httpParams });
  }

  // TODO - Try to define a return interface
  // tslint:disable-next-line:no-any
  getPaymentTermsByUrlInProduct(url: string): Observable<any> {
    return this.http.get(url);
  }

  // tslint:disable-next-line:no-any
  getJobsitePaymentTermsById(termId: number): Observable<any> {
    return this.http.get(`/v1/im/paymentterms?paymentTermId=${termId}`);
  }

  // tslint:disable-next-line:no-any
  getJobsitePaymentTermsByIdV2(termId: number): Observable<PaymentTerm> {
    return this.http.get(`/v2/im/paymentterms/${termId}`);
  }
}
