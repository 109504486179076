import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  PurchaseOrderValidationRequest,
  PurchaseOrderValidationRequestV4,
  PurchaseOrderValidationResponse,
  PurchaseOrderValidationResponseV4,
} from '../models/purchase-order';
import { CmxHttpClient } from '../services/api.service';
import { createHttpParams } from '../shared/helpers';

@Injectable()
export class PurchaseOrderService {
  constructor(private http: CmxHttpClient) {}

  validateV4(payload: PurchaseOrderValidationRequestV4): Observable<PurchaseOrderValidationResponseV4> {
    const purchaseOrder = encodeURIComponent(payload.purchaseOrder);

    delete payload.purchaseOrder;

    return this.http.get<PurchaseOrderValidationResponseV4>(`/v4/sm/purchaseorders/${purchaseOrder}/validate`, {
      params: createHttpParams(payload),
    });
  }

  validate(payload: PurchaseOrderValidationRequest): Observable<PurchaseOrderValidationResponse> {
    const purchaseOrder = encodeURIComponent(payload.purchaseOrder);

    delete payload.purchaseOrder;

    return this.http.get<PurchaseOrderValidationResponse>(`/v5/sm/purchaseorders/${purchaseOrder}/validate`, {
      params: createHttpParams(payload),
    });
  }
}
