import { Injectable } from '@angular/core';

@Injectable()
export class StoreService {
  get accessToken(): string | null {
    return sessionStorage.getItem('access_token');
  }
  get jwt(): string | null {
    return sessionStorage.getItem('jwt');
  }
  get countryCode(): string | null {
    return sessionStorage.getItem('country');
  }
  get customerId(): number | null {
    const userProfile: any = sessionStorage.getItem('user_profile');
    const jsonuserProfile: any = JSON.parse(userProfile);
    return jsonuserProfile && jsonuserProfile.customerId;
  }
  get customerCode(): string | null {
    const userLegalEntity: any = sessionStorage.getItem('user_legal_entity');
    const userLegalEntityObject: any = JSON.parse(userLegalEntity);
    return userLegalEntityObject && userLegalEntityObject.legalEntityTypeCode;
  }
  get userLegalEntity(): string | null {
    return sessionStorage.getItem('user_legal_entity');
  }
  get userProfile(): string | null {
    return sessionStorage.getItem('user_profile');
  }
  get language(): string | null {
    return sessionStorage.getItem('language');
  }
  public isUserLogged(): boolean {
    return sessionStorage.getItem('access_token') !== null;
  }
}
