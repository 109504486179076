import { Injectable } from '@angular/core';
import { TranslationService } from 'src/app/angular-localization-v7/services/translation.service';
import { DefaultLanguage } from 'src/app/models/login';

@Injectable({
  providedIn: 'root',
})
export class LangTools {
  public defaultLanguage: any;

  constructor(private translationService: TranslationService) {
    this.translationService = translationService;
  }

  // this method allows us to choose a string from the translation service or the default laguage
  private getLang = (key: string) => {
    // we query the translation service to get the necessary string
    const translationLang = this.translationService.pt(key);

    // We check for the characteristic "NOT:" at the beginning
    // to see if the key has been declared in the language requested.
    // We do it with regex so that it is more performant
    const regex = RegExp(/^NOT:/);
    const translationAvailable = !regex.test(translationLang);

    // if there's a translation, then return it, otherwise return the default string
    return translationAvailable ? translationLang : this.defaultLanguage[key];
  };

  // this method sets up the necessary strings to be used in the template in a local object
  // to avoid component refresh when using the translation service pt method directly in teh templates
  public setUpLang = (defaultLanguage: any) => {
    this.defaultLanguage = defaultLanguage;
    // we create a termporary object to fill with the default/translated language
    const tempLang: any = {};
    // we iterate through the keys to get the language values
    Object.keys(this.defaultLanguage).forEach((key) => {
      tempLang[key] = this.getLang(key);
    });
    // we assign all the lang strings at once to prevent multiple re-renders
    return Object.assign({}, tempLang);
  };
}
