import { Component, ElementRef, Renderer2, ViewChild, Input, AfterViewChecked, HostBinding, Optional, Inject } from "@angular/core";
@Component({
    selector: "cmx-dropdown-view",
    styleUrls: ["./../../../../../../scss/cmx-dropdow-view/v1/cmx-dropdown-view.component.scss"],
    templateUrl: "./cmx-dropdown-view.component.html",
})
export class CmxDropdownViewComponent implements AfterViewChecked {
    public showView: boolean = false;
    @HostBinding("attr.dir") public dirAttr: string = "auto";
    private _rtl: boolean = false;


    // viewchildren
    @ViewChild("dropdownView")
    private dropdownViewElement: ElementRef;

    @Input()
    get rtl(): boolean {
        return this._rtl;
    }
    set rtl($value: boolean) {
        if ($value != undefined) {
            this._rtl = $value;
            this.dirAttr = $value ? "rtl" : "ltr";
        }
    }

    constructor(private elementRef: ElementRef, private renderer: Renderer2, @Optional() @Inject("RTL") isRTL: boolean) {
        if (isRTL != undefined) {
            this.rtl = isRTL;
        }
     }

    public ngAfterViewChecked(): void {
        if (this.showView) {
            this.isInsideViewport();
        }
    }

    public openView(value: boolean): void {
        this.showView = value;
    }

    public toggle(): void {
        this.showView = !this.showView;
    }

    private isInsideViewport(): void {
        const sidePositioner: string = this.rtl ? "right" : "left";
        // clearing
        this.renderer.setStyle(this.dropdownViewElement.nativeElement, sidePositioner, undefined);
        // calculatiosn
        const rect: ClientRect = this.dropdownViewElement.nativeElement.getBoundingClientRect();
        const screenHeight: number = window.innerHeight;
        const screenWidth: number = window.innerWidth;
        const adjustment: number = 20;

        let pxValue: number = 0;

        if (this.rtl) {
            if (rect.left < 0) {
                pxValue = Math.abs(rect.left) + adjustment;
            }
        } else {
            if (rect.right > screenWidth) {
                pxValue = Math.abs(rect.right - screenWidth) + adjustment;
            }
        }

        this.renderer.setStyle(this.dropdownViewElement.nativeElement, sidePositioner, (-1 * pxValue) + "px");
    }
}
