import { City } from './city';
export class Region {
    regionId: number;
    regionCode: string;
    regionDesc: string;
    expanded?: boolean;
    checked?: boolean;
    citiesFetched?: boolean;
    cities?: City[];
}
