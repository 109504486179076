import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
interface Script {
    name: string;
    src: string;
    config?: any;
}

const walkmeUrl = 'https://cdn.walkme.com/users/e69507999d16467bbb091803bac288ae';
const walkmeFolder = window as { [key: string]: any }["WALKME_ENV_FOLDER"] || '/dev/';

export const ScriptStore: Script[] = [
    { name: 'botchat', src: window['BOTCHAT_JS_URL'] },
    {
        name: 'googlemaps',
        src: 'https://maps.googleapis.com/maps/api/js',
        config: {
            key: window as { [key: string]: any }["GOOGLE_MAPS_API_KEY"]
        }
    },
    {
        name: 'walkme',
        src: `${walkmeUrl}${walkmeFolder}walkme_e69507999d16467bbb091803bac288ae_https.js`
    }
];

declare var document: any;

@Injectable()
export class LoadScriptService {
    private scripts: any = {};
    private language: string;

    constructor(private storageSevice: StorageService) {
        this.language = this.storageSevice.getFromLocalStorage('language');
        ScriptStore.forEach((script: any) => {
            this.scripts[script.name] = {
                loaded: false,
                src: script.src,
                config: script.config
            };
        });
    }

    public get(...scripts: string[]) {
        const promises: any[] = [];
        scripts.forEach((script) => promises.push(this.loadScript(script)));
        return Promise.all(promises);
    }

    private loadScript(name: string) {
        return new Promise((resolve, reject) => {
            if (!this.scripts[name].loaded) {
                // load script
                const script = document.createElement('script');
                script.type = 'text/javascript';
                let src = this.scripts[name].src;
                if (name === 'googlemaps') {
                    if (this.scripts[name].config) {
                        const lang = this.scripts[name].config.lang;
                        const key = this.scripts[name].config.key.GOOGLE_MAPS_API_KEY;
                        src = `${src}?key=${key}&language=${this.language}`;
                    }
                }
                script.src = src;
                if (name === 'walkme') {
                    (window as any)._walkmeConfig = { smartLoad: true };
                }
                if (script.readyState) {  // IE
                    script.onreadystatechange = () => {
                        if (script.readyState === 'loaded' || script.readyState === 'complete') {
                            script.onreadystatechange = null;
                            this.scripts[name].loaded = true;
                            resolve({ script: name, loaded: true, status: 'Loaded' });
                        }
                    };
                } else {  // Others
                    script.onload = () => {
                        this.scripts[name].loaded = true;
                        resolve({ script: name, loaded: true, status: 'Loaded' });
                    };
                }
                script.onerror = (error: any) => resolve({ script: name, loaded: false, status: 'Loaded' });
                document.getElementsByTagName('head')[0].appendChild(script);
            } else {
                resolve({ script: name, loaded: true, status: 'Already Loaded' });
            }
        });
    }
}
