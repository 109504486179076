import { Injectable } from '@angular/core';

import { CmxDialogComponent } from './../components/cmx-dialog/cmx-dialog.component';

@Injectable({
    providedIn: 'root',
})
export class CmxDialogService {

    private cmxDialogs: CmxDialogComponent[];

    constructor() {
        this.cmxDialogs = new Array<CmxDialogComponent>();
    }

    public addDialog(cmxDialog: CmxDialogComponent): void {
        this.hideLastDialog();
        this.cmxDialogs.push(cmxDialog);
    }

    public removeDialog(cmxDialog: CmxDialogComponent, showLastDialog: boolean): void {
        this.cmxDialogs.splice(this.cmxDialogs.length - 1, 1);
        if (showLastDialog) {
            this.showLastDialog();
        }
    }

    public closeAllDialogs(): void {
        for (let i: number = this.cmxDialogs.length - 1; i >= 0; i--) {
            this.cmxDialogs[i].close(false);
        }
    }

    private showLastDialog(): void {
        if (this.cmxDialogs.length > 0) {
            this.cmxDialogs[this.cmxDialogs.length - 1].show();
        }
    }

    private hideLastDialog(): void {
        if (this.cmxDialogs.length > 0) {
            this.cmxDialogs[this.cmxDialogs.length - 1].hide();
        }
    }
}
