import { Directive, HostBinding, HostListener } from "@angular/core";

@Directive({
    selector: "a[cmx-button], a[cmx-btn], button[cmx-button], button[cmx-btn]",
})

export class ButtonRippleEffectDirective {
    @HostBinding("class.cmx-ripple") public rippleClass: boolean = true;

    constructor() {
        //
    }
}
