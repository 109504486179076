export class ExternalSystems {
    public externalSystemCode: string;
    public offsetId?: number;
    public page?: number;
    public fetch?: number;
    public links?: Links;
    public settings?: Settings[];
}

class Settings {
    public links?: Links;
    public settingId?: number;
    public settingKey?: string;
    public settingValue?: string;
}


class Links {
    public href?: string;
    public method?: string;
    public rel?: string;
}

