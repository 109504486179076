import {
    Component,
    Input,
    ViewChild,
    HostBinding,
    Optional,
    Inject,
    OnInit,
    OnDestroy,
} from '@angular/core';

import { CmxDialogComponent } from './../cmx-dialog/cmx-dialog.component';

export enum ConfirmationType {
    Success = 1,
    Warning = 2,
    Error = 3,
}

export interface IConfirmationDialogOptions {
    type: ConfirmationType;
    message?: string;
    messageDetail?: string;
    afterClosed?: any;
    afterOpened?: any;
}

@Component({
    selector: 'cmx-dialog-confirmation',
    styleUrls: ['./../../../../../../../../../../scssv4/cmx-components/cmx-dialog/v4/cmx-dialog-confirmation.component.scss'],
    templateUrl: './cmx-dialog-confirmation.component.html',
})
export class CmxDialogConfirmationComponent implements OnInit, OnDestroy {
    @HostBinding('attr.dir') public dirAttr = 'auto';
    @ViewChild(CmxDialogComponent) public dialog: CmxDialogComponent;

    @Input()
    get rtl(): boolean {
        return this._rtl;
    }
    set rtl($value: boolean) {
        if ($value !== undefined) {
            this.dirAttr = $value ? 'rtl' : 'ltr';
            this._rtl = $value;
        }
    }

    @Input()
    get okLabel(): string {
        return this._okLabel;
    }
    set okLabel($value: string) {
        if ($value !== undefined) {
            this._okLabel = $value;
        }
    }

    @Input() public width: string;
    @Input() public minWidth: string;
    @Input() public maxWidth: string;

    @Input() public height = '400px';
    @Input() public minHeight = '400px';
    @Input() public maxHeight: string;

    @Input() public backgroundColor: string;
    @Input() public enableClose: boolean;
    @Input() public enableHeader = false;
    @Input() public enableFooter = true;

    public confirmationType: any = ConfirmationType;
    public type: ConfirmationType;
    public message: string;
    public _okLabel = 'Ok';
    public messageDetail: string;

    private _rtl: boolean;
    private subscriptions: any[] = [];

    constructor(
        @Optional() @Inject('RTL') isRTL: boolean,
    ) {
        if (isRTL !== undefined) {
            this.rtl = isRTL;
        }
    }

    public ngOnInit(): void {
        this.width = this.width || this.dialog.width;
        this.minWidth = this.minWidth || this.dialog.minWidth;
        this.maxWidth = this.maxWidth || this.dialog.maxWidth;

        this.height = this.height || this.dialog.height;
        this.minHeight = this.minHeight || this.dialog.minHeight;
        this.maxHeight = this.maxHeight || this.dialog.maxHeight;

        this.backgroundColor = this.backgroundColor || this.dialog.backgroundColor;
        this.enableClose = this.enableClose || this.dialog.enableClose;
        this.enableHeader = this.enableHeader || this.dialog.enableHeader;
        this.enableFooter = this.enableFooter || this.dialog.enableFooter;

        this.rtl = this.rtl || this.dialog.rtl;

        this.subscriptions.push(this.dialog.afterClosed().subscribe((result: boolean) => {
            this.message = '';
            this.messageDetail = '';
        }));
    }

    public ngOnDestroy(): void {
        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }
    }

    public openAsSuccess(message: string): void {
        this.type = this.confirmationType.Success;
        this.message = message;
        this.dialog.open();
    }

    public openAsWarning(message: string): void {
        this.type = this.confirmationType.Warning;
        this.message = message;
        this.dialog.open();
    }

    public openAsError(message: string, messageDetail: string): void {
        this.type = this.confirmationType.Error;
        this.message = message;
        this.messageDetail = messageDetail;
        this.dialog.open();
    }

    public ok_click(): void {
        this.dialog.close();
    }
}
