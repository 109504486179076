export class EnvironmentConstants {
  static getWorkingPath() {
    return window['API_HOST'];
  }
}

export const PathConstants = {
  JOBSITES: 'v7/dm/jobsites/summary',
  ORDERS: 'v6/sm/summary/jobsites/',
  ORDERS_ALL: 'v1/odm/orders',
  SHIPMENT_LOCATIONS: (_, legalEntityId) =>
    `v5/sm/myshipmentlocations?legalEntityId=${legalEntityId}.1&shipmentLocationTypeId=2`,
  MY_PARAMETERS: (_, country) =>
    `v2/gm/myparameters?parameterdesc=UNIT_OF_MEASUREMENT_FOR_INDICATORS&countrycode=${country}`
};

export interface JobsiteDTO {
  jobsiteId: number;
  jobsiteDesc: string;
  orders: Array<{
    orderStatusGroupCode: string;
    orderStatus: string;
    orderId: string;
    totalLoads: number;
    totalDeliveries: number;
    totalQuantity: number;
    deliveredQuantity: number;
  }>;
}

export interface DateRange {
  from: string;
  to: string;
}

export enum OrderStatusCode {
  Draft = "DRFT",
  Pending = "PEND",
  Confirmed = "COMP",
  InEdit = "IPCO",
  InProgress = "INPR",
  Delivered = "DELV",
  Blocked = "BLCK",
  OnHold = "OHLD",
  Cancelled = "CNCL",
  DeletedDraft = "DDRF",
  NeedConfirmation = "NECF",
  PendingCancelation = "PCNL",
  PendingModification = "PMOD",
  TimeConfirmation = "TICO",
  TimeNegotiation = "TNEG",
  ClosureRMXOnline = "CLR",
  CompletedOnline = "CMP",
  CancelledOnline = "CNC",
  NotStartedOnline = "NST",
  OnHoldOnline = "HOLD",
  BlockedOnline = "PHD",
  InProgressOnline = "STR",
  ToBeConfirmedOnline = "WCL",
  CancelRequest = "CREQ",
  Completed = "CONF",
  Ddrf = "DDRF",
  Necf = "NECF",
  PendingToCancel = "PCNL",
  PendingToModify = "PMOD",
  Rqsd = "RQSD",
  AdvancedBooking = "ADBO",
  ToBeConfirmed = "TBCO",
  InProcess = "INPC",
  ItemConfirmed = "NOST",
  ItemToBeConfirmed = "TBCL",
  ItemOnHoldOnline = "HOLI",
  NewOnHold = "OHD",
  NoAvailability = "NOAV",
  ConfirmedOnline = "CNF",
  InReview = "IRV"
}

export interface Loads {
  delivered: number;
  total: number;
}

export interface RecentOrder {
  statusColor: string;
  statusOutline: boolean;
  statusDesc: string;
  orderCode: string;
  jobsiteCode: string;
  jobsiteDesc: string;
  totalQuantity: number;
  unitCode: string;
  params: Params;
}

export interface Params {
  orderId: number,
  orderCode: string,
  orderType: string,
  businessLine: string,
  country: string,
  orderHdrId: number,
  orderRequestId: number,
}