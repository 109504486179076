import { Component, Input, ElementRef, OnChanges } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'cmx-donut-chart',
  styleUrls: ['./donut-chart.component.scss'],
  template: '',
})
export class DonutChartComponent implements OnChanges {

  constructor(
    private host: ElementRef
  ) { }

  @Input()
  donutTotalLabel: string;

  @Input()
  data: Array<{ name: string; value: number }>;

  @Input()
  chartColor: string[];

  private height = 120;

  async ngOnChanges() {
    this.host.nativeElement.append(await this.donutChart())
  }

  async donutChart() {
    this.removeOldChart();

    const radius = Math.min(this.height, this.height) / 2 - 8;

    // create svg and set main parameters
    const svg = d3
      .create('svg')
      .attr('preserveAspectRatio', 'xMaxYMax meet')
      .attr('viewBox', [0, 0, this.height, this.height])
      .attr('class', 'chart');

    const g = svg.append('g').attr('transform', 'translate(' + this.height / 2 + ',' + this.height / 2 + ')');

    // transform array to have correct class name
    const transformedArray = [];
    for (let i = 0; i < this.chartColor.length; i++) {
      transformedArray.push('fill--' + this.chartColor[i]);
    }

    const color = d3.scaleOrdinal(transformedArray);

    // Compute the position of each group on the pie:
    const pie = d3
      .pie()
      .sort(null)
      .value(d => d.value);

    const data_ready = await pie(this.data);

    // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
    g.selectAll('whatever')
      .data(data_ready)
      .enter()
      .append('path')
      .attr(
        'd',
        d3
          .arc()
          .innerRadius(this.height / 2 - 16) // This is the size of the donut hole
          .outerRadius(radius),
      )
      .attr('class', color);

    const total = await d3.sum(this.data, d => d.value);

    g.append('text')
      .attr('text-anchor', 'middle')
      .attr('class', 'chart__donut-total')
      .attr('y', 0)
      .text(total);

    g.append('text')
      .attr('text-anchor', 'middle')
      .attr('class', 'chart__donut-total-label')
      .attr('y', 20)
      .text(this.donutTotalLabel);

    return svg.node();
  }

  removeOldChart() {
    this.host.nativeElement.innerHTML = '';
  }
}
