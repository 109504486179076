import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IJobsiteByIdResponse } from '../models/jobsite-sales-areas/jobsite-sales-areas-response';

import { CmxHttpClient } from '../services/api.service';
import { createHttpParams } from '../shared/helpers';

// INTERFACES
import { OrderDetail } from '../models/order-detail/order-detail';
import { OrderDetailReq } from '../models/order-detail/order-detail-req';
import { OrderIdDetailReq } from '../models/order-detail/order-id-detail-req';
import { OrdersOut } from '../models/order-detail/orders-out';

@Injectable()
export class OrderDetailService {
  constructor(private http: CmxHttpClient) {}

  // GET JOB SITE ORDERS GET REQUEST
  getJobSiteOrders(payload: OrderDetailReq, jobSiteId: string): Observable<OrdersOut> {
    const httpParams = payload ? createHttpParams(payload) : new HttpParams();
    return this.http.get<OrdersOut>(`/v5/sm/summary/jobsites/${jobSiteId}/orders`, {
      params: httpParams,
    });
  }

  // GET JOBSITE BY JOBSITE ID
  getJobsiteByJobsiteId(jobsiteId: string | number): Observable<IJobsiteByIdResponse> {
    return this.http.get<IJobsiteByIdResponse>(`/v7/dm/jobsites/${jobsiteId}`);
  }

  // GET SINGLE ORDER GET REQUEST
  getOrderId(payload: OrderIdDetailReq, orderId: string): Observable<OrderDetail> {
    const httpParams = payload ? createHttpParams(payload) : new HttpParams();
    return this.http.get<OrderDetail>(`/v5/sm/summary/jobsites/orders/${orderId}`, {
      params: httpParams,
    });
  }
}
