import { Component, Input, Output, EventEmitter } from '@angular/core';

// Providers
import { TranslationService } from 'src/app/angular-localization-v7/services/translation.service';

// Components
import { LocalizationComponent } from './../localization/localization.component';

@Component({
  selector: 'search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent extends LocalizationComponent {

  public searchTerm: string;

  @Input()
  public templateId: string;
  @Output()
  public onSearch = new EventEmitter();

  @Output()
  public onClickSearch = new EventEmitter();

  @Input()
  public placeholder: string = 'views.userManagement.searchPlaceholderDefault';

  @Input()
  public maxLength: number = 50;

  constructor(public translationService: TranslationService) {
    super();
  }

  public onSearchTermChanged(): void {
    this.onSearch.emit(this.searchTerm);
  }

  public onSearchClicked(): void {
    this.onClickSearch.emit(this.searchTerm);
  }
}
