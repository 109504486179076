import { Component, HostBinding } from '@angular/core';

@Component({
    selector: 'cmx-dialog-footer',
    template: `<ng-content style='width: 100%'></ng-content>`,
})
export class CmxDialogFooterComponent {
    @HostBinding('style.width') public widthStyle = '100%';

    constructor() { }

}
