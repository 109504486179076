export class UserLog {
    public auditTrailLogId: number;
    public entryDate: string;
    public executedBy: string;
    public changeType: string;
    public action: string;
    public actionType: string;
    public changedEntityTable: string;
    public show: boolean;
    public changedEntityId: number;
    public notes: string;
    public index?: number;
}
