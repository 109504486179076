import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CmxHttpClient } from '../services/api.service';
import { createHttpParams } from '../shared/helpers';

// INTERFACES
import { OpportunityContactManagementRequest } from '../models/opportunity-contact-req-management/opportunity-contact-management-req';
// tslint:disable-next-line:max-line-length
import { OpportunityContactManagementReqResponse } from '../models/opportunity-contact-req-management/opportunity-contact-management-req-res';
import { OpportunityContactManagementResponse } from '../models/opportunity-contact-req-management/opportunity-contact-management-res';

@Injectable()
export class OpportunityContactReqManagementService {
  constructor(private http: CmxHttpClient) {}

  // ALL OPPORTUNITIES CONTACT REQUEST MANAGEMENT
  getOpportunitiesContactReq(
    payload: OpportunityContactManagementRequest,
  ): Observable<OpportunityContactManagementReqResponse> {
    const httpParams = payload ? createHttpParams(payload) : new HttpParams();
    return this.http.get<OpportunityContactManagementReqResponse>(`/v5/sm/opportunitycontactrequests`, {
      params: httpParams,
    });
  }

  // SINGLE OPPORTUNITY CONTACT REQUEST MANAGEMENT
  getOpportunityContactReq(
    opportunityContactReqManagementId: string,
  ): Observable<OpportunityContactManagementResponse> {
    return this.http.get<OpportunityContactManagementResponse>(
      `/v5/sm/opportunitycontactrequests/${opportunityContactReqManagementId}`,
    );
  }

  // PATCH OPPORTUNITY CONTACT REQUEST MANAGEMENT
  patchOpportunityContactReq(
    opportunityContactReqManagementId: string,
  ): Observable<OpportunityContactManagementResponse> {
    return this.http.patch<OpportunityContactManagementResponse>(
      `/v5/sm/opportunitycontactrequests/${opportunityContactReqManagementId}`,
      {},
    );
  }

  // DELETE OPPORTUNITY CONTACT REQUEST MANAGEMENT
  removeOpportunityContactReq(opportunityContactReqManagementId: string) {
    return this.http.delete(
      `/v5/sm/opportunitybusinesslines/${opportunityContactReqManagementId}
            `,
    );
  }

  // CREATE OPPORTUNITY CONTACT REQUEST MANAGEMENT
  createOpportunityContactReq(): Observable<OpportunityContactManagementResponse> {
    return this.http.post<OpportunityContactManagementResponse>(`/v5/sm/opportunitycontactrequests`, {});
  }
}
