import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CmxHttpClient } from '../services/api.service';
import { createHttpParams } from '../shared/helpers';
import { ProductLineRequest, ProductLineResponse } from '../models/product-line';

@Injectable()
export class ProductLineService {
  constructor(private http: CmxHttpClient) {}

  getProductLines(payload: ProductLineRequest): Observable<ProductLineResponse> {
    const httpParams = payload ? createHttpParams(payload) : new HttpParams();
    return this.http.get<ProductLineResponse>(`/v1/pdm/productlines`, {
      params: httpParams,
    });
  }
}
