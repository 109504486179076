import { Component, Input, ViewContainerRef,
         HostListener, Output, EventEmitter,
         ViewChild, ElementRef, Renderer2, Optional, Inject } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';

@Component({
  selector: 'cmx-alert',
  styleUrls: ['./../../../../../../scssv4/cmx-components/cmx-alert/v4/cmx-alert.component.scss'],
  templateUrl: 'alert.component.html',
})

export class AlertComponent {
  @Input() public rtl: boolean = false;
  @Input() public preventClose: boolean = false;
  @Input() get unseen(): boolean {
    return this._unseen;
  }
  set unseen(unseen: boolean) {
    this._unseen = unseen;
    this.renderer.addClass(this.elementRef.nativeElement.querySelector('.cmx-alert-bar'), 'fade-in');
    this.renderer.addClass(this.elementRef.nativeElement.querySelector('.cmx-alert-bar'), 'is-paused');
  }
  @Output() public onClose: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onMouseEnter: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onMouseLeave: EventEmitter<any> = new EventEmitter<any>();
  public alertClass: any;
  public _unseen: boolean;
  private timer: CmxTimer;
  private lifeTime: number = 11000;
  private _type: string;
  private alertBar: any;
  @Input()
  get type(): string {
      return this._type;
  }
  set type( type: string ) {
    this._type = type;
    this.alertClass = {
      'cmx-alert-bar--error': type === 'error',
      'cmx-alert-bar--info': type === 'info',
      'cmx-alert-bar--success': type === 'success',
      'cmx-alert-bar--warning': type === 'warning',
    };
  }

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    @Optional() @Inject('RTL') isRTL: boolean,
  ) {
    if (isRTL != undefined) {
      this.rtl = isRTL;
    }

    this.timer = new CmxTimer(this.lifeTime);
  }

  public close(): void {
    this.onClose.emit();
    this.destroyComponent(this.elementRef);
  }

  public fadeIn(): void {
    this.alertBar = this.elementRef.nativeElement.querySelector('.cmx-alert-bar');
    this.renderer.removeClass(this.alertBar, 'fade-out');
    this.renderer.removeClass(this.alertBar, 'is-running');
    this.renderer.addClass(this.alertBar, 'fade-in');
    this.renderer.addClass(this.alertBar, 'is-running');
    this._unseen = !this._unseen;
  }

  public fadeOut(): void {
    this.renderer.removeClass(this.alertBar, 'fade-in');
    this.renderer.removeClass(this.alertBar, 'is-running');
    this.renderer.addClass(this.alertBar, 'fade-out');
    this.renderer.addClass(this.alertBar, 'is-running');
    this._unseen = !this._unseen;
  }

  public delay(ms: number): any {
      return new Promise((resolve: any) => setTimeout(resolve, ms));
  }

  private ngAfterViewInit(): void {
    if (!this.preventClose) {
      this.timer.setCallback(this.destroyComponent, this.elementRef);
    } else {
      this.timer.setCallback(() => { //
      },                     () => { //
      });
    }
  }

  private async destroyComponent(element: ElementRef): Promise<void> {
    const elementToDestroy: any = element.nativeElement;
    const elementToFadeOut: any = element.nativeElement.querySelector('.cmx-alert-bar');
    elementToFadeOut.style.opacity = 1;
    while (elementToFadeOut.style.opacity > 0) {
      elementToFadeOut.style.opacity -= .1;
      await AlertComponent.prototype.delay(40);
    }
    elementToDestroy.parentElement.removeChild(elementToDestroy);
    return;
  }

  @HostListener('mouseleave')
  private mouseLeave(): void {
    this.timer.resume();
    this.onMouseLeave.emit();
  }

  @HostListener('mouseenter')
  private mouseEnter(): void {
    this.timer.pause();
    this.onMouseEnter.emit();
  }
}

// tslint:disable-next-line:max-classes-per-file
export class CmxTimer {

  public timerId: any;
  public start: Date;
  public remaining: number;
  public timerCallback: any;
  public parameter: any;

  constructor(delay: number) {
      this.remaining = delay;
      this.resume();
  }

  public setCallback(callback: any, parameter: any): void {
    this.timerCallback = callback;
    this.parameter = parameter;
  }

  public pause(): void {
    clearTimeout(this.timerId);
    this.remaining -= new Date().getTime() - this.start.getTime();
  }

  public resume(): void {
    this.start = new Date();
    clearTimeout(this.timerId);
    this.timerId = setTimeout(() => { this.timerCallback(this.parameter); }, this.remaining);
  }

  public getRemaining(): number {
    return this.remaining;
  }
}
