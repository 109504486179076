import { Injectable } from '@angular/core';

export declare enum DateTimeActions {
  ConvertToLocal = 0,
  NoConvertToLocal = 1,
}

@Injectable()
export class FormatterService {
  /**
   * @description Transforms a valid string to a date object
   * @param $date The date as string
   * @returns A javascript date object
   */
  public getDateObject($date: string): Date {
    return new Date();
  }
  public formatDateTimeMoment(date: any, languageISO: any, format: any, action?: DateTimeActions): any {
    return {};
  }
  // tslint:disable-next-line:variable-name
  public formatNumberTS(number: any, languageISO: any, decimalNumbers: any): string | 0 {
    return 0;
  }
  public utcToLocal(date: any): any {
    return {};
  }
  public localToUtc(date: any, format?: any): any {
    return {};
  }
  public timeToMinutes(time: any): number {
    return 0;
  }
  /**
   * @description Formats a number as a currency depending on a country
   * @param  $value Value to format
   * @param  $numberDecimals The number of decimals to use
   * @param  $thousandSeparator The character to use when separating numbers
   * @param  $decimalSeparator The character to use when separating the decimal and integer part
   * @returns The number as a currency
   */
  public formatNumber(
    $value: number,
    $numberDecimals: number,
    $thousandSeparator?: string,
    $decimalSeparator?: string,
  ): string {
    return '';
  }
  public round(value: number, decimals: number): number {
    return 0;
  }
  public roundToDown(value: number): number {
    return 0;
  }
  public removeFirstZeros(value: string): string {
    return '';
  }
  public pad(text: string, wildcard: string, length: number): string {
    return '';
  }
  public formatMeasureUnit(measureUnit: any): any {
    return {};
  }
}
