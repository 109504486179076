import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CmxHttpClient } from '../services/api.service';
import { createHttpParams, formatEntityIdParameters } from '../shared/helpers';

// INTERFACES
import { IJobsiteSalesArea } from '../models/jobsite-sales-areas/jobsite-sales-area';
import { IJobsiteSalesAreasRequest } from '../models/jobsite-sales-areas/jobsite-sales-areas-request';
import { IJobsiteSalesAreasResponse } from '../models/jobsite-sales-areas/jobsite-sales-areas-response';

@Injectable()
export class JobsiteSalesAreasService {
  constructor(private http: CmxHttpClient) {}

  getJobsiteSalesAreas(request: IJobsiteSalesAreasRequest): Observable<IJobsiteSalesAreasResponse> {
    const httpParams = request ? createHttpParams(request) : new HttpParams();
    return this.http.get<IJobsiteSalesAreasResponse>(`/v5/sm/jobsitesalesareas`, {
      params: httpParams,
    });
  }

  getAddresses(payload: IJobsiteSalesAreasRequest): Observable<IJobsiteSalesAreasResponse> {
    const httpParams = payload ? createHttpParams(formatEntityIdParameters(payload)) : new HttpParams();
    return this.http.get<IJobsiteSalesAreasResponse>(`/v5/sm/jobsitesalesareas`, {
      params: httpParams,
    });
  }

  getAddress(jobsiteSalesAreaId: string): Observable<IJobsiteSalesArea> {
    return this.http.get<IJobsiteSalesArea>(`/v5/sm/jobsitesalesareas/${jobsiteSalesAreaId}`);
  }
}
