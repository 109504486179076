import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageSelectorComponent } from './language-selector.component';
//import { CmxDropdownModule } from 'src/app/cmx-dropdown/v4/src/cmx-dropdown.module';
import { CmxDropdownModule } from './../../cmx-dropdown/v4/src/cmx-dropdown.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RecaptchaModule } from 'ng-recaptcha';
import { Routes, RouterModule } from '@angular/router';
import { LangTools } from 'src/app/helpers/functions/lang.functions';
import { CmxWebComponentsModule } from '@cmx-web-components/angular';

export const ROUTES: Routes = [
  { path: '', component: LanguageSelectorComponent },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RecaptchaModule,
    RouterModule,
    CmxWebComponentsModule.forRoot(),
  ],
  exports: [LanguageSelectorComponent],
  declarations: [LanguageSelectorComponent],
  providers: [LangTools],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LanguageSelectorModule {}
