import { NgModule } from '@angular/core';
import { DateMomentPipe } from './date-moment.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { TranslationByCountryPipe } from './translation-by-country.pipe';
import { UserPositionPipe } from './user-position.pipe';

@NgModule({
    exports: [SafeHtmlPipe, DateMomentPipe, UserPositionPipe,TranslationByCountryPipe],
    declarations: [SafeHtmlPipe, DateMomentPipe, UserPositionPipe, TranslationByCountryPipe],
})

export class PipesModule { }
