import { Component, ElementRef, HostBinding, HostListener,
         Input, NgModule, Renderer2, ViewEncapsulation, ViewChild, AfterContentInit,
         Optional, Inject } from "@angular/core";
        
@Component({
    selector: "a[cmx-link-icon], button[cmx-link-icon]",
    styleUrls: ["./../../../../../../../scssv4/cmx-components/cmx-button/v4/cmx-button.component.scss"],
    templateUrl: "cmx-link-icon.component.html",
})

export class CmxLinkIconComponent implements AfterContentInit {
    @HostBinding("class.cmx-button") public cmxButtonClass: boolean = true;
    @HostBinding("class.cmx-button--icon") public cmxButttonIconClass: boolean = true;
    @HostBinding("class.cmx-button--text--icon") public cmxButtonTextIconClass: boolean = true;
    @HostBinding("attr.dir") public dirAttr: string = "auto";
    @ViewChild("textLabel") public textLabelChild: ElementRef;
    @Input() public srText: string = "";

    @Input()
    get rtl(): boolean {
        return this._rtl;
    }
    set rtl(value: boolean) {
        this.dirAttr = value ? "rtl" : "ltr";
    }
    private _rtl: boolean;

    constructor(@Optional() @Inject("RTL") isRTL: boolean) {
        if (isRTL != undefined) {
            this.rtl = isRTL;
        }
    }

    public ngAfterContentInit(): void {
        if (this.textLabelChild) {
            const x: string = this.textLabelChild.nativeElement.innerText;
            if (x.length > 0) {
                this.cmxButtonTextIconClass = true;
            }
        }
    }
}
