import { Component, Input, HostBinding, ContentChild, forwardRef, Optional, Inject } from '@angular/core';

import { DatepickerInputComponent } from './../datepicker-input/datepicker-input.component';
import { NumberInputComponent } from './../number-input/number-input.component';
import { CmxSelectComponent } from '../cmx-select/cmx-select.component';
import { TimeInputComponent } from '../time-input/time-input.component';

@Component({
    selector: 'cmx-form-field',
    styleUrls: ['./../../../../../../../../../scssv4/cmx-components/cmx-form-field/v4/cmx-form-field.component.scss'],
    templateUrl: './cmx-form-field.component.html',
})
export class CmxFormFieldComponent {

    @Input()
    get title(): string {
        return this._title;
    }
    set title($value: string) {
        if ($value !== undefined) {
            this._title = $value;
            if (this.title === '') {
                this.labelDisplay = 'none';
            } else {
                this.labelDisplay = 'inline-block';
            }
        } else {
            this.labelDisplay = 'none';
        }
    }

    @Input()
    get error(): boolean {
        return this._error;
    }

    set error($value: boolean) {
        if ($value !== undefined) {
            this._error = $value;
            this.errorClass = $value;
        }
    }

    @Input()
    get rtl(): boolean {
        return this._rtl;
    }

    set rtl($value: boolean) {
        if ($value !== undefined) {
            this._rtl = $value;
            this.dirAttr = $value ? 'rtl' : 'ltr';
            if (this.datepickerInputComponent) {
                this.datepickerInputComponent.rtl = $value;
            }
            if (this.numberInputComponent) {
                this.numberInputComponent.rtl = $value;
            }
            if (this.timeInputComponent) {
                this.timeInputComponent.rtl = $value;
            }
            if (this.selectComponent) {
                this.selectComponent.rtl = $value;
            }
        }
    }

    public labelDisplay = 'none';
    public _title = '';
    @HostBinding('class.cmx-form-group') public cmxFormGroupClass = true;
    @HostBinding('class.error') public errorClass = false;
    @HostBinding('attr.dir') public dirAttr = 'auto';
    @ContentChild(forwardRef(() => DatepickerInputComponent)) private datepickerInputComponent: DatepickerInputComponent;
    @ContentChild(forwardRef(() => NumberInputComponent)) private numberInputComponent: NumberInputComponent;
    @ContentChild(forwardRef(() => TimeInputComponent)) private timeInputComponent: TimeInputComponent;
    @ContentChild(forwardRef(() => CmxSelectComponent)) private selectComponent: CmxSelectComponent;
    private _error = false;
    private _rtl = false;

    constructor(
        @Optional() @Inject('RTL') isRTL: boolean,
    ) {
        if (isRTL !== undefined) {
            this.rtl = isRTL;
        }
    }
}
