<div class="search-container" *ngIf="enableSearch">
    <div id="cmx-checkbox-filter-search-input" [dir]="isRtl ? 'rtl':'auto'" class="filterSearchBox" fxLayoutAlign="center center" fxLayout="row">
        <input fxFlex class="search-input" type="text" placeholder="{{ searchPlaceholder }}" [(ngModel)]="searchTerm" (keyup)="searchInFilter(searchTerm)">
        <span fxFlex="35px" class="cmx-icon-search search-input-icon" aria-hidden="true" *ngIf="searchTerm === ''"></span>
        <span fxFlex="35px" class="cmx-icon-close search-input-icon" aria-hidden="true"  *ngIf="searchTerm !== ''" (click)="clearSearch()"></span>
    </div>
</div>
<div *ngIf="showAllOption" class="checkbox-container" [dir]="isRtl ? 'rtl':'auto'">
    <cmx-checkbox [checked]="allFilter.active" (change)="toggleAll($event)" [rtl]="isRtl">
        {{ allOptionName }}
    </cmx-checkbox>
</div>
<ng-container *ngFor="let filter of _filters; let $index = index;">
    <div [hidden]="!filter.visible" class="checkbox-container" [dir]="isRtl ? 'rtl':'auto'">
        <cmx-checkbox [id]="'checkbox-' + filter.columnKey + $index" [checked]="filter.active" (change)="toggleFilter(filter, $event)" [rtl]="isRtl">
            {{ filter.name }}
        </cmx-checkbox>
    </div>
</ng-container>