export class ContactInfo {
    public customer? : string;
    public firstName: string;
    public lastName: string;
    public position: string;
    public email: string;
    public phone: string;
    public countryAreaCode?: string;
    public areaCode?: string;
    public company: string;
    public country: string;
}
