import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CmxLoginComponent } from './cmx-login.component';
import {CwcInputValueAccessorDirective} from './cwcInputValueAccessor';
import {CmxWebComponentsModule} from "@cmx-web-components/angular";

@NgModule({
  declarations: [CmxLoginComponent, CwcInputValueAccessorDirective],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RouterModule,
        FormsModule,
        CmxWebComponentsModule
    ],
  exports: [CmxLoginComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CmxLoginModule { }
