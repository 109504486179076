import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import {
  IConnectionConfig,
  IFeatureToggleConfig,
} from './feature-toggle-types';
import { FeatureToggleService } from './feature-toggle.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [FeatureToggleService],
})
export class FeatureToggleModule {
  public static forRoot(
    connectionConfig: IConnectionConfig,
    featuresConfig: IFeatureToggleConfig
  ): ModuleWithProviders<any> {
    return {
      ngModule: FeatureToggleModule,
      providers: [
        { provide: 'connectionConfig', useValue: connectionConfig },
        { provide: 'featuresConfig', useValue: featuresConfig },
      ],
    };
  }
}
