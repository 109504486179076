import { HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IRepeatOrderValidationRequest } from '../models/repeat-order-validation/repeat-order-validation-req';
import { IRepeatOrderValidationResponse } from '../models/repeat-order-validation/repeat-order-validation-res';

import { CreateCommentResponse, PaginationRequest, UpdatePatchResponse } from '../models/common';
import { Manifesto, ManifestoException } from '../models/order-detail/manifesto';
import {
  Order,
  OrderComment,
  OrderCommentsResponse,
  OrderLogsResponse,
  OrderPatchPriceRequest,
  OrdersRequest,
  OrdersRequestV6,
  OrdersResponse,
  OrderStatusEnum,
  OrderTypeCodesEnum,
} from '../models/order-management';
import { CmxHttpClient } from '../services/api.service';
import { createHttpParams } from '../shared/helpers';

import { IOrderDeleted } from '../models/order-management/order-delet';

import { ICreateOrderPlaced } from '../models/order-management/create-order-response';
import { UpdateOrderPatch, UpdateOrderPatchResponse } from '../models/order-management/update-order-patch-response';
import { CustomHeaders } from '../shared/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrdersCodesResponse } from '../models/common/orders-codes-response';
import { PlacedPricesPostResponse } from '../models/prices/placed-prices-post-res';

export type EntityUpdateOrderResponse = HttpResponse<UpdateOrderPatchResponse>;

@Injectable()
export class OrdersService {
  constructor(private http: CmxHttpClient) {}

  /**
   * Gets all orders for the current customer
   * @ param payload
   */
  getOrders(payload?: OrdersRequest): Observable<OrdersResponse> {
    const httpParams = payload ? createHttpParams(payload) : new HttpParams();
    return this.http.get<OrdersResponse>(`/v5/sm/orders`, {
      params: httpParams,
    });
  }

  /**
   * Gets an order-record from the API based on its numeric ID and, optionally, type
   * @ param orderId
   * @ param orderType
   */
  getOrderById(orderId: number, orderType: string): Observable<Order> {
    return this.http.get<Order>(`/v5/sm/orders/${orderId}`, {
      params: createHttpParams({ orderType }),
    });
  }

  getOrderByCode(orderCode: string, orderType: string): Observable<Order> {
    return this.http.get<Order>(`/v5/sm/orders/${orderCode}`, {
      params: createHttpParams({ orderType }),
    });
  }

  updateOrder(orderId: number, payload: Order): Observable<UpdatePatchResponse> {
    return this.http.put<UpdatePatchResponse>(`/v5/sm/orders/${orderId}`, payload);
  }

  createOrder(order: Order): Observable<Order> {
    return this.http.post<Order>(`/v5/sm/orders`, order);
  }

  patchOrder(orderId: number, payload: Order): Observable<UpdatePatchResponse> {
    return this.http.patch<UpdatePatchResponse>(`/v5/sm/orders/${orderId}`, payload);
  }

  /**
   * Gets all orders for the current customer
   * @ param payload
   */
  getOrdersV6(payload?: OrdersRequestV6): Observable<OrdersResponse> {
    const httpParams = payload ? createHttpParams(payload) : new HttpParams();
    return this.http.get<OrdersResponse>(`/v6/sm/orders`, {
      params: httpParams,
    });
  }

  getOrdersV7(payload?: OrdersRequestV6): Observable<OrdersResponse> {
    const httpParams = payload ? createHttpParams(payload) : new HttpParams();
    return this.http.get<OrdersResponse>(`/v7/sm/orders`, {
      params: httpParams,
    });
  }

  /**
   * Gets an order-record from the API based on its numeric ID and, optionally, type
   * @ param orderId
   * @ param orderType
   */
  getOrderByIdV6(orderId: string | number, orderType: string | number): Observable<Order> {
    return this.http.get<Order>(`/v6/sm/orders/${orderId}`, {
      params: createHttpParams({ orderType }),
    });
  }

  getOrderByIdV7(orderId: string | number, orderType: string | number): Observable<Order> {
    return this.http.get<Order>(`/v7/sm/orders/${orderId}`, {
      params: createHttpParams({ orderType }),
    });
  }

  getOrderByCodeV6(orderCode: string, orderType: string): Observable<Order> {
    return this.http.get<Order>(`/v6/sm/orders/${orderCode}`, {
      params: createHttpParams({ orderType }),
    });
  }

  updateOrderV6(orderId: number, payload: Order): Observable<UpdatePatchResponse> {
    return this.http.put<UpdatePatchResponse>(`/v6/sm/orders/${orderId}`, payload);
  }

  updateOrderV7(orderId: number, payload: Order): Observable<UpdatePatchResponse> {
    return this.http.put<UpdatePatchResponse>(`/v7/sm/orders/${orderId}`, payload);
  }

  createOrderV6(order: Order): Observable<Order> {
    return this.http.post<Order>(`/v6/sm/orders`, order);
  }

  createOrderPlaced(order: Order): Observable<ICreateOrderPlaced> {
    return this.http.post<ICreateOrderPlaced>('/v6/sm/orders/placed', order);
  }

  createOrderPlacedV7(order: Order): Observable<ICreateOrderPlaced> {
    return this.http.post<ICreateOrderPlaced>('/v7/sm/orders/placed', order);
  }

  // tslint:disable-next-line:no-any
  patchOrderV6(orderId: number, payload: any, isMatch = null): Observable<UpdateOrderPatchResponse> {
    return this.http
      .patch<EntityUpdateOrderResponse>(`/v6/sm/orders/${orderId}`, payload, {
        headers: isMatch ? ({ 'If-Match': isMatch } as CustomHeaders) : ({} as CustomHeaders),
      })
      .pipe(map((response: EntityUpdateOrderResponse) => this.convertPathOrderResponse(response)));
  }

  // tslint:disable-next-line:no-any
  patchOrderAsRequested(orderId: number, payload?: any): Observable<UpdatePatchResponse> {
    return this.http.patch<UpdatePatchResponse>(`/v6/sm/orders/${orderId}/requested`, payload);
  }

  patchUpdateOrderV6(
    orderId: number,
    isLock: boolean,
    isModified: boolean,
    isHeaderResponse: boolean,
    isMatch: string = null,
  ): Observable<UpdateOrderPatchResponse> {
    const request: UpdateOrderPatch = {
      status: {
        statusCode: OrderStatusEnum.InEdit,
        inEdit: isLock,
      },
      orderType: {
        orderTypeCode: isModified ? OrderTypeCodesEnum.ModifyRequest : OrderTypeCodesEnum.Request,
      },
    };

    return this.http
      .patch<EntityUpdateOrderResponse>(`/v6/sm/orders/${orderId}`, request, {
        headers: isMatch ? ({ 'If-Match': isMatch } as CustomHeaders) : ({} as CustomHeaders), // 'accept-language': 'en_US'
        isHeaderResponse: isHeaderResponse,
      })
      .pipe(map((res: EntityUpdateOrderResponse) => this.convertPathOrderResponse(res)));
  }

  convertPathOrderResponse(response: EntityUpdateOrderResponse): UpdateOrderPatchResponse {
    return {
      ...response.body,
      ifMatch: response.headers ? response.headers.get('If-Match') : null,
    } as UpdateOrderPatchResponse;
  }

  // Unused?
  removeOrder(orderId: number) {
    return this.http.delete(`/v5/sm/orders/${orderId}`);
  }

  // Update TBC
  patchOrderPrice(orderId: number, payload: OrderPatchPriceRequest): Observable<UpdatePatchResponse> {
    return this.http.patch<UpdatePatchResponse>(`/v5/sm/orders/${orderId}/prices`, payload);
  }

  // Update TBC
  getOrderLogs(orderId: number, payload?: PaginationRequest): Observable<OrderLogsResponse> {
    const httpParams = payload ? createHttpParams(payload) : new HttpParams();
    return this.http.get<OrderLogsResponse>(`/v5/sm/orders/${orderId}/logs`, {
      params: httpParams,
    });
  }

  // Update TBC - may now go to /v6/rc/ORQ/${orderId}/comments
  getOrderComments(orderId: number, payload?: PaginationRequest): Observable<OrderCommentsResponse> {
    const httpParams = payload ? createHttpParams(payload) : new HttpParams();
    return this.http.get<OrderCommentsResponse>(`/v5/sm/orders/${orderId}/comments`, {
      params: httpParams,
    });
  }

  // Update TBC - may now go to /v6/rc/ORQ/${orderId}/comments
  createOrderComment(orderId: number, payload: OrderComment): Observable<CreateCommentResponse> {
    return this.http.post<CreateCommentResponse>(`/v5/sm/orders/${orderId}/comments`, payload);
  }

  pathOptimalSources(orderId: number): Observable<CreateCommentResponse> {
    return this.http.post<CreateCommentResponse>(`/v5/sm/orders/${orderId}/optimalsources`, {});
  }

  getCancelOrderConfig(orderId: number): Observable<Manifesto | ManifestoException> {
    return this.http.get(`/v5/sm/manifestos/ORQ/${orderId}?manifestoTypeCode=CNL`);
  }

  cancelOrder(orderId: number, comment: string, customerId: string | number): Observable<UpdatePatchResponse> {
    return this.http.patch(
      `/v5/sm/orders/${orderId}`,
      JSON.stringify({
        orderType: {
          orderTypeCode: 'CREQ',
        },
        comment: {
          commentDesc: comment,
          user: {
            userId: customerId,
          },
          isCustomer: true,
        },
      }),
      {
        headers: { 'Content-Type': 'application/json' },
      },
    );
  }

  getCancelOrderConfigV6(orderId: number): Observable<Manifesto | ManifestoException> {
    return this.http.get(`/v6/sm/manifestos/ORQ/${orderId}?manifestoTypeCode=MODCNL`);
  }

  cancelOrderV6(
    orderId: number,
    comment: string,
    customerId: string | number,
    statusCode: string,
  ): Observable<UpdatePatchResponse> {
    return this.http.patch(
      `/v6/sm/orders/${orderId}`,
      JSON.stringify({
        orderType: {
          orderTypeCode: 'CREQ',
        },
        status: {
          statusCode: statusCode,
        },
        comment: {
          commentDesc: comment,
          user: {
            userId: customerId,
          },
          isCustomer: true,
        },
      }),
      {
        headers: { 'Content-Type': 'application/json' },
      },
    );
  }

  repeatOrderValidation(
    orderId: number,
    validationRequest: IRepeatOrderValidationRequest,
  ): Observable<IRepeatOrderValidationResponse> {
    return this.http.patch(`/v6/sm/orders/${orderId}/validate/RFC`, validationRequest, {
      headers: { 'Content-Type': 'application/json' },
    });
  }

  deleteProfile(orderId: number, payload: IOrderDeleted): Observable<UpdatePatchResponse> {
    return this.http.patch<UpdatePatchResponse>(`/v6/sm/orders/${orderId}`, payload);
  }

  getOrderDetail(orderId: number | string): Observable<Order> {
    return this.http.get<Order>(`/v6/sm/orders/${orderId}?orderType=DFT`);
  }

  lockedOrder(orderId: number): Observable<UpdatePatchResponse> {
    return this.http.patch<UpdatePatchResponse>(`/v6/sm/orders/${orderId}/locked`, {});
  }

  modifiedOrder(orderId: number): Observable<UpdatePatchResponse> {
    return this.http.patch<UpdatePatchResponse>(`/v6/sm/orders/${orderId}/modified`, {});
  }

  unlockedOrder(orderId: number): Observable<UpdatePatchResponse> {
    return this.http.patch<UpdatePatchResponse>(`/v6/sm/orders/${orderId}/unlocked`, {});
  }

  acceptedOrder(orderId: number): Observable<UpdatePatchResponse> {
    return this.http.patch<UpdatePatchResponse>(`/v6/sm/orders/${orderId}/accepted`, {});
  }

  rejectedrder(orderId: number): Observable<UpdatePatchResponse> {
    return this.http.patch<UpdatePatchResponse>(`/v6/sm/orders/${orderId}/rejected`, {});
  }

  confirmedOrder(orderId: number, orderCode: string, orderServiceCode?: string): Observable<UpdatePatchResponse> {
    const body = {
      orderCode: orderCode,
      ...(orderServiceCode && { orderServiceCode }),
    };
    return this.http.patch<UpdatePatchResponse>(`/v6/sm/orders/${orderId}/confirmed`, body);
  }

  getOrdersCodes(orderId: number | string, orderCode: string): Observable<OrdersCodesResponse> {
    return this.http.get<OrdersCodesResponse>(`/v1/ofm/orders/?orderId=${orderId}&orderCode=${orderCode}`);
  }

  postOrderPlacedPrices(order: Order): Observable<PlacedPricesPostResponse> {
    return this.http.post<PlacedPricesPostResponse>('v7/sm/orders/placed/prices', order);
  }
}
