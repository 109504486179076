import { Component, Input, ViewChild, ElementRef, ChangeDetectorRef, forwardRef, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export const TIME_INPUT_VALUE_ACCESSOR: any = {
    multi: true,
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TimeInputComponent),
};

@Component({
    // tslint:disable-next-line
    selector: 'time-input',
    styleUrls: ['./../../../../../../../../../scssv4/cmx-components/cmx-form-field/v4/time-input.component.scss'],
    templateUrl: './time-input.component.html',
    providers: [TIME_INPUT_VALUE_ACCESSOR],
})
export class TimeInputComponent implements ControlValueAccessor {
    // @Input() public value: Date = new Date();
    @Input() public is24Period = false;
    @Input()
    get value(): Date {
        return this._value;
    }
    set value($value: Date) {
        if ($value !== undefined) {
            this._value = $value;
            this.propagateChange($value);
            this.propagateTouch();
        }
    }
    @Input()
    get rtl(): boolean {
        return this._rtl;
    }
    set rtl($value: boolean) {
        if ($value !== undefined) {
            this._rtl = $value;
        }
    }

    @Input()
    get placeholder(): string {
        return this._placeholder;
    }
    set placeholder($value: string) {
        if ($value !== undefined) {
            this._placeholder = $value;
        }
    }

    @Input()
    get hourStep(): number {
        return this._hourStep;
    }
    set hourStep($value: number) {
        if ($value !== undefined) {
            this._hourStep = $value;
        }
    }

    @Input()
    get minuteStep(): number {
        return this._minuteStep;
    }
    set minuteStep($value: number) {
        if ($value !== undefined) {
            this._minuteStep = $value;
        }
    }


    @ViewChild('hoursElement') public _hoursElement: ElementRef;
    @ViewChild('minutesElement') public _minutesElement: ElementRef;
    @ViewChild('periodElement') public _periodElement: ElementRef;

    public showTimeInput = false;
    public timePickerTxt = '';
    private _placeholder = '';
    private regex: RegExp = new RegExp(/^\d+$/);
    private specialKeys: string[] = ['Backspace', 'Tab', 'End', 'Home', 'Control', 'F5'];

    @ViewChild('timeInputPositioner') private timeInputPositioner: ElementRef;
    private _hoursKeysCount = 0;
    private _minutesKeysCount = 0;
    private _rtl = false;
    private _value: Date = new Date();
    private _hourStep = 1;
    private _minuteStep = 1;
    constructor(private ref: ChangeDetectorRef, private renderer: Renderer2) {
        if (this._placeholder === '') {
            this._placeholder = 'Select Request Time';
        }
     }

    public propagateChange: (value: any) => void = (value) => {
        //
    }

    public propagateTouch: () => any = () => {
        //
    }

    /**
     * @description Registers the control's value.
     * @param $value Value given to the cmx-select.
     */
    public writeValue($value: Date): void {
        if ($value !== undefined) {
            this._value = $value;
        }
    }

    /**
     * @description Registers a callback function to be invoked when the control's value changes from user input.
     * @param $fn Function that the change event will execute.
     */
    public registerOnChange($fn: any): void {
        this.propagateChange = $fn;
    }

    /**
     * @description Registers a callback function to be invoked when the control is blurred by the user.
     * @param $fn Function that the touched event will execute.
     */
    public registerOnTouched($fn: any): void {
        this.propagateTouch = $fn;
    }

    // hours
    public hours_onClick(e: any): void {
        this._hoursKeysCount = 0;
        setTimeout(() => {
            e.target.select();
        });
    }
    public hours_onFocus(e: any): void {
        e.target.select();
        this._hoursKeysCount = 0;
    }
    public hours_onBlur(e: any): void {
        this._hoursKeysCount = 0;
    }
    public hours_onKeyDown(e: any): boolean | undefined {
        if (e.key === 'ArrowLeft') {
            return false;
        } else if (e.key === 'ArrowRight') {
            setTimeout(() => {
                this._minutesElement.nativeElement.focus();
            });
        } else if (e.key === 'ArrowDown') {
            this.changeHour(false);
        } else if (e.key === 'ArrowUp') {
            this.changeHour(true);
        } else {
            const key: any = e.key.replace(/\s/g, '');

            if (this.specialKeys.indexOf(key) !== -1) {
                if (key === 'Backspace') {
                    this._hoursKeysCount = 0;
                }

                return;
            }
            const current: string = e.target.value;
            // we need this because the current value on the DOM element
            // is not yet updated with the value from this event
            let next: string = current.concat(key);
            next = next.replace('--', '');

            if (next && !String(next).match(this.regex)) {
                e.preventDefault();
            } else {
                this._hoursKeysCount++;

                // go to minutes, cuz havent 30s hours..
                if (key >= 3) {
                    setTimeout(() => {
                        this._minutesElement.nativeElement.focus();
                    });
                    return true;
                }

                // go to minutes, cuz the hour is complete
                if (this._hoursKeysCount === 2) {
                    setTimeout(() => {
                        this._minutesElement.nativeElement.focus();
                    });
                    return true;
                }
            }
        }
    }

    public toggle(): void {
        this.showTimeInput = !this.showTimeInput;
        setTimeout(() => {
            this.isInsideViewport();
        },         60);
        this.updatePlaceholder();
    }

    public get hours(): any {
        if (this.value === undefined) {
            return '--';
        }

        let hours: any;
        if (this.is24Period) {
            hours = this.value.getHours();
        } else {
            if (this.value.getHours() > 12) {
                hours = this.value.getHours() - 12;
            } else {
                if (this.value.getHours() === 0) {
                    return 12;
                } else {
                    hours = this.value.getHours();
                }
            }
        }

        if (+hours < 10) {
            hours = '0' + (+hours);
        }

        return hours;
    }
    public set hours(hours: any) {

        if (hours >= 24) {
            hours = 23;
        }

        if (this.value === undefined) {
            this.value = new Date();
        }

        // temporal hack
        if (+hours === 0) {
            this.value.setHours(1);
        } else {
            this.value.setHours(0);
        }

        this.ref.detectChanges();

        this.value.setHours(hours);
    }


    // minutes
    public minutes_onClick(e: any): void {
        this._minutesKeysCount = 0;
        setTimeout(() => {
            e.target.select();
        });
    }
    public minutes_onFocus(e: any): void {
        e.target.select();
        this._minutesKeysCount = 0;
    }
    public minutes_onBlur(): void {
        this._minutesKeysCount = 0;
    }
    public minutes_onKeyDown(e: any): true | undefined {
        if (e.key === 'ArrowLeft') {
            setTimeout(() => {
                this._hoursElement.nativeElement.focus();
            });
        } else if (e.key === 'ArrowRight') {
            if (!this.is24Period) {
                setTimeout(() => {
                    this._periodElement.nativeElement.focus();
                });
            }
        } else if (e.key === 'ArrowDown') {
            this.changeminute(false);
        } else if (e.key === 'ArrowUp') {
            this.changeminute(true);
        } else {
            const key: any = e.key.replace(/\s/g, '');
            if (this.specialKeys.indexOf(key) !== -1) {
                if (key === 'Backspace') {
                    this._minutesKeysCount = 0;
                }
                return;
            }
            const current: string = e.target.value;
            // we need this because the current value on the DOM element
            // is not yet updated with the value from this event
            const next: string = current.concat(key);
            if (next && !String(next).match(this.regex)) {
                e.preventDefault();
            } else {
                this._minutesKeysCount++;

                // go to minutes, cuz havent 30s hours..
                if (key >= 6) {
                    if (!this.is24Period) {
                        setTimeout(() => {
                            this._periodElement.nativeElement.focus();
                        });
                    }
                    return true;
                }

                // go to period, cuz the minutes is complete
                if (this._minutesKeysCount === 2) {
                    if (!this.is24Period) {
                        setTimeout(() => {
                            this._periodElement.nativeElement.focus();
                        });
                    }
                    return true;
                }
            }
        }
    }
    public get minutes(): any {
        if (this.value === undefined) {
            return '--';
        }

        let minutes: any;
        minutes = this.value.getMinutes();

        if (+minutes < 10) {
            minutes = '0' + (+minutes);
        }

        return minutes;
    }
    public set minutes(minutes: any) {

        // get the last 2 digits, only works for 24hours period
        if (minutes.length >= 3) {
            minutes = minutes.substring(minutes.length - 2, minutes.length);
        }
        if (minutes >= 60) {
            minutes = 59;
        }

        if (this.value === undefined) {
            this.value = new Date();
        }

        if (+minutes === 0) {
            this.value.setMinutes(1);
        } else {
            this.value.setMinutes(0);
        }

        this.ref.detectChanges();
        this.value.setMinutes(minutes);
    }


    // period
    public onPaste(e: any): void {
        e.preventDefault();
    }

    public keypressCatcher($event: Event): boolean {
        $event.preventDefault();
        this.timePickerTxt = '';
        return false;
    }

    /**
     * @param $p true to increase, false to decrease value
     */
    private changeHour(p: boolean): void {
        if (p) {
            this.value.setHours(this.value.getHours() + this.hourStep);
        } else {
            this.value.setHours(this.value.getHours() - this.hourStep);
        }
        this._hoursKeysCount = 0;
        this.updatePlaceholder();
    }

    /**
     * @param $p true to increase, false to decrease value
     */
    private changeminute(p: boolean): void {
        if (p) {
            this.value.setMinutes(this.value.getMinutes() + this.minuteStep);
        } else {
            this.value.setMinutes(this.value.getMinutes() - this.minuteStep);
        }
        this._hoursKeysCount = 0;
        this.updatePlaceholder();
    }

    private disableToggle($event: Event): void {
        $event.stopPropagation();
    }

    private period_onClick(e: any): void {
        setTimeout(() => {
            e.target.select();
        });
    }
    private period_onFocus(e: any): void {
        e.target.select();
    }
    private period_onKeyDown(e: any): false | undefined {

        // let key = e.key;

        if (this.specialKeys.indexOf(e.key) !== -1) {
            return;
        }

        if (e.key === 'ArrowLeft') {
            setTimeout(() => {
                this._minutesElement.nativeElement.focus();
            });
        } else if (e.key === 'ArrowRight') {
            return false;
        } else {

            if (this.value === undefined) {
                this.value = new Date();
            }

            if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                this.tooglePeriod();
            }

            if (e.key.toLowerCase() === 'a' || e.key.toLowerCase() === 'p') {
                if (this.period === 'AM' && e.key.toLowerCase() === 'p') {
                    this.value.setHours(this.value.getHours() + 12);
                } else if (this.period === 'PM' && e.key.toLowerCase() === 'a') {
                    this.value.setHours(this.value.getHours() - 12);
                }
            }

            setTimeout(() => {
                e.target.select();
            });

            return false;
        }
    }

    public hours_arrowUp(): void {
        this.changeHour(true);
    }

    public hours_arrowDown(): void {
        this.changeHour(false);
    }

    public minutes_arrowUp(): void {
        this.changeminute(true);
    }

    public minutes_arrowDown(): void {
        this.changeminute(false);
    }

    private updatePlaceholder(): void {
        this.placeholder = !this.is24Period ?
        this.hours + ':' + this.minutes + ' ' + this.period :
        this.hours + ':' + this.minutes;
    }

    public tooglePeriod(): void {
        if (this.period === 'AM') {
            this.value.setHours(this.value.getHours() + 12);
        } else if (this.period === 'PM') {
            this.value.setHours(this.value.getHours() - 12);
        }
    }

    public get period(): string {
        if (this.value === undefined) {
            return '--';
        }

        return this.value.getHours() >= 12 ? 'PM' : 'AM';
    }

    private isInsideViewport(): void {
        const sidePositioner: string = this.rtl ? 'right' : 'left';
        // clearing
        this.renderer.setStyle(this.timeInputPositioner.nativeElement, sidePositioner, undefined);
        this.renderer.setStyle(this.timeInputPositioner.nativeElement, 'top', undefined);
        // calculations
        const rect: ClientRect = this.timeInputPositioner.nativeElement.getBoundingClientRect();
        const screenHeight: number = window.innerHeight;
        const screenWidth: number = window.innerWidth;
        const adjustment = 20;
        if (this.rtl) {
            if (rect.left < 0) {
                const pxValue: number = Math.abs(rect.left);
                this.renderer.setStyle(
                    this.timeInputPositioner.nativeElement, sidePositioner, (-1 * pxValue) + 'px',
                );
            }
        } else {
            if (rect.right > screenWidth) {
                // move to the left
                const pxValue: number = Math.abs(rect.right - screenWidth) + adjustment;
                this.renderer.setStyle(
                    this.timeInputPositioner.nativeElement, sidePositioner, (-1 * pxValue) + 'px',
                );
            }
        }
        if (rect.bottom > screenHeight) {
            // move to the top
            const pxValue: number = Math.abs(rect.bottom - screenHeight);
            this.renderer.setStyle(
                this.timeInputPositioner.nativeElement, 'top', (-1 * pxValue) + 'px',
            );
        }
    }
}
