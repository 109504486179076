<div class="timepicker__toggler" (click)="toggle()">
</div>
<input class="cmx-form-group timepicker__input" type="text" [class.--black-placeholder]="value"
  [placeholder]="placeholder" (input)="keypressCatcher($event)" [(ngModel)]="timePickerTxt" [dir]="rtl ? 'rtl': 'ltr'">
<ng-content select="span.error-message"></ng-content>
<div #timeInputPositioner class="timepicker__positioner timepicker__animate" *ngIf="showTimeInput">
  <div class="timepicker__time">
    <div class="timepicker__time-num">
      <span (click)="hours_arrowUp()" class="arrowUp"></span>
      <input #hoursElement class="timeinput" type="text" [(ngModel)]="hours" (click)="hours_onClick($event)"
        (keydown)="hours_onKeyDown($event)" (focus)="hours_onFocus($event)" (paste)="onPaste($event)">
      <span (click)="hours_arrowDown()" class="arrowDown"></span>
    </div>
    <span (click)="hours_arrowUp()" class="timepicker__time-separator">:</span>
    <div class="timepicker__time-num">
      <span (click)="minutes_arrowUp()" class="arrowUp"></span>
      <input #minutesElement class="timeinput" type="text" [(ngModel)]="minutes" (keydown)="minutes_onKeyDown($event)"
        (click)="minutes_onClick($event)" (focus)="minutes_onFocus($event)" (blur)="minutes_onBlur()"
        (paste)="onPaste($event)">
      <span (click)="minutes_arrowDown()" class="arrowDown"></span>
    </div>
    <span #periodElement (click)="tooglePeriod()" [dir]="rtl ? 'rtl': 'ltr'" *ngIf="!is24Period"
      class="timepicker__time-am-pm" title="Click to toggle" tabindex="-1">{{ period }}</span>
  </div>
</div>
<div [ngClass]="{ 'timepicker__icon': !rtl, 'timepicker__icon-rtl' : rtl }">
  <span class="cmx-icon-clock"></span>
</div>
<div class="timepicker__mask" *ngIf="showTimeInput" (click)="toggle()"></div>