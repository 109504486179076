<list-chart-card
  [title]="title"
  [queryOptions]="queryOptions"
  [dateRanges]="dateRanges"
  [statusesList]="statusesList"
  [chartTotalLabel]="chartTotalLabel"
  [statusListItemOrdersLabel]="statusListItemOrdersLabel"
  [emptyListText]="emptyListText"
  [renderTotals]="extended"
  [buttonLabel]="buttonLabel"
  [emptyButtonLabel]="emptyButtonLabel"
></list-chart-card>
