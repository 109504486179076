import { FormControl } from '@angular/forms';

export class MultiEmailValidators {

  public static emailList(c: any): any {
    // tslint:disable-next-line:max-line-length
    const regex = /^(([^±!@£$%^&*|,´ñÑáéíóúÁÉÍÓÚäëïöüåÄËÏÖÜÅýÝ~+§¡!€#¢§¶¬"°•ªº{}()«\\/=<>?¿:;\s@"]+(\.[^±!@£$%^&*'|,´ñÑáéíóúÁÉÍÓÚäëïöüåÄËÏÖÜÅýÝ~+§¡!€#¢§¶¬"°•ªº{}()«\\/=<>?¿:;\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (c && c.value) {
      const emailList = c.value.replace(/[; :]/g, ',').split(',').filter(v => v && !regex.test(v));
      const emailMessage = emailList.length ? { 'emailList': emailList.join(' - ') } : null;
      return emailMessage;
    } else {
      return null;
    }
  }

  public static noSpecialChars(term) {
    const regex = /[±!@£$%^&*_+§¡!€#¢§¶¬"°•ªº{}()«\\/=<>?¿:;]/
    if(regex.test(term)) {
      return false;
    } else {
      return true;
    }
  }

  public static phoneNumber(term) {
    const regex = /^\d+$/
    if(!regex.test(term)) {
      return false;
    } else {
      return true;
    }
  }
}
