import { Injectable, Inject, Optional } from '@angular/core';

import * as moment_ from 'moment';

const moment = moment_;

import { FormatterService } from './formatter.service';

@Injectable()
export class DateFormatService {
    public currentLanguageConfig: any;
    constructor(
        @Inject('TRANSLATION_LANGUAGES') @Optional() private languagesList: any[],
        @Optional() private formatter: FormatterService,
    ) { }

    /**
     * @description Formats the range value of the datepicker
     * @param any $datepickerValue: The value of the range selected in the datepicker component,
     * requires a min and max attributes
     * @param string $momentFormat: format string for readable representation
     */
    public formatRange($datepickerValue: any, $momentFormat?: string): string {
        const $language: any = this.findLanguage();
        let $answer = '';
        if ($momentFormat !== undefined && $momentFormat !== null) {
            moment.updateLocale(localStorage['language'], this.currentLanguageConfig);
            return moment($datepickerValue.value.min).
                   locale(localStorage['language']).format($momentFormat) + ' - ' +
                   moment($datepickerValue.value.max).
                   locale(localStorage['language']).format($momentFormat);
        } else if (this.formatter !== undefined) {
            $answer = this.formatter.formatDateTimeMoment($datepickerValue.value.min, $language.languageISO, $language.formatDate) +
            ' - ' +
            this.formatter.formatDateTimeMoment($datepickerValue.value.max, $language.languageISO, $language.formatDate);
        } else {
            let minDay: string;
            let minMonth: number;
            let minYear: number;
            let maxDay: string;
            let maxMonth: number;
            let maxYear: number;
            if ($datepickerValue.value.min.getDate() <= 9) {
                minDay = '0' + $datepickerValue.value.min.getDate();
            } else {
                minDay = $datepickerValue.value.min.getDate();
            }
            minMonth = $datepickerValue.value.min.getMonth();
            minYear = $datepickerValue.value.min.getFullYear();
            if ($datepickerValue.value.max.getDate() <= 9) {
                maxDay = '0' + $datepickerValue.value.max.getDate();
            } else {
                maxDay = $datepickerValue.value.max.getDate();
            }
            maxMonth = $datepickerValue.value.max.getMonth();
            maxYear = $datepickerValue.value.max.getFullYear();
            const $monthNamesArray: any = $language.monthNames.split(',');
            $answer = (minDay + ' ' + $monthNamesArray[minMonth] + ', ' + minYear) +
            ' - ' +
            (maxDay + ' ' + $monthNamesArray[maxMonth] + ', ' + maxYear);
        }
        return $answer;
    }

    /**
     * @description Formats the single value of the datepicker
     * @param any $datepickerValue: The value of the range selected in the datepicker component,
     * requires a min and max attributes
     * @param string $momentFormat: format string for readable representation
     */
    public formatSingle($datepickerValue: any, $momentFormat?: string): string {
        const $language: any = this.findLanguage();
        let $answer = '';
        if ($momentFormat !== undefined && $momentFormat !== null) {
            if (localStorage.getItem('language') === undefined || localStorage.getItem('language') === null ) {
                localStorage.setItem('language', 'en_US');
            }
            moment.updateLocale(localStorage['language'], this.currentLanguageConfig);
            return moment($datepickerValue.value.min).locale(localStorage['language']).format($momentFormat);
        } else if (this.formatter !== undefined) {
            $answer = this.formatter.formatDateTimeMoment(
                $datepickerValue.value.min, $language.languageISO, $language.formatDate,
            );
        } else {
            let day: string;
            let month: number;
            let year: number;
            if ($datepickerValue.value.min.getDate() <= 9) {
                day = '0' + $datepickerValue.value.min.getDate();
            } else {
                day = $datepickerValue.value.min.getDate();
            }
            month = $datepickerValue.value.min.getMonth();
            year = $datepickerValue.value.min.getFullYear();
            const $monthNamesArray: any = $language.monthNames.split(',');
            $answer = (day + ' ' + $monthNamesArray[month] + ', ' + year);
        }
        return $answer;
    }

    /**
     * @description Looks for the language object in the languages structure
     * @returns any: The language found
     */
    private findLanguage(): any {
        const $storedLanguage: string = localStorage.getItem('language');
        let $languageToUse: any = {
            authorLanguage: 'Fabritzio Villegas',
            countryCode: 'US',
            currencyFormat: '%u%n',
            currencyName: 'dollar',
            currencySymbol: '$',
            currencySymbolFloat: 'left',
            dayNames: 'Sunday,Monday,Tuesday,Wednesday,Thursday,Friday,Saturday',
            decimalNumbers: 2,
            decimalSeparator: '.',
            formatDate: 'MMM D, YYYY',
            formatTime: 'hh:mm A',
            languageCountry: 'United States',
            languageISO: 'en-US',
            languageId: 8,
            languageName: 'English US',
            monthNames: 'January,February,March,April,May,June,July,August,September,October,November,December',
            textFloat: 'left',
            thousandSeparator: ',',
        };
        // tslint:disable-next-line
        if (!this.languagesList && !$storedLanguage) {
            this.languagesList.forEach(($language: any) => {
                if ($language.languageISO === $storedLanguage ||
                $language.countryCode.toLowerCase() === $storedLanguage.toLowerCase() ||
                $language.languageISO.includes($storedLanguage)) {
                    $languageToUse = $language;
                }
            });
        }
        return $languageToUse;
    }
}
