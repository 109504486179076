<app-dashboard-card title="{{ title }}" [padding]="true">
  <ng-container cardHeaderRight>
    <cwc-picker #dateRangePicker *ngIf="dateRanges" (cwcChange)="onDateRangeChange($event.detail)" unwrap
      variant="no-border" [placeholder]="datePlaceholder" [footerCancelButtonLabel]="pickerLabelCancel" required
      design-version="v2" label="{{datePlaceholder}}" id="cmx-dashboard-order-history-date-range-picker">
      <cwc-picker-option *ngFor="let opt of dateRanges; let i = index" [value]="opt" [selected]="i === 0">{{ opt.label
        }}</cwc-picker-option>
    </cwc-picker>
  </ng-container>
  <ng-container cardBody>
    <ng-container *ngIf="
        (loadingStatus$ | async) !== LoadingStatus.PENDING;
        else loadingState
      ">
      <cwc-grid columns="5" mobileColumns="5" *ngIf="changeEmitter$ | async; else emptyResult">
        <cwc-cell #statusCell colspan="3" class="list-content" (scroll)="trackScroll($event)" 
         [ngClass]="{'onTopScroll': !scrolledToBottom  && enableInsetShadowList, 
                     'onMiddleScroll': !scrolledToBottom && !scrolletToTop && enableInsetShadowList, 
                     'onBottomScroll': scrolledToBottom && !scrolletToTop && enableInsetShadowList,
                     'center-content': !enableInsetShadowList }">
          <ul #statusUl>
            <ng-container *ngFor="let status of (statuses$ | async)">
              <li *ngIf="status.value" attr.data-testid="li-{{ status.code }}" [ngClass]="{
                  'list-item': true
                }">
                <div class="list-item__status">
                  <cwc-status-indicator role="status" [variant]="getStatusVariant(status)" size="small"
                    [outline]="status.outline">
                    <span class="list-item__status-label">{{ status.name }}</span>
                  </cwc-status-indicator>
                  <div class="value">
                    {{ status.value }}&nbsp;{{ statusListItemOrdersLabel }}
                  </div>
                </div>
                <div class="list-item__total" *ngIf="renderTotals" [innerHTML]="status.formattedTotal">
                </div>
              </li>
            </ng-container>
          </ul>
        </cwc-cell>
        <cwc-cell colspan="2" class="chart-content">
          <cmx-donut-chart class="fade-in" [donutTotalLabel]="chartTotalLabel" [data]="chartData$ | async"
            [chartColor]="chartColor$ | async"></cmx-donut-chart>
        </cwc-cell>
        <cwc-cell *ngIf="renderTotals && buttonLabel" colspan="5" justify-items="flex-end">
          <a href="/invoices-payments/financial-documents">
            <cwc-button design-version="v2">{{ buttonLabel }}</cwc-button>
          </a>
        </cwc-cell>
        <cwc-cell colspan="5" *ngIf="renderRecentOrders && recentOrdersLabels && (recentOrdersData$ | async) && (recentOrdersData$ | async)?.length" class="recent-orders">
          <div class="recent-orders__header">
            <h4 class="recent-orders__title">
              {{recentOrdersLabels.title}}
            </h4>
            <a href="/ordersnproduct/app/orders" class="recent-orders__link">
              <cwc-button variant="link">{{recentOrdersLabels.viewAll}}</cwc-button>
            </a>
          </div>
          <div class="recent-orders__carousel">
            <cwc-icon class="recent-orders__control" name="arrow-left-rounded-fill" [ngClass]="{'disabled': !activeOrderIndex}" size="1.25rem" (click)="changeOrder(false)"></cwc-icon>
            <div class="recent-orders__order">
              <div class="recent-orders__order-header">
                <cwc-status-indicator class="fade-in" [variant]="(recentOrdersData$ | async)[activeOrderIndex]?.statusColor" [outline]="(recentOrdersData$ | async)[activeOrderIndex]?.statusOutline">
                  <span class="recent-orders__status-label fade-in">{{(recentOrdersData$ | async)[activeOrderIndex]?.statusDesc}}</span>
                </cwc-status-indicator>
                <div class="recent-orders__volume fade-in" *ngIf="(recentOrdersData$ | async)[activeOrderIndex]?.unitCode">
                  <cwc-icon class="recent-orders__volume-icon" name="volume" color="true-blue"></cwc-icon>
                  <div class="recent-orders__volume-quantity">{{(recentOrdersData$ | async)[activeOrderIndex]?.totalQuantity}} {{(recentOrdersData$ | async)[activeOrderIndex]?.unitCode}}</div>
                </div>
              </div>

              <cwc-button class="recent-orders__order-number fade-in" variant="link" size="medium" (cwcClick)="navigateToOrderDetail()">{{recentOrdersLabels.order}} #{{(recentOrdersData$ | async)[activeOrderIndex]?.orderCode}}</cwc-button>

              <div class="recent-orders__jobsite fade-in">{{(recentOrdersData$ | async)[activeOrderIndex]?.jobsiteCode}} - {{(recentOrdersData$ | async)[activeOrderIndex]?.jobsiteDesc}}</div>
            </div>
            <cwc-icon class="recent-orders__control" name="arrow-right-rounded-fill" [ngClass]="{'disabled': (activeOrderIndex + 1) === (recentOrdersData$ | async).length}" size="1.25rem" (click)="changeOrder(true)"></cwc-icon>
          </div>
          <div class="recent-orders__indicators">
            <div *ngFor="let indicator of (recentOrdersData$ | async); let j = index" class="recent-orders__indicator" [ngClass]="{'active': j === activeOrderIndex}"></div>
          </div>
        </cwc-cell>
      </cwc-grid>
      <ng-template #emptyResult>
        <cwc-empty-result icon="information-rounded-fill" variant="flat" class="empty-result fade-in" [ngClass]="{
          'empty-result--with-footer': emptyButtonLabel ? true : false
        }">
          {{ emptyListText }}
        </cwc-empty-result>
        <div class="card-footer" *ngIf="renderTotals && emptyButtonLabel">
          <a href="/invoices-payments/financial-documents?closedView=true">
            <cwc-button variant="support" design-version="v2">{{ emptyButtonLabel
              }}</cwc-button>
          </a>
        </div>
      </ng-template>
    </ng-container>
    <ng-template #loadingState>
      <div class="loader-wrapper"><cwc-loader size="medium"></cwc-loader></div>
    </ng-template>
  </ng-container>
</app-dashboard-card>