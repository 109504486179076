import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductColorRequest } from '../models/product-color/product-color-req';
import { ProductColorResponse } from '../models/product-color/product-color-res';
import { CmxHttpClient } from '../services/api.service';
import { createHttpParams } from '../shared/helpers';

@Injectable()
export class ProductColorService {
  constructor(private http: CmxHttpClient) {}

  productColors(payload: ProductColorRequest): Observable<ProductColorResponse> {
    const httpParams = payload ? createHttpParams(payload) : new HttpParams();
    // tslint:disable-next-line:no-any
    return this.http.get<any>(`/v2/mm/productcolors`, {
      params: httpParams,
    });
  }
}
