import { IAddress } from '../address/address.interface';

export interface Plant {
  address?: IAddress;
  plantId: number;
  plantCode: string;
  plantDesc?: string;
  telephone?: string;
  contactPhone?: string;
  id?: number;
  name?: string;
  optimalSource?: boolean;
}
