import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { ApiError } from '../models/apiError';
import { EmailStatus } from '../models/emailStatus';
import { Subject, Observable, BehaviorSubject, of } from 'rxjs';

@Injectable()
export class ErrorService {
    public generalErrorSubject: Subject<any>;
    public emailErrorSubject: Subject<any>;

    private _emailStatus: BehaviorSubject<EmailStatus> = new BehaviorSubject<EmailStatus>(null);
    private _internalServerError: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor() {
        this.emailErrorSubject = new Subject();
        this.generalErrorSubject = new Subject();
    }

    get emailStatus(): EmailStatus {
        return this._emailStatus.getValue();
    }

    set emailStatus(emailStatus: EmailStatus) {
        this._emailStatus.next(emailStatus);
    }

    public resetEmailStatus(): void {
        this._emailStatus.next(null);
    }

    get internalServerError(): Observable<boolean> {
        return this._internalServerError;
    }


    /**
     * @description Trigger an event with the correct error message and code.
     */
    public handleError(error: Response | any): Observable<ApiError> {
        let err;
        let status;
        if (error instanceof Response) {
            try {
                status = error.status;
                err = error.json();
            } catch (e) {
                status = '';
                err = error;
            }
        } else {
            err = error;
        }
        let apiError: ApiError;
        let code: string;
        let errorTranslationKey: string;
        if (err.moreInformation &&
            (err.moreInformation as string).match(/[^|]*/) &&
            (err.moreInformation as string).match(/[^|]*/)[0].includes(':')) {

            const keyValueString = (err.moreInformation as string).match(/[^|]*/)[0];
            code = keyValueString.split(':')[1].trim();

        } else {
            code = err.reasonCode || err.httpCode || status || err.status;
        }
        if (code) {
            code = code.toString();
            errorTranslationKey = this.getErrorMessage(code);
        }

        if (code === 'N500' || code === '2002') {
            this._internalServerError.next(true);
            return of(null);
        }

        apiError = {
            errorCode: code,
            errorDesc: errorTranslationKey,
            technicalError: err.moreInformation ? err.moreInformation : code
        };

        return of(apiError);
    }

    public getErrorMessage(code: string): string {
        const bareCode = code.substring(0, 4);
        const contactMessage = 'Please contact your Digital Administrator';
        switch (bareCode) {
            case 'N500': return 'views.errorCodes.OutOfServiceN500';
            case 'O500': return 'views.errorCodes.OIM0500';
            case 'N213': return 'views.errorCodes.UserAlreadyExistN213';
            case 'N211': return 'views.errorCodes.UserPatchN211';
            case 'N400': return 'views.errorCodes.TokenNotFoundN400';
            case 'N401': return 'views.errorCodes.TokenExpiredN401';
            case 'N214': return 'views.errorCodes.UserInactiveN214';
            case 'O401': return 'views.login.error';
            case 'O214': return 'views.errorCodes.AccountNotExists2008';
            case 'F103': return `Not contains all parameter required when send variables to API. ${contactMessage}`;
            case 'N103': return `Not contains all parameter required when receive from .NET. ${contactMessage}`;
            case 'N403': return 'views.errorCodes.ForbiddenResourceAccessN403';
            case '500': return 'views.errorCodes.InternalServerError500';
            case '2002': return 'views.errorCodes.InternalServerError500';
            case '400': return 'views.errorCodes.BadRequest400';
            case '401': return 'views.errorCodes.AuthError401';
            case '404': return 'views.errorCodes.resourceNotFound404';
            case '409': return 'views.profile.requests.errorCancelRequest';
            default: return `General error occurred. ${contactMessage}`;
        }
    }
}
