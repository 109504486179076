import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { CwcPicker } from '@cmx-web-components/angular';
import {
  CmxDashboardActiveLoadsCardService,
  LoadingStatus
} from './cmx-dashboard-active-loads-card.service';
import { JobsiteOption, Load } from './types';
import { CountlyEvents } from '../countly-events.service';

@Component({
  selector: 'cmx-dashboard-active-loads-card',
  templateUrl: './cmx-dashboard-active-loads-card.component.html',
  styleUrls: ['./cmx-dashboard-active-loads-card.component.scss'],
  providers: [CmxDashboardActiveLoadsCardService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CmxDashboardActiveLoadsCardComponent implements OnInit, OnChanges {
  @ViewChild('dateRangePicker') datePicker: CwcPicker;
  /**
   * @var tabsLabels List of tab labels
   */
  @Input()
  tabsLabels: string[] = [];
  /**
   * @var tableColumnsLabels List of table column labels
   */
  @Input()
  tableColumnsLabels: string[] = [];
  /**
   * @var jobsites list to be shown in the picker
   */
  @Input()
  jobsites: JobsiteOption[] = [];
  /**
   * @var activeLoads list of loads to be shown in the first tab
   */
  @Input()
  activeLoads: Load[] = [];
  /**
   * @var completeLoads list of loads to be shown in the second tab
   */
  @Input()
  completeLoads: Load[] = [];
  /**
   * @var customerId legal entity id to make API requests for
   */
  @Input()
  customerId: number;
  /**
   * @var countryCode country code, used for API requests
   */
  @Input()
  countryCode: string;
  /**
   * @var emptyListText label to show in the empty result message
   */
  @Input()
  emptyListText: string;
  /**
   * @var jobsitesPlaceholder placeholder for Jobsite selection
   */
  @Input()
  jobsitesPlaceholder: string;
  /**
   * @var searchPlaceholder placeholder for search in jobsite selection dropdown
   */
  @Input()
  searchPlaceholder: string;
  /**
   * @var searchEmptyMessage message shown when no results for search in jobsite selection
   */
  @Input()
  searchEmptyMessage: string;
  /**
   * @var dateRanges date ranges selection options
   */
  @Input()
  dateRanges: Array<{
    label: string;
    from: string; // ISO String
    to: string;
    id: string;
  }>;
  /**
   * @var datePlaceholder placeholder for Date range selection
   */
  @Input()
  datePlaceholder: string;
  /**
   * @var productsLabel label for products ending string in products column
   */
  @Input()
  productsLabel: string = 'Products';
  /**
   * @var productsTableLabels labels for products table columns in the overlay
   */
  @Input()
  productsTableLabels: string[] = ['Product', 'Volume'];
  /**
   * @var emptyMessage message shown when no jobsites are available
   */
  @Input()
  emptyMessage: string;
  /**
   * @var extended if to render another two columns
   */
  @Input()
  extended: boolean = false;

  /**
   * @var pickerLabelCancel label for cancel button in picker for mobile view
   */
    @Input()
    pickerLabelCancel: string;

  // counter for date range change to help prevent emitting analytics events on initial page loading
  private counterForDateRange: number = 0;

  constructor(
    private service: CmxDashboardActiveLoadsCardService,
    private countlyEventsService: CountlyEvents
  ) {}

  ngOnInit(): void {
    if (this.customerId) {
      this.fetchData(this.customerId, this.countryCode);
    } else {
      this.service.setLoads(this.activeLoads, this.completeLoads);
      this.service.setJobsites(this.jobsites);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('customerId' in changes) {
      const customerId = changes['customerId'].currentValue;
      if (customerId) {
        this.fetchData(customerId, this.countryCode);
        if (this.datePicker) {
          this.counterForDateRange = 0;
          this.datePicker.value = this.dateRanges[0]
        }
      }
    }
  }

  private fetchData(customerId: number, countryCode: string) {
    const from = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - 7
    ).toISOString();
    const to = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    ).toISOString();
    let range = { from, to };
    if (this.dateRanges && this.dateRanges.length) {
      if (this.dateRanges[0].from && this.dateRanges[0].to) {
        range = this.dateRanges[0];
      }
    }
    this.service.fetchInit(customerId, range, countryCode);
  }

  /**
   * Fetches loads for the component new data for the tabs
   * @param jobsiteId chosen jobsite identifier
   */
  private handleJobsiteChange(jobsiteId: string) {
    if (jobsiteId && this.customerId) {
      this.service.fetchNewQueryByJobsite(parseInt(jobsiteId, 10));
    }
  }

  /**
   * returns async source for jobsite options
   */
  public get jobsites$() {
    return this.service.jobsitesToChoose$;
  }

  Status = LoadingStatus;
  /**
   * returns async source for status of component
   */
  public get status$() {
    return this.service.status$;
  }

  /**
   * returns async source for table rows
   */
  public get loads$() {
    if (this.activeTab === Tabs.Active) {
      return this.service.activeLoads$;
    } else if (this.activeTab === Tabs.Completed) {
      return this.service.completeLoads$;
    }
    throw Error(`Impossible tab name: ${this.activeTab}`);
  }

  /**
   * Handles jobsite option choice
   * @param option
   */
  public onJobsiteChange(jobsiteId: string) {
    this.handleJobsiteChange(jobsiteId);
  }

  /**
   * Fetches loads for the component new data for the tabs
   * @param param0 new date range to fetch for
   */
  private handleNewDateRange(range: { from: string; to: string }) {
    if (range && this.customerId) {
      this.service.fetchNewQueryByDateRange(range);
    }
  }

  /**
   * handle date range change
   * @param option new date range
   */
  onDateRangeChange(option: { label: string; from: string; to: string, id: string }) {
    this.handleNewDateRange(option);
    // creating event for analytics, counter could be removed when cwc version gets to 0.9.61, after that we can use cwcUserChange
    if (this.counterForDateRange > 0) {
      this.countlyEventsService.onChangeForAnalytics('complete_loads_date_range', option.id)
    }
    this.counterForDateRange++
  }

  /**
   * Gets tab translation
   * @param tab identifier of tab (name)
   * @returns according tab label from tab labels input array
   */
  tabName(tab: Tabs) {
    if (this.tabsLabels && this.tabsLabels.length) {
      if (Tabs.Active === tab) {
        return this.tabsLabels[0];
      } else if (Tabs.Completed === tab) {
        return this.tabsLabels[1];
      }
      throw Error(`Impossible tab name: ${tab}`);
    }
    throw Error(
      'Input property tabsLabels must be set to array of two strings'
    );
  }

  activeTab: Tabs = Tabs.Active;
  Tabs = Tabs;
  /**
   * Tab choice handler
   * @param value Tab name
   */
  onTabChanged(value: Tabs) {
    this.activeTab = value;
  }

  /**
   * Creating event for analytics
   * @param value Tab name
   */
  createCountlyEvent(tab: Tabs) {
    this.countlyEventsService.onChangeForAnalytics('loads_tabs_state', tab)
  }
  
  /**
   *
   * @param colIdx Column index
   * @returns column translated label taken from input parameter accordingly
   */
  columnLabel(colIdx: number) {
    if (this.tableColumnsLabels && this.tableColumnsLabels.length > colIdx) {
      return this.tableColumnsLabels[colIdx];
    }
    throw Error(
      `Input property tableColumnsLabels must be set to array of ${colIdx +
        1} strings`
    );
  }
}

enum Tabs {
  Active = 'Active',
  Completed = 'Completed'
}
