import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject } from 'rxjs';
// Models
import JobsiteDTO from '../../../../../angular-types-v1/jobsiteDTO';
import CustomerDTO from '../../../../../angular-types-v1/customerDTO';

@Injectable()
export class SidebarEvents {
    // Observable items sources
    private jobsiteSubject = new BehaviorSubject<JobsiteDTO>(null);
    private customerSubject = new BehaviorSubject<CustomerDTO>(null);
    private customersSubject = new BehaviorSubject(null);

    // Observable items streams
    private jobsite$ = this.jobsiteSubject.asObservable();
    customer$ = this.customerSubject.asObservable();

    constructor(private router: Router) { }

    selectJobsite(jobsite: JobsiteDTO, redirect = false, lock = false) {
        const currentJobsite = this.jobsiteSubject.getValue();
        // Fire only if customer differs from the selected one
        if (currentJobsite !== jobsite) {
            if (!lock) {
                this.jobsiteSubject.next(jobsite);
            }
        }

        if (jobsite && redirect) {
            this.router.navigate(['app/jobsite', jobsite.jobsiteId]);
        }
    }

    selectCustomer(customer: CustomerDTO, redirect = true) {
        const currentCustomer = this.customerSubject.getValue();

        // Fire only if customer differs from the selected one
        if (currentCustomer !== customer) {
            this.customerSubject.next(customer);
        }

        // If its not requesting an order directly
        if (redirect) {
            this.selectJobsite(null);
            this.router.navigate(['app/client-detail']);
        }
    }

    setCustomers(customers) {
        this.customersSubject.next(customers);
    }

    getJobsiteSelected() {
        return this.jobsite$;
    }

    getCustomerSelected() {
        return this.customer$;
    }

    selectCustomerByCode(code, redirect = false) {
        for (const customer of this.customersSubject.getValue()) {
            if (customer.customerCode === code) {
                this.selectCustomer(customer, redirect);
            }
        }
    }

    currentCustomer() {
        return this.customerSubject.getValue();
    }

    currentJobsite() {
        return this.jobsiteSubject.getValue();
    }
}
