import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CmxHttpClient } from '../services/api.service';
import { createHttpParams } from '../shared/helpers';

// INTERFACES
import { LegalEntityRequest } from '../models/legal-entities/legal-entity-request';
import { LegalEntityResponse } from '../models/legal-entities/legal-entity-response';

@Injectable()
export class LegalEntitiesService {
  constructor(private http: CmxHttpClient) {}

  getAllLegalEntities() {
    return this.fetchLegalEntities({
      fetch: 10000,
      page: 1,
    });
  }

  getOneLegalEntity() {
    return this.fetchLegalEntities({
      fetch: 1,
      page: 1,
    });
  }

  getFilteredLegalEntities(code: string, page: number) {
    return this.fetchLegalEntities({
      fetch: 100,
      page,
      legalEntityCode: code,
    });
  }

  fetchLegalEntities(payload: LegalEntityRequest): Observable<LegalEntityResponse> {
    const httpParams = payload ? createHttpParams(payload) : new HttpParams();
    return this.http.get<LegalEntityResponse>(`/v5/sm/mylegalentities`, {
      params: httpParams,
    });
  }
}
