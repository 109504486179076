export enum OrderStatusEnum {
  Draft = 'DRFT',
  Pending = 'PEND',
  Confirmed = 'COMP',
  InEdit = 'IPCO',
  InProgress = 'INPR',
  Delivered = 'DELV',
  Blocked = 'BLCK',
  OnHold = 'OHLD',
  Cancelled = 'CNCL',
  DeletedDraft = 'DDRF',
  NeedConfirmation = 'NECF',
  PendingCancelation = 'PCNL',
  PendingModification = 'PMOD',
  TimeConfirmation = 'TICO',
  TimeNegotiation = 'TNEG',
  ClosureRMXOnline = 'CLR',
  CompletedOnline = 'CMP',
  CancelledOnline = 'CNC',
  NotStartedOnline = 'NST',
  OnHoldOnline = 'OHD',
  BlockedOnline = 'PHD',
  InProgressOnline = 'STR',
  ToBeConfirmedOnline = 'WCL',
  CancelRequest = 'CREQ',
  Completed = 'CONF',
  Ddrf = 'DDRF',
  Necf = 'NECF',
  PendingToCancel = 'PCNL',
  PendingToModify = 'PMOD',
  Rqsd = 'RQSD',
}
