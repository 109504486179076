import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CmxSpinnerComponent } from './cmx-spinner.component';
import { CmxSpinnerDirective } from './cmx-spinner.directive';

@NgModule({
  declarations: [CmxSpinnerComponent, CmxSpinnerDirective],
  entryComponents: [CmxSpinnerComponent],
  exports: [CmxSpinnerDirective, CmxSpinnerComponent],
  imports: [CommonModule],
  providers: [],
})
export class CmxSpinnerModule {
  /* */
}

export interface ISpinnerConfiguration {
  data: any;
  template: string;
  delay: number;
}

export interface ISpinnerTags {
  please_wait: string;
  taking_longer_than_expected: string;
  error: string;
  no_connection: string;
}
