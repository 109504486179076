import { ShippingConditionsCodesTypes } from '../shipping-conditions/shipping-conditions-codes';

export interface PlantPatchRequest {
  customer: {
    customerId: number
  };
  jobsite: {
    jobsiteId: number
  };
  shippingCondition: {
    shippingConditionid: number
    shippingConditionCode: ShippingConditionsCodesTypes | string
  };
  salesArea: {
    salesAreaId: number
  };
  items: [
    {
      product: {
        productId: number
      }
      uom: {
        unitId: number
      }
    }
  ];
}
