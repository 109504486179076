import { Injectable } from '@angular/core';

@Injectable()
export class ProjectConstants {
    protected apiBasePath: string;
    protected apiOrg: string;
    protected apiEnv: string;
    private appCode: string;
    private clientId: string;
    private countlyKey?: string;
    private countlyUrl?: string;

    constructor() {
        /* tslint:disable:no-string-literal */
        this.appCode = window as { [key: string]: any }["APP_CODE"];
        this.clientId = window as { [key: string]: any }["CLIENT_ID"];
        this.apiBasePath = window as { [key: string]: any }["API_HOST"];
        this.apiOrg = window as { [key: string]: any }["API_ORG"];
        this.apiEnv = window as { [key: string]: any }["API_ENV"];
    }

    public getAppCode(): string {
        return this.appCode;
    }

    public getClientId(): string {
        return this.clientId;
    }

    public getApiBasePath(): string {
        return this.apiBasePath;
    }

    public getApiOrg(): string {
        return this.apiOrg;
    }

    public getApiEnv(): string {
        return this.apiEnv;
    }
}
