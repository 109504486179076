import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CmxHttpClient } from '../services/api.service';
import { createHttpParams } from '../shared/helpers';

// INTERFACES
import { EntityId } from '../models/common';
import { IShipmentLocationRequest } from '../models/shipment-location/shipment-location-request';
import { IShipmentLocationResponse } from '../models/shipment-location/shipment-location-response';

export class ShipmentLocationRequest implements IShipmentLocationRequest {
  public legalEntityId: any;
  public shipmentLocationId: any;
  public shipmentLocationTypeId: any;
  public shippingConditionId: any;
  public hasDocuments: any;
  public productLineId: any;
  public orderBlocked: any;
  public orderBy: any;

  constructor(shipmentLocationRequest: IShipmentLocationRequest) {
    Object.keys(shipmentLocationRequest).forEach(key => {
      this[key] = shipmentLocationRequest[key];
    });
    if (this.legalEntityId) {
      this.legalEntityId = this.format(this.legalEntityId);
    }
    if (this.shipmentLocationId) {
      this.shipmentLocationId = this.format(this.shipmentLocationId);
    }
  }

  /**
   *  According to API documentation legalEntityIds parameter should be as
   *  `${legalEntityId.entityId}.${legalEntityId.entityId}` e.g. => 5012.1
   *  5012 is LegalEntityId and the 1 that it's
   *  SHIPMENT_LOCATION_TYPE_IDType.CUSTOMER.
   *
   *
   * @return string[]
   * @ param entityId
   */
  private format(entityId: EntityId): string {
    return `${entityId.entityId}.${entityId.entityTypeId}`;
  }
}

@Injectable()
export class ShipmentLocationsService {
  constructor(private http: CmxHttpClient) {}

  getShipmentLocations(payload: IShipmentLocationRequest): Observable<IShipmentLocationResponse> {
    const request = new ShipmentLocationRequest(payload);
    const httpParams = request ? createHttpParams(request) : new HttpParams();
    return this.http.get<IShipmentLocationResponse>(`/v5/sm/myshipmentlocations`, {
      params: httpParams,
    });
  }
}
