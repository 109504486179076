import { Injectable } from '@angular/core';
import { CmxHttpClient } from '../services/api.service';
import { Observable } from 'rxjs';
import { GroupingResponse } from '../models/grouping/grouping-response';
import { GroupingRequest } from '../models/grouping/grouping-request';
import { createHttpParams } from '../shared/helpers';
import { HttpParams } from '@angular/common/http';
import { ValidateResponse } from '../models/validate/validate-res';

@Injectable()
export class GroupingService {
  constructor(private http: CmxHttpClient) {}

  patchGroup(orderId: number | string, data: GroupingRequest): Observable<GroupingResponse> {
    const httpParams = data ? createHttpParams(data) : new HttpParams();
    return this.http.patch<GroupingResponse>(`/v2/oe/offers/${orderId}/group`, {
      params: httpParams,
    });
  }

  validateOffer(orderId: number | string): Observable<ValidateResponse> {
    return this.http.patch<ValidateResponse>(`/v2/oe/offers/${orderId}/validate`, {});
  }

  patchGroupV3(orderId: number | string): Observable<GroupingResponse> {
    return this.http.patch<GroupingResponse>(`/v3/oe/offers/${orderId}/group`, {});
  }

  validateOfferV3(orderId: number | string): Observable<ValidateResponse> {
    return this.http.patch<ValidateResponse>(`/v3/oe/offers/${orderId}/validate`, {});
  }

  patchGroupV4(orderId: number | string): Observable<GroupingResponse> {
    return this.http.patch<GroupingResponse>(`/v4/oe/offers/${orderId}/group`, {});
  }
}
