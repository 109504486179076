
import {
    Component, Input, Output, EventEmitter, AfterContentInit,
    QueryList, ContentChildren, Optional, Inject, OnDestroy, Renderer2,
} from '@angular/core';
import { CmxStepComponent } from './cmx-step.component';

@Component({
    selector: 'cmx-steps',
    styleUrls: ['./../../../../../../scssv4/cmx-components/cmx-steps/v4/cmx-steps.component.scss'],
    templateUrl: './cmx-steps.component.html',
})
export class CmxStepsComponent implements AfterContentInit {
    @Output()
    public onNextClick: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    public onBackClick: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    public onStepChange: EventEmitter<number> = new EventEmitter<number>();
    @Output()
    public onCancel: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output()
    public onFinish: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input() public _lastStepButtonText: string = 'Done';
    @Input() public _nextStepButtonText: string = 'Next';
    @Input() public _exitStepButtonText: string = 'Exit';
    @Input() public _backStepButtonText: string = 'Back';
    @Input() public enabledBack: boolean = false;
    @Input() public rtl: boolean = false;
    @Input() public manualControl: boolean = false;
    @Input() public enableGoToStep: boolean = false;

    public currentStep: CmxStepComponent;
    public stepIndex: number = 0;
    public showStepsBit: boolean = false;
    @ContentChildren(CmxStepComponent) public steps: QueryList<CmxStepComponent>;
    private classWidthSteps: string = '';

    constructor(
        @Optional() @Inject('RTL') isRTL: boolean,
        private renderer: Renderer2,

    ) {
        if (isRTL != undefined) {
            this.rtl = isRTL;
        }
    }

    public ngOnDestroy(): void {
        this.renderer.destroy();
    }
    public showSteps(): boolean {
        this.showStepsBit = !this.showStepsBit;
        if (this.showStepsBit) {
            this.renderer.setStyle(document.body, '', '');
            this.renderer.setStyle(document.body, 'display', 'block');
            this.renderer.setStyle(document.body, 'position', 'fixed');
            this.renderer.setStyle(document.body, 'background-color', 'rgba(0,27,58,.8)');
            this.renderer.setStyle(document.body, 'top', '0');
            this.renderer.setStyle(document.body, 'left', '0');
            this.renderer.setStyle(document.body, 'width', '100%');
            this.renderer.setStyle(document.body, 'height', '100%');
            this.renderer.setStyle(document.body, 'z-index', '1');
            this.renderer.setStyle(document.body, 'opacity', '1');
            this.renderer.setStyle(document.body, 'pointer-events', 'none');
            this.renderer.setStyle(document.body, 'transition', 'opacity 250ms ease');
        } else {
            this.renderer.setStyle(document.body, '', 'inherit');
            this.renderer.setStyle(document.body, 'display', 'inherit');
            this.renderer.setStyle(document.body, 'position', 'inherit');
            this.renderer.setStyle(document.body, 'background-color', 'inherit');
            this.renderer.setStyle(document.body, 'top', 'inherit');
            this.renderer.setStyle(document.body, 'left', 'inherit');
            this.renderer.setStyle(document.body, 'width', 'inherit');
            this.renderer.setStyle(document.body, 'height', 'inherit');
            this.renderer.setStyle(document.body, 'z-index', 'inherit');
            this.renderer.setStyle(document.body, 'opacity', 'inherit');
            this.renderer.setStyle(document.body, 'pointer-events', 'inherit');
        }
        return this.showStepsBit;
    }
    public ngAfterContentInit(): void {
        this.currentStep = this.steps.first;
        this.currentStep.active = true;
        this.currentStep.enabled = true;
        this.onStepChange.emit(this.stepIndex);
    }

    public nextStep(): void {
        this.currentStep.onNext.emit(true);
        this.resetActive();
        const stepsArray: any = this.steps.toArray();
        this.stepIndex++;
        if (this.stepIndex < this.steps.length) {
            this.currentStep = stepsArray[this.stepIndex];
            this.currentStep.active = true;
            this.currentStep.enabled = true;
            this.onStepChange.emit(this.stepIndex);
        } else {
            this.finish();
        }
    }

    public backStep(): void {
        this.currentStep.onBack.emit(true);
        this.currentStep.enabled = false;
        this.resetActive();
        const stepsArray: any = this.steps.toArray();
        this.stepIndex = this.stepIndex === 0 ? 0 : (this.stepIndex - 1);
        this.currentStep = stepsArray[this.stepIndex];
        this.currentStep.active = true;
        this.currentStep.enabled = true;
        this.onStepChange.emit(this.stepIndex);
    }

    public goToStep(index: number): void {
        if (this.enableGoToStep) {
            const stepsArray: any[] = this.steps.toArray();
            const auxStep: any = stepsArray[index];
            if (auxStep.enabled) {
                this.resetActive();
                this.steps.forEach((step: any, stepIndex: number) => {
                    if (stepIndex > index) {
                        step.enabled = false;
                    }
                });
                this.stepIndex = index;
                this.currentStep = stepsArray[this.stepIndex];
                this.currentStep.active = true;
                this.currentStep.enabled = true;
                this.onStepChange.emit(this.stepIndex);
            }
        }
    }

    public nextClick(): void {
        if (this.manualControl) {
            this.onNextClick.emit();
        } else {
            this.nextStep();
        }
    }

    public backClick(): void {
        if (this.manualControl) {
            this.onBackClick.emit();
        } else {
            this.backStep();
        }
    }

    public cancel(): void {
        this.stepIndex = 0;
        this.steps.forEach((step: any) => {
            step.active = false;
            step.done = false;
            step.enabled = false;
        });
        this.currentStep = this.steps.first;
        this.currentStep.active = true;
        this.currentStep.enabled = true;
        this.onStepChange.emit(this.stepIndex);
        this.onCancel.emit(true);
    }

    public finish(): void {
        this.onFinish.emit(true);
    }

    private resetActive(): void {
        this.steps.forEach((step: any) => {
            step.active = false;
        });
    }

}
