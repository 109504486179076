<div class="cmx-header-cell__text" (click)="toggle()">
    <div class="text-container">
        <ng-content></ng-content>
        <ng-container *ngIf="checkboxFilters || datepickerFilter || filterSorter">
            <div class="cmx-icon__container">
                <span class="cmx-icon-down-single" aria-hidden="true"></span>
            </div>
        </ng-container>
    </div>
</div>
<ng-container *ngIf="showFilters">
    <div #filtersContainer class="cmx-header-cell__filters-container">
        <ng-content select="cmx-checkbox-filters,cmx-datepicker-filter,cmx-filter-sorter"></ng-content>
    </div>
    <div class="cmx-header-cell__mask" (click)="closeFilters()"></div>
</ng-container>