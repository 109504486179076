import { Injectable } from '@angular/core';

@Injectable()
export class DateFormatterService {
  constructor() {
    //
  }

  /**
   * @description Formats a date object to a string given a date format from a language
   * @param value The date value to format
   * @param format The format to comply, e.x. DD MM, YYYY
   * @param daysNames String array of the weekdays' names
   * @param monthsNames String array of the months' names
   * @returns String of the formatted date
   */
  public format(
    $value: Date,
    $format: string,
    $daysNames: string[],
    $monthsNames: string[]
  ): string {
    let dateAsString = '';
    let $separator = '/';
    let day: string;
    let month: string;
    let year: string;
    let $formats = $format.split($separator);
    if ($formats.length === 1) {
      $separator = '-';
      $formats = $format.split($separator);
    }
    if ($formats.length === 1) {
      $separator = ' ';
      $formats = $format.split($separator);
    }
    if ($formats.length === 1) {
      $separator = '.';
      $formats = $format.split($separator);
    }
    $formats.forEach(($formatItem, $index) => {
      const $auxValue: any = $value;
      const $formatCode = $formatItem.replace(',', '');
      if ($formatItem.includes('d') || $formatItem.includes('D')) {
        day = this.getDay($auxValue, $formatCode, $daysNames);
        $formatItem = $formatItem.replace($formatCode, day);
      } else if ($formatItem.includes('m') || $formatItem.includes('M')) {
        month = this.getMonth($auxValue, $formatCode, $monthsNames);
        $formatItem = $formatItem.replace($formatCode, month);
      } else if ($formatItem.includes('y') || $formatItem.includes('Y')) {
        year = this.getYear($auxValue, $formatCode);
        $formatItem = $formatItem.replace($formatCode, year);
      }
      if ($index !== $formats.length - 1) {
        dateAsString += $formatItem + $separator;
      } else {
        dateAsString += $formatItem;
      }
    });
    return dateAsString;
  }

  /**
   * @description Transforms a valid string to a date object
   * @param date The date as string
   * @returns A javascript date object
   */
  public getDateObject(date: string): Date {
    try {
      const auxDate = date.replace(/-g/, '/');
      const bits: any = auxDate.split(/\D/);
      const month = parseInt(bits[1], undefined) - 1;
      if (bits.length === 3) {
        return new Date(bits[0], month, bits[2]);
      } else {
        return new Date(bits[0], month, bits[2], bits[3], bits[4], bits[5]);
      }
    } catch ($exception) {
      return undefined;
    }
  }

  private addZero($number: number): string {
    return $number < 10 ? '0' + $number : $number.toString();
  }

  private getDay($date: Date, $format: string, $daysNames: string[]): string {
    let day: string;
    if ($format.length === 4) {
      // requires the complete name of the day
      day = $daysNames[$date.getDay()];
    } else if ($format.length === 3) {
      // requires an abbreviation of the day's name
      day = $daysNames[$date.getDay()].substring(0, 3);
    } else if ($format.length === 2 || $format.length === 1) {
      // requires the value of the day
      day = this.addZero($date.getDate());
    }
    // check if names should use lower or uppercase
    if ($format === $format.toLowerCase()) {
      // the format uses d
      day = day.toLowerCase();
    }
    return day;
  }

  private getMonth(
    $date: Date,
    $format: string,
    $monthsNames: string[]
  ): string {
    let month: string;
    if ($format.length === 4) {
      // requires the complete name of the month
      month = $monthsNames[$date.getMonth()];
    } else if ($format.length === 3) {
      // requires an abbreviation of the day's name
      month = $monthsNames[$date.getMonth()].substring(0, 3);
    } else if ($format.length === 2) {
      // requires the value of the day
      month = this.addZero($date.getMonth() + 1);
    }
    // check if names should use lower or uppercase
    if ($format === $format.toLowerCase()) {
      // the format uses d
      month = month.toLowerCase();
    }
    return month;
  }

  private getYear($date: Date, $format: string): string {
    let year: string;
    if ($format.length === 4) {
      // requires the complete year
      year = '' + $date.getFullYear();
    } else {
      // requires only the last 2 numbers of the year value
      year = '' + $date.getFullYear();
      year = year.substring(2);
    }
    return year;
  }
}
