import {
    Component,
    Input,
    HostBinding,
    Directive,
    HostListener,
    Host,
    Optional,
    Inject,
    Renderer2,
} from '@angular/core';

import {
    Subject,
    Observable,
} from 'rxjs';

import { CmxDialogService } from './../../services/cmx-dialog.service';

@Directive({
    // tslint:disable-next-line
    selector: '[data-dismiss-dialog]',
})
export class DataDismissDialogDirective {

    constructor(
        @Host() private dialog: CmxDialogComponent,
    ) { }

    @HostListener('click', ['$event']) public onClick($event: any): void {
        if (this.dialog) {
            this.dialog.close();
        }
    }
}

export interface IDialogOptions {
    afterClosed?: any;
    afterOpened?: any;
}
// tslint:disable-next-line:max-classes-per-file
@Component({
    selector: 'cmx-dialog',
    styleUrls: ['./../../../../../../../../../../scssv4/cmx-components/cmx-dialog/v7/cmx-dialog.component.scss'],
    templateUrl: './cmx-dialog.component.html',
})
export class CmxDialogComponent {
    @HostBinding('attr.dir') public dirAttr = 'auto';

    @Input()
    get rtl(): boolean {
        return this._rtl;
    }
    set rtl($value: boolean) {
        if ($value !== undefined) {
            this.dirAttr = $value ? 'rtl' : 'ltr';
            this._rtl = $value;
        }
    }

    @Input() public width = '784px';
    @Input() public minWidth = 'auto';
    @Input() public maxWidth = '784px';
    @Input() public height = '510px';
    @Input() public minHeight = 'auto';
    @Input() public maxHeight = '80%';
    @Input() public backgroundColor = '#6E6E6E';
    @Input() public enableClose = true;
    @Input() public enableHeader = false;
    @Input() public enableFooter = false;

    public showDialog = false;
    private afterOpenedSubject: Subject<boolean> = new Subject<boolean>();
    private afterOpened$: Observable<boolean> = this.afterOpenedSubject.asObservable();
    private afterClosedSubject: Subject<boolean> = new Subject<boolean>();
    private afterClosed$: Observable<boolean> = this.afterClosedSubject.asObservable();
    private _rtl: boolean;

    constructor(
        private _renderer: Renderer2,
        private _cmxDialogService: CmxDialogService,
        @Optional() @Inject('RTL') isRTL: boolean,
    ) {
        if (isRTL !== undefined) {
            this.rtl = isRTL;
        }
    }

    public open(): void {
        this._cmxDialogService.addDialog(this);
        this.show();
        this.afterOpenedSubject.next(true);
    }

    public afterOpened(): Observable<boolean> {
        return this.afterOpened$;
    }

    public close(showLastDialog: boolean = true): void {
        this._cmxDialogService.removeDialog(this, showLastDialog);
        this.hide();
        this.afterClosedSubject.next(true);
    }

    public hide(): void {
        this.enableBackgroundScroll(true);
        this.showDialog = false;
    }

    public show(): void {
        this.enableBackgroundScroll(false);
        this.showDialog = true;
    }

    public closeAllDialogs(): void {
        this._cmxDialogService.closeAllDialogs();
    }

    public afterClosed(): Observable<boolean> {
        return this.afterClosed$;
    }

    public returnFalse(): boolean {
        return false;
    }

    private enableBackgroundScroll(value: boolean): void {
        if (value) {
            this._renderer.setStyle(document.getElementsByTagName('BODY')[0], 'overflow', '');
        } else {
            this._renderer.setStyle(document.getElementsByTagName('BODY')[0], 'overflow', 'hidden');
        }
    }
}
