import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';

import { CmxRadioModule } from '../../../../../../cmx-radio/v4/src/cmx-radio.module';
import { CmxButtonModule } from '../../../../../../cmx-button/v4/src/cmx-button.module';
import { CmxTooltipModule } from '../../../../../../cmx-tooltip/v4/src/cmx-tooltip.module';

import { CmxSidebarComponent } from './components/cmx-sidebar.component';
import { MenuService } from './services/menu.service';
import { ReplaceStringPipe } from './components/replaceString.pipe';
import { ClickOutsideDirective } from './components/click-outside.directive';
import { FilterLegalEntity } from './components/cmx-filter-entity.pipe';
import { CmxWebComponentsModule } from '@cmx-web-components/angular';
import { CopyrightService } from './services/copyright.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    CmxRadioModule,
    CmxButtonModule,
    CmxTooltipModule,
    CmxWebComponentsModule.forRoot()
  ],
  exports: [CmxSidebarComponent, FilterLegalEntity, ReplaceStringPipe],
  declarations: [CmxSidebarComponent, FilterLegalEntity, ClickOutsideDirective, ReplaceStringPipe],
  providers: [MenuService,CopyrightService],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
  ],
})
export class CmxSidebarModule {}
