import { BusinessLine } from '../business-lines/business-line';
import { Link } from '../common';

export interface ISalesArea {
  businessLine?: BusinessLine;
  channelCode?: string;
  countryCode?: string;
  customerId?: number;
  divisionCode?: string;
  divisionDesc?: string;
  salesAreaId?: number;
  salesOrganizationCode?: string;
  salesOrganizationDesc?: string;
  termsAndConditionDocumentCreationDate?: string; /* actually date-time string */
  // tslint:disable-next-line:no-any
  links?: Link | Link[] | any;
}
