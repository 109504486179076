import { Component, Input, Output, EventEmitter, HostBinding } from '@angular/core';

@Component({
    selector: 'cmx-step',
    styleUrls: [ './../../../../../../scssv4/cmx-components/cmx-steps/v4/cmx-steps.component.scss'],
    template: "<ng-content *ngIf='active'></ng-content>",
})
export class CmxStepComponent {
    @Output() public onDone: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public onNext: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public onBack: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() public title: string = '';
    public active: boolean = false;
    public enabled: boolean = false;
    private _done: boolean = false;
    // inputs
    @Input()
    set done(value: boolean) {
        if (value !== undefined) {
            this._done = value;
        }
        if (this._done === true) {
            this.onDone.emit(true);
        }
    }
    get done(): boolean {
        return this._done;
    }
}
