<div class="cmx-spinner text-center" [class.spinner-positioner]="size === 'Full'" *ngIf="isLoading">
  <ng-container *ngIf="error; else loadingTemplate">
    <span class="{{ icon }}" aria-hidden="true" [style]="errorSignStyle ? errorSignStyle : ''"></span>
  </ng-container>
  <ng-template #loadingTemplate>
    <svg
      [ngClass]="sizeClass"
      class="spinner-svg"
      height="24"
      viewbox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>spinner</title>

      <g data-name="Layer_1" class="spinner-group">
        <path
          d="M5.54217,14.08524A6.76819,6.76819,0,0,1,5.2675,12.864L3.37023,11.0987,1.5,12.982a10.47954,10.47954,0,0,0,4.29864,7.51612l-.3387-2.65327L7.98207,17.446A6.71757,6.71757,0,0,1,5.54217,14.08524Z"
          data-name="Tile_5"
          class="tile5"
        ></path>
        <path
          d="M15.93072,17.47448a6.77729,6.77729,0,0,1-5.20663,1.15862L8.45662,19.88716,9.67379,22.2437a10.53325,10.53325,0,0,0,8.49473-1.7571l-2.63646-.499Z"
          data-name="Tile_4"
          class="tile4"
        ></path>
        <path
          d="M21.02336,17.35435l-2.62571.42734-.49391-2.53532a6.71522,6.71522,0,0,0,.505-5.3324l0-.00011,2.27286,1.1552L21.9713,8.72269l.01155.03263A10.45038,10.45038,0,0,1,21.02336,17.35435Z"
          data-name="Tile_3"
          class="tile3"
        ></path>
        <path
          d="M12.00156,5.2497a6.79087,6.79087,0,0,1,4.89081,2.11662l2.57528.31549.40353-2.62032A10.561,10.561,0,0,0,11.96281,1.5l1.84053,1.95158Z"
          data-name="Tile_2"
          class="tile2"
        ></path>
        <path
          d="M5.54414,9.9154A6.74589,6.74589,0,0,1,9.07591,5.90232l1.09618-2.34551L7.79754,2.36336A10.49148,10.49148,0,0,0,1.96173,8.78685L4.385,7.6461Z"
          data-name="Tile_1"
          class="tile1"
        ></path>
      </g>
    </svg>
  </ng-template>
  <div
    *ngIf="size !== 'Small'"
    [ngClass]="{
      'cmx-spinner__message-moderate': size === 'Moderate',
      'cmx-spinner__message-full': size === 'Full',
      'cmx-spinner__message-big': size === 'Big'
    }"
  >
    <p class="cmx-spinner__message" *ngIf="waitMessage" [innerHTML]="waitMessage"></p>
  </div>
</div>
<div class="spinner-mask" *ngIf="isLoading && size === 'Full'"></div>
