export class Request {
  public authorizationRequestId?: number;
  public userFullName?: string;
  public payload?: string;
  public requestId: number;
  public userId: number;
  public requesterName: string;
  public customerDesc: string;
  public requestDate: any;
  public requestType: 'R' | 'D';
  public requestTypeDesc: string;
  public authorizedBy: number;
  public authorizedByFullName: string;
  public notes: string;
  public status: string;
  public statusDesc: string;
  public customerId: number;
  public details: any;
}
