
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Region, City } from '../models';
import { CmxHttpClient } from './http-client.service';

@Injectable()
export class RegionalZoneService {

    constructor(private cmxHttp: CmxHttpClient) { }

    updateZones(userId: number, payload: any) {
        const uri = `v6/secm/users/${userId}/zones`;
        return this.cmxHttp.patch(uri, { userId, zones: payload });
    }

    addZones(userId: number, payload: any) {
        const uri = `v6/secm/users/${userId}/zones`;
        return this.cmxHttp.post(uri, { userId, zones: payload });
    }

    getUserZones(userId: number) {
        const uri = `v6/secm/users/${userId}/zones`;
        return this.cmxHttp.get(uri);
    }

    getRegionsByCustomer(customerId: number) {
        const uri = `v7/dm/regions?customerId=${customerId}`;
        return this.cmxHttp.get<Region[]>(uri);
    }

    getCitiesByRegion(customerId: number, regionId: number) {
        const uri = `v7/dm/cities?customerId=${customerId}&regionId=${regionId}`;
        return this.cmxHttp.get<City[]>(uri);
    }

    getCitiesAndJobsitesByRegion(customerId: number, regionId: number, cityDesc?: string) {
        let uri = `v7/dm/jobsites?process=REGIONALZONES&customerId=${customerId}&regionId=${regionId}&include=addresses`;
        if (cityDesc) {
            uri = uri + `& cityDesc=${cityDesc}`;
        }
        return this.cmxHttp.get(uri).pipe(map((res) => res as {[key: string]: any} ['jobSites']));
    }
}
