// Temporal service.
// This be will replace by language config data.

import { Injectable } from '@angular/core';

@Injectable()
export class LocaleService {
  constructor() {
    // empty
  }

  public getConfiguration(languageISO: any) {
    switch (languageISO) {
      case 'en':
        return {
          hourShort: 'hr',
          minuteShort: 'min',
          monthsShort: 'Jan,Feb,Mar,Apr,May,Jun,Jul,Aug,Sep,Oct,Nov,Dec',
          secondShort: 'sec',
          weekdaysMin: 'Su,Mo,Tu,We,Th,Fr,Sa',
          weekdaysShort: 'Sun,Mon,Tue,Wed,Thu,Fri,Sat'
        };
      case 'es':
        return {
          hourShort: 'hr',
          minuteShort: 'min',
          monthsShort: 'Ene,Feb,Mar,Abr,May,Jun,Jul,Ago,Sep,Oct,Nov,Dic',
          secondShort: 'seg',
          weekdaysMin: 'Do,Lu,Ma,Mi,Ju,Vi,Sá',
          weekdaysShort: 'Dom,Lun,Mar,Mié,Jue,Vie,Sáb'
        };
      case 'fr':
        return {
          hourShort: 'h',
          minuteShort: 'min',
          monthsShort: 'Janv,Févr,Mars,Avr,Mai,Juin,Juil,Août,Sept,Oct,Nov,Déc',
          secondShort: 'sec',
          weekdaysMin: 'Di,Lu,Ma,Me,Je,Ve,Sa',
          weekdaysShort: 'Dim,Lun,Mar,Mer,Jeu,Ven,Sam'
        };
      case 'de':
        return {
          hourShort: 'Std.',
          minuteShort: 'Min.',
          monthsShort: 'Jan,Febr,Mrz,Apr,Mai,Jun,Jul,Aug,Sept,Okt,Nov,Dez',
          secondShort: 'Sekunde',
          weekdaysMin: 'So,Mo,Di,Mi,Do,Fr,Sa',
          weekdaysShort: 'So,Mo,Di,Mi,Do,Fr,Sa'
        };
      case 'he':
        return {
          hourShort: 'שעה',
          minuteShort: 'דקות',
          monthsShort:
            'ינו׳,פבר׳,מרץ,אפר׳,מאי,יוני,יולי,אוג׳,ספט׳,אוק׳,נוב׳,דצמ׳',
          secondShort: 'שנייה',
          weekdaysMin: 'א,ב,ג,ד,ה,ו,ש',
          weekdaysShort: 'א׳,ב׳,ג׳,ד׳,ה׳,ו׳,ש׳'
        };
      case 'pl':
        return {
          hourShort: 'h',
          minuteShort: 'min',
          monthsShort: 'Sty,Lut,Mar,Kwi,Maj,Cze,Lip,Sie,Wrz,Paź,Lis,Gru',
          secondShort: 's',
          weekdaysMin: 'Nd,Pn,Wt,Śr,Cz,Pt,So',
          weekdaysShort: 'Ndz,Pon,Wt,Śr,Czw,Pt,Sob'
        };
      default:
        return {
          hourShort: 'hr',
          minuteShort: 'min',
          monthsShort: 'Jan,Feb,Mar,Apr,May,Jun,Jul,Aug,Sep,Oct,Nov,Dec',
          secondShort: 'sec',
          weekdaysMin: 'Su,Mo,Tu,We,Th,Fr,Sa',
          weekdaysShort: 'Sun,Mon,Tue,Wed,Thu,Fri,Sat'
        };
    }
  }
}
