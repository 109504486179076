import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// cmx
import { CmxTooltipComponent } from './components/';
import { CmxTooltipDirective } from './directives';

@NgModule({
    declarations: [
        CmxTooltipComponent,
        CmxTooltipDirective,
    ],
    entryComponents: [
        CmxTooltipComponent,
    ],
    exports: [
        CmxTooltipDirective,
    ],
    imports: [
        CommonModule,
    ],
})
export class CmxTooltipModule {
    // tslint:disable-next-line:no-empty
    constructor() {
    }
}
