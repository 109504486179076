import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductLineCodesTypes } from '../models/business-lines/product-lines-codes';
import {
  GetProjectDocumentsRequest,
  GetProjectDocumentsResponse,
  GetProjectItemsRequest,
  GetProjectItemsResponse,
  GetProjectLotsRequest,
  GetProjectLotsResponse,
  GetProjectsRequest,
  GetProjectsResponse,
  Project,
  PROJECT_TYPE_CODE,
} from '../models/project-management';
import { CmxHttpClient } from '../services/api.service';
import { createHttpParams } from '../shared/helpers';

@Injectable()
export class ProjectManagementService {
  constructor(private http: CmxHttpClient) {}

  getProjects(reqPayload: GetProjectsRequest): Observable<GetProjectsResponse> {
    const params = reqPayload ? createHttpParams(reqPayload) : new HttpParams();
    return this.http.get(`/v6/sm/projects`, { params });
  }

  getProjectById(request: {
    projectId: number,
    productLineCode: ProductLineCodesTypes,
    projectTypeCode?: PROJECT_TYPE_CODE,
  }): Observable<Project> {
    const projectId = request.projectId;
    delete request.projectId;
    const params = request ? createHttpParams(request) : new HttpParams();
    return this.http.get(`/v6/sm/projects/${projectId}`, {
      params,
    });
  }

  getProjectDocuments(request: GetProjectDocumentsRequest): Observable<GetProjectDocumentsResponse> {
    const projectId = request.projectId;
    delete request.projectId;
    const params = request ? createHttpParams(request) : new HttpParams();
    return this.http.get(`/v6/sm/projects/${projectId}/documents`, {
      params,
    });
  }

  getProjectLots(request: GetProjectLotsRequest): Observable<GetProjectLotsResponse> {
    const projectId = request.projectId;
    delete request.projectId;
    const params = request ? createHttpParams(request) : new HttpParams();
    return this.http.get(`/v6/sm/projects/${projectId}/lots`, {
      params,
    });
  }

  getProjectItems(request: GetProjectItemsRequest): Observable<GetProjectItemsResponse> {
    const projectId = request.projectId;
    delete request.projectId;
    const params = request ? createHttpParams(request) : new HttpParams();
    return this.http.get(`/v6/sm/projects/${projectId}/items`, {
      params,
    });
  }
}
