import { Component, OnInit, Input, Output, EventEmitter, HostBinding, Optional, Inject } from "@angular/core";
import { IDay, IRange, IDisplayedDate } from "./../../models/datepicker-interfaces";
import { DateNamesService } from "./../../services/date-names.service";
import { DatepickerTags } from "./../../models/datepicker.classes";
import { Day } from "./../../models/day.class";
import { DatepickerWeekDays } from "../../models/datepicker-week-days.enum";
import { DatepickerLabel } from "../../models/datepicker-label";

@Component({
  selector: "cmx-datepicker",
  styleUrls: ["./../../../../../../../scssv4/cmx-components/cmx-datepicker/v4/cmx-datepicker.component.scss"],
  templateUrl: "./cmx-datepicker.component.html",
})
export class CmxDatepickerComponent implements OnInit {
  @HostBinding("attr.dir") public dirAttr: string = "auto";
  @Input()
  set labels(labels: DatepickerLabel[]) {
    if (labels && labels.length) {
      this._labels = labels;
      // flat optimization
      this.datesWithLabel = [];
      labels.forEach((label: DatepickerLabel) => {
        label.dates.forEach((labelDate: Date) => {
          this.datesWithLabel.push({
            color: label.cssColor,
            date: labelDate,
          });
        });
      });
    }
  }
  get labels(): DatepickerLabel[] {
    return this._labels;
  }

  @Input()
  set tags($value: DatepickerTags) {
    if ($value != undefined && $value instanceof DatepickerTags) {
      this._tags = $value;
      this.dateNames.months = $value.months;
      this.dateNames.monthsShort = $value.monthsShort;
      this.dateNames.daysShort = $value.daysShort;
    }
  }
  get tags(): DatepickerTags {
    return this._tags;
  }

  @Input()
  get max(): Date {
    return this._max;
  }
  set max($value: Date) {
    this._max = $value !== undefined ? $value : this.MAX_DATE;
    if (this.currentDate && this.range) {
      this.redrawCalendar();
    }
    this.updatePermissionsForMonthMoveButtons();
  }

  @Input()
  get min(): Date {
    return this._min;
  }
  set min($value: Date) {
    this._min = $value !== undefined ? $value : this.MIN_DATE;
    if (this.currentDate && this.range) {
      this.redrawCalendar();
    }
    this.updatePermissionsForMonthMoveButtons();
  }

  @Input()
  get forbiddenWeekDays(): number[] {
    return this._forbiddenWeekDays;
  }
  set forbiddenWeekDays($value: number[]) {
    if ($value != undefined) {
      this._forbiddenWeekDays = $value;
    }
  }

  @Input()
  get disabledDates(): Date[] {
    return this._disabledDates;
  }
  set disabledDates($value: Date[]) {
    if ($value !== undefined) {
      this._disabledDates = $value;
    }
  }

  @Input() public singleDateSelection: boolean = false;

  @Input()
  get datepickerId(): string {
    return this._datepickerId;
  }
  set datepickerId(value: string) {
    if (value !== undefined) {
      this._datepickerId = value;
      this.componentID = this._datepickerId;
    } else {
      this.componentID = "";
    }
  }

  @Input()
  get startWeekWith(): DatepickerWeekDays {
    return this._startWeekWith;
  }
  set startWeekWith(value: DatepickerWeekDays) {
    if (value !== undefined && value !== null) {
      this._startWeekWith = value;
      this.weekdays = this.dateNames.getWeekdays(value);
    }
  }

  @Input()
  get value(): any {
    return this._value;
  }
  set value(dateValue: any) {
    if (dateValue) {
      this._value = dateValue;
      if (this._value.min && this._value.max) {
        // a range was given
        try {
          let min: Date;
          let max: Date;
          min = this._value.min;
          max = this._value.max;
          this.currentDate = min;
          this.calculateDays();
          this.selectDate(this.findDay(min));
          const aux: IDay = {
            date: max,
            disabled: false,
            number: max.getDate(),
            range: false,
            selected: false,
          };
          this.selectDate(aux);
        } catch (exception) {
          console.error("No valid date was given", exception);
        }
      } else {
        // single date
        try {
          let param: Date;
          param = this._value;
          this.currentDate = param;
          this.calculateDays();
          this.selectDate(this.findDay(param), false);
        } catch (exception) {
          console.error("No valid date was given", exception);
        }
      }
    } else {
      this._value = undefined;
      this.reset();
    }
  }
  @Input()
  get rtl(): boolean {
    return this._rtl;
  }
  set rtl(value: boolean) {
    this._rtl = value;
    this.dirAttr = value ? "rtl" : "ltr";
  }

  @Input()
  get buttonless(): boolean {
    return this._buttonless;
  }
  set buttonless(value: boolean) {
    this._buttonless = value;
  }

  @Output() public select: EventEmitter<any> = new EventEmitter<any>();
  @Output() public cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() public dayClicked: EventEmitter<any> = new EventEmitter<any>();

  @HostBinding("id") public componentID: string;

  get currentDate(): Date {
    return this.displayedDate.date;
  }
  set currentDate(date: Date) {
    if (!this.displayedDate) {
      this.displayedDate = {};
    }

    if (!date) {
      date = this.displayedDate.date || this.min || new Date();
    }

    this.displayedDate.date = date;

    this.displayedDate.monthName = this.dateNames.getMonthName(date.getMonth());
    this.displayedDate.fullYear = date.getFullYear();

    this.updatePermissionsForMonthMoveButtons();
  }

  public action: string = "calendarView";
  public weekdays: string[] = [];
  public range: IRange = { min: undefined, max: undefined };
  public numberOfClicks: number = 0;
  public displayedDate: IDisplayedDate = {};
  public disableDone: boolean = true;
  public yearsRange: any[] = [];
  public weeks: Day[][] = [];
  public today: Date;
  public _labels: DatepickerLabel[] = [];
  public datesWithLabel: Array<{ color: string; date: Date }> = []; // optimization
  private _value: any;
  private _datepickerId: string;
  private _max: Date;
  private _min: Date;
  private MIN_DATE: Date = new Date(1990, 0, 1);
  private MAX_DATE: Date = new Date(2050, 11, 31);
  private _rtl: boolean = false;
  private _tags: DatepickerTags;
  private _startWeekWith: number;
  private _forbiddenWeekDays: number[];
  private _disabledDates: Date[];
  private _buttonless: boolean = false;

  constructor(
    @Optional() @Inject("CMX_DATEPICKER_WEEK_FIRST_DAY") public weekFirstDayProviderValue: DatepickerWeekDays,
    @Optional() @Inject("CMX_DATEPICKER_TAGS") public tagsProviderValue: DatepickerTags,
    private dateNames: DateNamesService,
    @Optional() @Inject("RTL") isRTL: boolean,
  ) {
    if (isRTL != undefined) {
      this.rtl = isRTL;
    }
    this.setToday();
  }

  public ngOnInit(): void {
    if (this.tagsProviderValue != undefined && this.tags == undefined) {
      try {
        const newTags: DatepickerTags = new DatepickerTags(this.tagsProviderValue.cancel, this.tagsProviderValue.apply);
        this.tags = newTags;
      } catch ($exception) {
        console.warn("DatepickerTags provider requires 'cancel' and 'apply' members");
      }
    }
    if (this.startWeekWith !== undefined) {
      this.weekdays = this.dateNames.getWeekdays(this.startWeekWith);
    } else if (this.weekFirstDayProviderValue !== undefined) {
      this.weekdays = this.dateNames.getWeekdays(this.weekFirstDayProviderValue);
    }
    if (this._min === undefined) {
      this.min = this.MIN_DATE;
    }
    if (this._max === undefined) {
      this.max = this.MAX_DATE;
    }
    if (this._value === undefined) {
      this.reset();
    }
  }

  public reset(): void {
    this.currentDate = new Date();
    this.calculateDays();
    this.numberOfClicks = 0;
  }

  public AfterViewInit(): void {
    this.calculateDays();
  }

  public userSelectedSomething(): boolean {
    let answer: boolean = false;
    if (this.range.min && this.range.max) {
      answer = true;
    } else if (this.range.min) {
      answer = true;
    }
    return answer;
  }

  public cancelSelection(): void {
    this.cancel.emit(undefined);
    this.reset();
  }

  public applyClicked(): void {
    this.select.emit(this.setSelection());
    this.reset();
  }

  public moveMonth(step: number): void {
    let newCurrentDate: Date = new Date(this.currentDate.valueOf());
    const newMonth: number = newCurrentDate.getMonth() + step;
    newCurrentDate.setMonth(newCurrentDate.getMonth() + step);

    /**
     * Check if month was set correctly.
     * If previous currentDate"s month has more days than new it"s necessary to set the last day of new month.
     */
    if (newCurrentDate.getMonth() !== newMonth) {
      newCurrentDate = new Date(this.currentDate.valueOf());
      newCurrentDate.setMonth(newCurrentDate.getMonth() + step + 1, 0);
    }
    this.currentDate = newCurrentDate;
    this.redrawCalendar();
  }

  public monthToggle(): void {
    this.action = this.action === "monthView" ? "calendarView" : "monthView";
  }

  public yearToggle(): void {
    this.action = this.action === "yearView" ? "calendarView" : "yearView";
  }

  public updateCurrentMonth(monthEvent: number): void {
    const newCurrentDate: Date = new Date(this.currentDate.valueOf());
    newCurrentDate.setMonth(monthEvent, 1);
    this.currentDate = newCurrentDate;
    this.redrawCalendar();
    this.action = "calendarView";
  }

  public updateCurrentYear(yearEvent: number): void {
    const newCurrentDate: Date = new Date(this.currentDate.valueOf());
    newCurrentDate.setFullYear(yearEvent);
    this.currentDate = newCurrentDate;
    this.redrawCalendar();
    this.action = "calendarView";
  }

  public updateActionView(actionViewEvent: string): void {
    this.action = actionViewEvent;
  }

  public selectDate(day: Day, emitEvent: boolean = true): void {
    if (day && day.disabled === false) {
      this.numberOfClicks += 1;
      const date: Date = new Date(day.date.getFullYear(), day.date.getMonth(), day.number);
      const endOfDayDate: Date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
      if (this.numberOfClicks === 1) {
        this.range.min = date;
        this.range.max = endOfDayDate;
        if (this.singleDateSelection) {
          this.numberOfClicks = 0;
          if (this.buttonless && emitEvent) {
            this.select.emit(this.setSelection());
          }
        }
      } else if (this.numberOfClicks === 2) {
        const dateTimestamp: number = date.getTime();
        const minDateTimestamp: number = this.range.min.getTime();
        if (dateTimestamp >= minDateTimestamp) {
          this.range.max = endOfDayDate;
        } else {
          this.range.max = this.range.min;
          this.range.min = date;
        }
        this.numberOfClicks = 0;
      }
      this.dayClicked.emit(this.setSelection());
      this.styleDaysRangeSelection(this.range);
    }
  }

  public getDateLabel(date: Date): { color: string; date: Date } {
    const isSameDay = (aDate: Date, otherDate: Date): boolean => {
      return (
        aDate.getFullYear() === otherDate.getFullYear() &&
        aDate.getMonth() === otherDate.getMonth() &&
        aDate.getDate() === otherDate.getDate()
      );
    };

    return this.datesWithLabel.find((label: { color: string; date: Date }) => isSameDay(label.date, date));
  }

  private redrawCalendar(): void {
    this.setToday();
    this.calculateDays();
    this.styleDaysRangeSelection(this.range);
  }

  private findDay(date: Date): Day {
    let dayFound: Day;
    this.weeks.forEach((week: Day[]) => {
      week.forEach((day: Day) => {
        if (day && day.date && day.number) {
          if (
            day.date.getDate() === date.getDate() &&
            day.date.getMonth() === date.getMonth() &&
            day.date.getFullYear() === date.getFullYear()
          ) {
            dayFound = day;
          }
        }
      });
    });
    return dayFound;
  }

  private getFirstDay(month: number, year: number): number {
    const dayOfWeek: number = new Date(year, month, 1).getDay();
    return dayOfWeek;
  }

  /**
   * @description Formats a date into a number for easier comparisons.
   * @param date A javascript date object
   * @return A number representing the date
   */
  private getFormattedDate(date: Date): number {
    if (!date) {
      return;
    }
    let month: string = "" + (date.getMonth() + 1);
    let day: string = "" + date.getDate();
    const year: string = "" + date.getFullYear();
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    return Number([year, month, day].join(""));
  }

  /**
   * @desscription Calcutates the number of days in a month and
   * creates an array to represent the distribution of the dates between the weeks.
   */
  private calculateDays(): void {
    const currentMonth: number = this.currentDate.getMonth();
    const currentYear: number = this.currentDate.getFullYear();
    const minFormattedDate: number = this.getFormattedDate(this.min);
    const maxFormattedDate: number = this.getFormattedDate(this.max);
    const totalDays: number = new Date(currentYear, currentMonth + 1, 0).getDate(); // get the number of days in the month
    let dayCounter: number = 1;
    const daysInMonth: Day[] = [];
    let firstDayOfWeekIndex: number; // get the day value of the first day of the week
    while (dayCounter <= totalDays) {
      const auxDate: Date = new Date(currentYear, currentMonth, dayCounter);
      if (dayCounter === 1) {
        firstDayOfWeekIndex = auxDate.getDay();
      }
      const newDay: Day = new Day(auxDate, dayCounter);
      // check if date should be disabled
      const formattedNewDayDate: number = this.getFormattedDate(newDay.date);
      if (
        (minFormattedDate !== null && formattedNewDayDate < minFormattedDate) ||
        (maxFormattedDate !== null && formattedNewDayDate > maxFormattedDate)
      ) {
        newDay.disabled = true;
      } else if (this.forbiddenWeekDays !== undefined && this.forbiddenWeekDays.indexOf(newDay.date.getDay()) > -1) {
        newDay.disabled = true;
      } else if (this.disabledDates !== undefined) {
        this.disabledDates.forEach((disabledDate: Date) => {
          const disabledFormattedDate: number = this.getFormattedDate(disabledDate);
          if (formattedNewDayDate === disabledFormattedDate) {
            newDay.disabled = true;
          }
        });
      }
      daysInMonth.push(newDay);
      dayCounter++;
    }
    let datepickerStartDay: number;
    if (this.startWeekWith !== undefined) {
      datepickerStartDay = this.startWeekWith;
    } else if (this.weekFirstDayProviderValue !== undefined) {
      datepickerStartDay = this.weekFirstDayProviderValue;
    }
    if (firstDayOfWeekIndex >= datepickerStartDay) {
      const indexOffset: number = firstDayOfWeekIndex - datepickerStartDay;
      let j: number = 0;
      while (j < indexOffset) {
        daysInMonth.unshift(undefined);
        j++;
      }
    } else {
      const indexOffset: number = 6 - (datepickerStartDay - 1) + firstDayOfWeekIndex;
      let j: number = 0;
      while (j < indexOffset) {
        daysInMonth.unshift(undefined);
        j++;
      }
    }
    // divide by weeks
    const weeks: Day[][] = [];
    let i: number = 0;
    while (i < daysInMonth.length) {
      const week: Day[] = daysInMonth.slice(i, i + 7);
      weeks.push(week);
      i = i + 7;
    }
    // blank spaces in last week
    const lastWeekIndex: number = weeks.length - 1;
    let dayIndex: number = weeks[lastWeekIndex].length;
    while (dayIndex < 7) {
      weeks[lastWeekIndex].push(undefined);
      dayIndex++;
    }
    this.weeks = weeks;
  }

  private setSelection(): any {
    if (this.range.min && this.range.max) {
      let isTodayFlag: boolean = false;
      let isRangeFlag: boolean = false;
      if (
        this.today.getDate() === this.range.min.getDate() &&
        this.today.getMonth() === this.range.min.getMonth() &&
        this.today.getFullYear() === this.range.min.getFullYear()
      ) {
        isTodayFlag = true;
      }
      if (
        this.range.max.getDate() !== this.range.min.getDate() ||
        this.range.max.getMonth() !== this.range.min.getMonth() ||
        this.range.max.getFullYear() !== this.range.min.getFullYear()
      ) {
        isRangeFlag = true;
      }
      return {
        isRange: isRangeFlag,
        isToday: isTodayFlag,
        value: this.range,
      };
    } else {
      return undefined;
    }
  }

  private styleDaysRangeSelection(range: IRange): void {
    if (range.min !== undefined && range.max !== undefined) {
      this.weeks.forEach((week: Day[]) => {
        week.forEach((day: Day) => {
          if (day && day.number !== undefined) {
            // it is not a blank space
            if (
              day.number === range.min.getDate() &&
              day.date.getMonth() === range.min.getMonth() &&
              day.date.getFullYear() === range.min.getFullYear()
            ) {
              day.selected = true;
              day.range = false;
            } else if (
              day.number === range.max.getDate() &&
              day.date.getMonth() === range.max.getMonth() &&
              day.date.getFullYear() === range.max.getFullYear()
            ) {
              day.selected = true;
              day.range = false;
            } else if (day.date.getTime() > range.min.getTime() && day.date.getTime() < range.max.getTime()) {
              day.selected = false;
              day.range = true;
            } else {
              day.selected = false;
              day.range = false;
            }
          }
        });
      });
    }
  }

  private updatePermissionsForMonthMoveButtons(): void {
    if (this.currentDate) {
      const date: Date = this.currentDate;
      const firstSecondInCurrentMonth: Date = new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0);
      const lastSecondInCurrentMonth: Date = new Date(date.getFullYear(), date.getMonth() + 1, 1, 0, 0, -1);
      this.displayedDate.canMoveToPreviousMonth = !this.min || this.min < firstSecondInCurrentMonth;
      this.displayedDate.canMoveToNextMonth = !this.max || this.max > lastSecondInCurrentMonth;
    }
  }

  private setToday(): void {
    this.today = new Date();
    this.today.setHours(0, 0, 0, 0);
  }
}
