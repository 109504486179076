import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { CookiebotConstants } from '../core/cmx-cookiebot.constants';

@Component({
  selector: 'cmx-cookiebot-declaration',
  template: '<div #cookiebot></div>',
  styles: [':host {display: block}']
})

export class CookiebotComponent implements AfterViewInit {

  @ViewChild('cookiebot')
  cookiebotElement: ElementRef;

  constructor() { }

  ngAfterViewInit(): void {
    if (!document.getElementById('CookieDeclaration')) {
      const cbId = CookiebotConstants.COOKIEBOT_KEY;
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = 'CookieDeclaration';
      script.src = CookiebotConstants.URL_COOKIEBOT_DECLARATION + cbId + CookiebotConstants.URL_COOKIEBOT_SUFFIX;
      script.async = true;
      this.cookiebotElement.nativeElement.append(script);
    }
  }
}
