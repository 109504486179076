import { TranslationService } from 'src/app/angular-localization-v7/services/translation.service';
import { Component } from '@angular/core';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {

  constructor(public translationService: TranslationService) { }

}
