import { Address } from './address';
export class CustomerDetail {
    public customerId: number;
    public customerCode: string;
    public customerDesc: string;
    public customerUserId: number;
    public customerName: string;
    public address: Address;
    public userId: number;
    public email: string;
    public vat: string;
    public countryCode: string;
    public phoneNumber: string;
    public streetName: string;
    public domicileNum: string;
    public regionDesc: string;
    public jobSites: any;
}
