import { Injectable } from '@angular/core';
import { TocResponse } from '../models/sales-area/toc-response';
import { CmxHttpClient } from '../services/api.service';

// ! Do not remove Observable import.. Needed for package build
// tslint:disable-next-line: no-unused-variable
import { Observable } from 'rxjs';
import { ISalesAreasResponse } from '../models/sales-area/sales-areas-response';

@Injectable()
export class SalesAreaService {
  constructor(private http: CmxHttpClient) {}

  getSalesAreas(salesAreaId: number) {
    return this.http.get<ISalesAreasResponse>(`/v2/im/salesareas/${salesAreaId}`);
  }

  getTermsAndConditions(salesAreadId: number) {
    return this.http.get<TocResponse>(`/v2/im/salesareas/${salesAreadId}/termsandconditions`);
  }

  getTermsAndConditionsDocument(documentUri: string) {
    // tslint:disable-next-line:no-any
    return this.http.get<any>(documentUri, {
      responseType: 'blob',
    });
  }
}
