export type ISortHeaders = ISortHeader[]

export type SortingOrder = 'asc' | 'desc' | 'none';

export interface ISortHeader {
  id: number
  label: string
  order?: SortingOrder,
  property: string
}

export interface SortingOptions {
  sortHeader?: ISortHeader
  sortCustomFunction?: any
}
