import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UpdatePatchResponse } from '../models/common';
import { CreateDraftRequest } from '../models/drafts/requests/create-draft-request';
import { CreateDraftResponse } from '../models/drafts/responses/create-draft-response';
import { Order, OrderTypeCodesEnum, Payment } from '../models/order-management';
import { CmxHttpClient } from '../services/api.service';
import { createHttpParams } from '../shared/helpers';

@Injectable()
export class DraftService {
  constructor(private http: CmxHttpClient) {}

  createDraft(req: CreateDraftRequest): Observable<CreateDraftResponse> {
    return this.http.post<CreateDraftResponse>(`/v5/sm/orders`, req);
  }

  createDraftV6(req: CreateDraftRequest): Observable<CreateDraftResponse> {
    return this.http.post<CreateDraftResponse>(`/v6/sm/orders`, req);
  }

  // tslint:disable-next-line:no-any
  createOrderFromDraft(draftId: any, payload?: Payment | string): Observable<UpdatePatchResponse> {
    return this.http.patch(`/v6/sm/orders/${draftId}/requested`, payload);
  }

  getDraft(draftId: string) {
    return this.http.get<Order>(`/v5/sm/orders/${draftId}`, {
      params: createHttpParams({
        orderType: OrderTypeCodesEnum.Draft,
      }),
    });
  }

  getDraftV6(draftId: string) {
    return this.http.get<Order>(`/v6/sm/orders/${draftId}`, {
      params: createHttpParams({
        orderType: OrderTypeCodesEnum.Draft,
      }),
    });
  }

  getDraftV7(draftId: string) {
    return this.http.get<Order>(`/v7/sm/orders/${draftId}`, {
      params: createHttpParams({
        orderType: OrderTypeCodesEnum.Draft,
      }),
    });
  }

  validateRequestId(id: number): Observable<Order> {
    return this.http.get<Order>(`/v6/sm/orders/${id}?orderType=SLS`);
  }

  validateOffer(draftId: string | number): Observable<CreateDraftResponse> {
    return this.http.patch<CreateDraftResponse>(`/v1/oe/offers/${draftId}/validate`, {});
  }

  patchOptimalSources(draftId: string | number): Observable<CreateDraftResponse> {
    return this.http.patch<CreateDraftResponse>(`/v4/sm/orders/${draftId}/optimalsources`, {});
  }
}
