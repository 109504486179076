import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DareminderModal } from './dareminder.modal';
import { CmxDialogModule } from 'src/app/cmx-dialog/v7/projects/cmx-dialog-v7/src/public_api';
import { CmxWebComponentsModule } from '@cmx-web-components/angular';

@NgModule({
  imports: [
    CommonModule,
    CmxDialogModule,
    CmxWebComponentsModule,
  ],
  exports: [
    DareminderModal
  ],
  declarations: [
    DareminderModal
  ],
  providers: [
    //PasswordService
  ]
})

export class DareminderModalModule { }
