import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';


@Injectable()
export class ReactGuard implements CanActivate {
    constructor(private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        const routesRegExp = new RegExp(`.*(${route.data['routes'].join('|')}).*`);
        if (window.location.pathname.match(routesRegExp)) {
          console.log(route);

            route.data['routerRef'].push(state.url, { ngSimulated: true });
        }
        return true;
    }
}
