import { Component, Input, Optional, Inject } from '@angular/core';
import { TranslationService } from 'src/app/angular-localization-v7/services/translation.service';
import { Router } from '@angular/router';

@Component({
  selector: 'breadcrumbs',
  styleUrls: ['breadcrumbs.component.scss'],
  templateUrl: 'breadcrumbs.component.html',
})

export class BreadcrumbsComponent {
  @Input() public rtl: boolean;
  @Input() public breadCrumbs: any[];

  constructor(@Optional() @Inject('RTL') isRTL: boolean, public translationService: TranslationService, private router: Router) {
    if (isRTL !== undefined) {
      this.rtl = isRTL;
    }
  }
  public goToLink(link: string, param?: string): void {
    if (param) {
      this.router.navigate([link], { queryParams: { id: param } });
    } else {
      this.router.navigate([link]);
    }
  }

  public onClick(breadCrumb: any): void {
    if (breadCrumb.hasOwnProperty('onClick') && breadCrumb.onClick !== undefined) {
      breadCrumb.onClick();
   }
 }
}
