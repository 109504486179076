import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomerDetail, Segmentation } from '../models';
import { CmxHttpClient } from './http-client.service';
import { CmxHttpOptions } from 'src/app/cmx-api-v7/projects/cmx-api-v7/src/public_api';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CustomerService {

  constructor(private cmxHttp: CmxHttpClient) { }

  getUserCustomers() {
    return this.cmxHttp.get<CustomerDetail[]>('v2/cum/mycustomers?include=address')
      .pipe(map((res) => res['customers'] ? res['customers'] : []));
  }

  // TODO: DEPRECATE
  getCustomersForUser(userId: number): Observable<CustomerDetail[]> {
    return this.cmxHttp.get('v5/secm/user/' + userId + '/data/customers')
      .pipe(map((res) => res['customers'] ? res['customers'] : []));
  }

  getCustomersAndJobsitesForUser(userId: number, jobsiteConfiguration: boolean = false) {
    let uri = `v5/secm/user/${userId}/data/customers?includeJobsites=true`;
    if (jobsiteConfiguration) {
      uri += '&includeConfigurations=true';
    }
    return this.cmxHttp.get(uri).pipe(map((res) => res['customers'] ? res['customers'] : []));
  }

  getUsersByCustomer(customerId: number): Observable<any[]> {
    const uri = `v6/secm/customers/${customerId}/users?userType=C&isDigitalAdmin=false`;
    return this.cmxHttp.get(uri).pipe(map((res) => res['users'] ? res['users'] : []));
  }

  getSegmentationsByCustomerCode(customerCode: string, countryCode: string): Observable<Segmentation[]> {
    const uri = `v3/crm/segmentations?customerCode=${customerCode}.${countryCode.trim()}`;
    return this.cmxHttp.get(uri).pipe(map((res) => res['segmentations'] ? res['segmentations'] : []));
  }

  getUserManagementReport(customerId: number): Observable<any> {
    const uri = `v6/secm/users/search`;
    const body = JSON.stringify({ customer: {customerId: customerId, accountType: 'U', userType: 'C', isDigitalAdmin: false}, resulType:'UMR'});
    const options: CmxHttpOptions = {responseType: 'blob'};
    return this.cmxHttp.post(uri, body, options).pipe(map(response => response));
  }
}
