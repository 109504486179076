
import {
  Component, OnInit, Input, Output,
  EventEmitter, ElementRef, Renderer2,
  Optional, HostBinding, Inject } from '@angular/core';
import { CmxRadioGroupDirective } from './cmx-radio-group.directive';
import { CmxRadioChange } from './cmx-radio-change';
let uniqueIdCounter: number = 0;

@Component({
  selector: 'cmx-radio-button',
  styleUrls: [ './../../../../../../scssv4/cmx-components/cmx-radio/v4/cmx-radio.component.scss'],
  templateUrl: './cmx-radio-button.component.html',
})
export class CmxRadioButtonComponent implements OnInit {
  @Input() public rtl: boolean;
  @Input() public name: string;
  @Output() public change: EventEmitter<CmxRadioChange> = new EventEmitter<CmxRadioChange>();
  @Input()
  public dataTIdRadio: string;
  @Input()
  public dataTIdLabel: string;

  @HostBinding('attr.id') @Input() public id: string = `md-radio-${uniqueIdCounter++}`;

  public radioGroup: CmxRadioGroupDirective;

  private _checked: boolean = false;
  private _value: number = undefined;
  private _disabled: boolean = false;
  private _error: boolean = false;

  constructor(
    @Optional() radioGroup: CmxRadioGroupDirective,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    @Optional() @Inject('RTL') isRTL: boolean,
  ) {
    if (isRTL != undefined) {
      this.rtl = isRTL;
    }

    this.radioGroup = radioGroup;
  }

  @Input()
  get value(): number {
    return this._value;
  }
  set value( value: number ) {
    if ( this._value !== value ) {
      this._value = value;
      if ( this.radioGroup != null ) {
        if ( !this.checked ) {
          this.checked = this.radioGroup.value === value;
        }
        if ( this.checked ) {
          this.radioGroup.selected = this;
        }
      }
    }
  }

  @Input()
  get checked(): boolean {
    return this._checked;
  }
  set checked( newCheckedState: boolean ) {
    if ( this._checked !== newCheckedState ) {
      this._checked = newCheckedState;
      if ( newCheckedState && this.radioGroup && this.radioGroup.value !== this.value ) {
        this.radioGroup.selected = this;
      } else if ( !newCheckedState && this.radioGroup && this.radioGroup.value === this.value ) {
        // when unchecking the selected radio button, update the selected radio
        // property on the group.
        this.radioGroup.selected = null;
      }
    }
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled( value: boolean ) {
    if ( value ) {
      this._disabled = true;
    }
  }

  @Input()
  get error(): boolean {
    return this._error;
  }
  set error( value: boolean ) {
    this._error = value;
  }

  get inputId(): string {
    return `${this.id}-input`;
  }

  public ngOnInit(): void {
    if ( this.radioGroup ) {
      // if the radio is inside a radio group, determine if it should be checked
      this.checked = this.radioGroup.value === this._value;
      // copy name from parent radio group
      this.name = this.radioGroup.name;
    }
  }

  public onInputChange( event: Event ): void {
    event.stopPropagation();
    const groupValueChanged: boolean = this.radioGroup && this.value !== this.radioGroup.value;
    this.checked = true;
    this.emitChangeEvent();
    if ( this.radioGroup ) {
      this.radioGroup.controlValueAccessorChangeFn( this.value );
      this.radioGroup.touch();
      if ( groupValueChanged ) {
        this.radioGroup.emitChangeEvent();
      }
    }
  }

  private emitChangeEvent(): void {
    const event: CmxRadioChange = new CmxRadioChange();
    event.source = this;
    event.value = this._value;
    this.change.emit( event );
  }

}
