import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonsFooterComponent } from './buttons-footer.component';

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
        ButtonsFooterComponent
    ],
    declarations: [
        ButtonsFooterComponent
    ]
})

export class ButtonsFooterModule { }
