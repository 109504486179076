import { Component, Input, Output, EventEmitter, Host } from '@angular/core';

import { CmxTableComponent } from '../cmx-table/cmx-table.component';

@Component({
    selector: 'cmx-pagination',
    templateUrl: './cmx-pagination.component.html',
    styleUrls: [ './../../../../../../../../../../scss/cmx-table/v1/cmx-pagination.component.scss' ],
})
export class CmxPaginationComponent {
    @Input()
    set pages($value: number) {
        if ($value !== undefined && $value !== null) {
            this._pages = $value;
            this.organizePages();
        }
    }
    get pages(): number {
        return this._pages;
    }

    @Input()
    set currentPage($value: number) {
        if ($value !== undefined && $value != null) {
            this._currentPage = $value;
            if (this._currentPage < this.range) {
                this.state = 1;
            } else if (this._currentPage >= this.range &&
            this._currentPage <= (this.pages - this.range)) {
                this.state = 2;
            } else if (this._currentPage > (this.pages - this.range)) {
                this.state = 3;
            }
        }
    }
    get currentPage(): number {
        return this._currentPage;
    }

    @Output() public pageChanged: EventEmitter<number> = new EventEmitter<number>();

    public beginning: number[] = [];
    public end: number[] = [];
    public state = 1;
    public isRtl = false;
    private _pages: number;
    private _currentPage = 1;
    private range = 5;

    constructor(@Host() private parent: CmxTableComponent) {
        // strange shit that a delay is needed to set the value from the parent
        setTimeout(() => {
            this.isRtl = this.parent.rtl;
        }, 100);
    }

    /**
     * @description Goes one page down from the current one.
     */
    public previousPage(): void {
        const $calc: number = this.currentPage - 1;
        if ($calc > 0) {
            this.setPage($calc);
        }
    }

    /**
     * @description Goes one page up from the current one.
     */
    public nextPage(): void {
        const $calc: number = this.currentPage + 1;
        if ($calc <= this.pages) {
            this.setPage($calc);
        }
    }

    /**
     * @description Check if the last page shortcut is shown
     * whether the first page is present or not.
     * @returns boolean
     */
    public showFirstPageShortcut(): boolean {
        let $show = true;
        this.end.forEach(($page) => {
            if ($page === 1) {
                $show = false;
            }
        });
        return $show && (this.state !== 1);
    }

    /**
     * @description Check if the last page shortcut is shown
     * whether the last page is present or not.
     * @returns boolean
     */
    public showLastPageShortcut(): boolean {
        let $show = true;
        this.beginning.forEach(($page) => {
            if ($page === this.pages) {
                $show = false;
            }
        });
        return $show && (this.state !== 3);
    }

    /**
     * @description Sets the current page from the user's click.
     * @param number $page The number of the page clicked.
     */
    public setPage($page: number): void {
        this.currentPage = $page;
        if (this.currentPage < this.range) {
            this.state = 1;
        } else if (this.currentPage >= this.range &&
        this.currentPage <= (this.pages - this.range)) {
            this.state = 2;
        } else if (this.currentPage > (this.pages - this.range)) {
            this.state = 3;
        }
        this.pageChanged.emit(this.currentPage);
    }

    private organizePages(): void {
        this.state = 1;
        this.currentPage = 1;
        this.beginning = [];
        this.end = [];
        let $i = 1;
        while ($i <= this.range && $i <= this.pages) {
            this.beginning.push($i);
            $i++;
        }
        $i = (this.pages - this.range);
        if ($i === 0) {
            $i++;
        }
        while ($i <= this.pages) {
            this.end.push($i);
            $i++;
        }
    }
}
