import {
    Component,
    OnDestroy,
    AfterContentInit,
    ViewChild,
    Input,
    ElementRef,
    ContentChildren,
    QueryList,
    EmbeddedViewRef,
    HostBinding
} from '@angular/core';

import { Subscription } from 'rxjs';

import { Table } from '../../classes/table.class';
import { CmxRowTemplateDirective } from '../../directives/cmx-row-template.directive';
import { CmxHeaderTemplateDirective } from '../../directives/cmx-header-template.directive';
import { CmxHeaderCellComponent } from '../cmx-header-cell/cmx-header-cell.component';
import { RowPlaceholderDirective } from '../../directives/cmx-row-placeholder.directive';
import { HeaderPlaceholderDirective } from '../../directives/cmx-header-placeholder.directive';
import { Filter } from '../../classes/filter.class';
import { WatcherService } from '../../services/watcher.service';

export interface Page {
    pageNumber: number;
    minIndex: number;
    maxIndex: number;
}

@Component({
    selector: 'cmx-table',
    templateUrl: './cmx-table.component.html',
    styleUrls: [ './../../../../../../../../../../scss/cmx-table/v1/cmx-table.component.scss' ],
})
export class CmxTableComponent implements AfterContentInit, OnDestroy {
    @Input()
    get tableSource(): Table {
        return this._table;
    }
    set tableSource($value: Table) {
        if ($value !== undefined && $value !== null && $value instanceof Table ) {
            this._table = $value;
            this.renderHeader();
        }
    }
    @Input()
    get dataSource(): any[] {
        return this._dataSource;
    }
    set dataSource($value: any[]) {
        if ($value !== undefined && $value !== null) {
            this._dataSource = $value;
            this.viewportData = $value;
            this.rowViewReferences = [];
            this.initialize();
        } else {
            this._dataSource = [];
        }
    }

    @Input()
    set updateDataSource($value: any[]) {
        if ($value !== undefined && $value !== null) {
            this._dataSource = $value;
            this.viewportData = $value;
            this.rowViewReferences = [];
            this.updateDataTable();
        } else {
            this._dataSource = [];
        }
    }

    @Input()
    get pageSize(): number {
        return this._pageSize;
    }
    set pageSize($value: number) {
        if ($value !== undefined && $value !== null) {
            this._pageSize = $value;
            this.calculateNumberOfPages();
            this.setPageData();
        }
    }
    @Input()
    get rtl(): boolean {
        return this._rtl;
    }
    set rtl($value: boolean) {
        if ($value !== undefined && $value !== null) {
            this._rtl = $value;
        }
    }

    @HostBinding('class.cmx-table') public cmxTableClass = true;
    @ContentChildren(CmxRowTemplateDirective)
    public rowDefinitions: QueryList<CmxRowTemplateDirective>;
    @ContentChildren(CmxHeaderTemplateDirective)
    public headerDefinitions: QueryList<CmxHeaderTemplateDirective>;
    @ContentChildren(CmxHeaderCellComponent, {descendants: true})
    public headerCells: QueryList<CmxHeaderCellComponent>;
    @ViewChild(RowPlaceholderDirective,{static:true}) public rowPlaceholder: RowPlaceholderDirective;
    @ViewChild(HeaderPlaceholderDirective,{static:true}) public headerPlaceholder: HeaderPlaceholderDirective;
    @ViewChild('cmxTableBody') public cmxTableBodyElement: ElementRef;

    public assignedId: number;
    public pages: Page[] = [];
    public tableHasScroll = false;

    private _dataSource: any[] = [];
    private _table: Table;
    private _pageSize = 30;
    private _rtl = false;
    public currentActivePage = 1;
    private currentPageData: any[] = [];
    private viewportData: any[] = [];
    private activeFiltersCopy: Filter[] = [];
    private activeFiltersSubscription: Subscription;
    private rowViewReferences: Array<EmbeddedViewRef<any>> = [];

    constructor(private watcher: WatcherService) { }

    public ngAfterContentInit(): void {
        this.renderHeader();
        this.assignedId = this.watcher.createSubject();
        this.activeFiltersSubscription = this.watcher.listenToActiveFilters(this.assignedId)
        .subscribe(($event) => {
            if ($event.length > 0) {
                this.activeFiltersCopy = $event;
                // there are filters to apply
                this.viewportData = this.filterData( $event );
            } else {
                this.activeFiltersCopy = [];
                // no filters to apply
                this.viewportData = this._dataSource;
            }
            this.currentActivePage = 1;
            this.calculateNumberOfPages();
            this.setPageData();
        });
    }

    public ngOnDestroy(): void {
        this.rowPlaceholder.viewContainerReference.clear();
        this.headerPlaceholder.viewContainerReference.clear();
        this.activeFiltersSubscription.unsubscribe();
    }

    /**
     * returns the items including filters applied in table
     * Author: Andres Santos
     */
    public filteredItems(): any[] {
        return this.viewportData;
    }

    /**
     * returns the items including filters applied in current page
     * Author: Andres Santos
     */
    public filteredItemsInPage(): any[] {
        return this.currentPageData;
    }

    public clearActiveFilters(): void {
        this.watcher.clearActiveFilters();
    }

    /**
     * returns the current active filters
     * Author: Antonio Vargas
     */
    public getActiveFiltersCopy(): Filter[] {
        return this.activeFiltersCopy;
    }

    /**
     * Triggers the filtering of data
     */
    public triggerFilter( $activeFilters: Filter[] ): void {
        if ( $activeFilters.length > 0 ) {
            // there are filters to apply
            this.viewportData = this.filterData( $activeFilters );
        } else {
            // no filters to apply
            this.viewportData = this._dataSource;
        }
        this.calculateNumberOfPages();
        this.setPageData();
    }

    /**
     * @description Resets the table visualization.
     */
    public reset(): void {
        this.currentActivePage = 1;
        this.rowViewReferences = [];
        this.initialize();
    }

    public filterData( activeFilters: Filter[] ): any[] {
        let result: any[] = this._dataSource;
        const data: any[] = this._dataSource;
        let aux: any[] = [];
        const filtered: { [ index: string ]: any } = [];
        activeFilters.forEach( ( $filter ) => {
            filtered[ $filter.columnKey ] = [];
        });
        activeFilters.forEach( ( $filter, $index ) => {
            aux = data.filter(
                this._table.getColumn( $filter.columnKey ).getFilterFunction( $filter )
            );
            filtered[ $filter.columnKey ] = filtered[ $filter.columnKey ].concat( aux );
        });
        for ( const $key of Object.keys( filtered ) ) {
            result = this.findIntersection( result, filtered[ $key ] );
        }
        return result;
    }

    public sortData( columnId: string, ASC: boolean ): void {
        this.viewportData = this.viewportData.sort(
            this._table.getColumn( columnId ).getSortFunction( ASC )
        );
        this.calculateNumberOfPages();
        this.setPageData();
    }

   /**
    * @description Apply the current active filters in the table
    */
    public applyCurrentFilters(): void {
        if (this.activeFiltersCopy.length > 0) {
            this.viewportData = this.filterData(this.activeFiltersCopy);
        } else {
            this.viewportData = this._dataSource;
        }
        this.calculateNumberOfPages();
        this.setPageData();
    }

    /**
     * @description Overwrite the active filters with new ones to apply
     * @param  $filters: array<Filter>
     */
    public applyNewFilters($filters: Filter[]): void {
        if ($filters && $filters.length > 0) {
            this.viewportData = this.filterData($filters);
        } else {
            this.viewportData = this._dataSource;
        }
        this.calculateNumberOfPages();
        this.setPageData();
    }

    public setCurrentPage($currentPage: number): void {
        this.currentActivePage = $currentPage;
        this.setPageData();
    }

    private initialize(): void {
        this.rowPlaceholder.viewContainerReference.clear();
        this.headerPlaceholder.viewContainerReference.clear();
        this.renderHeader();
        this.calculateNumberOfPages();
        this.setPageData();
    }

    private updateDataTable(): void {
        this.rowPlaceholder.viewContainerReference.clear();
        this.calculateNumberOfPages();
        this.setPageData();
    }

    private checkIfTableHasScroll(): void {
        if ( this.cmxTableBodyElement &&
        this.cmxTableBodyElement.nativeElement.offsetHeight <
        this.cmxTableBodyElement.nativeElement.scrollHeight ) {
            this.tableHasScroll = true;
        } else {
            this.tableHasScroll = false;
        }
    }

    // =============================================================================================
    // views' rendering
    // =============================================================================================

    private renderHeader(): void {
        this.headerPlaceholder.viewContainerReference.clear();
        if ( this.headerDefinitions ) {
            this.headerDefinitions.forEach( ( headerDef ) => {
                const context = { $implicit: this._table };
                this.headerPlaceholder.viewContainerReference.createEmbeddedView(
                    headerDef.template,
                    context
                );
            });
        }
    }

    private renderRows(): void {
        if ( this.rowDefinitions ) {
            if ( this.rowViewReferences && this.rowViewReferences.length > 0 ) {
                // the views already exist
                this.updateRowsContext();
            } else {
                // first rendering of the rows
                this.rowDefinitions.forEach( ( rowDef ) => {
                    this.currentPageData.forEach( ( rowData, $index ) => {
                        const context = { $implicit: rowData };
                        const ref = this.rowPlaceholder.viewContainerReference.createEmbeddedView(
                            rowDef.template,
                            context
                        );
                        this.rowViewReferences.push( ref );
                    });
                });
            }
        }
    }

    private updateRowsContext(): void {
        if ( this.rowDefinitions ) {
            this.rowDefinitions.forEach( ( rowDef ) => {
                this.rowViewReferences.forEach( ( viewRef, $index ) => {
                    if ( $index > ( this.currentPageData.length - 1 ) ) {
                        // the current page has less data to show than the set page size
                        if ( viewRef !== undefined ) {
                            viewRef.destroy();
                        }
                        this.rowViewReferences.splice( $index, 1, undefined );
                    } else {
                        // the view reference is used on the current page
                        const context = { $implicit: this.currentPageData[ $index ] };
                        if ( viewRef === undefined ) {
                            // the view reference was destroyed, a new replacement
                            const ref = this.rowPlaceholder.viewContainerReference
                            .createEmbeddedView(
                                rowDef.template,
                                context,
                                $index
                            );
                            this.rowViewReferences[ $index ] = ref;
                        } else {
                            // no new view reference is required, update the context data
                            viewRef.context.$implicit = context.$implicit;
                        }
                    }
                });
            });
        }
    }

    // =============================================================================================
    // filters
    // =============================================================================================

    private findIntersection( arrayOne: any[], arrayTwo: any[] ): any[] {
        const intersection: any[] = [];
        let $i = 0;
        let $j = 0;
        while ( $i < arrayOne.length ) {
            $j = 0;
            while ( $j < arrayTwo.length ) {
                if ( arrayOne[ $i ] === arrayTwo[ $j ] ) {
                    intersection.push( arrayOne[ $i ] );
                }
                $j++;
            }
            $i++;
        }
        return intersection;
    }

    // =============================================================================================
    // pagination
    // =============================================================================================

    /**
     * @description Calculates the number of pages given the array of data and
     * determines the index of the data source for each page.
     */
    private calculateNumberOfPages(): void {
        const numberOfPages: number = Math.ceil( this.viewportData.length / this._pageSize );
        this.pages = [];
        let i = 1;
        do {
            const min = ( ( i - 1 ) * this._pageSize );
            const max = min + this._pageSize - 1;
            this.pages.push({
                pageNumber: i,
                minIndex: min,
                maxIndex: max
            } as Page);
            i++;
        } while ( i <= numberOfPages );
    }

    private setPageData(): void {
        const $page = this.pages.find((page) => page.pageNumber === this.currentActivePage);
        this.currentPageData = [];
        if ($page !== undefined && $page !== null) {
            for (let $i = $page.minIndex; $i <= $page.maxIndex; $i++ ) {
                if (this.viewportData[$i]) {
                    this.currentPageData.push(this.viewportData[$i]);
                }
            }
        } else {
            this.currentActivePage = 1;
            for (let $i = this.pages[0].minIndex; $i <= this.pages[0].maxIndex; $i++ ) {
                if (this.viewportData[$i]) {
                    this.currentPageData.push(this.viewportData[$i]);
                }
            }
        }
        this.renderRows();
        this.checkIfTableHasScroll();
    }
}
