import { Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  forkJoin,
  Observable,
  of,
  ReplaySubject,
  Subject,
  Subscription,
  throwError
} from 'rxjs';
import { OrderDTO, OrderItemWithValue, ShipmentLocationDTO } from './types';
import {
  EnvironmentConstants,
  JobsiteDTO,
  OrderStatusCode,
  DateRange,
  PathConstants
} from '../shared/types';
import { JobsiteOption, Load } from './types';
import * as moment_ from 'moment';
import { HttpClient } from '@angular/common/http';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { UNIT_OF_MEASUREMENT_FOR_INDICATORS } from './constants';

const moment = moment_;

@Injectable()
export class CmxDashboardActiveLoadsCardService implements OnDestroy {
  /**
   * active loads collection (output)
   */
  public activeLoads$ = new BehaviorSubject<Load[]>([]);
  /**
   * complete loads collection (output)
   */
  public completeLoads$ = new BehaviorSubject<Load[]>([]);
  /**
   * jobsites collection to pick from (output)
   */
  public jobsitesToChoose$ = new BehaviorSubject<JobsiteOption[]>([]);
  /**
   * loading status (output)
   */
  public status$ = new BehaviorSubject<LoadingStatus>(LoadingStatus.IDLE);

  /**
   * date range to fetch for. Next value initiates a new request (input)
   */
  public dateRange$ = new BehaviorSubject<DateRange>(null);

  constructor(private api: HttpClient) {}

  /**
   * Sets status and erase data in case of error
   * @param newStatus new status
   */
  private setStatus(newStatus: LoadingStatus) {
    this.status$.next(newStatus);
    if (newStatus === LoadingStatus.ERROR) {
      setData([], this.activeLoads$);
      setData([], this.completeLoads$);
    }
  }
  /**
   * Sets initially data for loads table
   * @param activeLoads active loads array
   * @param completeLoads complete loads array
   */
  public setLoads(activeLoads: Load[], completeLoads: Load[]) {
    setData(activeLoads, this.activeLoads$);
    setData(completeLoads, this.completeLoads$);
  }
  /**
   * Sets initially jobsites
   * @param jobsites jobsites array
   */
  public setJobsites(jobsites: JobsiteOption[]) {
    setData(jobsites, this.jobsitesToChoose$);
  }

  private _subscriptions = [];

  private _jobsiteSummary$ = new ReplaySubject<number>(1);
  private _shipmentLocationsCollection$ = new Subject<ShipmentLocationDTO[]>();
  private _unit$ = new ReplaySubject<string>(1);

  /**
   * Initiates updates based on new jobsite has chosen
   * @param jobsiteId jobsite id
   */
  public fetchNewQueryByJobsite(jobsiteId: number) {
    this._jobsiteSummary$.next(jobsiteId);
  }

  /**
   * Initiates updates based on new date range has chosen
   * @param date date range
   */
  public fetchNewQueryByDateRange(date: DateRange) {
    this.dateRange$.next(date);
  }

  /**
   * Sets data for locations to choose from
   * @param locations shipment locations collection
   */
  private _setShipmentLocationsCollection(locations: ShipmentLocationDTO[]) {
    this._shipmentLocationsCollection$.next(locations);
    this.jobsitesToChoose$.next(
      locations.map(item => ({
        id: String(item.shipmentLocationId),
        name: item.shipmentLocationDesc
      }))
    );

    if (locations.length === 0) {
      this.setStatus(LoadingStatus.NOJOBSITES);
    }
  }

  /**
   * The event stream, which handles changes of inputs and fetches new data
   */
  private _fetchingStream: Subscription;

  /**
   * It initiates listening for inputs, fetches needed data from API.
   * One should call it if it intents to get data from API.
   *
   * fetches jobsites collection and orders' loads for the first jobsite
   *
   * @param customerId legal entity Id for jobsite API request
   * @param date date range to fetch
   * @returns nothing
   */
  public fetchInit(customerId: number, date: DateRange, country: string) {
    this.setStatus(LoadingStatus.PENDING);

    const from = removeTimeZone(
      moment(date.from).format('YYYY-MM-DD T00:00:00Z')
    );
    const to = removeTimeZone(
      moment(date.to).format('YYYY-MM-DD T23:59:59Z')
    );
    this.dateRange$.next({ from, to });

    this.subscribe(
      this.fetchParameters(country),
      parameters => {
        if (parameters && parameters.length) {
          const parameter = parameters.find(
            ({ parameterDesc }) =>
              parameterDesc === 'UNIT_OF_MEASUREMENT_FOR_INDICATORS'
          );
          if (parameter) {
            setData(parameter.parameterValue, this._unit$);
            return;
          }
        }
        console.error(
          'Something is wrong with parameter fetching request. ',
          "Parameter 'UNIT_OF_MEASUREMENT_FOR_INDICATORS' couldn't be found, fall back to default value"
        );
        setData(UNIT_OF_MEASUREMENT_FOR_INDICATORS, this._unit$);
      },
      'error while fetching parameters',
      undefined,
      false
    );

    this.subscribe(
      this.fetchShipmentLocations(customerId),
      ({ shipmentLocations: locations }) => {
        this._setShipmentLocationsCollection(locations);
      },
      'error while fetch shipment locations',
      undefined,
      false
    );

    if (this._fetchingStream) {
      this._fetchingStream.unsubscribe();
    }
    this._fetchingStream = this.subscribe(
      this.createStream(customerId),
      () => {},
      '',
      undefined,
      false
    );
  }

  /**
   * Initializes fetching routine, resulting observable will recover after error
   * @returns combined observables
   */
  createStream(customerId: number) {
    return combineLatest([this._jobsiteSummary$, this.dateRange$]).pipe(
      switchMap(([jobsiteId, { from, to }]) => {
        // remove timezone
        from = removeTimeZone(
          moment(from).format('YYYY-MM-DD T00:00:00Z')
        );
        to = removeTimeZone(
          moment(to).format('YYYY-MM-DD T23:59:59Z')
        );

        this.setStatus(LoadingStatus.PENDING);
        // ATTENTION: country code might should be trimmed
        return forkJoin([
          this.fetchOrders(from, to, jobsiteId),
          this.fetchJobsitesSummary(from, to, customerId)
        ]);
      }),
      withLatestFrom(this._unit$, this._jobsiteSummary$),
      map(([[{ Orders: orders }, { jobsites }], unit, jobsiteId]) => {
        const jobsite = jobsites.find(item => item.jobsiteId === jobsiteId);
        if (jobsite) {
          this.updateLoads(orders, jobsite, unit);
          this.setStatus(LoadingStatus.RESOLVED);
        } else {
          this.setStatus(LoadingStatus.RESOLVED);
          setData([], this.activeLoads$);
          setData([], this.completeLoads$);
        }
      }),
      catchError(error => {
        this._jobsiteSummary$.unsubscribe();
        this._jobsiteSummary$ = new ReplaySubject<number>(1);
        this.subscribe(this.createStream(customerId), () => {}, '');
        return throwError(error);
      })
    );
  }

  /**
   * Computes and assigns new value for output arrays of loads, both active and complete
   * @param orders orders to filter and pick needed data from
   * @param jobsite jobsite summary to count orders' loads data from
   * @param unit translation label for unit of measurement
   */
  private updateLoads(orders: OrderDTO[], jobsite: JobsiteDTO, unit: string) {
    const activeLoads = [];
    const completeLoads = [];
    for (const order of orders) {
      if (
        ![
          OrderStatusCode.Completed,
          OrderStatusCode.CompletedOnline,
          OrderStatusCode.InProgress,
          OrderStatusCode.InProgressOnline,
          OrderStatusCode.OnHold,
          OrderStatusCode.OnHoldOnline,
          OrderStatusCode.NewOnHold
        ].includes(order.orderStatusGroupCode)
      )
        continue;
      const orderSummary = jobsite.orders.find(
        item => item.orderId === order.orderId
      );
      if (orderSummary) {
        const loadObject: Load = {
          volume: order.isReadyMix ? `${orderSummary.deliveredQuantity} / ${orderSummary.totalQuantity} ${unit}` : order.orderItem.length > 1 ? '-' : `${order.orderItem[0].productQuantity} ${order.orderItem[0].unitDesc}` ,
          load: `${orderSummary.totalDeliveries} / ${orderSummary.totalLoads}`,
          orderId: order.orderId,
          orderCode: order.orderCode,
          purchaseOrder: order.purchaseOrder,
          instructionsDesc: order.instructionsDesc === '' ? '-' : order.instructionsDesc,
          status: order.orderStatusGroupCode
        };
        loadObject.products = this.calculateProducts(order);
  
        if (order.orderStatusGroupCode === OrderStatusCode.InProgress || order.orderStatusGroupCode === OrderStatusCode.InProgressOnline || order.orderStatusGroupCode === OrderStatusCode.OnHold || order.orderStatusGroupCode === OrderStatusCode.OnHoldOnline || order.orderStatusGroupCode === OrderStatusCode.NewOnHold) {
          activeLoads.push(loadObject);
        } else if
        (order.orderStatusGroupCode === OrderStatusCode.Completed || order.orderStatusGroupCode === OrderStatusCode.CompletedOnline) {
          completeLoads.push(loadObject);
        }
      }
    }

    // sort active orders to start with In Progress orders
    activeLoads.sort((a, b) => {
      const statusOrder = {
        [OrderStatusCode.InProgress]: SortingOrder.top,
        [OrderStatusCode.InProgressOnline]: SortingOrder.middle
      };
      const statusA = statusOrder[a.status] || SortingOrder.bottom;
      const statusB = statusOrder[b.status] || SortingOrder.bottom;
      return statusA - statusB;
    });

    setData(activeLoads, this.activeLoads$);
    setData(completeLoads, this.completeLoads$);
  }

  private calculateProducts(order: OrderDTO) {
    const products: OrderItemWithValue[] = [];
    try {
      for (const item of order.orderItem) {
        const product = products.find(
          p => p.productCode === item.productCode && p.unitId === item.unitId
        );

        // check if the product is already counted
        if (product !== undefined) {
          const pos = products.map(e => e.productCode).indexOf(product.productCode);
          products[pos].value += item.productQuantity
          continue;
        }
        products.push({
          ...item,
          value: item.productQuantity
        });
      }
    } catch (error) {
      console.error('Something went wrong at products calculation', error);
    }
    return products;
  }

  /**
   * Fetches jobsite summary
   * @param from from date
   * @param to to date
   * @param customerId customer id
   * @returns http request observable
   */
  private fetchJobsitesSummary(from: string, to: string, customerId: number) {
    return this.api.get<{ jobsites: JobsiteDTO[] }>(
      `${EnvironmentConstants.getWorkingPath() +
        PathConstants.JOBSITES}?dateFrom=${from}&dateTo=${to}&customerId=${customerId}&isWeb=true`
    );
  }

  /**
   * fetches shipment locations
   * @param customerId customer id
   * @returns http request observable
   */
  private fetchShipmentLocations(customerId: number) {
    return this.api.get<{ shipmentLocations: ShipmentLocationDTO[] }>(
      EnvironmentConstants.getWorkingPath() +
        PathConstants.SHIPMENT_LOCATIONS`${customerId}`
    );
  }

  /**
   * Fetches orders for jobsite
   * @param from from date
   * @param to to date
   * @param jobsiteId jobsite id
   * @returns http request observable
   */
  private fetchOrders(from: string, to: string, jobsiteId: number) {
    return this.api.get<{ Orders: OrderDTO[] }>(
      EnvironmentConstants.getWorkingPath() +
        PathConstants.ORDERS +
        jobsiteId +
        '/orders?startdate=' +
        from +
        '&enddate=' +
        to
    );
  }

  /**
   * Fetches parameter for load quantity
   * @param country country
   * @returns http request observable
   */
  private fetchParameters(country: string) {
    // YSK... the country parameter - from user_type:normal is from 'userInfo.country' and from user_type:guest is from 'order.countryCode'
    return this.api.get<[{ parameterValue: string; parameterDesc: string }]>(
      EnvironmentConstants.getWorkingPath() +
        PathConstants.MY_PARAMETERS`${country}`
    ).pipe(catchError(() => {
      return of([])
    }));;
  }

  /**
   * Subscribes on a subject, register it to unsubscribe later, sets error handler
   * @param subject$ subject to subscribe for
   * @param callback next function
   * @param message error message
   * @param onError error callback
   * @returns subscription
   */
  private subscribe<T>(
    subject$: Observable<T>,
    callback: (ang0: T) => void,
    message: string,
    onError?: () => void,
    unsubscribeLater: boolean = true
  ) {
    const s = subject$.subscribe(callback, error => {
      console.error(message ? message : '', error);
      this.setStatus(LoadingStatus.ERROR);
      if (onError) {
        onError();
      }
    });
    if (unsubscribeLater) {
      this._subscriptions.push(s);
    }
    return s;
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(s => s.unsubscribe());
    if (this._fetchingStream) {
      this._fetchingStream.unsubscribe();
    }
  }
}

function removeTimeZone(date: string) {
  const value = moment(date, 'YYYY-MM-DD THH:mm:ssZ')
    .locale('en')
    .utc()
    .format('YYYY-MM-DDTHH:mm:ss');
  return value;
}

export enum LoadingStatus {
  IDLE = 'IDLE',
  PENDING = 'PENDING',
  RESOLVED = 'RESOLVED',
  ERROR = 'ERROR',
  NOJOBSITES = 'NOJOBSITES'
}

/**
 * Convenient helper function to update the subject
 * @param data data to set
 * @param data$ subject to update
 */
function setData(
  data: Load[] | JobsiteOption[] | string | any,
  data$: Subject<Load[] | JobsiteOption[] | string | any>
) {
  data$.next(data);
}

enum Tabs {
  Active = 'Active',
  Completed = 'Completed'
}

enum SortingOrder {
  top = 1,
  middle = 2,
  bottom = 3
}