import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { CmxDropdownComponent } from "./components/cmx-dropdown";
import { SharedModule } from "src/app/shared/shared.module";
import { TestComponent } from "./components/test.component";
@NgModule({
    declarations: [
        CmxDropdownComponent,
        TestComponent
    ],
    exports: [
        SharedModule,
         CmxDropdownComponent,
         TestComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
    ],
})
export class CmxDropdownModule {
    //
}
