import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Plant, PlantPatchRequest, PlantsRequest, PlantsResponse } from '../models/order-management';
import { CmxHttpClient } from '../services/api.service';
import { createHttpParams } from '../shared/helpers';
import { PlantPatchResponse } from '../models/order-management/plants-patch-response';

@Injectable()
export class PlantsService {
  constructor(private http: CmxHttpClient) {}

  getPlants(payload?: PlantsRequest): Observable<PlantsResponse> {
    const httpParams = payload ? createHttpParams(payload) : new HttpParams();
    return this.http.get<PlantsResponse>(`/v7/dm/plants`, {
      params: httpParams,
    });
  }

  getPlant(plantId: number): Observable<Plant> {
    return this.http.get<Plant>(`/v7/dm/plants/${plantId}`);
  }

  patchPlant(payload: PlantPatchRequest): Observable<PlantPatchResponse> {
    return this.http.patch<PlantPatchResponse>(`/v7/dm/plants`, payload);
  }
}
