export class NumberValidators {

    public static isNumeric(c: any): any {
        if (!isNaN(parseFloat(c.value)) && isFinite(c.value)) {
            return null;
        } else {
            return {
                notNumeric: true,
            };
        }
    }

    public static onlyTwoDecimals(c: any): any {
        if (NumberValidators.regexTwoDecimals.test(c.value)) {
            return null;
        } else {
            return {
                patternInvalid: true,
            };
        }
    }

    public static greaterThanZero(c: any): any {
        if (parseFloat(c.value) > 0) {
            return null;
        } else {
            return {
                lessThanZero: true,
            };
        }
    }

    public static isPositive(c: any): any {
        if (parseFloat(c.value) >= 0) {
            return null;
        } else {
            return {
                isNegative: true,
            };
        }
    }

    private static regexTwoDecimals = /^-?[0-9]+(\.[0-9]{1,2})?$/;

}
