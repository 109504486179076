import { ProductType } from './product-type';

export interface Product {
  availabilityCheck: boolean;
  isFinished: boolean;
  productCode: string;
  productDesc: string;
  productHierarchy: string;
  productId: number;
  productType: ProductType;
  commercialDesc?: string;
}
