import { HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CmxHttpOptions, ICmxHttpClient } from '../shared/types';

/**
 * This service is just a placeholder that does nothing. Should be replaced by the app implementation
 * in the module configuration
 */
@Injectable()
export class CmxHttpClient implements ICmxHttpClient {
  public get<T>(_apiPath: string, _options?: CmxHttpOptions) {
    return new Observable<T>();
  }

  // tslint:disable-next-line:no-any
  public post<T>(_apiPath: string, _body: any | null, _options?: CmxHttpOptions) {
    return new Observable<T>();
  }

  // tslint:disable-next-line:no-any
  public patch<T>(_apiPath: string, _body: any | null, _options?: CmxHttpOptions) {
    return new Observable<T>();
  }

  // tslint:disable-next-line:no-any
  public put<T>(_apiPath: string, _body: any | null, _options?: CmxHttpOptions) {
    return new Observable<T>();
  }

  public delete<T>(_apiPath: string, _options?: CmxHttpOptions) {
    return new Observable<T>();
  }

  // tslint:disable-next-line:no-any
  public upload<T>(_apiPath: string, _body: any | null, _options?: CmxHttpOptions) {
    return new Observable<HttpEvent<T>>();
  }
}
