export enum OrderTypeCodesEnum {
  Draft = 'DFT',
  Request = 'REQ',
  CancelRequest = 'CREQ',
  Sls = 'SLS',
  OneClickToBuy = 'ONC',
  StandardOrder = 'ZTA',
  SpecialInvoiceOrder = 'ZTE',
  ModifyRequest = 'MREQ',
}
