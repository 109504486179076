export class Language {
    public languageId: number;
    public languageName: string;
    public languageCountry: string;
    public languageISO: string;
    public currencySymbol?: string;
    public currencyFormat?: string;
    public currencySymbolFloat?: string;
    public currencyName?: string;
    public formatDate?: string;
    public formatTime?: string;
    public decimalSeparator?: string;
    public decimalNumbers?: number;
    public thousandSeparator?: string;
    public shortDayNames?: string;
    public shortDayNames2?: string;
    public hoursCode?: string;
    public minutesCode?: string;
    public secondsCode?: string;
    public shortDayMonths?: string;
    public decimalNumbersMoney?: number;
    public textFloat?: string;
    public authorLanguage?: string;
    public countryCode?: string;
    public monthNames?: string;
    public dayNames?: string;
    public env?: string;
    public region?: string;
}

export class LanguageSelect {
    public languageName: string;
    public languageISO: string;
    public isSelected: boolean;
}
