import { Component, Input, ViewChild, ViewContainerRef, HostListener, Output, HostBinding } from "@angular/core";
import { EventEmitter } from "events";

@Component({
    selector: "cmx-dropdown-item",
    styleUrls: ["./../../../../../../../scssv4/cmx-components/cmx-dropdown/v4/cmx-dropdown-item.component.scss"],
    templateUrl: "./cmx-dropdown-item.component.html",
})
export class CmxDropdownItemComponent {

    @ViewChild("dynamicComponentContainer", { read: ViewContainerRef }) public dynamicComponentContainer: ViewContainerRef;
    public selected: boolean = false;
    private _searchValue: string;
    private _hide: boolean = false;
    private _selectedid: any;
    private _disableTick: boolean = false;
    constructor() {
        //
    }
    @Input()
    set searchValue( value: string ) {
        if ( value !== undefined ) {
            this._searchValue = value;
        }
    }
    get searchValue(): string {
        return this._searchValue;
    }
    @Input()
    set hide( value: boolean ) {
        if ( value !== undefined ) {
            this._hide = value;
        }
    }
    get hide(): boolean {
        return this._hide;
    }

    @Input()
    set selectedid(value: any) {
        this._selectedid = value;
    }
    get selectedid(): any {
        return this._selectedid;
    }
    @Input()
    set disableTick( value: boolean) {
        this._disableTick = value;
    }
    get disableTick(): boolean {
        return this._disableTick;
    }

    @HostListener("click", ["$event"]) public onClick($event: any): void {
        this.selectUserInput();
    }

    private selectUserInput(): void {
        this.selected = !this.selected && !this._disableTick;
    }
}
