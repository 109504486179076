import { Directive, ViewContainerRef, HostBinding } from '@angular/core';

@Directive({
    selector: '[rowPlaceholder]',
})
export class RowPlaceholderDirective {
    @HostBinding( 'class.cmx-row' ) public cmxRowClass = true;

    constructor( public viewContainerReference: ViewContainerRef ) { }
}
