export class DatepickerTags {
    public cancel: string;
    public apply: string;
    public months: string[];
    public monthsShort: string[];
    public daysShort: string[];
    public daysLong: string[];
    public daysMin: string[];
    public momentFormat: string;

    constructor($cancel?: string, $apply?: string, $months?: string[], $monthsShort?: string[],
                $daysShort?: string[], $daysLong?: string[], $daysMin?: string[], $momentFormat?: string) {
        this.cancel = $cancel;
        this.apply = $apply;
        this.months = $months;
        this.monthsShort = $monthsShort;
        this.daysShort = $daysShort;
        this.daysLong = $daysLong;
        this.daysMin = $daysMin;
        this.momentFormat = $momentFormat;
    }
}
