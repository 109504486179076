import { Component, EventEmitter, Input, Output, HostBinding, forwardRef, HostListener, Optional, Inject } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export const CMX_SLIDETOGGLE_VALUE_ACCESSOR: any = {
    multi: true,
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CmxSlideToggleComponent),
};

@Component({
    providers: [
        CMX_SLIDETOGGLE_VALUE_ACCESSOR,
    ],
    selector: 'cmx-slide-toggle',
    styleUrls: ['./../../../../../../scssv4/cmx-components/cmx-slide-toggle/v4/cmx-slide-toggle.component.scss'],
    templateUrl: './cmx-slide-toggle.component.html',
})
export class CmxSlideToggleComponent implements ControlValueAccessor {
    @HostBinding('attr.dir') public dirAttr: string = 'auto';

    @Input()
    get checked(): boolean {
        return this._checked;
    }
    set checked($value: boolean) {
        if ($value !== undefined && $value !== null) {
            this._checked = $value;
            this.propagateChange($value);
            this.propagateTouch();
        }
    }

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }
    set disabled($value: boolean) {
        if ($value !== undefined && $value !== null) {
            this._disabled = $value;
        }
    }

    @Input()
    get error(): boolean {
        return this._error;
    }
    set error($value: boolean) {
        if ($value !== undefined && $value !== null) {
            this._error = $value;
        }
    }

    @Input()
    get rtl(): boolean {
        return this._rtl;
    }
    set rtl($value: boolean) {
        if ($value !== undefined && $value !== null) {
            this._rtl = $value;
            this.dirAttr = $value ? 'rtl' : 'ltr';
        }
    }

    @Output('toggle') public toggle: EventEmitter<boolean> = new EventEmitter<boolean>();

    private _disabled: boolean = false;
    private _checked: boolean = false;
    private _error: boolean = false;
    private _rtl: boolean = false;

    constructor(
        @Optional() @Inject('RTL') isRTL: boolean,
    ) {
        if (isRTL != undefined) {
            this.rtl = isRTL;
        }
    }

    public propagateChange: (value: any) => void = (value) => {
        // control value accesor
    }

    public propagateTouch: () => any = () => {
        // control value accesor
    }

    public writeValue($value: boolean): void {
        // control value accesor
        if ($value !== undefined || $value !== null) {
            this._checked = $value;
        }
    }

    public registerOnChange($fn: any): void {
        // control value accesor
        this.propagateChange = $fn;
    }

    public registerOnTouched($fn: any): void {
        // control value accesor
        this.propagateTouch = $fn;
    }

    public setDisabledState($isDisabled: boolean): void {
        // control value accesor
        this.disabled = $isDisabled;
    }

    // component interactions
    @HostListener('click') public toggleCheckHost(): void {
        this.toggleValue();
    }

    public toggleValue(): void {
        if (this.disabled === false) {
            this.checked = !this.checked;
            this.toggle.emit(this.checked);
        }
    }
}
