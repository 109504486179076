import { Component, Input, Host } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { Filter } from '../../classes/filter.class';
import { WatcherService } from '../../services/watcher.service';
import { CmxTableComponent } from '../cmx-table/cmx-table.component';

@Component({
    selector: 'cmx-datepicker-filter',
    templateUrl: './cmx-datepicker-filter.component.html',
    styleUrls: ['./../../../../../../../../../../scss/cmx-table/v1/cmx-datepicker-filter.component.scss']
})
export class CmxDatepickerFilterComponent {
    @Input() public filter: Filter;

    public isRtl = false;
    private activeFilter: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(private watcher: WatcherService, @Host() private parent: CmxTableComponent) {
        this.isRtl = this.parent.rtl;
    }

    public listenToActiveFilter(): Observable<boolean> {
        return this.activeFilter.asObservable();
    }

    public activateFilter($event: any): void {
        this.filter.value = $event.value;
        this.filter.active = true;
        const $aux = [this.filter];
        this.activeFilter.next(true);
        this.watcher.emitActiveFilters(this.parent.assignedId, $aux);
    }

    public removeFilter(): void {
        this.filter.active = false;
        const $aux = [this.filter];
        this.activeFilter.next(false);
        this.watcher.emitActiveFilters(this.parent.assignedId, $aux);
    }
}
