/**
 * @author Abdelghani AINOUSS
 */

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
  Optional,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import AutoNumeric from '@cemex/autonumeric';

import { NgAutonumericOptionsSelect } from './autonumeric-options-select';
import { BasicInput } from './basic-input';
import { NumberInputComponent } from './number-input.component';

@Component({
  encapsulation: ViewEncapsulation.None,
  providers: [{
    multi: true,
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => NgAutonumericComponent),
  }],
  // tslint:disable-next-line
  selector: 'ng-autonumeric',
  styleUrls: ['./../../../../../../../../../scssv4/cmx-components/cmx-form-field/v4/cmx-form-field.component.scss'],
  templateUrl: './autonumeric.component.html',
})
export class NgAutonumericComponent extends BasicInput implements OnInit, OnChanges, ControlValueAccessor, AfterViewInit, OnDestroy {

  @Input() public align = 'center';
  @Input()
  public ngModel: number | string;
  @Input()
  public options: NgAutonumericOptionsSelect;
  @Input()
  public type: string;
  @ViewChild('input')
  public input: ElementRef;
  public instance: any;
  public unlistenFormatted: () => void;
  public internal: string;
  @Output()
  public format: any = new EventEmitter();

  constructor(
    @Optional() private host: NumberInputComponent,
    private cd: ChangeDetectorRef,
    private renderer: Renderer2,
  ) {
    super();
  }

  public _onChange = (_: any) => {
    //
  }

  public _onTouched = () => {
    //
  }

  public ngOnInit(): void {
    //
  }

  public ngAfterViewInit(): void {
    if (AutoNumeric === undefined) {
      // tslint:disable-next-line: max-line-length
      throw new Error('AutoNumeric is a peer dependency, please make sure you install it before using this library. Hint : npm install --save autonumeric@latest');
    }
    this.instance = new AutoNumeric(this.input.nativeElement, this.options);
    this.instance.set(this.ngModel);
    this.unlistenFormatted = this.renderer.listen(this.input.nativeElement, 'autoNumeric:formatted', ($event: any) => {
      this.onFormatted($event);
    });
    this.renderer.listen(this.input.nativeElement, 'autoNumeric:rawValueModified', ($event: any) => {
      const newVal: number = parseFloat($event.detail.newRawValue);
      if (newVal > this.host.max || newVal < this.host.min) {
        this.host.fakeValidator(true);
      } else {
        this.host.fakeValidator(false);
      }
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!this.instance) {
      return;
    }
    if (changes['ngModel']) {
      this.instance.set(this.ngModel);
    }
    if (changes['options']) {
      this.instance.update(this.options);
    }
  }

  public forceRefresh(): void {
    if (!this.instance) {
      return;
    }
    this.instance.update(this.options);
  }

  public set(value: any): void {
    if (this.instance) {
      this.instance.set(value);
    } else { throw new Error('NgAutonumeric instance not available. try using two binding by providing [(ngModel)]'); }
  }

  @HostListener('change', ['$event.target.value'])
  public handleChange(value: any): void {
    this.writeValue(value);
    if (this.instance) {
      value = this.instance.getNumber();
    }
    this._onChange(value);
    this._onTouched();
  }

  public onFormatted($event: any): void {
    this.format.emit($event);
  }

  @HostListener('blur')
  public handleTouched(): void {
    this._onTouched();
  }

  public registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public writeValue(obj: any): void {
    if (this.instance) {
      this.internal = this.instance.getFormatted();
    }
  }

  public ngOnDestroy(): void {
    if (this.unlistenFormatted) {
      this.unlistenFormatted();
    }
  }
}
