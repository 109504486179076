
export interface IUserNotificationResponse {
  typeNotification: number;
  notificationsCount: number;
  notifications?: any[];
}

export interface IUserNotificationRequest {
  typeNotification: number;
  notificationsCount: number;
  notifications?: any[];
}

export interface IUserNotificationCount {
  newCount: number;
  viewCount: number;
  totalCount?: number;
  readCount: number;
}

export interface IUserNotificationStatisticsItem {
  statusCode: string;
  count: number;
  lastUpdate: string;
}

export interface IUserNotificationFilterDto {
  pageNumber: number;
  pageSize: number;
  params: object;
  additionalProperties: string;
}

export enum UserNotificationStatusCodes {
  New = "N",
  Viewed = "V",
  Read = "R",
}

export interface IUserNotificationItem {
  notificationId: number;
  date: string;
  summary: string;
  statusCode: string;
  processCode: string;
  subprocessCode: string;
  content: string;
  isHtmlContent: boolean;

  isToday?: boolean;
  localDate?: string;
  localTime?: string;
  descProcessCode?: string;
  descSubProcessCode?: string;
  group?: string;
}

export interface IUserNotificationNotifications {
  count: number;
  serverDate: string;
  items: IUserNotificationItem[];
}

export interface IUserNotificationStatusDto {
  oldStatusCode: string;
  newStatusCode: string;
}

export interface IUserNotificationModuleDto {
  code: string;
  desc: string;
}

export interface IUserNotificationModule extends IUserNotificationModuleDto {
  subprocessItems: IUserNotificationModuleDto[];
}

export interface IUserNotificationModules {
  processItems: IUserNotificationModule[];
}

export interface IUserNotificationSuccessResponse {
  SuccessResponse: boolean;
}

export interface IUserNotificationException {
  moreInformation: string;
  httpMessage: string;
  reasonCode: string;
  message: string;
  httpCode: number;
}
// *************************************
// // #FIXME: this is a fake request used for development
export interface IFakeUserNotificationRequest {
  dateCreate: string; //  "2019-07-31T21:36:32"
  dateUpdate: string; //  "2019-07-31T21:36:32"
  enabled: boolean; //  true
  mandatory: boolean; //  true
  topicCode: string; //  "ORDCO"
  userCreateCode: string; //  "emoralesc"
  userUpdateCode: string; //  "emoralesc"

}

// ********************************************************************
// #FIXME: adjust SERVICES_LIST and ICONS
// #TODO: moved to user-notification-entry.dto.ts
export const iconsArray: any = {
  "-": "error.svg",
  "error": "error.svg",
  "info": "info.svg",
  "s1": "customerinfo.svg",
  "s10": "userprov.svg",
  "s2": "deliverysched.svg",
  "s3": "drivers.svg",
  "s4": "invoices.svg",
  "s5": "quotationpricing.svg",
  "s6": "orderprodcat.svg",
  "s7": "Payments_App.svg",
  "s8": "scheduling.svg",
  "s9": "track.svg",
};

export const fakeServiceTranslationDict: any = {
  ACCSTATE: "s1",
  CRMDISPUTE: "s2",
  LIENRELEASE: "s5",
  LRCRT: "s4",
  LRSBM: "s5",
  MDRSBM: "s1",
  ORDCO: "s2",
  PAYMENTS: "s5",
  PAYUNBLOCK: "s4",
  PoDCRT: "s5",
  PoDRSBM: "s1",
  QRSBM: "s2",
  QRUL: "s5",
};

export const fakeSubServiceTranslationDict: any = {
  ACCSTATE: "LOC",
  CRMDISPUTE: "OC",
  LIENRELEASE: "s3",
  LRCRT: "BI",
  LRSBM: "WC",
  MDRSBM: "QRE",
  ORDCO: "ORM",
  PAYMENTS: "s3",
  PAYUNBLOCK: "DNE",
  PoDCRT: "PWD",
  PoDRSBM: "QRE",
  QRSBM: "OSC",
  QRUL: "s3",
};

// // #FIXME: once S3 service available
export const descProcessCode: any = {
  S1: "Customer Information",
  S2: "Orders & Products",
  S4: "Invoice & Payments",
  S5: "User Provisioning",
};

// // #FIXME: once S3 service available
export const fakeDescMessageCode: any = {
  S1: "Su ubicación 67304 ha sido creada",
  S2: "New Delivery Time Selected",
  S3: "** hardcoded message **",
  S4: "Confirmation Payment",
  S5: "Password update",
};

// // #FIXME: once S3 service available
export const descSubProcessCode: any = {
  "S1.LOC": "Locations",
  "S1.QRE": "Quote Request",
  "S2.OC": "Order Confirmation",
  "S2.ORM": "Order Request Modifications",
  "S2.OSC": "Order Status Change",
  "S4.BI": "Billing Issues",
  "S4.DNE": "Documents Notification by Email",
  "S4.PMT": "Payments",
  "S4.RPT": "Reports",
  "S5.PWD": "Password",
  "S5.RE": "Request",
  "S5.WC": "Welcome",
};
