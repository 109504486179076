import { Directive, ViewContainerRef, HostBinding } from '@angular/core';

@Directive({
    selector: '[headerPlaceholder]',
})
export class HeaderPlaceholderDirective {
    @HostBinding( 'class.cmx-header' ) public cmxHeaderClass = true;

    constructor( public viewContainerReference: ViewContainerRef ) { }
}
