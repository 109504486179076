import { SortingOptions, SortingOrder } from "../models/sortHeader";
import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable()
export class SortingService {

    constructor() { }

    public orderCollection(collection: any[], options: SortingOptions, defaultOptions: SortingOptions): any[] {
        if (!options.sortHeader.order || !options.sortHeader.property) {
            return collection;
        }

        const sortCollection: any[] = this.orderByWithNullsAtEnd(collection, options, defaultOptions);
        return sortCollection;
    }

    private orderByWithNullsAtEnd(collection: any[], options: SortingOptions, defaultOptions: SortingOptions) {
        const partition = _.partition(collection, (x) => !!_.get(x, options.sortHeader.property, null));
        let orderType = [];
        let orderTypeDefault = [];
        orderType.push(options.sortHeader.order);
        orderTypeDefault.push(defaultOptions.sortHeader.order);

        return _.concat(
          options.sortCustomFunction ? _.orderBy(partition[0], [options.sortCustomFunction], orderType) :
          _.orderBy(partition[0], options.sortHeader.property, orderType),
          _.orderBy(partition[1], [defaultOptions.sortCustomFunction], orderTypeDefault));
      }
}
