import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CmxHttpClient } from "../services/api.service";
import { createHttpParams } from "../shared/helpers";
import {
  GetSchedulesResponse,
  GetSchedulesRequest,
  GetLoadsRequest,
  GetLoadsResponse
} from "../models/schedules";

@Injectable()
export class ScheduleService {
  constructor(private http: CmxHttpClient) {}

  // Get schedules Request
  getschedules(payload: GetSchedulesRequest): Observable<GetSchedulesResponse> {
    const httpParams = payload ? createHttpParams(payload) : new HttpParams();
    return this.http.get<GetSchedulesResponse>(`/v9/dm/schedules`, {
      params: httpParams
    });
  }

  getLoads(payload: GetLoadsRequest): Observable<GetLoadsResponse> {
    const httpParams = payload ? createHttpParams(payload) : new HttpParams();
    return this.http.get<GetLoadsResponse>(`/v1/ofm/loads`, {
      params: httpParams
    });
  }
}
