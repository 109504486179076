/**
 * @author Abdelghani AINOUSS
 * @description
 * Basic HTML attributes for numeric component
 */
import { Injectable, Input } from "@angular/core";
@Injectable()
export abstract class BasicInput {
  @Input()
  public required: boolean;
  @Input()
  _disabled: boolean;
  public get disabled(): boolean {
    return this._disabled;
  }
  public set disabled(value: boolean) {
    this._disabled = value;
  }
  @Input()
  public readonly: boolean;
  @Input()
  public title: string;
  @Input()
  public placeholder: string;
}
