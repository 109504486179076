<div class="cmx-stepper-container" [dir]="rtl? 'rtl' : ''">
  <ul class="cmx-stepper">        	
    <ng-container *ngFor="let step of steps; let i = index">
      <li (click)="goToStep(i)"
        [class.cmx-stepper__step]="!step.enabled && !step.active" 
        [class.cmx-stepper__step--active]="step.active"
        [class.cmx-stepper__step--done]=" step.enabled && !step.active">
        {{ step.title }}
      </li>
    </ng-container>
  </ul>
  <div class="cmx-stepper__controller" [dir]="rtl? 'rtl' : ''">
    <div class="mobile-stepper">
        <div class="prev">
            <span (click)="backClick()" class="cmx-icon-arrow-left-single"></span>
        </div>
        <div class="current" (click)="showSteps()">
            <span class="cmx-current-title">{{ currentStep.title }} </span>
            <span class="cmx-steps-action-open cmx-icon-arrow-up-single" [class.open]="!showStepsBit"></span>
        </div>
        <div class="next">
           <span (click)="nextClick()" class="cmx-icon-arrow-right-single"></span>
        </div>
  
  
        <div class="stepper-open" [class.open]="showStepsBit">
          <ng-container *ngFor="let step of steps; let last = last">
            <div class="step">
              <div class="cmx-step-icon" 
              [class.cmx-step-disabled]="!step.enabled && !step.active" 
              [class.cmx-step-completed]=" step.enabled && !step.active">
                <div *ngIf="!last" class="bottom-connector"></div>
              </div>
              <a href="#">{{ step.title }}</a>
            </div>
          </ng-container>
        </div>
        <!-- end .stepper-open -->
    </div>

    
  </div>
  <!-- <div class="cmx-bottom-bar d-flex justify-content-end">
    <button id="cmx-stepper-btn-cancel" cmx-button color="blue-inverse" (click)="cancel()">
      {{ _exitStepButtonText }}
    </button>
    <ng-container *ngIf="enabledBack && stepIndex > 0">
        <button id="cmx-stepper-btn-back" cmx-button color="blue" (click)="backClick()">
          {{ _backStepButtonText }}
        </button>
      </ng-container>
    <ng-container *ngIf="stepIndex < ( steps.length - 1 )">
      <button id="cmx-stepper-btn-next" cmx-button color="blue" [disabled]="!currentStep.done" (click)="nextClick()">
        {{ _nextStepButtonText }}
      </button>
    </ng-container>
    <ng-container *ngIf="stepIndex === ( steps.length - 1 )">
      <button id="cmx-stepper-btn-done" cmx-button color="green" [disabled]="!currentStep.done" (click)="finish()">
        {{ _lastStepButtonText }}
      </button>
    </ng-container>
  </div> -->
  <div class="cmx-stepper__content">
      <ng-content select="cmx-step"></ng-content>
  </div>


</div>
