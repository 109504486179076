import { Injectable } from '@angular/core';
import {
  ApplicationInsights,
  DistributedTracingModes,
  IPageViewTelemetry,
  Util,
} from '@microsoft/applicationinsights-web';
import { getLocation } from '@microsoft/applicationinsights-core-js';
import {
  Router,
  NavigationEnd,
  Navigation,
  RouterEvent,
} from '@angular/router';
import { filter } from 'rxjs/operators';
import { SessionService } from '../angular-services-v8/session.service';

@Injectable({
  providedIn: 'root',
})
export class AppInsightsService {
  private appInsights: ApplicationInsights;

  constructor(private router: Router, private NavigationEnd: Router, private sessionService:SessionService) {
    try {
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: window as {
            [key: string]: any;
          }['APPINSIGHTS_INSTRUMENTATIONKEY'],
          disableFetchTracking: false,
          enableCorsCorrelation: true,
          enableAjaxErrorStatusText: true,
          enableAjaxPerfTracking: true,
          distributedTracingMode: DistributedTracingModes.AI_AND_W3C,
          correlationHeaderDomains: ['*.cemexgo.com'],
        },
      });
      // this.loadAppInsights();
    } catch (error) {
      console.log(error);
    }
  }

  loadAppInsights() {
    try {
      if (this.appInsights && !this.appInsights.core.isInitialized()) {
        this.appInsights.loadAppInsights();
        this.subscribeToRouter();
      }
    } catch (error) {
      console.log(error);
    }
  }

  private subscribeToRouter() {
    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        )
      )
      .subscribe((event: NavigationEnd) => {
        try {
          if (this.appInsights) {
            const username = this.getUsername();
            if (username) {
              this.setAuthenticatedUserContext(username);
            } else {
              this.clearAuthenticatedUserContext();
            }
            const pageViewTelemetry: IPageViewTelemetry = {
              name: event.urlAfterRedirects,
            };
            this.trackPageView(pageViewTelemetry);
          }
        } catch (error) {
          console.log(error);
        }
      });
  }

  private trackPageView(pageView: IPageViewTelemetry) {
    try {
      if (this.appInsights) {
        const context = this.appInsights.context;
        if (context && context.telemetryTrace) {
          const location = getLocation();
          context.telemetryTrace.traceID = Util.generateW3CId();
          context.telemetryTrace.name =
            (location && location.pathname) || '_unknown_';
        }
        this.appInsights.trackPageView(pageView);
      }
    } catch (error) {
      console.log(error);
    }
  }

  private setAuthenticatedUserContext(userId: string, accountId?: string) {
    try {
      if (this.appInsights) {
        const context = this.appInsights.context;
        if (
          !context ||
          !context.user ||
          context.user.authenticatedId !== userId
        ) {
          this.appInsights.setAuthenticatedUserContext(userId, accountId, true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  private clearAuthenticatedUserContext() {
    try {
      if (this.appInsights) {
        this.appInsights.clearAuthenticatedUserContext();
      }
    } catch (error) {
      console.log(error);
    }
  }

  private getUsername(): string {
    try {
      const username = sessionStorage.getItem('username');
      if (!username) {
        const profileJson = this.sessionService.profile || undefined;
        return profileJson ? profileJson.userAccount : username;
      }
      return username;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
}
