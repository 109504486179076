<div class="cmx-radio" [attr.dir]="rtl? 'rtl' : 'ltr'">
  <input type="radio"
         [attr.data-tid]="dataTIdRadio"
         [checked]="checked"
         [class.error]="error"
         [disabled]="disabled"
         [id]="inputId"
         [name]="name"
         [value]="value"
         (change)="onInputChange( $event )">
  <label [attr.data-tid]="dataTIdLabel" [attr.for]="inputId" for="radio">
    <ng-content></ng-content>
  </label>
</div>
