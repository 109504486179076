<cwc-modal id="modal-3-ltr" [open]="viewModal" (cwcClose)="closeNotificationSettingsModal()" designVersion="v2">
  <header slot="header">
    <div class="notification-preferences">
      <div class="notification-preferences__header-icon">
        <span class="notification-preferences__icon">
          <cwc-icon name="notification" color="true-white" size="3rem"></cwc-icon>
        </span>
      </div>
      <p class="notification-preferences__title">{{ translationService.pt('views.modal.notification-preferences.title') }}</p>
    </div>
  </header>
  <div class="notification-preferences__description">
    {{ this.translationService.pt('views.modal.notification-preferences.text1') }}
    {{this.translationService.pt('views.modal.notification-preferences.text2')}}
  </div>
  <footer slot="footer">
    <div class="notification-preferences__footer align-end">
      <cwc-button variant="support" designVersion="v2" (cwcClick)="closeNotificationSettingsModal()">
        {{ translationService.pt('views.modal.notification-preferences.button.skip') }}
      </cwc-button>&nbsp;
      <cwc-button variant="regular" designVersion="v2" (cwcClick)="goToNotificationSettings()">
        {{ translationService.pt('views.modal.notification-preferences.button.go') }}
      </cwc-button>
    </div>
  </footer>
</cwc-modal>
