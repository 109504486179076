import { Injectable } from "@angular/core";
import { AuthGuard } from "./auth.guard";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";
import { MsalGuard } from "../msal-services-v1/guards/msal.guard";
import { IamAuthService } from "../msal-services-v1/services/iam-auth.service";
import { Observable } from "rxjs";


@Injectable({
    providedIn: 'root'
  })
  export class AuthFactoryGuard implements CanActivate {
    constructor(
      private authGuard: AuthGuard,
      private msalGuard: MsalGuard,
      private iamService: IamAuthService
    ) {}
  
    canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
      const isAuthIamStarted = this.iamService.isAuthIamStarted();
      
      return isAuthIamStarted 
        ? this.msalGuard.canActivate(route, state)
        : this.authGuard.canActivate(route, state);
    }
  }