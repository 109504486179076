import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  CmxWebComponentsFormsModule,
  CmxWebComponentsModule
} from '@cmx-web-components/angular';
import {
  CmxDashboardOrderHistoryCardComponent,
  CmxDashboardOrderHistoryCardService
} from './cmx-dashboard-order-history-card';
import { DashboardCardComponent } from './dashboard-card/dashboard-card/dashboard-card.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CmxInterceptor } from './cmx-interceptor.service';
import {
  CmxDashboardActiveLoadsCardComponent,
  CmxDashboardActiveLoadsCardService
} from './cmx-dashboard-active-loads-card';
import { ListChartCardComponent } from './list-chart-card';
import { CmxDashboardPaymentsCardComponent } from './cmx-dashboard-payments-card';
import { CmxCurrencyPipe } from '../../../../../../angular-localization-v7/pipes/cmx-currency.pipe';
import { DonutChartComponent } from './donut-chart/donut-chart.component';
import { CountlyEvents } from './countly-events.service';

@NgModule({
  declarations: [
    CmxDashboardOrderHistoryCardComponent,
    CmxDashboardPaymentsCardComponent,
    DashboardCardComponent,
    ListChartCardComponent,
    CmxDashboardActiveLoadsCardComponent,
    DonutChartComponent
  ],
  imports: [
    CommonModule,
    CmxWebComponentsModule.forRoot(),
    HttpClientModule,
    CmxWebComponentsFormsModule
  ],
  exports: [
    CmxDashboardOrderHistoryCardComponent,
    CmxDashboardActiveLoadsCardComponent,
    CmxDashboardPaymentsCardComponent
  ],
  providers: [
    CmxDashboardActiveLoadsCardService,
    CmxCurrencyPipe,
    { provide: HTTP_INTERCEPTORS, useClass: CmxInterceptor, multi: true },
    CountlyEvents
  ]
})
export class CmxDashboardComponentsModule {}
