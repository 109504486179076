import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StdLotSizeReq } from '../models/std-lot-size';
import { CmxHttpClient } from '../services/api.service';
import { createHttpParams } from '../shared/helpers';
import { MinLotSizeRequest } from '../models/std-lot-size/min-std-lot-size-req';
import { MinLotSizeResponse } from '../models/std-lot-size/min-std-lot-size-res';
import { StdLotSizeRes } from '../models/std-lot-size/std-lot-size-res';
import { MultipleStdLotSizeRes } from '../models/std-lot-size/multiple-std-lot-size-res';

@Injectable()
export class StdLotSizeService {
  constructor(private http: CmxHttpClient) {}

  getStdLotSize(request: StdLotSizeReq): Observable<StdLotSizeRes> {
    const httpParams = request ? createHttpParams(request) : new HttpParams();
    return this.http.get<StdLotSizeRes>(`/v6/sm/projects/${request.jobsiteId}/lots?`, {
      params: httpParams,
    });
  }

  getProductMinLotSize(request: MinLotSizeRequest): Observable<MinLotSizeResponse> {
    const httpParams = request ? createHttpParams(request) : new HttpParams();
    return this.http.get<MinLotSizeResponse>(`/v6/sm/projects/${request.productId}/lotmin?`, {
      params: httpParams,
    });
  }

  getMultipleStdLotSize(request: StdLotSizeReq): Observable<MultipleStdLotSizeRes> {
    const httpParams = request ? createHttpParams(request) : new HttpParams();
    return this.http.get<MultipleStdLotSizeRes>(`/v7/sm/projects/${request.jobsiteId}/lots?`, {
      params: httpParams,
    });
  }
}
