export class CookiebotConstants {
  public static RELOAD_PAGES: string[] =
    [
      'resetPassword',
      'forgotPassword',
      'public',
      'invoices-payments',
      'track',
      'ordersnproduct',
      'customer-dashboards',
    ];
    public static CX_ENABLE_COOKIEBOT: boolean = (window as any).CX_ENABLE_COOKIEBOT !== 'false';
    public static COOKIEBOT_KEY: string = (window as any).COOKIEBOT_KEY || '';
    public static PAGES_WIDGET_ENABLED: string[] =
    [
      '/login',
      '/settings',
      '/track/login',
    ];

    public static URL_COOKIEBOT = 'https://consent.cookiebot.com/uc.js';
    public static URL_COOKIEBOT_DECLARATION = 'https://consent.cookiebot.com/';
    public static URL_COOKIEBOT_SUFFIX = '/cd.js';
}

