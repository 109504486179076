import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CmxHttpClient } from '../services/api.service';
import { createHttpParams } from '../shared/helpers';

// INTERFACES
import { HoldingSaleAreaManagementRes } from '../models/holding-sales-area-management/holding-sale-area-management-res';
import { HoldingSalesAreaManagementReq } from '../models/holding-sales-area-management/holding-sales-area-management-req';
import { HoldingSalesAreaManagementRes } from '../models/holding-sales-area-management/holding-sales-area-management-res';

@Injectable()
export class HoldingSalesAreaManagementService {
  constructor(private http: CmxHttpClient) {}

  // GET ALL HOLDING SALES AREA MANAGEMENT
  getHoldingSalesAreaManagement(payload: HoldingSalesAreaManagementReq): Observable<HoldingSalesAreaManagementRes> {
    const httpParams = payload ? createHttpParams(payload) : new HttpParams();
    return this.http.get<HoldingSalesAreaManagementRes>(`/v5/sm/holdingsalesareas`, {
      params: httpParams,
    });
  }

  // GET SINGLE HOLDING SALES AREA MANAGEMENT
  getHoldingSaleAreaManagement(holdingSalesAreaId: string): Observable<HoldingSaleAreaManagementRes> {
    return this.http.get<HoldingSaleAreaManagementRes>(`/v5/sm/holdingsalesareas/${holdingSalesAreaId}`);
  }
}
