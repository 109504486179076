import { Component } from '@angular/core';

@Component({
    selector: 'cmx-dialog-body',
    template: '<ng-content></ng-content>',
})
export class CmxDialogBodyComponent {
    constructor() { }

}
