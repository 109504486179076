import { Component, Input } from "@angular/core";

@Component({
  selector: "cmx-tags",
  styleUrls: ["./../../../../../scssv4/cmx-components/cmx-tags/v4/cmx-tags.component.scss"],
  templateUrl: "tags.component.html",
})
export class CmxTagsComponent {
  @Input() public tags: string[];

  public OnDeleteTag(tag: string): void {
    const index: number = this.tags.indexOf(tag);

    if (index !== -1) {
      this.tags.splice(index, 1);
    }
  }
}
