import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DCMConstants } from '../helpers/DCM.constants';

@Injectable()
export class GoogleMapsService {
  constructor(private http:HttpClient) { }

  private static promise:any;
  private static http: any;

  public static load(config:any) {
    // First time 'load' is called?
    if (!GoogleMapsService.promise) {
      // Make promise to load
      GoogleMapsService.promise = new Promise(resolve => {
        // Set callback for when google maps is loaded.
        window['__onGoogleLoaded'] = (ev) => {
          resolve('google maps api loaded');
        };
        const node = document.createElement('script');
        const key = config.key || window['GOOGLE_MAPS_API_KEY'];
        if (key === undefined) {
          console.log('Missing Google Maps api key');
        }
        const language = config.language || window['LANGUAGE'];
        node.src = `https://maps.googleapis.com/maps/api/js?key=${key}&loading=async&language=${language}&callback=__onGoogleLoaded`;
        node.type = 'text/javascript';
        node.defer = true;
        document.getElementsByTagName('head')[0].appendChild(node);
      });
    }
    // Always return promise. When 'load' is called many times, the promise is already resolved.
    return GoogleMapsService.promise;
  }

  public getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resp => {
          resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
        });
    });
  }

  public getCountry(lat: string, lng: string): Observable<any>{
    const key = window['GOOGLE_MAPS_API_KEY'];
    let url = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + ',' + lng + '&sensor=false&key=' + key ;
    return this.http.get(url).pipe(map(response => response));
  }

}
