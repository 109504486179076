
import { throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

// Providers
import { UserService } from './user.service';

@Injectable()
export class UserDetailsResolver implements Resolve<any> {

    constructor(private userService: UserService) { }

    public resolve(route: ActivatedRouteSnapshot) {

        return this.userService.getUserDetails(route.params['id']);
    }

    private handleError(error: any) {
        return observableThrowError(error || 'Server error');
    }
}
