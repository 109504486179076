export class DocumentVersion {
    documentVersionId: number;
    documentVersionDesc: string;
    dataVersion: string;
    requireSignature: boolean;
    signatureInfo: SignatureInfo;
    configuration: Configuration;
}

class Document {
    documentId: number;
    documentCode: string;
}

class Configuration {
    documentConfigurationId: number;
    document: Document;
}

class SignatureInfo {
    signatures: Signature[];
}

class Signature {
    signatureId: number;
    agreementVersionId: number;
}
