import {
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Output,
} from '@angular/core';

// tslint:disable-next-line
@Directive({ selector: '[clickOutside]' })
export class ClickOutsideDirective {

    @Output()
    public clickOutside: any = new EventEmitter();

    constructor(private _elementRef: ElementRef) { }

    @HostListener('document:click', ['$event.target'])
    public onClick(targetElement: any): void {
        const clickedInside: boolean = this._elementRef.nativeElement.contains(targetElement);
        if (!clickedInside) {
            this.clickOutside.emit();
        }
    }
}
