export class Segmentation {
    public segmentationId: number;
    public segmentationCode: string;
    public segmentationDesc: string;
    public countryCode: string;
    public segmentationType: SegmentationType;
}

class SegmentationType {
    segmentationTypeCode: string;
    segmentationTypeId: number;
}
