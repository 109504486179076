import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { VehicleResp } from '../models/vehicles/vehicle-resp';
import { CmxHttpClient } from '../services/api.service';

@Injectable()
export class VehiclesService {
  constructor(private http: CmxHttpClient) {}

  getVehicles(haulerId: number): Observable<VehicleResp> {
    return this.http.get(`/v7/dm/vehicles?haulerId=${haulerId}`);
  }
}
