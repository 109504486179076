<cwc-card design-version="v2">
    <div
      [ngClass]="{
        confined: confined, 'p-all-reg': padding
      }"
    >
      <div class="widget-card__header">
        <span class="widget-card__title" *ngIf="title; else titleAlt">{{ title }}</span>
        <ng-template #titleAlt>
          <ng-content select="[cardHeaderLeft]"></ng-content>
        </ng-template>
        <ng-content select="[cardHeaderRight]"></ng-content>
      </div>
      <div class="widget-card__content"><ng-content select="[cardBody]"></ng-content></div>
    </div>
  </cwc-card>
  