export class Address {
    public addressId: number;
    public addressCode: string;
    public cityDesc: string;
    public regionId: number;
    public regionCode: string;
    public regionDesc: string;
    public countryCode: string;
    public countryDesc: string;
    public streetName: string;
    public domicileNum: string;
    public settlementDesc: string;
    public postalCode: string;
    public geoPlace: GeoPlace;

}

class GeoPlace {
    public longitude?: number;
    public latitude?: number;
}
