import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CmxHttpClient } from '../services/api.service';
import { createHttpParams } from '../shared/helpers';

// INTERFACES
import { UpdatePatchResponse } from '../models/common';
import { CreateProfileRequest } from '../models/profiles-management/create-profile-request';
import { Profile } from '../models/profiles-management/profile';
import { ProfilesRequest } from '../models/profiles-management/profiles-request';
import { ProfilesResponse } from '../models/profiles-management/profiles-response';

@Injectable()
export class ProfileManagementService {
  constructor(private http: CmxHttpClient) {}

  // GET PROFILES GET REQUEST
  getProfiles(payload: ProfilesRequest): Observable<ProfilesResponse> {
    const httpParams = payload ? createHttpParams(payload) : new HttpParams();
    return this.http.get<ProfilesResponse>(`/v1/rc/myprofiles`, { params: httpParams });
  }

  // GET A SINGLE PROFILE
  getProfile(profileId: string | number) {
    return this.http.get<Profile>(`/v1/rc/profiles/${profileId}`);
  }

  // UPDATE PROFILE PUT REQUEST
  updateProfile(profileId: number, payload: Profile): Observable<UpdatePatchResponse> {
    return this.http.put<UpdatePatchResponse>(`/v1/rc/profiles/${profileId}`, payload);
  }

  // CREATE PROFILE POST REQUEST
  createProfile(profile: CreateProfileRequest): Observable<Profile> {
    return this.http.post<Profile>(`/v1/rc/profiles`, profile);
  }

  // REMOVE PROFILE DELETE REQUEST
  removeProfile(profileId: string | number) {
    return this.http.delete(`/v1/rc/profiles/${profileId}`);
  }
}
