import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

// tslint:disable-next-line:pipe-naming
@Pipe({name: 'replaceString'})
export class ReplaceStringPipe implements PipeTransform {
    constructor(protected sanitizer: DomSanitizer) {}
    public transform(wholeString: string, toReplace: string, replacedFor: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(wholeString.replace(toReplace, replacedFor));
    }
}
