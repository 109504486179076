import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { DateNamesService } from "./../../services/date-names.service";
import { IYear } from "./../../models/datepicker-interfaces";

@Component({
    selector: "year-selector",
    styleUrls: ["./../../../../../../../scssv4/cmx-components/cmx-datepicker/v4/cmx-datepicker.component.scss"],
    templateUrl: "./year-selector.component.html",
})
export class YearSelectorComponent implements OnInit {
    @Input() public currentDate: Date;
    @Input()
    get max(): Date {
        return this._max;
    }
    set max($value: Date) {
        this._max = $value;
        if (this.currentDate && this._min) {
            this.reset();
        }
    }
    @Input()
    get min(): Date {
        return this._min;
    }
    set min($value: Date) {
        this._min = $value;
        if (this.currentDate && this._min) {
            this.reset();
        }
    }

    @Output() public selectedYear: EventEmitter<number> = new EventEmitter<number>();
    @Output() public actionView: EventEmitter<string> = new EventEmitter<string>();

    public currentMonth: string;
    public currentYear: number;
    public yearsRange: IYear[][] = [];
    public rangeIncrement: number = 15;
    private _max: Date;
    private _min: Date;

    constructor(private dateNames: DateNamesService) {
        //
    }

    public ngOnInit(): void {
        this.reset();
    }

    public previousYearsRange(): void {
        if (this._min.getFullYear() < (this.yearsRange[0][0]).number) {
            this.modifyYearsRange(this.rangeIncrement * -1);
        }
    }

    public nextYearsRange(): void {
        if (this._max.getFullYear() >= (this.yearsRange[0][0]).number + this.rangeIncrement) {
            this.modifyYearsRange(this.rangeIncrement);
        }
    }

    public monthToggle(): void {
        this.actionView.emit("monthView");
    }

    public yearToggle(): void {
        this.selectedYear.emit(this.currentYear);
        this.actionView.emit("calendarView");
    }

    public selectYear(year: IYear): void {
        if (!year.disabled) {
            this.selectedYear.emit(year.number);
        }
    }

    private reset(): void {
        this.currentMonth = this.dateNames.getMonthName(this.currentDate.getMonth());
        this.currentYear = this.currentDate.getFullYear();
        this.getYearsRange();
    }

    private getYearsRange(): void {
        const yearsArray: IYear[] = [];
        const rangeMax: number = Math.floor(this.rangeIncrement / 2);
        const rangeMin: number = rangeMax * -1;
        const rangeCol: number = Math.floor(this.rangeIncrement / 5);
        for (let i: number = rangeMin; i <= rangeMax; i++) {
            const auxYear: number = this.currentYear + i;
            const aux: IYear = {
                disabled: (auxYear < this._min.getFullYear() || auxYear > this._max.getFullYear()),
                number: auxYear,
            };
            yearsArray.push(aux);
        }
        this.yearsRange = [];
        const auxRows: IYear[][] = [];
        let auxRowsIndex: number = 0;
        let yearsCounter: number = 0;
        while (yearsCounter < this.rangeIncrement) {
            auxRows[auxRowsIndex] = yearsArray.slice(yearsCounter, yearsCounter + rangeCol);
            auxRowsIndex += 1;
            yearsCounter += rangeCol;
        }
        this.yearsRange = auxRows;
    }

    private modifyYearsRange(value: number): void {
        const auxYearsRange: IYear[][] = [];
        this.yearsRange.forEach(($row: IYear[]) => {
            const auxRow: any[] = [];
            $row.forEach(($year: IYear) => {
                const auxYear: number = $year.number + value;
                const aux: IYear = {
                    disabled: (auxYear < this._min.getFullYear() || auxYear > this._max.getFullYear()),
                    number: auxYear,
                };
                auxRow.push(aux);
            });
            auxYearsRange.push(auxRow);
        });
        this.yearsRange = auxYearsRange;
    }
}
