import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { CmxHttpClient } from './http-client.service';
import { UserProcess, UserProcessCat } from '../models/userProcess';

@Injectable()
export class UserProcessService {

    constructor(private cmxHttp: CmxHttpClient) { }

    public getProcessesCat() {
        const uri = `v3/im/processes`;
        return this.cmxHttp.get<UserProcessCat[]>(uri)
            .pipe(map((res) => res ? res['processes'] : []));
    }

    public getUserProcesses() {
        const uri = `v6/secm/users/processes`;
        return this.cmxHttp.get<UserProcess[]>(uri)
            .pipe(map((res) => res ? res['processes'] : []));
    }

    updateUserProcess(processId: number) {
        const uri = `v6/secm/users/processes/${processId}`;
        return this.cmxHttp.post(uri, null);
    }

    deleteUserProcess(userId: number, processId: number) {
      const uri = `v6/secm/users/${userId}/processes/${processId}`;
      return this.cmxHttp.delete(uri).pipe(map((res) => res ? res : null));
    }
}
