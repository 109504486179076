<div class="login__language" *ngIf="languages" [dir]="rtl ? 'rtl' : 'ltr'">
  <cwc-button data-id="cmx-login-languages-dropdown" variant="link" class="hydrated" (click)="langModalShow()" [rtl]="rtl">
    {{ currentLanguage?.languageName || "Language" }}
  </cwc-button>
  <cwc-modal designVersion="v2" [open]="langModalOpen" (cwcClose)="langModalHide()" [rtl]="rtl">
    <header slot="header">
      <h2>{{ this.lang["views.login.languageSelect"] }}</h2>
    </header>
    <ul class="login__language-list">
      <li *ngFor="let language of languages" class="login__language-list-item
      {{currentLanguage?.languageISO === language?.languageISO ? 'active' : ''}}">
        <cwc-button variant="link" (click)="changeLanguage(language); langModalHide()" [rtl]="rtl">
          <span [attr.data-tid]=" 'cmx-login-languages-item-' + language?.languageISO">
            {{ language?.languageName }}
          </span>
        </cwc-button>
      </li>
    </ul>
  </cwc-modal>
</div>
