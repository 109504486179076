import { Inject, Injectable, Optional } from '@angular/core';
import { Headers, RequestOptions, Response } from '@angular/http';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { SessionServiceConstants } from './constants-v2';
import { Broadcaster } from 'src/app/events-v7/projects/events-v7/src/lib/broadcaster.event';
import {
  IApplicationMenu,
  ICustomer,
  ILegalEntity,
  IUserProfile
} from '../angular-types-v2/index.interface';

import { HttpCemex } from './http.service';

export enum Regions {
  AMEA = 'AMEA',
  EU = 'Europe',
  MX = 'Mexico',
  US = 'US',
  SCACC = 'SCA&CC',
}

@Injectable()
export class SessionService {
  public static LOGIN_SUCCESS_EVENT = 'LOGIN_SUCCESS_EVENT';
  public static LOGIN_FAIL_EVENT = 'LOGIN_FAIL_EVENT';
  public static LOGIN_LOGOUT_EVENT = 'LOGIN_LOGOUT_EVENT';
  public static AUTH_TOKEN_VERSION = 'auth_token_version';

  private siteDomain = 'SITE_DOMAIN';
  private _siteDomain = window[this.siteDomain] || '';
  private _endpoint: string;
  private _menuApplication = new BehaviorSubject<IApplicationMenu[]>([]);
  private _currentLegalEntity = new BehaviorSubject<ILegalEntity>(null);
  private _userProfile: IUserProfile = null;
  private _beforeLogout: Observable<any> = null;

  private scaccCountries = ['CO', 'CR', 'DO', 'GT', 'NI', 'PA', 'PE', 'PR', 'SV'];
  private europeCountries = ['CZ', 'DE', 'ES', 'FR', 'GB', 'PL'];
  private ameaCountries = ['AE', 'EG', 'IL', 'PH'];

  useSession = window['CX_ENABLE_SESSIONSTORAGE'] || false;
  constructor(
    protected http: HttpCemex,
    protected eventBroadcaster: Broadcaster,
    @Inject(SessionService.AUTH_TOKEN_VERSION)
    @Optional()
    private authTokenVersion: string
  ) {
    if (authTokenVersion) {
      switch (authTokenVersion.toLocaleLowerCase()) {
        case 'v6':
          this._endpoint = SessionServiceConstants.LOGIN_TOKEN_V6;
          break;
        default:
          this._endpoint = SessionServiceConstants.LOGIN_TOKEN_DEFAULT;
      }
    } else {
      this._endpoint = SessionServiceConstants.LOGIN_TOKEN_DEFAULT;
    }
    if (
      localStorage.getItem('language') === undefined ||
      (localStorage.getItem('language') === null &&
        (sessionStorage.getItem('language') !== undefined && sessionStorage.getItem('language') !== null))
    ) {
      localStorage.setItem('language', sessionStorage.getItem('language'));
    }
  }

  public get menuApplicationItems(): Observable<IApplicationMenu[]> {
    if (this._menuApplication.getValue().length === 0) {
      this.reloadAppMenuItems();
    }
    return this._menuApplication.asObservable();
  }

  public menuApplicationItemsParam(applicationCode?: string): Observable<IApplicationMenu[]> {
    if (this._menuApplication.getValue().length === 0) {
      this.reloadAppMenuItems(applicationCode);
    }
    return this._menuApplication.asObservable();
  }

  public setLegalEntity(value: ILegalEntity) {
    sessionStorage.setItem('user_legal_entity', JSON.stringify(value));
    this.eventBroadcaster.broadcast(Broadcaster.DCM_LEGAL_ENTITY_CHANGE, JSON.stringify(value));
    this._currentLegalEntity.next(value);
  }

  public get currentLegalEntity(): Observable<ILegalEntity> {
    return this._currentLegalEntity.asObservable();
  }

  public get isLoggedIn(): boolean {
    const accessToken =  (this.dataSession && this.dataSession["oauth2"]) ?  this.dataSession.oauth2.access_token :null;
    const jwtToken =   this.dataSession ?  this.dataSession.jwt : null;

    return (
      accessToken !== null &&
      accessToken !== undefined &&
      accessToken !== '' &&
      jwtToken != null &&
      jwtToken !== undefined &&
      jwtToken !== ''
    );
  }

  public logout(): Observable<{
    success: boolean;
    message: string;
  }> {
    return new Observable(observer => {
      if (this._beforeLogout) {
        this._beforeLogout.subscribe(
          result => {
            this.logoutApp();
            observer.next({
              message: 'success',
              success: true
            });
          },
          error => {
            observer.next({
              message: error,
              success: false
            });
          }
        );
      } else {
        this.logoutApp();
        observer.next({
          message: 'success',
          success: true
        });
      }

      return observer;
    });
  }

  public login(
    user: string,
    password: string,
    generateCookie?: boolean,
    appName?: string,
    validateTempPassword?: boolean,
    grant_type?: string,
    scope?: string,
    userInformation?: string,
    include?: string,
    mprCode?: boolean,
    consent?: any
  ): Promise<any> {
    this.clean();
    const options = new RequestOptions({
      headers: new Headers({
        'Content-Type': 'application/json',
        accept: 'text/plain, */*'
      })
    });
    mprCode ? options.headers.append('App-Code','MPR') : null;
    const endpoint = this.http.generateEndpoint(this._endpoint);
    const urlPost = this.generateJsonBody(user, password, appName, grant_type, scope, userInformation, include, consent);
    return this.http
      .post(endpoint, urlPost, options)
      .toPromise()
      .then(async response => {
        const data = response.json();
        const userId =
          this.authTokenVersion === 'v6'
            ? data.userId
              ? data.userId
              : data.profile.userId
            : data.profile.userId;
        this.saveProcessDataFromLogin(data, generateCookie);
        if(this.useSession){
          this.processDataFromLogin(data, generateCookie);   
        }      

        this.eventBroadcaster.broadcast(SessionService.LOGIN_SUCCESS_EVENT);

        if (
          validateTempPassword &&
          data.profile &&
          data.profile.hasTemporaryPassword
        ) {
          return Promise.resolve(data);
        }
      })
      .catch(error => {
        console.error(error);
        this.eventBroadcaster.broadcast(SessionService.LOGIN_FAIL_EVENT);
        return Promise.reject(error);
      });
  }

  public get userRegion(): string {
    return  (this.dataSession && this.dataSession["oauth2"]) ? this.dataSession.oauth2.region : 'US' ; 
  }

  public get userCountry(): string {
    return  (this.dataSession && this.dataSession.country) ? this.dataSession.country : '' ;
  }


  public get country(): string {   
    return  (this.dataSession && this.dataSession.country) ? this.dataSession.country : '' ;
  }

  public get userProfile(): IUserProfile {
    if (!this._userProfile) {
      this.setUserProfile();
    }

    return this._userProfile;
  }

  public getLegalEntities(
    fetch?: number,
    page?: number,
    legalEntityCode?: string,
  ): Observable<ILegalEntity[]> {
    let endpoint = this.http.generateEndpoint(SessionServiceConstants.USER_LEGAL_ENTITIES);
    endpoint = this.addQueryParams(endpoint, fetch, page, legalEntityCode);

    return this.http.get(endpoint).pipe(
      map((response: Response) => {
        let responseMap = [];
        const tmpJson = response.json();
        if (tmpJson) {
          responseMap = response.json();
        }
        return responseMap;
      }),
      catchError(e => throwError(this.handleError(e)))
    );
  }

  public setBeforeLogout(method: Observable<any>): void {
    this._beforeLogout = method;
  }

  public getBeforeLogout(): Observable<any> {
    return this._beforeLogout;
  }

  public reloadAppMenuItems(applicationCode?: string): void {
    this.getDCMApplications(applicationCode).subscribe(result => {
      this._menuApplication.next(result);
    });
  }

  public createCookie(name: string, value: string, days?: number) {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = date.toUTCString();
    } else {
      days = 0;
    }

    const cookie = `${name}=${value};expires=${expires};domain=${this._siteDomain};path=/`;
    document.cookie = cookie;
  }

  public readCookie(name: string): string {
    const ca = document.cookie.split(';');
    const caLen: number = ca.length;
    const cookieName = `${name}=`;
    let c: string;
    for (let i = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) === 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return null;
  }

  public clearCookie(name:any) {
    this.createCookie(name, '', -1);
  }

  public setUserProfile(): void {
    const tmpProfile: IUserProfile = this.dataSession["profile"];
    if (tmpProfile) {
      const userCustomer:ICustomer = this.dataSession["customer"];
      if (userCustomer) {
        tmpProfile.customer = userCustomer;
      }
      this._userProfile = tmpProfile;
    }
  }

  public clean(): void {
    this.clearCookie('CemexGoRegion');
    this.clearCookie('up_session');
    this.cleanSessionStorge();
    sessionStorage.removeItem('_cmx_');
    this._menuApplication.next([]);
  }

  public generateUrlString(
    user: string,
    password: string,
    appName?: string
  ): string {
    const include = '&include=userinfo,profile,applications,roles,customers,oldVersion';
    let url =
      'grant_type=password&scope=security&username=' +
      user +
      '&password=' +
      encodeURIComponent(password) +
      '&client_id=' +
      this.http.clientId;

    if (appName) { url = url + '&app_name=' + appName; }
    if (this.authTokenVersion) {
      if (this.authTokenVersion.toLowerCase() === 'v6') {
        url = `${url}&userinformation=false${include}`;
      }
    }
    return url;
  }

  public generateJsonBody(
    userName: string,
    password: string,
    appName?: string,
    grant_type?: string,
    scope?: string,
    userInformation?: string,
    include?: string,
    consent?: any
  ): string {
    const body = {
    grant_type : grant_type != null ? grant_type : 'password' ,
    scope : scope != null ? scope : 'security',
    username : userName,
    password : password,
    app_name : appName,
    userInformation : userInformation != null ? userInformation : 'false',
    include : include != null ? include : 'userinfo,profile,applications,roles,customers,oldVersion',
    consent : consent
  };


    return JSON.stringify(body);
  }

  public getDCMApplications(applicationCode?: string): Observable<IApplicationMenu[]> {
    let endpoint = this.http.generateEndpoint(SessionServiceConstants.USER_APPLICATION_MENU);
    if (applicationCode) {
      endpoint = endpoint + '?applicationCode=' + applicationCode;
    }
    return this.http.get(endpoint).pipe(
      map((response: Response) => {
        const tmpJson = response.json();
        if (!tmpJson || tmpJson === undefined) {
          console.warn('Configuration of menu application is empty!!');
          return [];
        }
        const responseMap: IApplicationMenu[] = response.json() as IApplicationMenu[];
        return responseMap;
      }),
      catchError((e: any) => throwError(this.handleError(e)))
    );
  }

  public convertJsonTo<T>(obj: string): T {
    try {
      const tmpJson = JSON.parse(obj);
      return tmpJson as T;
    } catch (e) {
      console.warn('Exception at parsing object from sessionStore:', e);
    }

    return null;
  }

  public get customer(): ICustomer {
    return Object.keys(this.dataSession.customer).length > 0 ?  this.dataSession.customer as ICustomer : null; 
  }

  public handleError(error: Response | any): any {
    // In a real world app, we might use a remote logging infrastructure
    let errMsg: string;
    if (error instanceof Response) {
      const body = error.json() || '';
      errMsg = `${error.status} - ${error.statusText || ''}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(error);
    return throwError(error);
  }

  public addQueryParams(endpoint: string, fetch?: number, page?: number, legalEntityCode?: string) {
    const validPagination: boolean = Number.isInteger(fetch) && Number.isInteger(page);

    endpoint += validPagination ? '?fetch=' + fetch + '&page=' + page : '';
    endpoint +=
      legalEntityCode && validPagination
        ? '&legalEntityCode=' + legalEntityCode
        : legalEntityCode && !validPagination
          ? '?legalEntityCode=' + legalEntityCode
          : '';

    return endpoint;
  }

  public logoutApp(): void {
    this.clean();
    this.eventBroadcaster.broadcast(Broadcaster.DCM_APP_LOGOUT);
  }

  public getUserRegionByCountry(countryCode?: string): string {
    if (!countryCode) {
      countryCode = this.dataSession.country;
    }
    
    let region = '';
    if (countryCode === 'MX') {
      region = Regions.MX;
    } else if (countryCode === 'US') {
      region = Regions.US;
    } else if (!!this.scaccCountries.find((c) => c === countryCode)) {
      region = Regions.SCACC;
    } else if (!!this.europeCountries.find((c) => c === countryCode)) {
      region = Regions.EU;
    } else if (!!this.ameaCountries.find((c) => c === countryCode)) {
      region = Regions.AMEA;
    }
    return region;
  }

  public get userSegment(): string {
    return sessionStorage.getItem('userSegment') || '';
  }

  public get userSubsegment(): string {
    return sessionStorage.getItem('userSubsegment') || '';
  }

  public encrypt(str) {
    return window.btoa(unescape(encodeURIComponent(str)));
  }

  public decrypt(str) {
    return str ? decodeURIComponent(escape(window.atob(str))) : str;
  }

  private saveProcessDataFromLogin(data:any,  generateCookie?: boolean){
    sessionStorage.setItem('_cmx_', this.encrypt(JSON.stringify(data)));

    if (generateCookie) {
      const sessionCookie = {
        auth: data.oauth2.access_token,
        expires_in: data.oauth2.expires_in,
        jwt: data.jwt,
        refresh_token: data.oauth2.refresh_token,
        region: data.oauth2.region,
      };
      this.createCookie('up_session', JSON.stringify(sessionCookie), null);
    }
    this.createCookie('CemexGoRegion', data.oauth2.region, null);
    this.setUserProfile();
    sessionStorage.setItem('sessionId', data.profile.userId);
    sessionStorage.setItem('role', data.role);
    sessionStorage.setItem('country', data.country);
  }


  public get dataSession(){
    let data = sessionStorage.getItem("_cmx_");
    return data ?  JSON.parse(this.decrypt(data)) : {} ;
  }

  public get applicationsList(){
    return  (this.dataSession && this.dataSession.applications) ?  this.dataSession.applications : [];
  }

  public get access_token(){
    return (this.dataSession && this.dataSession["oauth2"]) ?  this.dataSession.oauth2.access_token : null;
  }

  public get refresh_token(){
    return (this.dataSession && this.dataSession["oauth2"]) ?  this.dataSession.oauth2.refresh_token : null;
  }

  public get jwt(){
    return (this.dataSession && this.dataSession.jwt) ?  this.dataSession.jwt : null;
  }

  public get profile(){
    return (this.dataSession && this.dataSession.profile) ?  this.dataSession.profile : {};
  }

  public get sessionId(){
    return (this.dataSession && this.dataSession["profile"]) ?  this.dataSession.profile.userId : null;
  }

public get customerDetail(){
  return (this.dataSession && this.dataSession.customer) ?  this.dataSession.customer : {};
}

public get expiresIn(){
  return (this.dataSession && this.dataSession["oauth2"]) ? this.dataSession.oauth2.expires_in : null; 
}

public get role(){
  return (this.dataSession && this.dataSession.role) ? this.dataSession.role : null;
}

public get username(){
  return (this.dataSession && this.dataSession["profile"]) ?  this.dataSession.profile.userAccount : null;
}

public updateDataSession(data:any){
  sessionStorage.setItem('_cmx_', this.encrypt(JSON.stringify(data)));
}

public processDataFromLogin(data: any, generateCookie?: boolean): void {
  // session cookie
  if (generateCookie) {
    const sessionCookie = {
      auth: data.oauth2.access_token,
      expires_in: data.oauth2.expires_in,
      jwt: data.jwt,
      refresh_token: data.oauth2.refresh_token,
      region: data.oauth2.region,
    };
    this.createCookie('up_session', JSON.stringify(sessionCookie), null);
    this.setUserProfile();
  }

  this.createCookie('CemexGoRegion', data.oauth2.region, null);

  sessionStorage.setItem('access_token', data.oauth2.access_token);
  sessionStorage.setItem('refresh_token', data.oauth2.refresh_token);
  sessionStorage.setItem('expires_in', data.oauth2.expires_in);
  sessionStorage.setItem('jwt', data.jwt);
  sessionStorage.setItem('auth_token', data.oauth2.access_token);
  sessionStorage.setItem('user_profile', JSON.stringify(data.profile));
  sessionStorage.setItem('user_customer', JSON.stringify(data.customer));
  sessionStorage.setItem('user_applications', JSON.stringify(data.applications));
  sessionStorage.setItem('sessionId', data.profile.userId);

  // react
  sessionStorage.setItem('userInfo', JSON.stringify(data));
  sessionStorage.setItem('country', data.country);
  sessionStorage.setItem('token_data', JSON.stringify(data));
  sessionStorage.setItem('applications', JSON.stringify(data.applications));

  sessionStorage.setItem('region', data.oauth2.region);
  sessionStorage.setItem('role', data.role);
  sessionStorage.setItem('username', data.profile.userAccount);

  this.setUserProfile();
}

public cleanSessionStorge(){
  sessionStorage.removeItem('access_token');
  sessionStorage.removeItem('refresh_token');
  sessionStorage.removeItem('expires_in');
  sessionStorage.removeItem('jwt');
  sessionStorage.removeItem('region');
  sessionStorage.removeItem('user_profile');
  sessionStorage.removeItem('user_customer');
  sessionStorage.removeItem('user_legal_entity');
  sessionStorage.removeItem('user_applications');
  // patch-fix for react
  sessionStorage.removeItem('auth_token');
  sessionStorage.removeItem('userInfo');

  sessionStorage.removeItem('token_data');
  sessionStorage.removeItem('applications');

  sessionStorage.removeItem('username');
}

}
