export class FunctionGroup {
    public functionGroupCode: string;
    public functionGroupId: number;
    public countryCode: string;
    public checked?: boolean;
    public descLines?: string[];
    public roles?: any;
    public isAssigned?: boolean;
    public applicationId?: number;
    public applicationCode?: string;
}

export class FunctionGroupResponse {
    public functions: FunctionGroup[];
}
