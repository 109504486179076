import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject } from 'rxjs';

import { Filter } from '../classes/filter.class';

let $tableId = 0;

interface WatchSubject {
    id: number;
    activeFilters: Filter[];
    activeFiltersSubject: BehaviorSubject<Filter[]>;
}

@Injectable()
export class WatcherService {
    private watchSubjects: WatchSubject[] = [];

    constructor() { }

    public clearActiveFilters(): void {
        this.watchSubjects.forEach((sub) => {
            sub.activeFiltersSubject.next([]);
        });
    }

    public createSubject(): number {
        $tableId++;
        const $subject: WatchSubject = {
            id: $tableId,
            activeFilters: [],
            activeFiltersSubject: new BehaviorSubject<Filter[]>([]),
        };
        this.watchSubjects.push($subject);
        return $tableId;
    }

    public listenToActiveFilters($id: number): Observable<Filter[]> {
        const $aux = this.watchSubjects.find(
            ($element) => $element.id === $id
        );
        return $aux.activeFiltersSubject.asObservable();
    }

    public emitActiveFilters($id: number, $filtersValue: Filter[]): void {
        const $aux = this.watchSubjects.find(
            ($element) => $element.id === $id
        );
        $filtersValue.forEach(($filter) => {
            const $foundIndex = $aux.activeFilters.findIndex(
                ($element) => $element.name === $filter.name
            );
            if ($foundIndex === -1 && $filter.active === true) {
                // new filter to push
                $aux.activeFilters.push($filter);
            } else if ($foundIndex > -1 && $filter.active === false) {
                // filter was in the array but now has been deactivaded
                $aux.activeFilters.splice($foundIndex, 1);
            }
        });
        $aux.activeFiltersSubject.next($aux.activeFilters);
    }
}
