import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { CmxHttpClient } from "../services/api.service";
import { createHttpParams } from "../shared/helpers";

// INTERFACES
import { AddressesRequest } from "../models/addresses-req-management/addresses-request";
import {
  AddressesResponse,
  IAddressResponse
} from "../models/addresses-req-management/addresses-response";

@Injectable()
export class AddressesService {
  constructor(private http: CmxHttpClient) {}

  getAddresses(payload: AddressesRequest): Observable<AddressesResponse> {
    const httpParams = payload ? createHttpParams(payload) : new HttpParams();
    return this.http.get<AddressesResponse>(`/v6/sm/addresses`, {
      params: httpParams
    });
  }

  getAddress(addressId: string | number): Observable<IAddressResponse> {
    return this.http.get<IAddressResponse>(`/v6/sm/addresses/${addressId}`);
  }
}
