import { Role } from './role';

export class Application {
        public applicationName: string;
        public applicationCode: string;
        public applicationType: string;
        public applicationId: number;
        public roles: Role[];
        public applicationIcon: string;
        public applicationUrl: string;
        public applicationTitle: string;
        public assigned?: boolean;
        public isAssigned?: boolean;
        public isChecked?: boolean;
        public isPushed?: boolean;
        public isVisible?: boolean;
        public assignedRolesString: string;
        public allRolesChecked?: boolean;
        public iconClass?: string;
}
