import { Injectable } from '@angular/core';
import { MenuResponse } from '../models/menu/menu';
import { CmxHttpClient } from '../services/api.service';

// ! Do not remove Observable import.. Needed for package build
// tslint:disable-next-line: no-unused-variable
import { Observable } from 'rxjs';
// tslint:disable-next-line: no-unused-variable
import { ApplicationMenu } from '../models/menu/menu';

@Injectable()
export class MenuService {
  constructor(private http: CmxHttpClient) {}

  public getMenu() {
    const apiPath = '/v1/secm/applications/menu';
    return this.http.get<MenuResponse>(apiPath);
  }
}
