<div class="cmx-dialog__background" (click)="enableClose ? close() : returnFalse()" [ngStyle]="{
    'background-color': backgroundColor}" [class.cmx-dialog__background--show]="showDialog"
  [class.cmx-dialog__background--hide]="!showDialog"></div>

<div class="cmx-dialog__container" [ngStyle]="{
'width': width,
'min-width': minWidth,
'max-width': maxWidth,
'height': height,
'min-height': minHeight,
'max-height': maxHeight}" [class.cmx-dialog__container--show]="showDialog"
  [class.cmx-dialog__container--hide]="!showDialog">

  <button *ngIf="enableClose" cmx-link-icon class="cmx-dialog__container__close-button" data-dismiss-dialog
    srText="Close">
    <span class="cmx-icon-close" aria-hidden="true"></span>
  </button>

  <div class="cmx-dialog__container__header" *ngIf="enableHeader">
    <ng-content select="cmx-dialog-header"></ng-content>
  </div>
  <div class="cmx-dialog__container__body">
    <ng-content style="margin-top: 20px;" select="cmx-dialog-body"></ng-content>
  </div>
  <div class="cmx-dialog__container__footer" *ngIf="enableFooter">
    <ng-content style="width:100%;" select="cmx-dialog-footer"></ng-content>
  </div>
</div>
