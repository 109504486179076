export class Subscription {
    topicCode: string;
    mandatory?: boolean;
    enabled?: boolean;
    channels: Channel[];
    failed?: boolean;
}

class Channel {
    channelCode: string;
    subscription: boolean;
}
