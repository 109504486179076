import { Jobsite } from './jobsite';
import { CustomerDetail } from './customer';
import { Application } from './application';
import { Role } from './role';

export class RequestDetail {
    public applicationList?: Application[];
    public roleList?: Role[];
    public customerList?: CustomerDetail[];
    public jobsiteList?: Jobsite[];
}
