import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CmxHttpClient } from '../services/api.service';
import { createHttpParams } from '../shared/helpers';

// INTERFACES
import { BusinessLine } from '../models/business-lines/business-line';
import { BusinessLineRequest } from '../models/business-lines/business-lines-request';
import { BusinessLineResponse } from '../models/business-lines/business-lines-response';

@Injectable()
export class BusinessLinesService {
  constructor(private http: CmxHttpClient) {}

  getBusinessLines(payload: BusinessLineRequest): Observable<BusinessLineResponse> {
    const httpParams = payload ? createHttpParams(payload) : new HttpParams();
    return this.http.get<BusinessLineResponse>(`/v4/sm/productlines`, {
      params: httpParams,
    });
  }

  getBusinessLine(productLineId: string): Observable<BusinessLine> {
    return this.http.get<BusinessLine>(`/v4/sm/productlines/${productLineId}`);
  }

  getBusinessLineV6(payload: BusinessLineRequest): Observable<BusinessLineResponse> {
    const httpParams = payload ? createHttpParams(payload) : new HttpParams();
    return this.http.get<BusinessLineResponse>(`/v6/sm/productlines`, {
      params: httpParams,
    });
  }
}
