import {
    Component,
    Input,
    OnDestroy,
    AfterContentInit,
    ContentChildren,
    QueryList,
    Renderer2,
    ElementRef,
    HostListener,
    ViewChild,
    ViewContainerRef,
    Optional,
    Inject} from "@angular/core";
import { Subscription } from "rxjs";
import { CmxDropdownItemComponent } from "./../cmx-dropdown-item/";


@Component({
    selector: "cmx-dropdown",
    styleUrls: ["./../../../../../../../scssv4/cmx-components/cmx-dropdown/v4/cmx-dropdown.component.scss"],
    templateUrl: "./cmx-dropdown.component.html",
})
export class CmxDropdownComponent implements AfterContentInit, OnDestroy {
    @ViewChild("itemsPositioner", { read: ViewContainerRef }) public dynamicComponentContainer: ViewContainerRef;
    public _title: string;
    public _placeholder: string;
    public showItems: boolean = false;
    public displayItems: string = "hidden";

    @Input()
    set title( value: string ) {
        if ( value !== undefined ) {
            this._title = value;
        }
    }
    get title(): string {
        return this._title;
    }
    @Input()
    set rtl( value: boolean ) {
        if ( value !== undefined ) {
            this._rtl = value;
        }
    }
    get rtl(): boolean {
        return this._rtl;
    }
    @Input()
    set closeOnClick( value: boolean ) {
        if ( value !== undefined ) {
            this._closeOnClick = value;
        }
    }
    get closeOnClick(): boolean {
        return this._closeOnClick;
    }
    @Input()
    set enableSearch( value: boolean ) {
        if ( value !== undefined ) {
            this._enableSearch = value;
        }
    }
    get enableSearch(): boolean {
        return this._enableSearch;
    }
    @Input()
    set placeholder(value: string) {
        if ( value !== undefined ) {
            this.originalPlaceholder = value;
            this._placeholder = this.truncate(value);
        }
    }
    get placeholder(): string {
        return this._placeholder;
    }
    @Input()
    set maxLength(value: number) {
        if ( value ) {
            this._maxLength = value;
        }
    }
    get maxLength(): number {
        return this._maxLength;
    }
    @Input()
    get disabled(): boolean {
        return this._disabled;
    }
    set disabled(value: boolean) {
        const coerceBoolean: boolean = ( value != undefined && `${value}` !== "false" );
        this._disabled = coerceBoolean;
    }

    @ContentChildren( CmxDropdownItemComponent )
    public dropdownItems: QueryList<CmxDropdownItemComponent>;

    public _enableSearch: boolean = false;
    public viewportHeight: number = 0;
    public searchValue: string = "";

    @ViewChild("itemsPositioner")
    private itemsPositioner: ElementRef;

    private _rtl: boolean = false;
    private _closeOnClick: boolean = false;
    private _maxLength: number;
    private _disabled: boolean;
    private originalPlaceholder: string;
    private changeSubscription: Subscription;
    private childHeight: number = 47;
    private _selectedItem: CmxDropdownItemComponent;

    constructor(
        private renderer: Renderer2,
        @Optional() @Inject("RTL") isRTL: boolean,
    ) {
        if (isRTL != undefined) {
            this.rtl = isRTL;
        }
    }

    public ngAfterContentInit(): void {
        if ( this.placeholder ) {
            this.originalPlaceholder = this.placeholder;
        }
        this.processOptions();
        this.changeSubscription = this.dropdownItems.changes.subscribe( () => {
            this.processOptions();
        });

        let counter: number = 0;
        this.dropdownItems.forEach((item: CmxDropdownItemComponent) => {
            item.selectedid = counter;
            counter++;
        });
    }

    public ngOnDestroy(): void {
        this.changeSubscription.unsubscribe();
    }

    public close( event: any ): void {
        const id: any = event.target.id;
        // this.setselectedItem(id);
        if (event) { event.stopPropagation(); }
        this.showItems = false;
        this.displayItems = "hidden";
        // this.processToggle();
    }

    @HostListener( "click", [ "$event" ] )
    public open( event: any ): void {
        const id: any = event.target.getAttribute("selectedid");
        if ( this.showItems === false ) {
            this.showItems = true;
            // this.processToggle();
            setTimeout(() => {
                this.isInsideViewport();
                this.displayItems = "visible";
            },         100);
        } else {
            if ( event.target.className.indexOf("dropdown-item") !== -1 ) {
                this.setselectedItem(id);
            }
        }
    }

    public filterDropdownItems( searchTerm: string ): void {
        if ( this.dropdownItems.length > 0 && searchTerm.length > 0) {
            this.dropdownItems.forEach( ( dropdownItem: CmxDropdownItemComponent ) => {
                if ( dropdownItem.searchValue !== undefined  &&
                dropdownItem.searchValue.toLowerCase().includes( searchTerm.toLowerCase() ) ) {
                    dropdownItem.hide = false;
                } else {
                    dropdownItem.hide = true;
                }
            });
        } else {
            this.dropdownItems.forEach( ( dropdownItem: CmxDropdownItemComponent ) => {
                dropdownItem.hide = false;
            });
        }
    }

    public checkIfClose( $event: Event ): void {
        if ( this._closeOnClick === true ) {
            this.close( $event );
        }
    }

    // ================================================================================
    // functionality
    // ================================================================================

    private setselectedItem(id: any): void {
        if (this._selectedItem !== undefined && this._selectedItem.selectedid !== Number(id)) {
            this._selectedItem.selected = false;
        }
        this._selectedItem = this.dropdownItems.find((item: CmxDropdownItemComponent) => item.selectedid === Number(id));
    }

    private processOptions(): void {
        if ( this.dropdownItems.length > 0 && this.dropdownItems.length < 4 ) {
            this.viewportHeight = ( this.childHeight * this.dropdownItems.length );
        } else if ( this.dropdownItems.length >= 4 ) {
            this.viewportHeight = ( this.childHeight * 4 );
        }
    }

    private truncate( value: string ): string {
        const trail: string = "...";
        if ( value.length > this._maxLength ) {
            return value.substring( 0, this._maxLength ) + trail;
        } else {
            return value;
        }
    }

    // ================================================================================
    // hTML view interactions
    // ================================================================================

    // function and its calls dislabed due to DCM020CX1117-22 issue "cmx-dropdown removes scrolldown"
    // private processToggle(): void {
    //     if ( !this.disabled && this.dropdownItems && this.dropdownItems.length > 0 ) {
    //         const bodyElement = document.getElementsByTagName('BODY')[0];
    //         if ( this.showItems ) {
    //             this.renderer.setElementStyle( bodyElement, 'overflow', 'hidden' );
    //         } else {
    //             this.renderer.setElementStyle( bodyElement, 'overflow', undefined );
    //         }
    //     }
    // }


    /**
     * @description Checks whether to move the container of the items if it is outside the viewport
     */
    private isInsideViewport(): void {
        const sidePositioner: string = this.rtl ? "right" : "left";
        const sidePositionerAux: string = !this.rtl ? "right" : "left";
        // clearing
        this.renderer.setStyle( this.itemsPositioner.nativeElement, sidePositioner, undefined );
        this.renderer.setStyle( this.itemsPositioner.nativeElement, "top", undefined );
        // calculations
        const rect: ClientRect = this.itemsPositioner.nativeElement.getBoundingClientRect();
        const screenHeight: number = window.innerHeight;
        const screenWidth: number = window.innerWidth;
        const adjustment: number = 0;

        if (this.rtl) {

            if ( rect.left < 0 ) {
                const pxValue: number = 0;
                this.renderer.setStyle(
                    this.itemsPositioner.nativeElement, sidePositionerAux, (-1 * pxValue ) + "px",
                );
            } else if ( rect.right > screenWidth ) {
                const pxValue: number = Math.abs( rect.right ) - screenWidth + adjustment;
                this.renderer.setStyle(
                    this.itemsPositioner.nativeElement, sidePositionerAux, pxValue + "px",
                );
            } else {
                this.renderer.setStyle( this.itemsPositioner.nativeElement, sidePositioner, "0px" );
            }

        } else {
            if ( rect.right > screenWidth ) {
                const pxValue: number = 0;
                this.renderer.setStyle(
                    this.itemsPositioner.nativeElement, sidePositionerAux, pxValue + "px",
                );
            } else if ( rect.left < 0 ) {
                const pxValue: number = Math.abs( rect.left ) + adjustment;
                this.renderer.setStyle(
                    this.itemsPositioner.nativeElement, sidePositionerAux, ( pxValue ) + "px",
                );
            } else {
                this.renderer.setStyle( this.itemsPositioner.nativeElement, sidePositioner, "0px" );
            }
        }

        if ( rect.bottom > screenHeight ) {
            // move to the top
            const pxValue: number = Math.abs( rect.bottom - screenHeight );
            this.renderer.setStyle(
                this.itemsPositioner.nativeElement, "top", ( -1 *  pxValue ) + "px",
            );
        }
    }
}
