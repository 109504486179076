import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CmxHttpClient } from '../services/api.service';
import { createHttpParams } from '../shared/helpers';

// INTERFACES
import { OpportunityBusinessLine } from '../models/opportunity-business-line/opportunity-business-line';
import { OpportunityBusinessLineRequest } from '../models/opportunity-business-line/opportunity-business-line-request';
import { OpportunityBusinessLineResponse } from '../models/opportunity-business-line/opportunity-business-line-response';

@Injectable()
export class OpportunityBusinessLineManagementService {
  constructor(private http: CmxHttpClient) {}

  // ALL OPPORTUNITY BUSINESS LINES AREA MANAGEMENT GET REQUEST
  getOpportunitiesBusinessLines(payload: OpportunityBusinessLineRequest): Observable<OpportunityBusinessLineResponse> {
    const httpParams = payload ? createHttpParams(payload) : new HttpParams();
    return this.http.get<OpportunityBusinessLineResponse>(`/v5/sm/opportunitybusinessline`, { params: httpParams });
  }

  // SINGLE OPPORTUNITY BUSINESS LINE GET REQUEST
  getOpportunityBusinessLines(opportunityBusinessLineId: string): Observable<OpportunityBusinessLineResponse> {
    return this.http.get<OpportunityBusinessLineResponse>(`/v5/sm/opportunitybusinessline/${opportunityBusinessLineId}`);
  }

  // POST BUSINESS LINE
  postOpportunityBusinessLine(): Observable<OpportunityBusinessLine> {
    return this.http.post<OpportunityBusinessLine>(`/v5/sm/opportunitybusinesslines`, {});
  }

  // DELETE OPPORTUNITY BUSINESS LINE
  deleteOpportunityBusinessLine(opportunityBusinessLineId: string) {
    return this.http.delete(`/v5/sm/opportunitybusinesslines/${opportunityBusinessLineId}`);
  }

  // POST OPPORTUNITY BUSINESS LINE QUOTE
  createOpportunityBusinessLineQuote(opportunityBusinessLineId: string) {
    return this.http.post(`/v5/sm/opportunitybusinesslines/${opportunityBusinessLineId}/documents/quotes`, {});
  }

  // PATCH BUSINESS LINE
  patchBusinessLine(opportunityBusinessLineId: string) {
    return this.http.patch(`/v5/sm/opportunitybusinesslines/${opportunityBusinessLineId}/quoted`, {});
  }

  // PATCH BUSINESS LINE APPROVED
  patchBusinessLineApproved(opportunityBusinessLineId: string) {
    return this.http.patch(`/v5/sm/opportunitybusinesslines/${opportunityBusinessLineId}/approved`, {});
  }

  // PATCH BUSINESS LINE REJECTED
  patchBusinessLineRejected(opportunityBusinessLineId: string) {
    return this.http.patch(`/v5/sm/opportunitybusinesslines/${opportunityBusinessLineId}/rejected`, {});
  }

  // PATCH BUSINESS LINE CLOSED
  patchBusinessLineClosed(opportunityBusinessLineId: string) {
    return this.http.patch(`/v5/sm/opportunitybusinesslines/${opportunityBusinessLineId}/closed`, {});
  }

  // PATCH BUSINESS LINE ACCEPTED
  patchBusinessLineAccepted(opportunityBusinessLineId: string) {
    return this.http.patch(`/v5/sm/opportunitybusinesslines/${opportunityBusinessLineId}/accepted`, {});
  }

  // PATCH BUSINESS LINE ASSIGNED
  patchBusinessLineAssigned(opportunityBusinessLineId: string) {
    return this.http.patch(`/v5/sm/opportunitybusinesslines/${opportunityBusinessLineId}/assigned`, {});
  }
}
