import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IHaulersReq, IHaulersRes } from '../models/hauler';
import { CmxHttpClient } from '../services/api.service';
import { createHttpParams } from '../shared/helpers';

@Injectable()
export class HaulersService {
  constructor(private http: CmxHttpClient) {}

  getHaulers(request: IHaulersReq): Observable<IHaulersRes> {
    const httpParams = request ? createHttpParams(request) : new HttpParams();
    return this.http.get<IHaulersRes>(`/v7/dm/haulers`, {
      params: httpParams,
    });
  }
}
