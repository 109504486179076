import { Injectable } from '@angular/core';
import { CmxHttpClient } from './http-client.service';
import { map } from 'rxjs/operators';
import { TranslationService } from '../angular-localization-v7/services/translation.service';
import { SessionService } from '../angular-services-v8/session.service';
import { UserService } from './user.service';
import { DCMConstants } from '../helpers/DCM.constants';

declare const WalkMeAPI: any;

@Injectable()
export class LanguageService {

  private languages = [];

  constructor (
    private cmxHttp: CmxHttpClient,
    private translationService: TranslationService,
    private sessionService: SessionService,
    private userServices: UserService
  ) { }

  private getLanguages() {
    const uri = `v3/im/languages`;
    return this.cmxHttp.get(uri).pipe(map((res) => res['languages'] ? res['languages'] : []));;
  }

  public updateLanguage(language, validateDefaultLanguage = false) {
    if(this.languages.length === 0) {
      this.getLanguages().subscribe((languages: any) => {
        if (languages) {
          this.languages = languages;
          this.changeLanguageBasedOnValidation(validateDefaultLanguage, language);
        }
      });
    }
    else {
      this.changeLanguageBasedOnValidation(validateDefaultLanguage, language);
    }
  }

  private changeLanguageLogin(localLanguage: string) {
    let profileV6 = this.getProfileV6();
    const userLanguage = profileV6.languageId ?
      this.languages.find((lang) => lang.languageId === profileV6.languageId) || null: null;

    const isChangeLanguage = localStorage.getItem('isChangeLanguage');
    if (isChangeLanguage === 'true') {
      if (userLanguage === null || localLanguage !== userLanguage.languageCode) {
        localStorage.removeItem('isChangeLanguage');
        this.saveLanguage(localLanguage);
      }
    } else {
      if (userLanguage !== null && localLanguage !== userLanguage.languageCode) {
        this.setLanguage(userLanguage.languageCode);
      } else if (userLanguage === null) {
        this.saveLanguage(localLanguage);
      }
    }
  }

  private changeLanguage(localLanguage: string) {
      this.saveLanguage(localLanguage);
      this.setLanguage(localLanguage);
  }

  private saveLanguage(language: string) {
    let profileV6 = this.getProfileV6();
    const localLanguage = this.languages.find((lang) => lang.languageCode === language);
    const payload = {
      "creationType": "External",
      "profile": {
        "languageId": localLanguage.languageId
      }
    }

    this.userServices.updateUserV6(profileV6.userId, payload).subscribe((res) => {
      profileV6['languageId'] = localLanguage.languageId;
      let data = this.sessionService.dataSession;
      data.profile = profileV6;
      this.sessionService.updateDataSession(data);
    });
  }

  private setLanguage(languageISO: string) {
    this.translationService.setLanguage(languageISO);
    this.startIntervalWalkmeLanguage(languageISO)
    try {
      const direction = this.translationService.selectedLanguage.rtl ? 'rtl': 'ltr';
      let htmlElement = document.getElementsByTagName('html');
      let bodyElement = document.getElementsByTagName('body')
      htmlElement[0].setAttribute('dir', direction);
      bodyElement[0].setAttribute('dir', direction);
    } catch ($exception) {
      console.error($exception);
    }
  }

  private changeLanguageBasedOnValidation(validateDefaultLanguage: boolean, language: string): void {
    if (validateDefaultLanguage) {
      this.changeLanguageLogin(language);
    } else {
      this.changeLanguage(language);
    }
  }

  private startIntervalWalkmeLanguage(languageISO) {
    const intervalId = setInterval(() => {
      if ((window as any).WalkMeAPI) {
        clearInterval(intervalId);
        languageISO = languageISO.replace('_', '-');
        if(DCMConstants.WALKME_LANGUAGE_EN.indexOf(languageISO) !== -1){
          languageISO = 'en-US';
        }
        else if (DCMConstants.WALKME_LANGUAGE_ES.indexOf(languageISO) !== -1){
          languageISO = 'es-MX';
        }
        WalkMeAPI.changeLanguage(languageISO);
      }
    }, 500);
  }

  private getProfileV6() {
    return this.sessionService.profile;
  }

}
