import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ShippingConditionsRequest } from "../models/shipping-conditions/shipping-conditions-request";
import { ShippingConditionsResponse } from "../models/shipping-conditions/shipping-conditions-response";
import { CmxHttpClient } from "../services/api.service";
//import { cacheMethod } from "../shared/cache-method";
import { createHttpParams } from "../shared/helpers";

@Injectable()
export class ShippingConditionsService {
  constructor(private http: CmxHttpClient) {}

  // @cacheMethod()
  getShippingConditionByProductLine(
    payload: ShippingConditionsRequest
  ): Observable<ShippingConditionsResponse> {
    const httpParams = payload ? createHttpParams(payload) : new HttpParams();
    return this.http.get<ShippingConditionsResponse>(
      `/v7/dm/shippingconditions`,
      {
        params: httpParams,
      }
    );
  }

  getShippingConditionsV1(
    payload: ShippingConditionsRequest
  ): Observable<ShippingConditionsResponse> {
    const httpParams = payload ? createHttpParams(payload) : new HttpParams();
    return this.http.get<ShippingConditionsResponse>(
      "/v1/shm/shippings/conditions",
      {
        params: httpParams,
      }
    );
  }
}
