import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CmxHttpClient } from '../services/api.service';
import { createHttpParams } from '../shared/helpers';
import { GetSuggestionsRequest } from '../models/analytics';
import { GetSuggestionsResponse } from '../models/analytics/suggestions-response';

@Injectable()
export class AnalyticsService {
  constructor(private http: CmxHttpClient) {}

  getSuggestions(payload?: GetSuggestionsRequest): Observable<GetSuggestionsResponse> {
    const params = payload ? createHttpParams(payload) : new HttpParams();
    return this.http.get(`/v2/amm/suggestions`, { params });
  }
}
