import { Inject, Injectable, OnDestroy } from '@angular/core';
import * as Rox from 'rox-browser';
import { Observable } from 'rxjs';
import { interval } from 'rxjs';
import { merge } from 'rxjs';
import { ReplaySubject } from 'rxjs';
import { Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import {
  Feature,
  FeatureTogglePropertyType,
  FeatureTogglePropertyTypeDefinition,
  FeatureToggleType,
  IConnectionConfig,
  IFeatureToggleConfig,
  IFeatureToggleProperties,
  IFeatureTogglePropertyDefinition,
  IParametrizedFeature,
  IUpdateInfo,
  UpdateStatus,
} from './feature-toggle-types';

type FeatureToggle = Rox.Flag | Rox.Variant | Rox.Configuration<boolean | number | string>;

type NamedFeatureToggle = [string, FeatureToggle];

interface IFeatureToggles {
  [key: string]: FeatureToggle;
}

@Injectable()
export class FeatureToggleService implements OnDestroy {
  /**
   * This observable says, whether the feature toggle rules are load from the server or not.
   *
   * @returns {Observable<boolean>}
   */
  get stateUpdateInfo$(): Observable<IUpdateInfo> {
    return this.stateUpdateInfoSubject$
      .asObservable()
      .pipe(takeUntil(this.destroyed$));
  }

  /**
   * Emits whenever the state is updated (it contains new configuration for feature toggles)
   * @returns {Observable<void>}
   */
  get stateUpdated$(): Observable<void> {
    return this.stateUpdateInfo$.pipe(
      filter((item) => item.status === UpdateStatus.CACHE || item.hasChanges),
      map((item) => {
        return;
      })
    );
  }

  /**
   * Emits once the boolean value if the configuration is loaded (regardless on
   * whether the configuration is loaded from server or from localStorage).
   * @returns {Observable<boolean>}
   */
  get isLoaded$(): Observable<boolean> {
    return merge(
      this.stateUpdateError$.pipe(map((error) => false)),
      this.stateUpdateInfo$.pipe(map((info) => true))
    ).pipe(take(1));
  }

  /**
   * Emits whenever the loading error from server occurs
   *
   * @returns {Observable<Error>}
   */
  get stateUpdateError$(): Observable<Error> {
    return this.stateUpdateErrorSubject$
      .asObservable()
      .pipe(takeUntil(this.destroyed$));
  }
  /**
   * Used with takeUntil for observables
   * @type {Subject<any>}
   */
  private destroyed$: Subject<void> = new Subject();
  /**
   * Emits info about if the info is local or from server and if it contains new changes
   * @type {ReplaySubject<IUpdateInfo>}
   */
  private stateUpdateInfoSubject$: Subject<IUpdateInfo> =
    new ReplaySubject<IUpdateInfo>(1);
  /**
   * Emits if there is an error during fetching from server
   * @type {ReplaySubject<Error>}
   */
  private stateUpdateErrorSubject$: Subject<Error> = new ReplaySubject<Error>(
    1
  );
  /**
   * Configuration of feature toggles provided by the application.
   * It contains the basic information, which is then used to create feature toggles in the service
   * and for validation and type checking.
   */
  private featureToggleConfig: IFeatureToggleConfig;
  /**
   * Contains Rollout.io flags, variants and configurations. This is provided in the setup stage.
   */
  private featureToggles: IFeatureToggles;

  /**
   * It accepts the information about connection to the service and configuration for feature toggles.
   *
   * @param {IConnectionConfig} connectionConfig
   * @param {IFeatureToggleConfig} featuresConfig
   */
  constructor(
    @Inject('connectionConfig') connectionConfig: IConnectionConfig,
    @Inject('featuresConfig') featuresConfig: IFeatureToggleConfig
  ) {
    this.setConfiguration(featuresConfig);
    if (connectionConfig.appName !== 'test') {
      this.connect(connectionConfig, this.featureToggles);
    }
  }

  /**
   * solution to check, whether configuration is available
   * @param results
   */
  public fetchedHandler = (result): void => {
    if (result.fetcherStatus === 'ERROR_FETCH_FAILED') {
      this.stateUpdateErrorSubject$.next(result.errorDetails);
    } else {
      const status =
        result.fetcherStatus === 'APPLIED_FROM_CACHE'
          ? UpdateStatus.CACHE
          : UpdateStatus.REMOTE;
      if (result.hasChanges || status === UpdateStatus.CACHE) {
        this.unfreezeAll();
      }
      this.stateUpdateInfoSubject$.next({
        hasChanges: result.hasChanges,
        status,
      });
    }
  };

  /**
   * Set user information, which is used in the server to configure user dependent feature toggles.
   * This should be called after the login. Then isEnabledFor method will be called with properties for logged user
   *
   * @param {IFeatureToggleProperties} userProperties
   */
  public setUserProperties(userProperties: IFeatureToggleProperties) {
    this.unfreezeUserFeatures();
    if (this.featureToggleConfig.userProperties) {
      this.validateProperties(
        userProperties,
        this.featureToggleConfig.userProperties
      );
    }
    this.setProperties(userProperties);
  }

  /**
   * Check if the feature is available for the user
   *
   * @param {string} featureName
   */
  public isEnabled(featureName: string): boolean {
    this.validate(featureName, FeatureToggleType.Flag, true);
    return (this.featureToggles[featureName] as Rox.Flag).isEnabled();
  }

  /**
   * Check if the feature is available for defined configuration of properties.
   * @param {string} featureName
   * @param {IFeatureToggleProperties} properties
   */
  public isEnabledFor(
    featureName: string,
    properties: IFeatureToggleProperties
  ): boolean {
    this.validate(featureName, FeatureToggleType.Flag, false, properties);
    this.unfreezeFeature(featureName);
    this.setProperties(properties);
    return (this.featureToggles[featureName] as Rox.Flag).isEnabled();
  }

  /**
   * Return the configuration value based on user configuration
   * @param {string} featureName variant or configuration
   */
  public getVariant(featureName: string): string {
    this.validate(featureName, FeatureToggleType.Variant, true);
    return (this.featureToggles[featureName] as Rox.Variant).getValue();
  }

  /**
   *
   * @param {string} featureName
   * @param {FeatureToggleProperty[]} properties
   */
  public getVariantFor(
    featureName: string,
    properties: IFeatureToggleProperties
  ) {
    this.validate(featureName, FeatureToggleType.Variant, false, properties);
    this.unfreezeFeature(featureName);
    this.setProperties(properties);
    return (this.featureToggles[featureName] as Rox.Configuration<boolean | number | string>).getValue();
  }

  /**
   * Return the configuration value based on user configuration
   * @param {string} featureName variant or configuration
   */
  public getConfiguration(featureName: string): boolean | number | string {
    this.validate(featureName, FeatureToggleType.Configuration, false);
    return (this.featureToggles[featureName] as Rox.Configuration<boolean | number | string>).getValue();
  }

  public getFullConfiguration() {
    return this.featureToggleConfig;
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public setNew(
    connectionConfig: IConnectionConfig,
    featureConfig: IFeatureToggleConfig
  ) {
    this.setConfiguration(featureConfig);
    if (connectionConfig.appName !== 'test') {
      this.connect(connectionConfig, this.featureToggles);
    }
  }
  /**
   * Connect to the server and if the configuration contains the syncInterval, register for
   * fetching of update from server repeatedly
   *
   * @param {IConnectionConfig} config
   * @param {IFeatureToggles} features
   */
  private connect(config: IConnectionConfig, features: IFeatureToggles) {
    Rox.register(config.appName, features);
    Rox.setup(
      typeof config.appKey === 'function' ? config.appKey() : config.appKey,
      {
        configurationFetchedHandler: this.fetchedHandler,
        version: config.appVersion,
        // freeze: 'none',
      }
    );
    if (config.syncInterval) {
      this.initializeUpdatesFetching(config.syncInterval);
    }
  }

  /**
   * It registers for fetching of update from server repeatedly
   *
   * @param {number} fetchInterval
   */
  private initializeUpdatesFetching(fetchInterval: number) {
    interval(fetchInterval)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        Rox.fetch();
      });
  }

  /**
   * Unfreeze user features. It should be called when user properties are updated.
   */
  private unfreezeUserFeatures() {
    this.featureToggleConfig.features
      .filter((featureConfig) => featureConfig.isUserFeature)
      .forEach((featureConfig) => {
        this.unfreezeFeature(featureConfig.name);
      });
  }

  /**
   * Unfreeze one feature. It should be called when the properties for the feature are updated.
   * @param {string} featureName
   */
  private unfreezeFeature(featureName: string) {
    this.featureToggles[featureName].unfreeze();
  }

  /**
   * Unfreeze all features. It should be called, when new configuration is loaded.
   */
  private unfreezeAll() {
    Object.keys(this.featureToggles).forEach((featureName) => {
      this.featureToggles[featureName].unfreeze();
    });
  }

  /**
   * Set properties depending on the value types.
   * @param {IFeatureToggleProperties} properties
   */
  private setProperties(properties: IFeatureToggleProperties) {
    Object.keys(properties).forEach((propertyName) => {
      this.setProperty(propertyName, properties[propertyName]);
    });
  }

  /**
   * Set property depending on the value type. It can be boolean, number or string.
   * @param {string} name
   * @param {FeatureTogglePropertyType} value
   */
  private setProperty(name: string, value: any) {
    switch (typeof value) {
      case FeatureTogglePropertyTypeDefinition.Boolean:
        Rox.setCustomBooleanProperty(name, value);
        break;
      case FeatureTogglePropertyTypeDefinition.Number:
        Rox.setCustomNumberProperty(name, value);
        break;
      case FeatureTogglePropertyTypeDefinition.String:
        Rox.setCustomStringProperty(name, value);
        break;
    }
  }

  /**
   * This is called when feature toggle is set. There could be any json parser, that provides those properties.
   * @param {Feature[]} features
   * @param {IFeatureTogglePropertyDefinition[]|null} userProperties
   */
  private setConfiguration(featureToggleConfig: IFeatureToggleConfig) {
    this.featureToggleConfig = featureToggleConfig;
    this.featureToggles = this.createFeatureToggles(featureToggleConfig);
  }

  /**
   * Create Rox features object from features configuration, which is then registered with Rox.
   * @param {IFeatureToggleConfig} featureToggleConfig
   * @returns {IFeatureToggles}
   */
  private createFeatureToggles(
    featureToggleConfig: IFeatureToggleConfig
  ): IFeatureToggles {
    return featureToggleConfig.features
      .map((feature) => this.createRoxFeature(feature))
      .reduce((roxFeatures, roxFeature: NamedFeatureToggle) => {
        roxFeatures[roxFeature[0]] = roxFeature[1];
        return roxFeatures;
      }, {});
  }

  /**
   * Factory method for Rollout.io feature toggle objects.
   * @param {Feature} feature
   * @returns {FeatureToggle}
   */
  private createRoxFeature(feature: Feature): NamedFeatureToggle {
    switch (feature.type) {
      case FeatureToggleType.Flag:
        return [feature.name, new Rox.Flag(feature.defaultValue)];
      case FeatureToggleType.Variant:
        return [
          feature.name,
          new Rox.Variant(feature.defaultValue, feature.variants),
        ];
      case FeatureToggleType.Configuration:
        return [feature.name, new Rox.Configuration(feature.defaultValue)];
      default:
        throw new Error(
          `Feature toggle ${feature.name} has unsupported type ${feature.type}`
        );
    }
  }

  /* VALIDATORS */

  private validate(
    featureName: string,
    featureType: FeatureToggleType,
    calledForUser: boolean,
    properties?: IFeatureToggleProperties
  ) {
    const featureConfig = this.featureToggleConfig.features.find(
      (feature) => feature.name === featureName
    );
    if (!featureConfig) {
      throw new Error(`Feature name ${featureName} is not defined`);
    }
    this.validateIsForUser(featureConfig, calledForUser);
    if (featureConfig.type !== featureType) {
      throw new Error(
        `Feature is not of type ${featureType}. It is of type ${featureConfig.type}`
      );
    }
    if (properties && (featureConfig as IParametrizedFeature).properties) {
      this.validateProperties(
        properties,
        (featureConfig as IParametrizedFeature)
          .properties as IFeatureTogglePropertyDefinition[]
      );
    }
  }

  private validateProperties(
    properties: IFeatureToggleProperties,
    propDefinitions: IFeatureTogglePropertyDefinition[],
    featureName?: string
  ) {
    propDefinitions.forEach((propertyDef) => {
      const propertyName = Object.keys(properties).find(
        (name) => name === propertyDef.name
      );
      if (!propertyName) {
        throw new Error(
          featureName
            ? `Missing property ${propertyDef.name} for feature ${featureName}`
            : `Missing user property ${propertyDef.name}`
        );
      }
      if (typeof properties[propertyName] !== propertyDef.type) {
        throw new Error(
          `Property ${propertyDef.name}${
            featureName ? 'for the feature ' + featureName : ''
          }` +
            ` must be of type ${propertyDef.type}. ${typeof properties[
              propertyName
            ]} given.`
        );
      }
    });
  }

  private validateIsForUser(featureConfig: Feature, calledForUser: boolean) {
    if (featureConfig.isUserFeature && !calledForUser) {
      throw new Error(
        `Feature ${featureConfig.name} is set as user feature but not called so.`
      );
    } else if (!featureConfig.isUserFeature && calledForUser) {
      throw new Error(`Feature ${featureConfig.name} should be checked with parameters,
            but is called as user feature.`);
    }
  }
}
