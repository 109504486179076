<ul class="pagination" [dir]="isRtl ? 'rtl':''">
    <ng-container *ngIf="currentPage !== 1">
        <li class="pagination-prev">
            <a (click)="previousPage()">
                <ng-container *ngIf="isRtl === false">
                    <svg id="icon-straight-arrow-left" viewBox="0 0 6 10" width="100%" height="100%">
                        <title>icon-straight-arrow-left</title>
                        <polygon class="icon-straight-arrow-left" points="4.86 0 6 1.18 2.29 5 6 8.83 4.86 10 0 5 4.86 0"></polygon>
                    </svg>
                </ng-container>
                <ng-container *ngIf="isRtl === true">
                    <svg id="icon-straight-arrow-right" viewBox="0 0 6 10" width="100%" height="100%">
                        <title>icon-straight-arrow-right</title>
                        <polygon class="icon-straight-arrow-right" points="1.14 0 0 1.18 3.71 5 0 8.83 1.14 10 6 5 1.14 0"></polygon>
                    </svg>
                </ng-container>
            </a>
        </li>
    </ng-container>
    <ng-container *ngIf="showFirstPageShortcut()">
        <li>
            <a (click)="setPage(1)">
                1
            </a>
        </li>
        <li>
            ...
        </li>
    </ng-container>
    <ng-container *ngIf="state === 1">
        <ng-container *ngFor="let $page of beginning;">
            <li>
                <a [class.current]="$page === currentPage" (click)="setPage($page)">
                    {{ $page }}
                </a>
            </li>    
        </ng-container>
    </ng-container>
    <ng-container *ngIf="state === 2">
        <li>
            <a (click)="setPage(currentPage - 1)">
                {{ currentPage - 1 }}
            </a>
        </li>
        <li>
            <a class="current" (click)="setPage(currentPage)">
                {{ currentPage }}
            </a>
        </li>
        <li>
            <a (click)="setPage(currentPage + 1)">
                {{ currentPage + 1 }}
            </a>
        </li>   
    </ng-container>
    <ng-container *ngIf="state === 3">
        <ng-container *ngFor="let $page of end;">
            <li>
                <a [class.current]="$page === currentPage" (click)="setPage($page)">
                    {{ $page }}
                </a>
            </li>      
        </ng-container>
    </ng-container>
    <ng-container *ngIf="showLastPageShortcut()">
        <li>
            ...
        </li>
        <li>
            <a (click)="setPage(pages)">
                {{ pages }}
            </a>
        </li>
    </ng-container>
    <ng-container *ngIf="currentPage !== pages">
        <li class="pagination-next">
            <a (click)="nextPage()">
                <ng-container *ngIf="isRtl === false">
                    <svg id="icon-straight-arrow-right" viewBox="0 0 6 10" width="100%" height="100%">
                        <title>icon-straight-arrow-right</title>
                        <polygon class="icon-straight-arrow-right" points="1.14 0 0 1.18 3.71 5 0 8.83 1.14 10 6 5 1.14 0"></polygon>
                    </svg>
                </ng-container>
                <ng-container *ngIf="isRtl === true">
                    <svg id="icon-straight-arrow-left" viewBox="0 0 6 10" width="100%" height="100%">
                        <title>icon-straight-arrow-left</title>
                        <polygon class="icon-straight-arrow-left" points="4.86 0 6 1.18 2.29 5 6 8.83 4.86 10 0 5 4.86 0"></polygon>
                    </svg>
                </ng-container>
            </a>
        </li>
    </ng-container>
</ul>