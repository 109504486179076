import {
    Input,
    Component,
    Directive,
    ComponentFactoryResolver,
    ViewContainerRef,
    ComponentRef,
    AfterContentChecked,
    AfterViewInit,
    ElementRef,
    Renderer2,
    Optional,
    Inject,
} from '@angular/core';

@Component({
    selector: '<cmx-tooltip></cmx-tooltip>',
    styleUrls: [ './../../../../../../scssv4/cmx-components/cmx-tooltip/v4/cmx-tooltip.component.scss'],
    templateUrl: './cmx-tooltip.component.html',
})
export class CmxTooltipComponent implements AfterViewInit {
    private static readonly spacingUp: number = 10;
    private static readonly spacingDown: number = 15;

    @Input('rtl') public rtl: boolean;
    @Input('diffYLeft') public diffYLeft: number;
    @Input('diffYRight') public diffYRight: number;
    // tslint:disable-next-line:no-input-rename
    @Input('tooltipMaxWidth') public maxWidth: number = undefined;

    public tooltipPlacementClass: string;

    private _options: any;

    private _tooltipClasses: any = {
        'cmx-tooltip--down': false,
        'cmx-tooltip--left': false,
        'cmx-tooltip--right': false,
        'cmx-tooltip--up': false,
        'multiline': false,
    };

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
        @Optional() @Inject('RTL') isRTL: boolean,
    ) {
        if (isRTL != undefined) {
            this.rtl = isRTL;
        }
    }

    public ngAfterViewInit(): void {
        this.positionElement();
    }

    public ngAfterContentInit(): void {
        this._tooltipClasses.multiline = !!this.maxWidth;
    }

    set options( op: any ) {
        this._options = op;
    }

    get options(): any {
        return this._options;
    }

    get tooltipClass(): {[c: string]: boolean} {
        return this._tooltipClasses;
    }

    get tooltipStyle(): {[c: string]: number} {
        return !this.maxWidth ? {} : {'max-width.px': this.maxWidth};
    }

    private positionElement(): void {
        const tooltipElement: any = this.elementRef.nativeElement.querySelector('div.cmx-tooltip');

        const tooltipHeight: number = tooltipElement.offsetHeight;
        const tooltipWidth: number = tooltipElement.offsetWidth;

        const parentWidth: any = this._options.width;
        const parentHeight: any = this._options.height;

        const tooltipOffsetLeftCenter: any = this._options.x + (tooltipWidth / 2);
        const tooltipOffsetTopCenter: any = this._options.y + (tooltipHeight / 2);
        const parentOffsetLeftCenter: any = this._options.x + (this._options.width / 2);
        const parentOffsetTopCenter: any = this._options.y + (this._options.height / 2);

        const differenceX: any = Math.abs(tooltipOffsetLeftCenter - parentOffsetLeftCenter);
        let differenceY: any = Math.abs(tooltipOffsetTopCenter - parentOffsetTopCenter);

        switch (this._options.position) {
            case 'up':
                this._options.y = this._options.y + this._options.height + CmxTooltipComponent.spacingUp;

                if (tooltipWidth > parentWidth) {
                    this._options.x = this._options.x - differenceX;
                } else if (tooltipWidth < parentWidth) {
                    this._options.x = this._options.x + differenceX;
                }

                this.tooltipPlacementClass = 'cmx-tooltip--up';
                this._tooltipClasses['cmx-tooltip--up'] = true;
                break;

            case 'left':
                this._options.x = this._options.x + this._options.width + 10;

                if (tooltipHeight > parentHeight) {
                    differenceY = this.diffYLeft ? this.diffYLeft : differenceY;
                    this._options.y = this._options.y - differenceY;
                } else if (tooltipHeight < parentHeight) {
                    this._options.y = this._options.y + differenceY;
                }

                this.tooltipPlacementClass = 'cmx-tooltip--left';
                this._tooltipClasses['cmx-tooltip--left'] = true;
                break;

            case 'right':
                this._options.x = this._options.x - tooltipWidth - 10;

                if (tooltipHeight > parentHeight) {
                    differenceY = this.diffYRight ? this.diffYRight : differenceY;
                    this._options.y = this._options.y - differenceY;
                } else if (tooltipHeight < parentHeight) {
                    this._options.y = this._options.y + differenceY;
                }

                this.tooltipPlacementClass = 'cmx-tooltip--right';
                this._tooltipClasses['cmx-tooltip--right'] = true;
                break;

            // case "down": is not necessary - default behavior is the same as defined for position "down"
            default:
                this._options.y = this._options.y - tooltipHeight - CmxTooltipComponent.spacingDown;

                if (tooltipWidth > parentWidth) {
                    this._options.x = this._options.x - differenceX;
                } else if (tooltipWidth < parentWidth) {
                    this._options.x = this._options.x + differenceX;
                }

                this.tooltipPlacementClass = 'cmx-tooltip--down';
                this._tooltipClasses['cmx-tooltip--down'] = true;
                break;
        }

        this.setStyleValues();

    }

    private setStyleValues(): void {
        this.renderer.setStyle(this.elementRef.nativeElement, 'display', 'block');
        this.renderer.setStyle(this.elementRef.nativeElement, 'position', 'fixed');
        this.renderer.setStyle(this.elementRef.nativeElement, 'z-index', '999');
        this.renderer.setStyle(this.elementRef.nativeElement, 'top', this._options.y + 'px');
        this.renderer.setStyle(this.elementRef.nativeElement, 'left', this._options.x + 'px');
    }
}
