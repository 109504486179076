import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Order } from '../models/order-management';
import { CmxHttpClient } from '../services/api.service';
import { PricesPatchResponse } from '../models/prices/prices-patch-res';

@Injectable()
export class PricesService {
  constructor(private http: CmxHttpClient) {}

  patchPricesByOrderId(orderId: number): Observable<Order> {
    return this.http.patch<Order>(`/v6/sm/prices/ORD/${orderId}`, {});
  }

  patchPrices(orderId: number): Observable<PricesPatchResponse> {
    return this.http.patch<PricesPatchResponse>(`/v2/prc/prices/ORQ/${orderId}`, null);
  }

  patchPricesV3(orderId: number): Observable<PricesPatchResponse> {
    return this.http.patch<PricesPatchResponse>(`/v3/prc/prices/ORQ/${orderId}`, null);
  }
}
