import { Directive, HostBinding } from '@angular/core';

@Directive({
    selector: 'cmx-cell, [cmx-cell]',
})
export class CmxCellDirective {
    @HostBinding( 'class.cmx-cell' ) public cmxCellClass = true;

    constructor() { }
}
