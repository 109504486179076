import { Directive } from '@angular/core';

@Directive({
    selector: 'error-message',
})
export class ErrorMessageDirective {
    constructor() {
        //
    }
}
