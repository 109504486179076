import { JobsiteOption, Load } from './types';

export const UNIT_OF_MEASUREMENT_FOR_INDICATORS = 'M³';

export const JOBSITES: JobsiteOption[] = [
  {
    id: '1',
    name: 'South Central Mall'
  }
];

export const LOADS: Load[] = [
  {
    orderId: '29193429',
    orderCode: '3033',
    load: '1/7',
    volume: '10 / 67 Yd3',
    purchaseOrder: 'AT CO 103',
    instructionsDesc: 'FIFTH ST & MARINA VILLAGE PKWY,ALAMEDA,CA,94501. FIFTH ST & MARINA VILLAGE PKWY,ALAMEDA,CA,94501. MARINA VILLAGE PKWY,ALAMEDA,CA,94501. MARINA VILLAGE PKWY,ALAMEDA,CA,94501. MARINA VILLAGE PKWY,ALAMEDA,CA,94501.',
    products: [
      {
        productDesc: '4P 470 C+S 30% WR',
        value: 10,
        unitDesc: 'Yd3'
      },
      {
        productDesc: '4P 470 C+S 30% WR',
        value: 10,
        unitDesc: 'Yd3'
      }
    ]
  },
  {
    orderId: '29193429',
    orderCode: '3033',
    load: '1/7',
    volume: '10 / 67 Yd3',
    products: [
      {
        productDesc: '4P 470 C+S 30% WR',
        value: 10,
        unitDesc: 'Yd3'
      }
    ]
  },
  {
    orderId: '29193429',
    orderCode: '3033',
    load: '1/7',
    volume: '10 / 67 Yd3',
    products: [
      {
        productDesc: '4P 470 C+S 30% WR',
        value: 10,
        unitDesc: 'Yd3'
      }
    ]
  },
  {
    orderId: '29193429',
    orderCode: '3033',
    load: '1/7',
    volume: '10 / 67 Yd3',
    products: [
      {
        productDesc: '4P 470 C+S 30% WR',
        value: 10,
        unitDesc: 'Yd3'
      }
    ]
  },
  {
    orderId: '29193429',
    orderCode: '3033',
    load: '1/7',
    volume: '10 / 67 Yd3',
    products: [
      {
        productDesc: '4P 470 C+S 30% WR',
        value: 10,
        unitDesc: 'Yd3'
      }
    ]
  },
  {
    orderId: '29193429',
    orderCode: '3033',
    load: '1/7',
    volume: '10 / 67 Yd3',
    products: [
      {
        productDesc: '4P 470 C+S 30% WR',
        value: 10,
        unitDesc: 'Yd3'
      }
    ]
  },
  {
    orderId: '29193429',
    orderCode: '3033',
    load: '1/7',
    volume: '10 / 67 Yd3',
    products: [
      {
        productDesc: '4P 470 C+S 30% WR',
        value: 10,
        unitDesc: 'Yd3'
      }
    ]
  }
];
