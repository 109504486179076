import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { LoadingStatus } from '../cmx-dashboard-active-loads-card/cmx-dashboard-active-loads-card.service';
import { Status } from './types';

@Injectable()
export abstract class ListChartCardService {
  /**
   * @var statuses$ subject for data collection of cwc-statuses
   */
  public statuses$ = new BehaviorSubject([]);

  /**
   * @var loadingStatus$ loading status
   */
  public loadingStatus$ = new BehaviorSubject(LoadingStatus.IDLE);

  /**
   * @var recentOrders$ subject for data for recent orders
   */
  public recentOrders$ = new BehaviorSubject([]);

  /**
   * Statuses List to show without API request
   * @param statuses list of statuses to show
   */
  abstract initStatusesData(statuses: Status[]): void;

  /**
   * API fetching method
   * @param options options passed to API fetching method
   */
  abstract fetchNewQuery(options: Record<string, unknown>): Subscription;

  /**
   * To be used in derived classes to set loading status new value
   * @param value new loading status value
   */
  protected setLoadingStatus(value: LoadingStatus) {
    this.loadingStatus$.next(value);
  }
}
