import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CmxHttpClient } from '../services/api.service';
import { ActionLogsResponse } from '../models/action-logs';

@Injectable()
export class ActionLogsService {
  constructor(private http: CmxHttpClient) {}

  getOrderActionLogs(orderId: number): Observable<ActionLogsResponse> {
    return this.http.get<ActionLogsResponse>(`/v4/rc/logs/ORQ/${orderId}`);
  }
}
