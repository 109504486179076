import {
    Injectable,
    ComponentFactoryResolver,
    ViewContainerRef,
    ComponentFactory,
    ComponentRef,
} from '@angular/core';

import { CmxDialogComponent, IDialogOptions } from './../components/cmx-dialog/cmx-dialog.component';
import { CmxDialogConfirmationComponent } from '../components/cmx-dialog-confirmation/cmx-dialog-confirmation.component';
import { ConfirmationType, IConfirmationDialogOptions } from '../components/cmx-dialog-confirmation/cmx-dialog-confirmation.component';

@Injectable({
    providedIn: 'root',
})
export class CmxDialogGeneratorService {

    private dialogDelay = 100;
    private defaultSuccessMessage = 'This is a dynamically generated confirmation success dialog';
    private defaultWarningMessage = 'This is a dynamically generated confirmation warning dialog';
    private defaultErrorMessage = 'This is a dynamically generated confirmation error dialog';
    private defaultErrorMessageDetail = 'This is a message detail';

    public createConfirmationDialog(
        viewContainerRef: ViewContainerRef,
        componentFactoryResolver: ComponentFactoryResolver,
        options: IConfirmationDialogOptions): CmxDialogConfirmationComponent {
        // tslint:disable-next-line:max-line-length
        const componentFactory: ComponentFactory<CmxDialogConfirmationComponent> = componentFactoryResolver.resolveComponentFactory(CmxDialogConfirmationComponent);
        const componentRef: ComponentRef<CmxDialogConfirmationComponent> = viewContainerRef.createComponent(componentFactory);

        // tslint:disable-next-line:typedef
        const component = (componentRef.instance as CmxDialogConfirmationComponent);
        this.setDialogSubscriptions(options, component.dialog);
        this.openConfirmationDialog(options, component);
        return component;
    }

    /* experimental */
    public createCustomDialog(
        viewContainerRef: ViewContainerRef,
        componentFactoryResolver: ComponentFactoryResolver,
        componentTemplate: CmxDialogComponent,
        options: IDialogOptions): CmxDialogComponent {

        const componentFactory: ComponentFactory<CmxDialogComponent> =
            componentFactoryResolver.resolveComponentFactory(CmxDialogComponent);
        const componentRef: ComponentRef<CmxDialogComponent> = viewContainerRef.createComponent(componentFactory);
        // tslint:disable-next-line:typedef
        const component = (componentRef.instance as CmxDialogComponent);

        if (!(componentTemplate instanceof CmxDialogComponent)) {
            return component;
        }
        this.setDialogComponent(component, componentTemplate);
        this.setDialogComponentTimer(options, component);
        return component;
    }

    private setDialogSubscriptions(options: any, component: any): void {
        if (options.afterClosed) {
            component.afterClosed().subscribe(options.afterClosed());
        }
        if (options.afterOpened) {
            component.afterOpened().subscribe(options.afterOpened());
        }
    }

    private openConfirmationDialog(options: any, component: any): void {
        if (options.type === ConfirmationType.Success) {
            component.openAsSuccess(options.message ? options.message : this.defaultSuccessMessage);
        } else if (options.type === ConfirmationType.Warning) {
            component.openAsWarning(options.message ? options.message : this.defaultWarningMessage);
        } else if (options.type === ConfirmationType.Error) {
            component.openAsError(options.message ? options.message : this.defaultErrorMessage,
                options.messageDetail ? options.messageDetail : this.defaultErrorMessageDetail);
        }
    }

    private setDialogComponent(component: any, componentTemplate: any): void {
        component.enableHeader = componentTemplate.enableHeader;
        component.enableFooter = componentTemplate.enableFooter;
        component.enableClose = componentTemplate.enableClose;
        component.backgroundColor = componentTemplate.backgroundColor;
        component.rtl = componentTemplate.rtl;
        component.dirAttr = componentTemplate.dirAttr;

        console.log(component);

        if (component.enableHeader) {
            setTimeout(() => {
                component.dialogHeader.nativeElement.innerHTML = componentTemplate.dialogHeader.nativeElement.innerHTML;
            }, this.dialogDelay);
        }
        if (component.enableFooter) {
            setTimeout(() => {
                component.dialogFooter.nativeElement.innerHTML = componentTemplate.dialogFooter.nativeElement.innerHTML;
            }, this.dialogDelay);
        }

        setTimeout(() => {
            component.dialogBody.nativeElement.innerHTML = componentTemplate.dialogBody.nativeElement.innerHTML;
        }, this.dialogDelay);

    }

    private setDialogComponentTimer(options: any, component: any): void {
        setTimeout(() => {
            this.setDialogSubscriptions(options, component);
            component.open();
        }, this.dialogDelay);
    }
}
