import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { CmxButtonModule } from "../../../../../../cmx-button/v4/src/cmx-button.module";
import { CmxDialogComponent } from "./components/cmx-dialog/cmx-dialog.component";
import { CmxDialogConfirmationComponent } from "./components/cmx-dialog-confirmation/cmx-dialog-confirmation.component";
import { CmxDialogHeaderComponent } from "./components/cmx-dialog-header/cmx-dialog-header.component";
import { CmxDialogBodyComponent } from "./components/cmx-dialog-body/cmx-dialog-body.component";
import { CmxDialogFooterComponent } from "./components/cmx-dialog-footer/cmx-dialog-footer.component";
import { DataDismissDialogDirective } from "./components/cmx-dialog/cmx-dialog.component";

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, CmxButtonModule],
  exports: [
    CmxDialogComponent,
    CmxDialogConfirmationComponent,
    CmxDialogHeaderComponent,
    CmxDialogBodyComponent,
    CmxDialogFooterComponent,
    DataDismissDialogDirective,
  ],
  declarations: [
    CmxDialogComponent,
    CmxDialogConfirmationComponent,
    CmxDialogHeaderComponent,
    CmxDialogBodyComponent,
    CmxDialogFooterComponent,
    DataDismissDialogDirective,
  ],
})
export class CmxDialogModule {}
