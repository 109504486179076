import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
// import { HttpCemex } from '@cemex-core/angular-services-v7';
import { catchError, map } from 'rxjs/operators';
import { ICopyrightResponse } from '../interfaces/copyright.dto';
import { CmxHttpClient } from '../../../../../../../services/http-client.service';

@Injectable()
export class CopyrightService {
  constructor(private httpClient: CmxHttpClient) {}

  public getCopyright(): Observable<ICopyrightResponse> {
    const apiUrl: string = `v5/legal/copyrights`;
    return this.httpClient.get<ICopyrightResponse>(apiUrl)
      .pipe(
        map(response => response ?? { DocumentLanguages: [] }),
        catchError(error => this.handleError(error))
      );
    }

  private handleError(error: Response | any): Observable<any> {
    return throwError(error);
  }
}
