import { FunctionGroup } from './functionGroup';
export class CustomerProfile {
    public customerProfileId: number;
    public customerProfileDesc: string;
    public functions: FunctionGroup[];
}

export class CustomerProfileResponse {
    public customerProfiles: CustomerProfile[];
}
