// tslint:disable:trailing-comma
import { IOrderDeleted } from './order-delet';
import { OrderStatusEnum } from './order-status.enum';
import { OrderType } from './order-type';
import { OrderTypeCodesEnum } from './order-type-codes.enum';

// Request entity for Order update.
export class UpdateOrderPatch implements IOrderDeleted {
  status: {
    statusCode: OrderStatusEnum
    inEdit: boolean
  };
  orderType: {
    orderTypeCode: OrderTypeCodesEnum
  };
}

export class UpdateOrderPatchResponse {
  id: number;
  loggableModification: boolean;
  orderType: OrderType;
  sendEmailNotification: boolean;
  success: boolean;
  IfMatch?: string;
  updatedAt?: string;
}
