import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// INTERFACES
import { AddressesRequest } from '../models/addresses-req-management/addresses-request';
import { SalesOfficeContactData, SalesOfficeData } from '../models/sales-office';
import { CmxHttpClient } from '../services/api.service';
import { createHttpParams } from '../shared/helpers';


@Injectable()
export class SalesOfficeService {
  constructor(private http: CmxHttpClient) {}

  // SALES OFFICE GET REQUEST
  getSalesOffice(payload: AddressesRequest): Observable<SalesOfficeData> {
    const httpParams = payload ? createHttpParams(payload) : new HttpParams();
    return this.http.get<SalesOfficeData>(`/v5/sm/salesoffice`, { params: httpParams });
  }

  // SALES OFFICE CONTACTS POST REQUEST
  salesOfficeContacts(salesOfficeContactData: SalesOfficeContactData) {
    return this.http.post(`/v5/sm/salesofficecontacts`, salesOfficeContactData);
  }
}
