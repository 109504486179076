import { Observable, range } from 'rxjs';
import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';

import { CmxFlugeeService } from './services/flugee.service';
import { map, filter, toArray } from 'rxjs/operators';

@Component({
  selector: 'cmx-pagination',
  templateUrl: './cmx-pagination.component.html',
  styleUrls: ['./cmx-pagination.component.scss'],
})
export class CmxPaginationComponent implements OnInit, OnChanges {
  @Input() public currentPage = 1;
  @Input() public totalPages = 0;
  @Input() public range = 2;
  @Input() public rtl = false;
  @Output() public changePage: EventEmitter<number> = new EventEmitter<number>();

  public pages: Observable<number[]>;
  public dirState: string;

  constructor(private cmxFlugeeService: CmxFlugeeService) {}

  public ngOnChanges() {
    this.getPages();
  }

  public getPages() {
    const currentPage = Number(this.currentPage);
    const totalPages = Number(this.totalPages);
    const cRange = Number(this.range);

    this.pages = range(-cRange, cRange * 2 + 1).pipe(
      map(offset => currentPage + offset),
      filter(pageNumber => pageNumber > 0 && pageNumber <= totalPages),
      toArray(),
    );

    if (this.rtl) {
      this.pages = this.pages.pipe(map(page => page.reverse()));
    }
  }

  public setPage(page: number) {
    if (this.totalPages >= page) {
      this.changePage.emit(page);
    }
  }

  public ngOnInit() {
    // GET PAGES
    this.getPages();

    // DIR STATE SUBSCRIBER
    this.dirState = 'ltr';
    this.cmxFlugeeService.dirObservable.subscribe(data => {
      this.dirState = data;
    });
  }
}
