import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment_ from 'moment';
import { ListChartCardService } from '../list-chart-card/list-chart-card.service';
import {
  EnvironmentConstants,
  PathConstants,
  OrderStatusCode,
  RecentOrder
} from '../shared/types';
import { Status } from '../list-chart-card';
import { LoadingStatus } from '../cmx-dashboard-active-loads-card/cmx-dashboard-active-loads-card.service';
const moment = moment_;

@Injectable()
export class CmxDashboardOrderHistoryCardService extends ListChartCardService {
  constructor(private api: HttpClient) {
    super();
  }

  public initStatusesData(statuses: Status[]) {
    this.setStatuses(statuses);
  }

  public fetchNewQuery(options: Record<string, string>) {
    let { customerId } = options;
    let from = removeTimeZone(
      moment(options['from']).format('YYYY-MM-DD T00:00:00Z')
    );
    const to = removeTimeZone(
      moment(options['to']).format('YYYY-MM-DD T22:59:59Z')
    );
    
    // compare difference, because max number of days for API is 90
    const timeDifference = new Date(to).getTime() - new Date(from).getTime()
    if ((timeDifference / 86400000) > 90) {
      from = removeTimeZone(moment(new Date(to)).subtract(89, "days").format('YYYY-MM-DD T00:00:00Z'))
    }
    
    this.setLoadingStatus(LoadingStatus.PENDING);
    return this.fetchStatusesSource(from, to, customerId).subscribe(
      result => {
        this.updateData(result);
        this.setLoadingStatus(LoadingStatus.RESOLVED);
      },
      error => {
        console.error('error in cmx-dashboard-components: ', error);
        this.setLoadingStatus(LoadingStatus.ERROR);
      }
    );
  }

  private updateData(orders) {
    const statuses = getDefaultStatuses();
    const recentOrders: RecentOrder[] = [];

    if (orders.orders) {
      // We need to filter out orders with status 'Draft', these should not be shown to customer
      const ordersWithoutDraftStatus = orders.orders.filter(order => order.status.statusCode !== OrderStatusCode.Draft);
      orders.orders = ordersWithoutDraftStatus;
      for (let i = 0; i < orders.orders.length; i++) {
        const order = orders.orders[i];
        const status = statuses.find(
          item => item.code === order.status.statusCode
        );
        if (!status) {
          console.error(`This order doesn't match with any status:`, order);
          continue;
        }
        status.name = order.status.statusDesc;
        status.value++;
  
        // recent orders
        if (i < 4) {
          const statusInfo = getDefaultStatuses().find(item => item.code === order.status.statusCode)
          recentOrders.push({
            statusColor: statusInfo.color,
            statusOutline: statusInfo.outline,
            statusDesc: order.status.statusDesc,
            orderCode: order.orderCode,
            jobsiteCode: order.jobsite.jobsiteCode,
            jobsiteDesc: order.jobsite.jobsiteDesc,
            totalQuantity: order.totalQuantity,
            unitCode: order.unit?.unitCode,
            params: {
              orderId: order.orderId ? order.orderId : null,
              orderCode: order.orderCode && order.orderCode.length > 0 ? order.orderCode : null,
              orderType: order.orderType.orderTypeCode ? order.orderType.orderTypeCode : null,
              businessLine: order.businessLine.businessLineCode ? order.businessLine.businessLineCode : null,
              country: order.countryCode ? order.countryCode.trim() : null,
              orderHdrId: order.orderHdrId ? order.orderHdrId : null,
              orderRequestId: order.orderRequestId ? order.orderRequestId : order.orderId,
            }
          })
        }
      }
    }

    // check if statuses array doesn't have items with same name
    const mergedStatuses: Status[] = Object.values(statuses.reduce((acc, { code, chartColor, color, name, value, outline }) => {
      if (!acc[name]) {
        acc[name] = { chartColor, color, name, value, outline };
      } else {
        acc[name].value += value;
      }
      delete acc[name].code; // Delete the 'code' property, because it is not needed anymore
      return acc;
    }, {}));
    // Sort by the color
    mergedStatuses.sort((a, b) => b.color.localeCompare(a.color));

    this.setStatuses(mergedStatuses);
    this.setRecentOrders(recentOrders)
  }

  private setStatuses(statuses: Status[]) {
    this.statuses$.next(statuses);
  }

  private setRecentOrders(orders: RecentOrder[]) {
    this.recentOrders$.next(orders)
  }

  private fetchStatusesSource(from: string, to: string, customerId: string) {
    return this.api.get<any>(`${EnvironmentConstants.getWorkingPath() + PathConstants.ORDERS_ALL}?page=1&ignoreDateRangeLimit=true&fetch=3000&dateTimeCreateFrom=${from}&dateTimeCreateTo=${to}&CustomerId=${customerId}&include=additionalInformation%2CorderHdrId`)
  }
}

function removeTimeZone(date: string) {
  const value = moment(date, 'YYYY-MM-DD THH:mm:ssZ')
    .locale('en')
    .utc()
    .format('YYYY-MM-DDTHH:mm:ss');
  return value;
}

function getDefaultStatuses(): Status[] {
  return [
    {
      code: OrderStatusCode.Draft,
      chartColor: 'disabled-grey',
      color: 'disabled',
      name: 'Draft',
      value: 0
    },
    {
      code: OrderStatusCode.Pending,
      chartColor: 'bright-orange',
      color: 'warning',
      name: 'Pending',
      value: 0
    },
    {
      code: OrderStatusCode.Confirmed,
      chartColor: 'bright-green',
      color: 'success',
      name: 'Confirmed',
      value: 0,
      outline: true
    },
    {
      code: OrderStatusCode.InEdit,
      chartColor: 'bright-blue',
      color: 'info',
      name: 'In Edit',
      value: 0
    },
    {
      code: OrderStatusCode.InProgress,
      chartColor: 'bright-blue',
      color: 'info',
      name: 'In Progress',
      value: 0
    },
    {
      code: OrderStatusCode.Delivered,
      chartColor: 'bright-green',
      color: 'success',
      name: 'Delivered',
      value: 0
    },
    {
      code: OrderStatusCode.Blocked,
      chartColor: 'true-red',
      color: 'alert',
      name: 'Blocked',
      value: 0
    },
    {
      code: OrderStatusCode.OnHold,
      chartColor: 'bright-orange',
      color: 'warning',
      name: 'On Hold',
      value: 0
    },
    {
      code: OrderStatusCode.Cancelled,
      chartColor: 'true-red',
      color: 'alert',
      name: 'Canceled',
      value: 0
    },
    {
      code: OrderStatusCode.DeletedDraft,
      chartColor: 'disabled-grey',
      color: 'disabled',
      name: 'Deleted Draft',
      value: 0
    },
    {
      code: OrderStatusCode.NeedConfirmation,
      chartColor: 'bright-orange',
      color: 'warning',
      name: 'Need Confirmation',
      value: 0
    },
    {
      code: OrderStatusCode.PendingCancelation,
      chartColor: 'bright-orange',
      color: 'warning',
      name: 'Pending Cancelation',
      value: 0
    },
    {
      code: OrderStatusCode.PendingModification,
      chartColor: 'bright-orange',
      color: 'warning',
      name: 'Pending Modification',
      value: 0
    },
    {
      code: OrderStatusCode.TimeConfirmation,
      chartColor: 'bright-orange',
      color: 'warning',
      name: 'Time Confirmation',
      value: 0
    },
    {
      code: OrderStatusCode.TimeNegotiation,
      chartColor: 'bright-blue',
      color: 'info',
      name: 'Time Negotiation',
      value: 0
    },
    {
      code: OrderStatusCode.CompletedOnline,
      chartColor: 'bright-green',
      color: 'success',
      name: 'Completed',
      value: 0
    },
    {
      code: OrderStatusCode.CancelledOnline,
      chartColor: 'true-red',
      color: 'alert',
      name: 'Canceled',
      value: 0
    },
    {
      code: OrderStatusCode.NotStartedOnline,
      chartColor: 'disabled-grey',
      color: 'disabled',
      name: 'Not Started',
      value: 0
    },
    {
      code: OrderStatusCode.OnHoldOnline,
      chartColor: 'bright-orange',
      color: 'warning',
      name: 'On Hold',
      value: 0
    },
    {
      code: OrderStatusCode.BlockedOnline,
      chartColor: 'true-red',
      color: 'alert',
      name: 'Blocked',
      value: 0
    },
    {
      code: OrderStatusCode.InProgressOnline,
      chartColor: 'bright-blue',
      color: 'info',
      name: 'In Progress',
      value: 0
    },
    {
      code: OrderStatusCode.ToBeConfirmedOnline,
      chartColor: 'bright-orange',
      color: 'warning',
      name: 'To Be Confirmed',
      value: 0
    },
    {
      code: OrderStatusCode.InReview,
      chartColor: 'bright-orange',
      color: 'warning',
      name: 'In Review',
      value: 0
    },
    {
      code: OrderStatusCode.ConfirmedOnline,
      chartColor: 'bright-green',
      color: 'success',
      name: 'Confirmed',
      value: 0,
      outline: true
    },
    {
      code: OrderStatusCode.NewOnHold,
      chartColor: 'bright-orange',
      color: 'warning',
      name: 'On Hold',
      value: 0,
      outline: false
    }
  ];
}
