import { Component } from '@angular/core';

// Providers
import { TranslationService } from 'src/app/angular-localization-v7/services/translation.service';

@Component({
    selector: 'localization',
    templateUrl: 'localization.component.html',
    styleUrls: []
})

export class LocalizationComponent {

    private static _translationService: TranslationService = null;

    public get isRTL(): boolean {
        return this.cmxTranslation.selectedLanguage.rtl;
    }

    public get dir(): string {
        return this.cmxTranslation.selectedLanguage.dir;
    }

    protected get cmxTranslation(): TranslationService {
        if (!LocalizationComponent._translationService) {
            console.error('The TranslationService is null.');
        }
        return LocalizationComponent._translationService;
    }

    protected set cmxTranslation(translationService: TranslationService) {
        LocalizationComponent._translationService = translationService;
    }

    public get designVersion(): string {
      return 'v2';
    }
}
