<div class="cmx-number-field-wrapper" [dir]="rtl ? 'rtl' : 'ltr'">
  <!-- appCurrencyMask [min]="min" [max]="max" [thousandSeparator]="thousandSeparator" [decimalSeparator]="decimalSeparator" [decimalNumber]="decimalNumber" -->
  <!-- <input id="myauto" class="cmx-form-control cmx-number-control" (focus)="touched()" [(ngModel)]="value"  (keydown)="onKeyDown($event)" [attr.disabled]="disabled" [disabled]="disabled" type="text" /> -->
  <ng-autonumeric #autoElement [align]="align" [attr.disabled]="disabled" [disabled]="disabled" (ngModel)="value"
    [options]="defaultConfig" [placeholder]="placeholder">
  </ng-autonumeric>
  <ng-container *ngIf="!hideControls">
    <span class="cmx-number-field-btn cmx-minus-btn" [ngClass]="{'ripple': !disabled}">
      <button type="button" class="cmx-number-field-btn__btn btn--minus" (click)="decreaseValue()"
        [disabled]="disabled">
        <span class="cmx-icon-minus" aria-hidden="true"></span>
        <span class="sr-only">Decrease</span>
      </button>
    </span>
    <span class="cmx-number-field-btn cmx-plus-btn" [ngClass]="{'ripple': !disabled}">
      <button type="button" class="cmx-number-field-btn__btn btn--plus" (click)="increaseValue()" [disabled]="disabled">
        <span class="cmx-icon-plus" aria-hidden="true"></span>
        <span class="sr-only">Increase</span>
      </button>
    </span>
  </ng-container>
</div>