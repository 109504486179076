<div class="container-fluid">
  <div class="row cmx-jumbotron">
    <div class="offset-md-1 col-md-8">
      <h1>{{ translationService.pt('message.404_error') }}</h1>
      <h2>{{ translationService.pt('message.page_not_found') }}</h2>
      <p>{{ translationService.pt('message.page_not_exists') }}</p>
      <div class="pull-end">
        <a class="cmx-button" href="/">{{ translationService.pt('message.btn_back') }}</a>
      </div>
    </div>
  </div>
</div>
