import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class LocationService {

    constructor(@Inject(DOCUMENT) private document: any) { }

    /**
     * Redirects using location href
     * @param destination
     */
    public redirect(destination: string) {
        if (destination) {
            this.document.location.href = destination;
        }
    }
}
