export enum SHIPMENT_LOCATION_TYPE_ID {
  CUSTOMER = 1,
  JOBSITE = 2,
  POINT_OF_DELIVERY = 3,
  OPPORTUNITY = 4,
  JOBSITE_REQUEST = 5,
  POINT_OF_DELIVERY_REQUEST = 6,
  PLANT = 7,
}

export type ShipmentLocationTypeIdType =
  | SHIPMENT_LOCATION_TYPE_ID.CUSTOMER
  | SHIPMENT_LOCATION_TYPE_ID.JOBSITE
  | SHIPMENT_LOCATION_TYPE_ID.POINT_OF_DELIVERY
  | SHIPMENT_LOCATION_TYPE_ID.OPPORTUNITY
  | SHIPMENT_LOCATION_TYPE_ID.JOBSITE_REQUEST
  | SHIPMENT_LOCATION_TYPE_ID.POINT_OF_DELIVERY_REQUEST
  | SHIPMENT_LOCATION_TYPE_ID.PLANT;
