import {
  NgModule,
  NO_ERRORS_SCHEMA,
  CUSTOM_ELEMENTS_SCHEMA,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { NgAutonumericComponent } from './autonumeric.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
  ],
  exports: [
    NgAutonumericComponent,
  ],
  declarations: [
    NgAutonumericComponent,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA,
  ],
})
export class CurrencyMaskModule { }
