<div class ="cmx-dropdown__wrapper" [ngClass]="{ 'cmx-dropdown__rtl': !!rtl }">
    <label for="text-field" *ngIf="_title">{{ _title }}</label>
    <div class="cmx-dropdown__placeholder">
        <span>
            {{ _placeholder }}
        </span>
        <svg id="chevron" fill="#3FA9F5" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 7.5">
            <title>down-single</title>
            <path d="M15.83.26a.71.71,0,0,0-1-.09L8,5.86,1.17.17A.71.71,0,0,0,.26,1.26L7.54,7.33a.71.71,0,0,0,.91,0l7.29-6.08A.71.71,0,0,0,15.83.26Z"
                transform="translate(0 0)" />
        </svg>
    </div>
    <div #itemsPositioner class="cmx-dropdown__items-positioner" *ngIf="showItems" [style.visibility]="displayItems">
        <ng-container *ngIf="_enableSearch">
            <div class="filtering-box">
                <div class="filter-search">
                    <span>
                        <svg id="icon-magnifier-glass" viewBox="0 0 25.68 25.68" width="100%" height="100%">
                            <title>magnifier-glass</title>
                            <path class="magnifier-glass" d="M25.49,23.37l-6.2-6.2A10.58,10.58,0,0,0,3.34,3.34,10.58,10.58,0,0,0,17.17,19.29l6.2,6.2a1.5,1.5,0,0,0,2.12-2.12Zm-20-7.18a7.59,7.59,0,1,1,10.73,0A7.6,7.6,0,0,1,5.46,16.19Z" transform="translate(-0.24 -0.24)"></path>
                        </svg>
                        <!-- end .icon-magnifier-glass -->
                    </span>
                    <input type="search" [value]="searchValue" [placeholder]="_placeholder" (input)="filterDropdownItems( searchValue = $any($event.target).value )"/>
                </div>
                <!-- end .filter-search -->
            </div>
        </ng-container>
        <div class="dropdown-items" [style.height]="viewportHeight + 'px'" (click)="checkIfClose( $event )">
            <ng-content></ng-content>
        </div>
    </div>
    <div #optionsBackdrop class="cmx-dropdown__mask" (click)="close( $event )" *ngIf="showItems"></div>
</div>