import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmxPaginationComponent } from './cmx-pagination.component';

import { CmxFlugeeService } from './services/flugee.service';

@NgModule({
  imports: [CommonModule],
  providers: [CmxFlugeeService],
  declarations: [CmxPaginationComponent],
  exports: [CmxPaginationComponent],
})
export class CmxPaginationModule {}
