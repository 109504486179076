<ul class="pagination" dir="{{ dirState }}">
  <ng-container [ngSwitch]="!!rtl">
    <ng-template [ngSwitchCase]="false">
      <li *ngIf="currentPage !== 1" class="pagination__item pagination__item--control">
        <a (click)="setPage(currentPage - 1)" class="pagination__item-elem">
          <i class="cmx-icon-straight-arrow-left" *ngIf="dirState == 'ltr' || dirState == ''"></i>
          <i class="cmx-icon-straight-arrow-right" *ngIf="dirState == 'rtl'"></i>
        </a>
      </li>
      <li *ngIf="currentPage > range + 1" class="pagination__item pagination__item--control">
        <a (click)="setPage(1)" class="pagination__item-elem">
          1
        </a>
      </li>
      <li *ngIf="currentPage > range + 2" class="pagination__item pagination__item--control">
        <a class="pagination__item-elem">
          ...
        </a>
      </li>
    </ng-template>
    <ng-template [ngSwitchCase]="true">
      <li *ngIf="totalPages !== currentPage" class="pagination__item pagination__item--control">
        <a (click)="setPage(currentPage + 1)" class="pagination__item-elem">
          <i class="cmx-icon-straight-arrow-left" *ngIf="dirState == 'ltr' || dirState == ''"></i>
          <i class="cmx-icon-straight-arrow-right" *ngIf="dirState == 'rtl'"></i>
        </a>
      </li>
      <li *ngIf="currentPage < totalPages - range" class="pagination__item pagination__item--control">
        <a (click)="setPage(totalPages)" class="pagination__item-elem">
          {{ totalPages }}
        </a>
      </li>
      <li *ngIf="currentPage < totalPages - range - 1" class="pagination__item pagination__item--control">
        <a class="pagination__item-elem">
          ...
        </a>
      </li>
    </ng-template>
  </ng-container>
  <li *ngFor="let page of pages | async" class="pagination__item">
    <a
      [class.pagination__item-elem--active]="page === currentPage"
      (click)="setPage(page)"
      class="pagination__item-elem"
    >
      {{ page }}
    </a>
  </li>
  <ng-container [ngSwitch]="!!rtl">
    <ng-template [ngSwitchCase]="false">
      <li *ngIf="currentPage < totalPages - range - 1" class="pagination__item pagination__item--control">
        <a class="pagination__item-elem">
          ...
        </a>
      </li>
      <li *ngIf="currentPage < totalPages - range" class="pagination__item pagination__item--control">
        <a (click)="setPage(totalPages)" class="pagination__item-elem">
          {{ totalPages }}
        </a>
      </li>
      <li *ngIf="totalPages !== currentPage" class="pagination__item pagination__item--control">
        <a (click)="setPage(currentPage + 1)" class="pagination__item-elem">
          <i class="cmx-icon-straight-arrow-right" *ngIf="dirState == 'ltr' || dirState == ''"></i>
          <i class="cmx-icon-straight-arrow-left" *ngIf="dirState == 'rtl'"></i>
        </a>
      </li>
    </ng-template>
    <ng-template [ngSwitchCase]="true">
      <li *ngIf="currentPage > range + 2" class="pagination__item pagination__item--control">
        <a class="pagination__item-elem">
          ...
        </a>
      </li>
      <li *ngIf="currentPage > range + 1" class="pagination__item pagination__item--control">
        <a (click)="setPage(1)" class="pagination__item-elem">
          1
        </a>
      </li>
      <li *ngIf="currentPage !== 1" class="pagination__item pagination__item--control">
        <a (click)="setPage(currentPage - 1)" class="pagination__item-elem">
          <i class="cmx-icon-straight-arrow-right" *ngIf="dirState == 'ltr' || dirState == ''"></i>
          <i class="cmx-icon-straight-arrow-left" *ngIf="dirState == 'rtl'"></i>
        </a>
      </li>
    </ng-template>
  </ng-container>
</ul>
