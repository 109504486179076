import { Injectable } from '@angular/core';

@Injectable()
export class Logger {
  public static log(value: any): void {
    console.log(value);
  }
  public static error(value: any): void {
    console.error(value);
  }
  public static warn(value: any): void {
    console.warn(value);
  }
  public static logf(value: any): void {
    console.log(value);
  }
}
