import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { CmxDatepickerComponent } from "./components/cmx-datepicker/cmx-datepicker.component";
import { MonthSelectorComponent } from "./components/month-selector/month-selector.component";
import { YearSelectorComponent } from "./components/year-selector/year-selector.component";
import { DateNamesService } from "./services/date-names.service";
import { CmxButtonModule } from "src/app/cmx-button/v4/src/cmx-button.module";

@NgModule({
  declarations: [
    CmxDatepickerComponent,
    MonthSelectorComponent,
    YearSelectorComponent,
  ],
  exports: [
    CmxDatepickerComponent,
    MonthSelectorComponent,
    YearSelectorComponent,
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, CmxButtonModule],
  providers: [DateNamesService],
})
export class CmxDatepickerModule {}
