import * as _ from 'lodash';
import { appsContainer, ApplicationCode } from './../../spa';

export class SpaFunctions {
  
  public registerGlobal(
    
    applicationCode: ApplicationCode,
    path: string[],

    value = ''
  ): void {
    const publics = window as any;

    console.log(publics);
    _.set(window as any, [appsContainer, applicationCode, ...path], value);
  }

  public extractGlobal(applicationCode: ApplicationCode, path: string[]): any {
    return _.get(window as any, [appsContainer, applicationCode, ...path]);
  }
}
