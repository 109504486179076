import { Pipe, PipeTransform } from '@angular/core';

import { FormatterService } from './../services/formatter.service';
import { TranslationService } from './../services/translation.service';

@Pipe({
  name: 'cmxNumber',
  pure: false
})
export class CmxNumberPipe implements PipeTransform {
  constructor(
    private translationService: TranslationService,
    private formatterService: FormatterService
  ) {}

  public transform(numberEntry) {
    return this.formatterService.formatNumberTS(
      numberEntry,
      this.translationService.selectedLanguage.languageISO.replace('_', '-'),
      this.translationService.selectedLanguage.decimalNumbers
    );
  }
}
