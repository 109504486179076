import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/';
import { SessionService } from '../angular-services-v8/session.service';
import { Logger } from '../angular-services-v8/logger.service';
import { Profile, CustomerDetail, LegalEntity } from '../models';
import { Application } from '../models/application';
import { User } from '../models/user';

class LegalEntityChange {
    changed: boolean;
    value: number;
}

@Injectable()
export class StorageService {

    public legalEntityChanged$ = new BehaviorSubject<LegalEntityChange>(null);

    constructor(private sessionService: SessionService) { }

    public getFromLocalStorage(key: string): string {
        return localStorage.getItem(key);
    }

    public getFromSessionStorage(key: string): string {
        return sessionStorage.getItem(key);
    }

    public get selectedLegalEntity(): LegalEntity {
      const legalEntityInStorage = sessionStorage.getItem('user_legal_entity');
      if(legalEntityInStorage && legalEntityInStorage !== 'undefined' && legalEntityInStorage !== 'null') {
        try {
          return JSON.parse(legalEntityInStorage);
        } catch (error) {
          console.log('Error parsing legal entity from storage', error);
          return undefined;
        }
      }
      else {
        return undefined;
      }
    }

    public get userId(): number {
        const profileJson = this.sessionService.profile ? this.sessionService.profile as Profile : undefined;
        return profileJson ? profileJson.userId : undefined;
    }

    public get userApplications(): Application[] {
        return this.sessionService.applicationsList || [];
    }

    public get defaultCustomer(): CustomerDetail {
        const customer = this.sessionService.customerDetail;
        let customerJson: CustomerDetail;
        try {
            customerJson = customer;
        } catch (error) {
            Logger.log('User does not have default customer');
        }
        return customerJson;
    }

    public get userCountry(): string {
        const country = this.sessionService.country || undefined;
        return country;
    }


    getUserListPermissions(): User[] {
        const usersStorage = sessionStorage.getItem('users_edit');
        const usersEdit = usersStorage ? JSON.parse(usersStorage) as User[] : [];
        return usersEdit;
    }

    setUserListPermissions(users: User[]) {
        sessionStorage.setItem('users_edit', JSON.stringify(users));
    }

    clearUserListPermissions() {
        sessionStorage.removeItem('users_edit');
    }
}
