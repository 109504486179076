import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetOrderServicesResponse } from '../models/pumping-service/get-order-services.response';
import { PumpingServiceRequest } from '../models/pumping-service/pumping-service-request';
import { PumpingServiceResponse } from '../models/pumping-service/pumping-service-response';
import { CmxHttpClient } from '../services/api.service';
import { createHttpParams } from '../shared/helpers';
import { PatchOrderServicesResponse } from '../models/pumping-service/patch-order-services-response';
import { PatchOrderServicesRequest } from '../models/pumping-service/patch-order-services-request';

@Injectable()
export class PumpingServiceService {
  constructor(private http: CmxHttpClient) {}

  getPumpingServices(payload: PumpingServiceRequest): Observable<PumpingServiceResponse> {
    const httpParams = payload ? createHttpParams(payload) : new HttpParams();
    return this.http.get<PumpingServiceResponse>(`/v7/sm/services/pumping`, { params: httpParams });
  }

  getOrderServices(orderId: string): Observable<GetOrderServicesResponse> {
    return this.http.get<GetOrderServicesResponse>(`/v7/sm/orders/${orderId}/services`);
  }

  patchOrderServices(draftId: string, payload: PatchOrderServicesRequest): Observable<PatchOrderServicesResponse> {
    return this.http.patch(`/v7/sm/orders/${draftId}/services`, payload);
  }

  removeOrderServices(orderId: number | string): Observable<null> {
    return this.http.delete(`/v7/sm/orders/${orderId}/services`);
  }
}
