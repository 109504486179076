import { Injectable } from '@angular/core';

declare var global: any;

@Injectable()
export class ProjectSettings {
  private _appCode: string;
  private _clientId: string;
  private _apiBasePath: string;
  private _apiOrg: string;
  private _apiEnv: string;

  constructor() {
    const APP_KEYS = {
      API_ENV_KEY: 'API_ENV',
      API_HOST_KEY: 'API_HOST',
      API_ORG_KEY: 'API_ORG',
      APP_CODE_KEY: 'APP_CODE',
      CLIENT_ID_KEY: 'CLIENT_ID'
    };

    this._appCode = (global as any)[APP_KEYS.APP_CODE_KEY];
    this._clientId = (global as any)[APP_KEYS.CLIENT_ID_KEY];
    this._apiBasePath = (global as any)[APP_KEYS.API_HOST_KEY];
    this._apiOrg = (global as any)[APP_KEYS.API_ORG_KEY];
    this._apiEnv = (global as any)[APP_KEYS.API_ENV_KEY];

    if (this._apiBasePath === undefined) {
      console.warn('environment variable \'API_HOST\' is missing !!');
    }
  }

  // ============================================================================================
  // Getters
  // ============================================================================================

  public get appCode(): string {
    return this._appCode;
  }

  public get clientId(): string {
    return this._clientId;
  }

  public get apiBasePath(): string {
    return this._apiBasePath;
  }

  public get apiOrg(): string {
    return this._apiOrg;
  }

  public get apiEnv(): string {
    return this._apiEnv;
  }

  public generateEndpoint(apiEndpoint: string): string {
    const url = `${this._apiBasePath}${this._apiOrg}${
      this._apiEnv
    }${apiEndpoint}`;
    return url;
  }

  public getBaseOrgEnvPath(): string {
    return this._apiBasePath + this._apiOrg + this._apiEnv;
  }

  public setAppKeys(newKeys: any): void {
    this._appCode = newKeys.APP_CODE;
    this._clientId = newKeys.CLIENT_ID;

    if (this._apiBasePath === undefined) {
      console.warn('environment variable \'API_HOST\' is missing !!');
    }
  }
}
