import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { ListChartCardService } from '../list-chart-card';
import { CmxDashboardOrderHistoryCardService } from './cmx-dashboard-order-history-card.service';
import { Status } from '../list-chart-card';

@Component({
  selector: 'cmx-dashboard-order-history-card',
  templateUrl: './cmx-dashboard-order-history-card.component.html',
  styleUrls: ['./cmx-dashboard-order-history-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: ListChartCardService,
      useClass: CmxDashboardOrderHistoryCardService
    }
  ]
})
export class CmxDashboardOrderHistoryCardComponent implements OnInit, OnChanges {
  @Input()
  customerId: string;
  @Input()
  title: string;
  @Input()
  dateRanges: Array<{
    label: string;
    from: string; // ISO String
    to: string;
    id: string;
  }>;

  @Input()
  statusesList: Status[];
  @Input()
  chartTotalLabel = 'Total';
  @Input()
  statusListItemOrdersLabel = 'Orders';
  /**
   * @var emptyListText label to show in the empty result message
   */
  @Input()
  emptyListText;
  
  /**
   * @var datePlaceholder placeholder for Date range selection
   */
  @Input()
  datePlaceholder: string;

  /**
   * @var extended render also loads progress bar
   */
  @Input()
  extended: boolean;

  /**
   * @var pickerLabelCancel label for cancel button in picker for mobile view
   */
  @Input()
  pickerLabelCancel: string;

  /**
   * @var recentOrdersLabels labels for extended version {title, deliveredLoads, pendingLoads}
   */
  @Input()
  recentOrdersLabels: {
    title: string,
    viewAll: string,
    order: string
  };

  public queryOptions: Record<string, string | number>;
  ngOnInit() {
    if (this.customerId) {
      this.queryOptions = {
        customerId: this.customerId
      };
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('customerId' in changes) {
      const {
        customerId: { currentValue: customerId }
      } = changes;
      if (customerId) {
        this.queryOptions = {
          customerId: this.customerId
        };
      }
    }
  }
}
